// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Types
import Can from 'ember-can/services/can';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Account from 'airthings/services/airthings/account';
import Recordings from 'airthings/services/airthings/recordings';
import Apollo from 'airthings/services/apollo';
import {RecordingsUnitSystem} from 'airthings/types/recordings';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import {RecordingsDetailedDatasetByExternalIdResponse} from 'airthings/graphql/queries/recordings-detailed-dataset-by-external-id';
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import IntlService from 'ember-intl/services/intl';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

interface RouteParams {
  externalId: string;
}

interface RouteModel {
  currentUser: AccountCurrentUserProfileResponse;
  dataset: RecordingsDetailedDatasetByExternalIdResponse;
  externalId: string;
  unitSystem: RecordingsUnitSystem;
}

export default class AppDatasetsShowRoute extends Route {
  @service('intl')
  intl: IntlService;

  @service('fastboot')
  fastboot: FastBoot;

  @service('airthings/account')
  account: Account;

  @service('can')
  can: Can;

  @service('router')
  router: RouterService;

  @service('airthings/recordings')
  recordings: Recordings;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @queryManager
  queryManager: Apollo;

  beforeModel(transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    if (this.can.cannot('show datasets', null, {currentUser})) {
      transition.abort();

      this.router.replaceWith('app');
    }

    hideIntercom();
  }

  async model(params: RouteParams): Promise<RouteModel | null> {
    // NOTE: We had problems with large datasets loaded in memory with FastBoot so we
    //       delegate it to the browser.
    if (this.fastboot.isFastBoot) return null;

    const currentUser = await this.account.watchCurrentUser(this.queryManager);

    const unitSystem = currentUser.viewer.profile.preferredUnitSystem;

    const dataset = await this.recordings.watchDetailedDatasetByExternalId(
      params.externalId,
      unitSystem,
      this.queryManager
    );

    return {
      currentUser,
      dataset,
      unitSystem,
      externalId: params.externalId
    };
  }

  afterModel(model: RouteModel, transition: Transition) {
    if (model === null) return;
    if (model.dataset.viewer.company.datasetByExternalId) return;

    transition.abort();
    this.router.replaceWith('app.datasets');
  }
}
