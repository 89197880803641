export default {
  "summary": "_summary_8jl9eu",
  "radon-average": "_radon-average_8jl9eu",
  "radon-average-icon": "_radon-average-icon_8jl9eu",
  "radon-average-measurement": "_radon-average-measurement_8jl9eu",
  "radon-average-value": "_radon-average-value_8jl9eu",
  "radon-average-unit": "_radon-average-unit_8jl9eu",
  "radon-average-label": "_radon-average-label_8jl9eu",
  "samples-date": "_samples-date_8jl9eu",
  "samples-started-at": "_samples-started-at_8jl9eu",
  "samples-ended-at": "_samples-ended-at_8jl9eu"
};
