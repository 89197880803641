// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';

export default class CustomerAndPropertyOwnerBlock
  extends Service
  implements ReportsBlockService
{
  @service('intl')
  intl: IntlService;

  get definition(): Readonly<ReportsBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'customer-and-property-owner-information',
      get name() {
        return intl.t(
          'reports.block-definitions.customer-and-property-owner-information.name'
        );
      },
      get description() {
        return intl.t(
          'reports.block-definitions.customer-and-property-owner-information.description'
        );
      },
      get helper() {
        return intl.t(
          'reports.block-definitions.customer-and-property-owner-information.helper'
        );
      },
      componentNamespace: 'customer-and-property-owner-information',
      lightIconPath:
        '/assets/svgs/report-block-icons/customer-and-property-owner-information-light.svg',
      darkIconPath:
        '/assets/svgs/report-block-icons/customer-and-property-owner-information-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockCustomerAndPropertyOwnerInformation'
    });
  }
}
