// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {bool} from '@ember/object/computed';

// Constants
const INPUT_TYPE = 'datetime';

interface Args {
  disabled?: boolean;
  error?: string;
  label: string;
  onChange: (changedValue: Date) => void;
  didInsert?: (inputElement: HTMLInputElement) => void;
  onKeydown?: (event: KeyboardEvent) => void;
  willDestroy?: () => void;
  required?: boolean;
  value: string;
  helper?: string;
  placeholder?: string;
  inline?: boolean;
  autofocus?: boolean;
  enableTime: boolean | null;
  minDate: Date | null;
  maxDate: Date | null;
}

export default class FormInput extends Component<Args> {
  @bool('args.error')
  isErrored: boolean;

  @bool('args.value')
  hasValue: boolean;

  get disabled() {
    if (typeof this.args.onChange !== 'function') return true;

    return this.args.disabled;
  }

  get type() {
    return INPUT_TYPE;
  }

  get placeholder() {
    return this.args.placeholder || '';
  }

  get inline() {
    return this.args.inline || false;
  }

  @action
  handleChange(selectedDates: Date[]) {
    if (typeof this.args.onChange !== 'function') return;

    this.args.onChange(selectedDates[0]);
  }

  @action
  handleDidInsert(inputElement: HTMLInputElement): void {
    if (this.args.autofocus) inputElement.focus();

    if (typeof this.args.didInsert !== 'function') return;

    this.args.didInsert(inputElement);
  }

  @action
  handleWillDestroy(): void {
    if (typeof this.args.willDestroy !== 'function') return;

    this.args.willDestroy();
  }
}
