// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';

// Types
import Analytics from 'airthings/services/analytics';
import {
  ReportsConfigurableBlock,
  ReportsConfigurationState
} from 'airthings/types/reports';
import {
  RecordingsListDatasetStruct,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';

import {AccountUserStruct} from 'airthings/types/account';
import {ModalSize} from 'airthings/types/modal';

interface Args {
  dataset: RecordingsListDatasetStruct;
  currentUser: AccountUserStruct;
  isFromMobile?: boolean;
  scrollTop?: () => {};
  onCancel: () => {};
  onSetModalSize?: (size: ModalSize) => {};
}

export default class ReportsGenerationConfiguration extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @tracked
  reportState: ReportsConfigurationState | null = null;

  @tracked
  configurableBlocks: ReportsConfigurableBlock[] = [];

  @tracked
  stagedIndex: number;

  sampleUnitTypes = RecordingsSampleUnitType;

  get stagedConfigurableBlock(): ReportsConfigurableBlock | undefined {
    return this.configurableBlocks[this.stagedIndex];
  }

  get isFirstStep(): boolean {
    return this.stagedIndex === 0;
  }

  get isReportInitialized(): boolean {
    return Boolean(this.reportState);
  }

  get isConfiguringBlock(): boolean {
    return Boolean(this.stagedConfigurableBlock);
  }

  @action
  initializeReportState(reportState: ReportsConfigurationState) {
    if (this.args.scrollTop) this.args.scrollTop();

    this.reportState = reportState;
    this.configurableBlocks = reportState.unconfiguredReportBlocks;
    this.stagedIndex = 0;
  }

  @action
  handleDidInsert() {
    this.analytics.trackReportConfiguration(this.args.dataset, {
      reportConfigurationAction: 'start'
    });
  }

  @action
  handleCancel(reportStage: string) {
    this.analytics.trackReportConfiguration(this.args.dataset, {
      reportConfigurationAction: 'cancel',
      reportStage
    });

    if (this.args.onCancel) this.args.onCancel();
  }

  @action
  handleBlockConfiguration(configuredBlock: ReportsConfigurableBlock) {
    if (this.stagedConfigurableBlock) {
      this.stagedConfigurableBlock.reportSettingsStruct =
        configuredBlock.reportSettingsStruct;
    }

    if (this.args.scrollTop) this.args.scrollTop();

    this.stagedIndex++;
  }

  @action
  goToPreviousStep() {
    if (this.stagedIndex > 0) {
      this.stagedIndex--;
      return;
    }

    this.reportState = null;
    this.configurableBlocks = [];
  }
}
