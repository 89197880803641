// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';

export default class CompanyLogoBlock
  extends Service
  implements ReportsBlockService
{
  @service('intl')
  intl: IntlService;

  get definition(): Readonly<ReportsBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'company-logo',
      get name() {
        return intl.t('reports.block-definitions.company-logo.name');
      },
      get helper() {
        return intl.t('reports.block-definitions.company-logo.helper');
      },
      lightIconPath: '/assets/svgs/report-block-icons/company-logo-light.svg',
      darkIconPath: '/assets/svgs/report-block-icons/company-logo-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockCompanyLogo',
      componentNamespace: 'company-logo'
    });
  }
}
