// Vendor
import {gql} from '@apollo/client/core';

export enum JobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED'
}

export interface ReportGenerationJobVariables {
  jobId: string;
}

export interface ReportGenerationJobResponse {
  viewer: {
    id: string;

    company: {
      id: string;

      reportGenerationJob: {
        id: string;
        state: JobState;

        report: {
          id: string;
          pdfFileUrl?: string;

          dataset: {
            id: string;

            device: {
              company: {
                name: string;
              };
            };

            metadata: {
              customer: {
                email: string;
              };
              measurement: {
                address: string;
                city: string;
                state: string;
                postalCode: string;
                country: string;
              };
            };
          };
        };
      };
    };
  };
}

export default gql`
  query ReportsReportGenerationJob($jobId: ID!) {
    viewer {
      id

      company {
        id

        reportGenerationJob(jobId: $jobId) {
          id
          state

          report {
            id
            pdfFileUrl

            dataset {
              id

              device {
                company {
                  name
                }
              }

              metadata {
                customer {
                  email
                }
                measurement {
                  address
                  city
                  state
                  postalCode
                  country
                }
              }
            }
          }
        }
      }
    }
  }
`;
