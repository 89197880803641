export default {
  "container": "_container_187zc0",
  "title": "_title_187zc0",
  "with-radon-short-term-average-icon": "_with-radon-short-term-average-icon_187zc0",
  "with-temperature-icon": "_with-temperature-icon_187zc0",
  "with-pressure-icon": "_with-pressure-icon_187zc0",
  "with-humidity-icon": "_with-humidity-icon_187zc0",
  "with-wind-speed-icon": "_with-wind-speed-icon_187zc0",
  "with-precipitations-icon": "_with-precipitations-icon_187zc0",
  "chart": "_chart_187zc0"
};
