// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import IntlService from 'ember-intl/services/intl';
import {MutationResponse} from 'airthings/services/airthings/graphql';
import {task, TaskGenerator} from 'ember-concurrency';

interface Args {
  validate?: () => string[];
  submitTask: {perform: () => Promise<MutationResponse>};
  onSuccess: (result: MutationResponse) => void;
  onError: (result: MutationResponse) => void;
  onValidationError?: (errors: object) => void;
}

export default class Form extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @tracked
  errors?: object;

  @action
  dismissErrors() {
    this.errors = undefined;
  }

  @task
  // eslint-disable-next-line complexity
  *handleSubmit(event: Event): TaskGenerator<void> {
    event.preventDefault();

    if (typeof this.args.validate === 'function') {
      this.errors = this.args.validate();
    }

    if (this.errors) {
      if (this.args.onValidationError)
        return this.args.onValidationError(this.errors);

      return;
    }

    const result = yield this.args.submitTask.perform();

    if (result) {
      if (result.successful && this.args.onSuccess) this.args.onSuccess(result);

      if (!result.successful) {
        if (this.args.onError) this.args.onError(result);

        this.errors = result.messages
          ? result.messages
          : {
              generic: this.intl.t('general.generic-error-message')
            };
      }
    }
  }
}
