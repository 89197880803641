// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

// Types
import {
  RecordingsAggregatedSamples,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';

interface Args {
  title: string;
  aggregatedSamples: RecordingsAggregatedSamples;
  potentialTamperingDetected: boolean;
  includeTampering: boolean;
  includeUncertainty: boolean;
  tamperingEvents: string[];
  timeZone: string;
}

interface GroupedTamperingEvents {
  [key: string]: string[];
}

export default class RecordingsDetailedMeasurementData extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @service('intl')
  intl: IntlService;

  sampleUnitTypes = RecordingsSampleUnitType;

  get radonUnitLabel() {
    return this.recordings.sampleUnitLabel(
      this.args.aggregatedSamples.metadata.radonUnit
    );
  }

  get pressureUnitLabel() {
    return this.recordings.sampleUnitLabel(
      this.args.aggregatedSamples.metadata.pressureUnit
    );
  }

  get temperatureUnitLabel() {
    return this.recordings.sampleUnitLabel(
      this.args.aggregatedSamples.metadata.temperatureUnit
    );
  }

  get humidityUnitLabel() {
    return this.recordings.sampleUnitLabel(
      this.args.aggregatedSamples.metadata.humidityUnit
    );
  }

  get hasTamperingEvents() {
    return this.args.tamperingEvents.length > 0;
  }

  get groupedTamperingEvents() {
    const dates = this.args.tamperingEvents.reduce(
      (memo: GroupedTamperingEvents, event: string) => {
        const formattedDateBasedOnTimeZone = new Date(event).toLocaleDateString(
          this.intl.primaryLocale,
          {
            timeZone: this.args.timeZone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }
        );

        const date = formattedDateBasedOnTimeZone.split(',')[0];

        if (!memo[date]) {
          memo[date] = [];
        }

        memo[date].push(event);

        return memo;
      },
      {}
    );

    const eventsGroupedByDate = Object.keys(dates).map((date: string) => {
      return {
        value: date,
        events: dates[date]
      };
    });

    return eventsGroupedByDate;
  }
}
