// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import {AccountCompletionStatus} from 'airthings/types/account';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';

export default class OAuthCallbackRoute extends Route {
  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  async model() {
    return this.account.isLoggedIn
      ? this.account.fetchAccountCompletionStatus()
      : null;
  }

  // eslint-disable-next-line complexity
  afterModel(
    completionStatus: AccountCompletionStatus,
    transition: Transition
  ) {
    const accessToken = transition.to.queryParams.access_token;
    const refreshToken = transition.to.queryParams.refresh_token;

    if (completionStatus) {
      if (!completionStatus.hasProfile) {
        transition.abort();

        this.router.replaceWith('lobby.profile');

        return;
      }

      if (!completionStatus.hasCompany) {
        transition.abort();

        this.router.replaceWith('lobby.company');

        return;
      }

      transition.abort();

      this.router.replaceWith('app');

      return;
    }

    if (!accessToken || !refreshToken) {
      transition.abort();

      return this.account.logout({
        queryParams: {error: transition.to.queryParams.error}
      });
    }

    this.account.persistTokens(accessToken, refreshToken);

    this.router.replaceWith('lobby.profile');

    return;
  }
}
