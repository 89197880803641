export default {
  "font-bold": "700",
  "header": "_header_gszu3i",
  "block-title": "_block-title_gszu3i",
  "configuration-helper": "_configuration-helper_gszu3i",
  "actions": "_actions_gszu3i",
  "actions-group": "_actions-group_gszu3i",
  "action": "_action_gszu3i",
  "settings-wrapper": "_settings-wrapper_gszu3i"
};
