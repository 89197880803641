// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {AdminCommunicationBoardMessageStruct} from 'airthings/types/admin/communication-board';
import {AdminCommunicationBoardEditMessageByIdResponse} from 'airthings/graphql/queries/admin-communication-board-edit-message-by-id';
import {AdminCommunicationBoardPaginatedMessagesResponse} from 'airthings/graphql/queries/admin-communication-board-paginated-messages';
import API from 'airthings/services/airthings/admin/communication-board/api';
import Apollo from 'airthings/services/apollo';
import Form, {Validator} from 'airthings/services/form';

export default class AdminCommunicationBoardMessage extends Service {
  @service('airthings/admin/communication-board/api')
  api: API;

  @service('form')
  form: Form;

  async watchPaginatedMessages(queryManager: Apollo, page?: number) {
    return this.api.watchPaginatedMessages(queryManager, page);
  }

  async watchEditMessageById(queryManager: Apollo, id: string) {
    return this.api.watchEditMessageById(queryManager, id);
  }

  async publishMessage(messageId: string) {
    return this.api.publishMessage(messageId);
  }

  async deleteMessage(messageId: string) {
    return this.api.deleteMessage(messageId);
  }

  async createMessage(message: AdminCommunicationBoardMessageStruct) {
    return this.api.createMessage(message);
  }

  async updateMessage(message: AdminCommunicationBoardMessageStruct) {
    return this.api.updateMessage(message);
  }

  presentCommunicationBoardPaginatedMessages(
    admin: AdminCommunicationBoardPaginatedMessagesResponse['admin']
  ) {
    return {
      messages: admin.paginatedMessages.entries,
      pagination: admin.paginatedMessages.metadata
    };
  }

  presentCommunicationBoardEditMessageById(
    admin: AdminCommunicationBoardEditMessageByIdResponse['admin']
  ) {
    return admin.message;
  }

  validateCreate(message: AdminCommunicationBoardMessageStruct) {
    const validators: Array<Validator<string, 'required' | 'match'>> = [
      {
        field: 'title',
        isValid: Boolean(message.title),
        code: 'required'
      },

      {
        field: 'content',
        isValid: Boolean(message.content),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'communication-board.message'
    });
  }

  validateUpdate(message: AdminCommunicationBoardMessageStruct) {
    const validators: Array<Validator<string, 'required' | 'match'>> = [
      {
        field: 'title',
        isValid: Boolean(message.title),
        code: 'required'
      },

      {
        field: 'content',
        isValid: Boolean(message.content),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'communication-board.message'
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/communication-board/message': AdminCommunicationBoardMessage;
  }
}
