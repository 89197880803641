export default {
  "font-normal": "400",
  "font-bold": "700",
  "message-actions": "_message-actions_1jiz76",
  "title-cell": "_title-cell_1jiz76",
  "message-title": "_message-title_1jiz76",
  "is-read": "_is-read_1jiz76",
  "message-excerpt": "_message-excerpt_1jiz76",
  "status-cell": "_status-cell_1jiz76",
  "date-label": "_date-label_1jiz76",
  "link": "_link_1jiz76",
  "read-actions": "_read-actions_1jiz76",
  "read-action": "_read-action_1jiz76",
  "read-fake-button": "_read-fake-button_1jiz76",
  "table-row": "_table-row_1jiz76",
  "dataset-actions": "_dataset-actions_1jiz76",
  "status": "_status_1jiz76"
};
