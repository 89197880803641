// Vendor
import {Ability} from 'ember-can';

// Types
import {AccountUserStruct} from 'airthings/types/account';
import {CommunicationBoardMessageStruct} from 'airthings/types/communication-board';

export default class MessageAbility extends Ability {
  currentUser: AccountUserStruct;
  message: CommunicationBoardMessageStruct;

  get canList() {
    return true;
  }

  get canDelete() {
    return true;
  }

  get canMarkAsRead() {
    return this.message.readAt === null;
  }

  get canMarkAsUnread() {
    return this.message.readAt !== null;
  }
}
