export default {
  "toolbar": "_toolbar_wr10g2",
  "toolbar-button": "_toolbar-button_wr10g2",
  "is-align": "_is-align_wr10g2",
  "is-bold": "_is-bold_wr10g2",
  "is-italic": "_is-italic_wr10g2",
  "is-underline": "_is-underline_wr10g2",
  "is-strike": "_is-strike_wr10g2",
  "is-subscript": "_is-subscript_wr10g2",
  "is-superscript": "_is-superscript_wr10g2",
  "is-align-left": "_is-align-left_wr10g2",
  "is-align-center": "_is-align-center_wr10g2",
  "is-align-right": "_is-align-right_wr10g2",
  "is-bullet-list": "_is-bullet-list_wr10g2",
  "is-ordered-list": "_is-ordered-list_wr10g2",
  "is-link": "_is-link_wr10g2",
  "is-undo": "_is-undo_wr10g2",
  "is-redo": "_is-redo_wr10g2",
  "is-clean": "_is-clean_wr10g2"
};
