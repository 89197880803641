export default {
  "font-normal": "400",
  "font-bold": "700",
  "button": "_button_1q5web",
  "is-gray": "_is-gray_1q5web",
  "is-flat-gray": "_is-flat-gray_1q5web",
  "is-light-gray": "_is-light-gray_1q5web",
  "is-small": "_is-small_1q5web",
  "loader": "_loader_1q5web",
  "is-small-green": "_is-small-green_1q5web"
};
