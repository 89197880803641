// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {
  ReportSettingsStruct,
  TemplateSettingsStruct
} from 'airthings/services/airthings/reports/block-definitions/dynamic-paragraph';
import {FormSelectOption} from 'airthings/pods/components/form/select/component';

interface Args {
  settingsStruct: ReportSettingsStruct;
  templateSettingsStruct: RecursiveRequired<TemplateSettingsStruct>;
  errors?: object;
  onChange: (settings: ReportSettingsStruct) => void;
}

export default class ReportsBlocksDynamicParagraphReportSettings extends Component<Args> {
  get selectOptions(): FormSelectOption[] {
    return this.args.templateSettingsStruct.options.map((option) => ({
      value: option.id,
      label: option.title
    }));
  }

  @action
  changeOptionId(optionId: string) {
    this.args.onChange({...this.args.settingsStruct, optionId});
  }
}
