// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import Analytics from 'airthings/services/analytics';
import {AccountUserStruct} from 'airthings/types/account';
import {RecordingsListDatasetStruct} from 'airthings/types/recordings';
import {GraphqlPagination} from 'airthings/types/graphql';
import Recordings from 'airthings/services/airthings/recordings';
import RouterService from '@ember/routing/router-service';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import {OnboardingSectionSlug} from 'airthings/types/account';
import {ModalSize} from 'airthings/types/modal';

interface Args {
  currentUser: AccountUserStruct;
  datasets: RecordingsListDatasetStruct[];
  pagination: GraphqlPagination;
  searchTerm?: string;
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageAppDevicesIndex extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('router')
  router: RouterService;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  stagedDatasetForReportGeneration: RecordingsListDatasetStruct | null = null;

  @tracked
  stagedDatasetForCSVGeneration: RecordingsListDatasetStruct | null = null;

  @tracked
  stagedDatasetForDeletion: RecordingsListDatasetStruct | null = null;

  @tracked
  showBatchCSVGenerationModal: Boolean = false;

  @tracked
  isReportModalLarge: boolean = false;

  get availableDatasets() {
    return this.args.datasets.filter((dataset) => dataset.deletedAt === null);
  }

  @dropTask
  *deleteDatasetTask(): TaskGenerator<void> {
    if (!this.stagedDatasetForDeletion) return;

    const result = yield this.recordings.deleteDataset(
      this.stagedDatasetForDeletion
    );

    this.stagedDatasetForDeletion = null;

    if (result.successful) {
      this.analytics.trackDatasetDeletion(result.result);

      this.flashMessages.success(
        this.intl.t('app-datasets-index.delete-modal.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  generateReport(dataset: RecordingsListDatasetStruct) {
    this.stagedDatasetForReportGeneration = dataset;
  }

  @action
  generateCSV(dataset: RecordingsListDatasetStruct) {
    this.stagedDatasetForCSVGeneration = dataset;
  }

  @action
  initiateDeleteDataset(dataset: RecordingsListDatasetStruct) {
    this.stagedDatasetForDeletion = dataset;
  }

  @action
  cancelReportGeneration() {
    this.stagedDatasetForReportGeneration = null;
  }

  @action
  setReportModalSize(size: ModalSize) {
    this.isReportModalLarge = size === ModalSize.LARGE;
  }

  @action
  cancelCSVGeneration() {
    this.stagedDatasetForCSVGeneration = null;
  }

  @action
  cancelDeletion() {
    this.stagedDatasetForDeletion = null;
  }

  @action
  batchCSVGeneration() {
    this.showBatchCSVGenerationModal = true;
  }

  @action
  cancelBatchCSVGeneration() {
    this.showBatchCSVGenerationModal = false;
  }

  @action
  search(term: string) {
    this.router.replaceWith('app.datasets.index', {queryParams: {q: term}});
  }

  @action
  clearSearch() {
    this.router.replaceWith('app.datasets.index', {
      queryParams: {q: null}
    });
  }
}
