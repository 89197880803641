// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import move from 'ember-animated/motions/move';
import {fadeIn, fadeOut} from 'ember-animated/motions/opacity';

// Types
import {ComparisonDevice} from 'airthings/types/admin/recordings';
import AdminRecordings from 'airthings/services/airthings/admin/recordings';
import {Sprite} from 'ember-animated';

interface TransitionParams {
  keptSprites: Sprite[];
  insertedSprites: Sprite[];
  removedSprites: Sprite[];
}

interface Args {
  title: string;
  formData: ComparisonDevice[];
  onChange: (updatedComparisonDevices: ComparisonDevice[]) => void;
  errors?: object;
}

export default class RecordingsComparisonDevicesForm extends Component<Args> {
  @service('airthings/admin/recordings')
  adminRecordings: AdminRecordings;

  *transition({
    keptSprites,
    insertedSprites,
    removedSprites
  }: TransitionParams) {
    for (const sprite of keptSprites) {
      move(sprite);
    }

    for (const sprite of insertedSprites) {
      fadeIn(sprite);
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite);
    }
  }

  @action
  changeComparisonDevice(
    updatedComparisonDevice: ComparisonDevice,
    index: number
  ) {
    const comparisonDevices = [...this.args.formData];
    comparisonDevices[index] = updatedComparisonDevice;

    this.args.onChange(comparisonDevices);
  }

  @action
  addComparisonDevice() {
    const comparisonDevices = [...this.args.formData];
    comparisonDevices.push(this.adminRecordings.comparisonDeviceStruct());

    this.args.onChange(comparisonDevices);
  }

  @action
  removeComparisonDevice(index: number) {
    const comparisonDevices = [...this.args.formData];
    comparisonDevices.splice(index, 1);

    this.args.onChange(comparisonDevices);
  }
}
