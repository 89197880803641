// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import Reports from 'airthings/services/airthings/reports';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {ReportsBlockDefinition} from 'airthings/types/reports';

interface Args {
  onSelect: (blockDefinition: any) => void;
}

export default class ReportsBlockPicker extends Component<Args> {
  @service('airthings/reports')
  reports: Reports;

  @tracked
  blockGroups: ReportsBlockDefinition[];

  @dropTask
  *fetchBlockListTask(): TaskGenerator<void> {
    const blockGroups = yield this.reports.listTemplateBlockGroups();

    this.blockGroups = blockGroups;
  }
}
