// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';

// Services
import Account from 'airthings/services/airthings/account';
import IntlService from 'ember-intl/services/intl';
import Reports from 'airthings/services/airthings/reports';
import Translations from 'airthings/services/translations';

// Types
import {MockedReport} from 'airthings/graphql/queries/mocked-report';

// Utils
import reportsLanguageToLocale from 'airthings/utils/locales';

// Config
import config from 'airthings/config/environment';

interface RouteParams {
  reportTemplateId: string;
}

export default class ReportTemplatePreviewRoute extends Route {
  @service('airthings/account')
  account: Account;

  @service('fastboot')
  fastboot: FastBoot;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @service('airthings/reports')
  reports: Reports;

  @service('translations')
  translations: Translations;

  // eslint-disable-next-line complexity
  async beforeModel(transition: Transition) {
    const completionStatus = await this.account.fetchAccountCompletionStatus();

    if (!this.account.isLoggedIn || !completionStatus) {
      transition.abort();

      return this.account.logout();
    }

    if (!completionStatus.hasProfile) {
      transition.abort();

      this.router.replaceWith('lobby.profile');

      return;
    }

    // If an admin user doesn’t have a company assigned, it means he
    // should get back to the admin section.
    if (completionStatus.isAdmin && !completionStatus.hasCompany) {
      transition.abort();

      this.router.replaceWith('admin');

      return;
    }

    if (!completionStatus.hasCompany) {
      transition.abort();

      this.router.replaceWith('lobby.company');

      return;
    }
  }

  async model(params: RouteParams) {
    // NOTE: We had problems with large datasets loaded in memory with FastBoot so we
    //       delegate it to the browser.
    if (this.fastboot.isFastBoot) return;

    return this.reports.fetchMockedReport(params.reportTemplateId);
  }

  async afterModel(model: MockedReport) {
    if (!model) return;

    const locale = reportsLanguageToLocale(model.language);

    this.intl.setLocale([locale, 'en-ca']);

    if (config.intl.ASYNC_TRANSLATIONS) {
      await this.translations.loadForLocale(locale);
    }
  }
}
