// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

// Types
import IntlService from 'ember-intl/services/intl';

interface Args {
  stepsLength: number;
  currentStepIndex: number;
}

export default class ReportsGenerationProgress extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get progressLabel() {
    const currentStep = this.args.currentStepIndex + 1;
    const totalSteps = this.args.stepsLength;

    return this.intl.t('reports.generation.progress.current-step', {
      currentStep,
      totalSteps
    });
  }
}
