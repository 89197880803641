// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

interface Args {
  searchTerm?: string;
  searchPlaceholder?: string;
  onSearch: (searchTerm: string) => void;
  onClearSearch: () => void;
}

export default class FormSearch extends Component<Args> {
  inputElement?: HTMLInputElement;

  @tracked
  searchTerm: string = '';

  @action
  inputInsert(inputElement: HTMLInputElement) {
    this.inputElement = inputElement;
  }

  @action
  clearSearch() {
    this.searchTerm = '';

    if (typeof this.args.onClearSearch === 'function') {
      this.args.onClearSearch();
    }

    this.inputElement?.focus();
  }

  @action
  changeSearchTerm(event: KeyboardEvent) {
    this.searchTerm = (event.target as HTMLInputElement).value;
  }

  @action
  onSearch(event: Event) {
    event.preventDefault();
    this.args.onSearch(this.searchTerm);
  }
}
