// @ts-nocheck
/* eslint-disable camelcase */

export interface IntercomBootOptions {
  appId: string;
  email: string;
  userHash: string;
  webappVersion: string;
}

export interface IntercomOptions {
  hide_default_launcher: boolean;
}

const bootIntercom = (options: IntercomBootOptions) => {
  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: options.appId,
      email: options.email,
      user_hash: options.userHash,
      webapp_version: options.webappVersion
    });
  }
};

const showIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update', {hide_default_launcher: false});
  }
};

const hideIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update', {hide_default_launcher: true});
  }
};

export {bootIntercom, showIntercom, hideIntercom};
