export default {
  "font-bold": "700",
  "container": "_container_sbwb97 _animations-fade-in_7nu7fk",
  "is-expanded": "_is-expanded_sbwb97",
  "header": "_header_sbwb97",
  "has-error": "_has-error_sbwb97",
  "header-title": "_header-title_sbwb97",
  "delete-button": "_delete-button_sbwb97",
  "body": "_body_sbwb97"
};
