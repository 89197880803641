// Vendor
import Service from '@ember/service';
import {inject as service} from '@ember/service';
import Cookies from 'ember-cookies/services/cookies';

// Types
import {GraphqlSortDirection} from 'airthings/types/graphql';

interface SortQueryParams {
  sortField?: string;
  sortDirection?: GraphqlSortDirection;
}

export default class SortManager extends Service {
  @service('cookies')
  cookies: Cookies;

  /**
   * @description If field and directions are passed, store settings in cookie.
   * If sortfield is an empty string, removes the sort settings.
   * Returns value from cookie.
   */
  loadAndPersistSorting(key: string, params: SortQueryParams) {
    if (params.sortField && params.sortDirection) {
      this.cookies.write(
        key,
        JSON.stringify({
          field: params.sortField,
          direction: params.sortDirection
        }),
        {
          raw: true,
          path: '/'
        }
      );
    } else if (params.sortField === '') {
      this.cookies.write(key, JSON.stringify({}), {
        raw: true,
        path: '/'
      });
      return {};
    }

    const sort = this.cookies.read(key) || '{}';

    return JSON.parse(sort);
  }
}

declare module '@ember/service' {
  interface Registry {
    'sort-manager': SortManager;
  }
}
