// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Types
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import AdminRecordings from 'airthings/services/airthings/admin/recordings';
import Apollo from 'airthings/services/apollo';
import {AdminRecordingsEditCalibrationCertificateByIdResponse} from 'airthings/graphql/queries/admin-recordings-edit-calibration-certificate-by-id';

interface RouteParams {
  id: string;
}

interface RouteModel {
  calibrationCertificate: AdminRecordingsEditCalibrationCertificateByIdResponse | null;
}

export default class AdminCalibrationCertificatesEditRoute extends Route {
  @service('airthings/admin/recordings')
  adminRecordings: AdminRecordings;

  @service('router')
  router: RouterService;

  @queryManager
  queryManager: Apollo;

  async model(params: RouteParams): Promise<RouteModel> {
    const calibrationCertificate =
      await this.adminRecordings.watchEditCalibrationCertificateById(
        this.queryManager,
        params.id
      );

    return {
      calibrationCertificate
    };
  }

  afterModel(model: RouteModel, transition: Transition) {
    if (model.calibrationCertificate) return;

    transition.abort();
    this.router.replaceWith('admin.calibration-certificates');
  }
}
