// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {TaskGenerator, timeout} from 'ember-concurrency';
import config from 'airthings/config/environment';
import {perform} from 'ember-concurrency-ts';

// Types
import Analytics from 'airthings/services/analytics';
import Reports from 'airthings/services/airthings/reports';
import {JobState} from 'airthings/graphql/queries/reports-report-generation-job';
import WebviewBridge from 'airthings/services/webview-bridge';
import {AccountUserStruct} from 'airthings/types/account';
import {ModalSize} from 'airthings/types/modal';

interface GeneratedReport {
  id: string;
  pdfFileUrl?: string;
  dataset: {
    id: string;
    device: {
      company: {
        name: string;
      };
    };
    metadata: {
      customer: {
        email: string;
      };
      measurement: {
        address: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
  };
}

interface Args {
  reportId: string;
  currentUser: AccountUserStruct;
  isFromMobile?: boolean;
  onCancel: () => void;
  onPrevious: () => void;
  onSetModalSize?: (size: ModalSize) => void;
}

enum ComponentState {
  WAITING,
  LOADING,
  ERRORED,
  COMPLETED,
  PREPARING_TO_SEND_EMAIL
}

export default class ReportsGenerationConfirmation extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/reports')
  reports: Reports;

  @service('webview-bridge')
  webviewBridge: WebviewBridge;

  @tracked
  state: ComponentState = ComponentState.WAITING;

  @tracked
  generatedReport: GeneratedReport | null = null;

  get customerEmail() {
    if (!this.generatedReport) return null;

    return this.generatedReport.dataset.metadata.customer.email;
  }

  get isWaiting() {
    return this.state === ComponentState.WAITING;
  }

  get isLoading() {
    return this.state === ComponentState.LOADING;
  }

  get hasErrored() {
    return this.state === ComponentState.ERRORED;
  }

  get isCompleted() {
    return this.state === ComponentState.COMPLETED;
  }

  get isEmailDeliveryForm() {
    return this.state === ComponentState.PREPARING_TO_SEND_EMAIL;
  }

  @dropTask
  *generateReportTask(reportId: string): TaskGenerator<GeneratedReport> {
    const {successful, result} = yield this.reports.generateReport(reportId);

    if (successful) {
      return yield perform(this.pollForReportGenerationJobTask, result?.id);
    }

    throw new Error('Unable to generate report');
  }

  @restartableTask
  *pollForReportGenerationJobTask(jobId: string): TaskGenerator<void> {
    while (true) {
      const {state, report} = yield this.reports.fetchReportGenerationJob(
        jobId
      );

      if (state === JobState.COMPLETED) {
        this.analytics.trackReportGenerationSuccess(report);

        return report;
      }

      if (state === JobState.ERRORED) {
        this.analytics.trackReportGenerationFailure(report);

        throw new Error('Report generation errored');
      }

      yield timeout(config.APP.reportGeneration.jobPollingDelay);
    }
  }

  @action
  async generateReport() {
    if (this.args.isFromMobile) return this.launchMobileReportGeneration();

    return this.launchReportGeneration();
  }

  @action
  showEmailDeliveryForm() {
    this.state = ComponentState.PREPARING_TO_SEND_EMAIL;
  }

  @action
  cancelEmailDelivery() {
    this.state = ComponentState.COMPLETED;
  }

  private launchMobileReportGeneration() {
    this.webviewBridge.triggerReportConfigurationCompleted(this.args.reportId);
  }

  private async launchReportGeneration() {
    this.state = ComponentState.LOADING;

    try {
      const report: GeneratedReport = await perform(
        this.generateReportTask,
        this.args.reportId
      );

      this.state = ComponentState.COMPLETED;
      this.generatedReport = report;
    } catch (error) {
      this.state = ComponentState.ERRORED;
    }
  }
}
