// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import {GraphTemplateSettingsActionLevelStruct} from 'airthings/services/airthings/reports/block-definitions/graph';
import {RecordingsRadonShortTimeAverageUnit} from 'airthings/types/recordings';
import {FormSelectOption} from 'airthings/pods/components/form/select/component';
import Recordings from 'airthings/services/airthings/recordings';

interface Errors {
  [field: string]: string;
}

interface Args {
  actionLevel: GraphTemplateSettingsActionLevelStruct;
  errors?: Errors;
  index: number;
  onChange: (actionLevel: GraphTemplateSettingsActionLevelStruct) => void;
  onDelete: () => void;
}

export default class ReportsBlocksDynamicParagraphTemplateSettingsOptionStruct extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  get unitOptions(): FormSelectOption[] {
    return [
      {
        value: RecordingsRadonShortTimeAverageUnit.PICOCURIES_PER_LITER,
        label:
          this.recordings.sampleUnitLabel(
            RecordingsRadonShortTimeAverageUnit.PICOCURIES_PER_LITER
          ) || ''
      },
      {
        value: RecordingsRadonShortTimeAverageUnit.BECQUERELS_PER_CUBIC_METER,
        label:
          this.recordings.sampleUnitLabel(
            RecordingsRadonShortTimeAverageUnit.BECQUERELS_PER_CUBIC_METER
          ) || ''
      }
    ];
  }

  get labelError() {
    if (!this.args.errors) return;
    return this.args.errors[`action-levels.${this.args.index}.label`];
  }

  get editableThreshold() {
    const threshold = this.args.actionLevel.threshold;
    if (!threshold) return '';

    return threshold.toString();
  }

  get thresholdError() {
    if (!this.args.errors) return;
    return this.args.errors[`action-levels.${this.args.index}.threshold`];
  }

  get unitError() {
    if (!this.args.errors) return;
    return this.args.errors[`action-levels.${this.args.index}.unit`];
  }

  @action
  changeLabel(label: string) {
    this.args.onChange({...this.args.actionLevel, label});
  }

  @action
  changeThreshold(rawThreshold: string) {
    this.args.onChange({
      ...this.args.actionLevel,
      threshold: rawThreshold ? parseFloat(rawThreshold) : 0
    });
  }

  @action
  changeUnit(unit: RecordingsRadonShortTimeAverageUnit) {
    this.args.onChange({...this.args.actionLevel, unit});
  }
}
