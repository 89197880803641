export default {
  "font-bold": "700",
  "container": "_container_ph1j81",
  "is-expanded": "_is-expanded_ph1j81",
  "header": "_header_ph1j81",
  "is-expanding": "_is-expanding_ph1j81",
  "blocks": "_blocks_ph1j81",
  "block": "_block_ph1j81",
  "block-name": "_block-name_ph1j81",
  "block-icon": "_block-icon_ph1j81"
};
