export default {
  "content-field": "_content-field_10s4q5",
  "row": "_row_10s4q5",
  "column": "_column_10s4q5",
  "expiration-date": "_expiration-date_10s4q5",
  "label": "_label_10s4q5",
  "calibration-icon": "_calibration-icon_10s4q5",
  "calibration-due-icon": "_calibration-due-icon_10s4q5",
  "calibration-expired-icon": "_calibration-expired-icon_10s4q5"
};
