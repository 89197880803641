// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';

// Types
import {
  ReportsBlockStruct,
  ReportsBlockDefinition
} from 'airthings/types/reports';
import Reports from 'airthings/services/airthings/reports';

interface Args {
  templateId: string;
  block: ReportsBlockStruct;
  onCancel: () => void;
  onPersistSuccess: (block: ReportsBlockStruct) => void;
}

export default class ReportsTemplateSettingsLayout extends Component<Args> {
  @service('airthings/reports')
  reports: Reports;

  blockDefinition: ReportsBlockDefinition;

  @tracked
  settingsStruct: ReportsBlockStruct['templateSettingsStruct'] =
    this.args.block.templateSettingsStruct;

  get templateSettingsComponent() {
    if (!this.blockDefinition.templateSettingsStruct) return null;

    return `reports/blocks/${this.blockDefinition.componentNamespace}/template-settings`;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.blockDefinition = this.reports.getTemplateBlockDefinition(
      this.args.block.key
    );
  }

  @dropTask
  *blockPersistTask() {
    const block: ReportsBlockStruct = {
      ...this.args.block,
      templateSettingsStruct: this.blockDefinition.templateSettingsSanitize
        ? this.blockDefinition.templateSettingsSanitize(this.settingsStruct)
        : this.settingsStruct
    };

    if (block.id) return this.reports.updateTemplateBlock(block);
    return this.reports.createTemplateBlock(block, this.args.templateId);
  }

  @action
  changeSettingsStruct(
    settingsStruct: ReportsBlockStruct['templateSettingsStruct']
  ) {
    this.settingsStruct = settingsStruct;
  }

  @action
  validate() {
    if (!this.blockDefinition.templateSettingsValidate) return;

    return this.blockDefinition.templateSettingsValidate(this.settingsStruct);
  }
}
