// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {dasherize} from '@ember/string';
import {task, dropTask, restartableTask} from 'ember-concurrency-decorators';
import {perform} from 'ember-concurrency-ts';

// Services
import AdminAccountCompany from 'airthings/services/airthings/admin/account/company';
import AdminRecordingsDevice from 'airthings/services/airthings/admin/recordings/device';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';

// Types
import {
  AdminRecordingsDeviceMoveStep,
  AdminRecordingsDevicesMoveResultStruct,
  AdminRecordingsDeviceStruct
} from 'airthings/types/admin/recordings';
import {AdminAccountListCompanyStruct} from 'airthings/types/admin/account';
import {AdminAccountPaginatedCompaniesResponse} from 'airthings/graphql/queries/admin-account-paginated-companies';

const SERIAL_NUMBER_LENGTH = 10;

const STEPS = [
  AdminRecordingsDeviceMoveStep.SELECT_DEVICES,
  AdminRecordingsDeviceMoveStep.SELECT_COMPANY,
  AdminRecordingsDeviceMoveStep.REVIEW,
  AdminRecordingsDeviceMoveStep.COMPLETED
];

export default class PageAdminDevicesMove extends Component {
  @service('airthings/admin/account/company')
  adminCompany: AdminAccountCompany;

  @service('airthings/admin/recordings/device')
  adminDevice: AdminRecordingsDevice;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @tracked
  stepIndex: number = STEPS.indexOf(
    AdminRecordingsDeviceMoveStep.SELECT_DEVICES
  );

  @tracked
  movingDevices: boolean = false;

  @tracked
  moveResult: AdminRecordingsDevicesMoveResultStruct;

  @tracked
  devices: Array<
    RecursivePartial<AdminRecordingsDeviceStruct> & {
      error?: string;
      loading: boolean;
    }
  > = [];

  @tracked
  company: AdminAccountListCompanyStruct;

  @tracked
  searchCompanyResults: AdminAccountPaginatedCompaniesResponse['admin'];

  get steps() {
    return ['1', '2', '3'];
  }

  get stepComponent() {
    const componentName = dasherize(STEPS[this.stepIndex].toLowerCase());

    return `admin/devices-move/steps/${componentName}`;
  }

  get validDeviceCount() {
    return this.devices.filter(({id}) => Boolean(id)).length;
  }

  get invalidDeviceCount() {
    return this.devices.filter(({id, loading}) => !loading && !Boolean(id))
      .length;
  }

  get validDeviceIds(): string[] {
    const ids: string[] = [];

    this.devices.forEach(({id}) => {
      if (id) ids.push(id);
    });

    return ids;
  }

  @action
  nextStep() {
    this.stepIndex = this.stepIndex + 1;
  }

  @action
  previousStep() {
    this.stepIndex = this.stepIndex - 1;
  }

  @action
  async addDevice(input: string) {
    const newDevices = this.parseDevicesFromSerialNumbers(input);

    this.devices = [...this.devices, ...newDevices];

    for (const device of newDevices) {
      await perform(this.validateDevice, device.serialNumber);
    }
  }

  @action
  removeDevice(serialNumberToRemove: string) {
    this.devices = this.devices.filter(
      ({serialNumber}) => serialNumber !== serialNumberToRemove
    );
  }

  @action
  setCompany(company: AdminAccountListCompanyStruct) {
    this.company = company;
  }

  @action
  searchCompany(term: string) {
    perform(this.searchCompanyTask, term);
  }

  @action
  moveDevices() {
    this.movingDevices = true;
    perform(this.moveDevicesTask);
  }

  @dropTask
  *searchCompanyTask(term: string) {
    this.searchCompanyResults = yield this.adminCompany.fetchCompanies(term);
  }

  @restartableTask
  *moveDevicesTask() {
    const {successful, result} = yield this.adminDevice.moveDevices(
      this.validDeviceIds,
      this.company.id
    );

    if (successful) {
      this.moveResult = result;
      this.stepIndex = STEPS.indexOf(AdminRecordingsDeviceMoveStep.COMPLETED);
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }

    this.movingDevices = false;
  }

  @task
  *validateDevice(serialNumber: string) {
    let device = {},
      error;

    if (serialNumber.length < SERIAL_NUMBER_LENGTH) {
      error = this.intl.t(
        'admin-devices-move.steps.select-devices.invalid-serial-number'
      );
    } else {
      device = yield this.adminDevice.fetchDeviceBySerialNumber(serialNumber);

      if (device == null) {
        error = this.intl.t(
          'admin-devices-move.steps.select-devices.device-not-found'
        );
      }
    }

    const devices = [...this.devices];
    const index = devices.findIndex(
      (device) => device.serialNumber === serialNumber
    );

    if (index < 0) return;

    devices[index] = {
      ...devices[index],
      ...device,
      loading: false,
      error
    };

    this.devices = devices;
  }

  parseDevicesFromSerialNumbers(input: string) {
    return input
      .replace(/[^0-9,; ]/g, '')
      .split(/[,; ]+/)
      .filter((serialNumber: string) => {
        if (serialNumber.trim().length === 0) return false;
        return !this.devices.find(
          (device) => device.serialNumber === serialNumber
        );
      })
      .map((serialNumber: string) => ({
        serialNumber,
        loading: true
      }));
  }
}
