// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {dropTask} from 'ember-concurrency-decorators';
import {inject as service} from '@ember/service';
import parseISO from 'date-fns/parseISO';
import {TaskGenerator} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

// Types
import Analytics from 'airthings/services/analytics';
import {AccountUserStruct} from 'airthings/types/account';
import {
  ReportsLanguage,
  ReportsTemplate,
  ReportsTemplateStruct
} from 'airthings/types/reports';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import Reports from 'airthings/services/airthings/reports';
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';
import {MutationResponse} from 'airthings/services/airthings/graphql';
import {OnboardingSectionSlug} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
  templates: ReportsTemplate[];
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageAppReportTemplatesIndex extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('airthings/reports')
  reports: Reports;

  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  @tracked
  editingReportTemplate: ReportsTemplateStruct | null = null;

  @tracked
  creatingReportTemplate: ReportsTemplateStruct;

  @tracked
  duplicatingReportTemplate: ReportsTemplateStruct | null = null;

  @tracked
  deletingReportTemplate: ReportsTemplateStruct | null = null;

  @tracked
  showCreationModal = false;

  get sortedTemplates() {
    return this.args.templates.sort((firstTemplate, secondTemplate) => {
      if (!firstTemplate.reportTemplatePublication) return 1;
      if (!secondTemplate.reportTemplatePublication) return -1;

      return (
        parseISO(
          secondTemplate.reportTemplatePublication.insertedAt
        ).getTime() -
        parseISO(firstTemplate.reportTemplatePublication.insertedAt).getTime()
      );
    });
  }

  get languageOptions() {
    return Object.values(ReportsLanguage).map((language) => ({
      value: language,
      label: this.intl.t(`general.languages.${language}`)
    }));
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.creatingReportTemplate = this.reports.templateStruct();
  }

  @dropTask
  *createReportTemplateTask() {
    return this.reports.createTemplate(this.creatingReportTemplate);
  }

  @dropTask
  *editReportTemplateTask() {
    if (!this.editingReportTemplate?.id) return;

    return this.reports.updateTemplate(this.editingReportTemplate);
  }

  @dropTask
  *duplicateReportTemplateTask(): TaskGenerator<void> {
    if (!this.duplicatingReportTemplate?.id) return;

    const result = yield this.reports.duplicateTemplate(
      this.duplicatingReportTemplate
    );

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('app-report-templates-index.duplication-modal.success')
      );

      this.redirectAfterSuccess(result);
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *markReportTemplateAsCompanyFavoriteClick(
    reportTemplateId: string
  ): TaskGenerator<void> {
    const result = yield this.account.assignFavoriteReportTemplateToCompany(
      reportTemplateId
    );

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t(
          'app-report-templates-index.assign-favorite-report-template-to-company.success'
        )
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *deleteReportTemplateTask(): TaskGenerator<void> {
    if (!this.deletingReportTemplate?.id) return;

    const result = yield this.reports.deleteTemplate(
      this.deletingReportTemplate.id
    );

    this.deletingReportTemplate = null;

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('app-report-templates-index.delete-modal.success')
      );

      this.analytics.trackReportTemplateDeletion(result.result);
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  changeName(name: string) {
    this.creatingReportTemplate = {...this.creatingReportTemplate, name};
  }

  @action
  changeDescription(description: string) {
    this.creatingReportTemplate = {...this.creatingReportTemplate, description};
  }

  @action
  changeLanguage(language: ReportsLanguage) {
    this.creatingReportTemplate = {...this.creatingReportTemplate, language};
  }

  @action
  initiateCreateReportTemplate() {
    this.showCreationModal = true;
  }

  @action
  initiateEditReportTemplate(reportTemplate: ReportsTemplateStruct) {
    this.analytics.trackReportTemplateEditionStart(reportTemplate);

    this.editingReportTemplate = reportTemplate;
  }

  @action
  initiateDuplicateReportTemplate(reportTemplate: ReportsTemplateStruct) {
    const reportTemplateCopy = Object.assign({}, reportTemplate);
    reportTemplateCopy.name = this.intl.t(
      'app-report-templates-index.duplicate-default-name',
      {name: reportTemplateCopy.name}
    );

    this.duplicatingReportTemplate = reportTemplateCopy;
  }

  @action
  initiateDeleteReportTemplate(reportTemplate: ReportsTemplateStruct) {
    this.deletingReportTemplate = reportTemplate;
  }

  @action
  handleEditReportTemplateChange(updatedReportTemplate: ReportsTemplateStruct) {
    this.editingReportTemplate = updatedReportTemplate;
  }

  @action
  async handleMarkReportTemplateAsCompanyFavoriteClick(
    reportTemplate: ReportsTemplateStruct
  ) {
    if (!reportTemplate.id) return;

    await perform(
      this.markReportTemplateAsCompanyFavoriteClick,
      reportTemplate.id
    );
  }

  @action
  handleDuplicateReportTemplateChange(
    updatedReportTemplate: ReportsTemplateStruct
  ) {
    this.duplicatingReportTemplate = updatedReportTemplate;
  }

  @action
  cancelCreateReportTemplate() {
    this.analytics.trackReportTemplateCreationAbort();
    this.showCreationModal = false;
  }

  @action
  cancelEditReportTemplate() {
    this.editingReportTemplate = null;
  }

  @action
  cancelDuplicateReportTemplate() {
    this.duplicatingReportTemplate = null;
  }

  @action
  cancelDeleteReportTemplate() {
    this.deletingReportTemplate = null;
  }

  @action
  validate() {
    return this.reports.validateTemplate(this.creatingReportTemplate);
  }

  @action
  editValidate() {
    if (!this.editingReportTemplate) return;

    return this.reports.validateTemplate(this.editingReportTemplate);
  }

  @action
  duplicateValidate() {
    if (!this.duplicatingReportTemplate) return;

    return this.reports.validateTemplate(this.duplicatingReportTemplate);
  }

  @action
  handleEditionSuccess() {
    this.flashMessages.success(
      this.intl.t('app-report-templates-index.edition-modal.success')
    );

    this.editingReportTemplate = null;
  }

  @action
  handleFormFlip(isFlipped: boolean) {
    if (isFlipped) {
      this.analytics.trackReportTemplateCreationStart();
    }
  }

  @action
  onSuccess(result: MutationResponse<ReportsTemplateStruct | null>) {
    this.flashMessages.success(
      this.intl.t('app-report-templates-index.creation-modal.success')
    );

    if (result.result) {
      this.analytics.trackReportTemplateCreation(result.result);
    }

    this.redirectAfterSuccess(result);
  }

  @action
  handleConfigureReportTemplateClick(reportTemplate: ReportsTemplate) {
    this.analytics.trackReportTemplateConfigurationStart(reportTemplate);
  }

  private redirectAfterSuccess(
    result: MutationResponse<ReportsTemplateStruct | null>
  ) {
    if ('result' in result && result.result?.id) {
      this.router.transitionTo(
        'app.report-templates.configure',
        result.result.id
      );
    }
  }
}
