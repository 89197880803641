/**
 * Based on
 * https://www.highcharts.com/blog/snippets/synchronisation-of-multiple-charts/
 */

// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {Chart, Point} from 'highcharts';

export default class RecordingsSynchronizedCharts extends Component {
  subscribedCharts: Chart[] = [];

  @action
  subscribeChart(chart: Chart) {
    chart.pointer.reset = () => {};

    this.subscribedCharts.push(chart);

    this.setActiveChartPoint(chart, chart.series[0].points[0]);
  }

  @action
  synchronizeCharts(event: PointerEvent) {
    this.subscribedCharts.forEach((chart) => {
      const normalizedEvent = chart.pointer.normalize(event);

      // The searchPoint() method is missing in Highcharts typings
      // @ts-ignore
      const point = chart.series[0].searchPoint(normalizedEvent, true);
      if (!point) return;

      this.setActiveChartPoint(chart, point);
    });
  }

  private setActiveChartPoint(chart: Chart, point: Point) {
    point.onMouseOver();
    chart.tooltip.refresh(point);
  }
}
