// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

// Services
import Account from 'airthings/services/airthings/account';
import FastBoot from 'ember-cli-fastboot/services/fastboot';

interface Args {
  onOpenUserSidebar: () => void;
}

export default class UnreadMessagesNoticeModal extends Component<Args> {
  @service('airthings/account')
  account: Account;

  @service('fastboot')
  fastboot: FastBoot;

  @tracked
  isOpen: boolean = true;

  @action
  async close() {
    await perform(this.dismissUnreadMessagesNoticeTask);
    this.isOpen = false;
  }

  @action
  async openSidebar() {
    await this.close();
    this.args.onOpenUserSidebar();
  }

  @task
  *dismissUnreadMessagesNoticeTask(): TaskGenerator<void> {
    yield this.account.dismissUnreadMessagesNotice();
  }
}
