// Vendor
import Service, {inject as service} from '@ember/service';
import API from 'airthings/services/airthings/reports/api';

export default class ReportJob extends Service {
  @service('airthings/reports/api')
  api: API;

  async fetchReportGenerationJob(jobId: string) {
    return this.api.fetchReportGenerationJob(jobId);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/reports/job': ReportJob;
  }
}
