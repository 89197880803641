// Vendor
import Helper from '@ember/component/helper';
import {inject as service} from '@ember/service';

// Types
import IntlService, {FormatTimeOptions} from 'ember-intl/services/intl';

export default class extends Helper {
  @service('intl')
  intl: IntlService;

  // eslint-disable-next-line complexity
  compute(posArgs: [string | null], namedArgs?: FormatTimeOptions) {
    if (!posArgs.length || !posArgs[0]) return '';

    const [value] = posArgs;
    const formatOptions = namedArgs ?? {};

    return this.intl.formatTime(value, {
      ...formatOptions,
      timeZone: 'America/New_York',
      timeZoneName:
        formatOptions.hour || formatOptions.minute ? 'short' : undefined
    });
  }
}
