// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';

// GraphQL
import affiliateProgramAds, {
  AffiliateProgramAdsResponse
} from 'airthings/graphql/queries/affiliate-program-ads';

export default class API extends Service {
  @service('apollo')
  apollo: Apollo;

  async fetchAds() {
    const response: AffiliateProgramAdsResponse = await this.apollo.query({
      query: affiliateProgramAds
    });

    return response.affiliateProgram.ads;
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/affiliate-program/api': API;
  }
}
