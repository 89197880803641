export default {
  "font-bold": "700",
  "container": "_container_g55vmh",
  "logo": "_logo_g55vmh",
  "title": "_title_g55vmh",
  "body": "_body_g55vmh",
  "actions": "_actions_g55vmh",
  "action": "_action_g55vmh",
  "training-link": "_training-link_g55vmh _button_znm4p3 _is-yellow_znm4p3"
};
