// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Types
import Can from 'ember-can/services/can';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Apollo from 'airthings/services/apollo';
import Reports from 'airthings/services/airthings/reports';
import Account from 'airthings/services/airthings/account';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

interface RouteParams {
  reportTemplateId: string;
}

export default class AppReportTemplatesConfigureRoute extends Route {
  @service('can')
  can: Can;

  @service('airthings/account')
  account: Account;

  @service('airthings/reports')
  reports: Reports;

  @service('router')
  router: RouterService;

  @queryManager
  queryManager: Apollo;

  beforeModel(transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    if (this.can.cannot('configure reportTemplates', null, {currentUser})) {
      transition.abort();

      this.router.replaceWith('app');
    }

    hideIntercom();
  }

  async model(params: RouteParams) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    const templateBlocks = await this.reports.watchCompanyTemplateBlocks(
      this.queryManager,
      params.reportTemplateId
    );

    return {
      currentUser,
      templateBlocks
    };
  }
}
