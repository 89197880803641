// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';
import FastBoot from 'ember-cli-fastboot/services/fastboot';

// Types
import Account from 'airthings/services/airthings/account';
import Recordings from 'airthings/services/airthings/recordings';
import {OnboardingSectionSlug} from 'airthings/types/account';

export default class AppDevicesMapController extends Controller {
  @service('airthings/account')
  account: Account;

  @service('airthings/recordings')
  recordings: Recordings;

  @service('fastboot')
  fastboot: FastBoot;

  onboardingSectionSlugs = OnboardingSectionSlug;
}
