// Vendor
import Component from '@glimmer/component';
import {action, computed} from '@ember/object';
import {bool} from '@ember/object/computed';
import {inject as service} from '@ember/service';

// Types
import {
  AdminRecordingsCalibrationCertificateStruct,
  OtherCalibration
} from 'airthings/types/admin/recordings';

// Services
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';

interface Args {
  formData: AdminRecordingsCalibrationCertificateStruct;
  metaFormData: OtherCalibration;
  onChange: (
    updatedFormData: AdminRecordingsCalibrationCertificateStruct
  ) => void;
  onMetaChange: (updatedFormData: OtherCalibration) => void;
}

export default class OtherCalibrationForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @bool('args.formData.id')
  persisted: boolean;

  @computed('args.formData.activatedAt', function () {
    return this.recordings.computeExpirationDate(
      this.args.formData.activatedAt
    );
  })
  expiredAt: Date | null;

  @computed('args.formData.activatedAt', function () {
    return this.recordings.isCalibrationDue(this.expiredAt);
  })
  calibrationDue: boolean;

  @computed('args.formData.activatedAt', function () {
    return this.recordings.isCalibrationExpired(this.expiredAt);
  })
  calibrationExpired: boolean;

  @action
  changeDeviceSerialNumber(deviceSerialNumber: string) {
    this.args.onChange({
      ...this.args.formData,
      deviceSerialNumber
    });
  }

  @action
  changeActivatedAt(activatedAt: Date) {
    this.args.onChange({
      ...this.args.formData,
      activatedAt
    });
  }
}
