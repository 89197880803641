// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {bool} from '@ember/object/computed';
import {inject as service} from '@ember/service';

// Types
import {
  AdminRecordingsCalibrationCertificateStruct,
  FactoryCalibration
} from 'airthings/types/admin/recordings';

// Services
import IntlService from 'ember-intl/services/intl';

interface Args {
  formData: AdminRecordingsCalibrationCertificateStruct;
  metaFormData: FactoryCalibration;
  onChange: (
    updatedFormData: AdminRecordingsCalibrationCertificateStruct
  ) => void;
  onMetaChange: (updatedFormData: FactoryCalibration) => void;
}

export default class FactoryCalibrationForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @bool('args.formData.id')
  persisted: boolean;

  @action
  changeDeviceSerialNumber(deviceSerialNumber: string) {
    this.args.onChange({
      ...this.args.formData,
      deviceSerialNumber
    });
  }

  @action
  changeCalibrationFactorMultiplier(calibrationFactorMultiplier: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      calibrationFactorMultiplier
    });
  }

  @action
  changeStartedAt(startedAt: Date) {
    this.args.onChange({
      ...this.args.formData,
      startedAt
    });
  }

  @action
  changeTestDuration(testDuration: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      testDuration
    });
  }

  @action
  changeAverageRadonConcentration(averageRadonConcentration: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      averageRadonConcentration
    });
  }

  @action
  changeAverageTemperature(averageTemperature: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      averageTemperature
    });
  }

  @action
  changeAverageRelativeHumidity(averageRelativeHumidity: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      averageRelativeHumidity
    });
  }

  @action
  changeAverageBarometricPressure(averageBarometricPressure: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      averageBarometricPressure
    });
  }
}
