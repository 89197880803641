// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import IntlService from 'ember-intl/services/intl';

// Types
import {Certificate} from 'airthings/types/account';

interface Errors {
  [field: string]: string;
}

interface Args {
  certificate: Certificate;
  onChange: (updatedCertificate: Certificate, index: number) => void;
  onRemove: (index: number) => void;
  index: number;
  errors?: Errors;
}

export default class AccountCertificatesFormItem extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get nameError(): string | undefined {
    if (!this.args.errors) return;
    return this.args.errors[`certificates.${this.args.index}.name`];
  }

  get numberError(): string | undefined {
    if (!this.args.errors) return;
    return this.args.errors[`certificates.${this.args.index}.number`];
  }

  get expirationDateError(): string | undefined {
    if (!this.args.errors) return;
    return this.args.errors[`certificates.${this.args.index}.expiration-date`];
  }

  get expirationDateInputMask() {
    return {
      regex: '[0-9]{2}/[0-9]{2}/[0-9]{4}',
      placeholder: this.intl.t(
        'account.company.certificates.expiration-date.placeholder'
      )
    };
  }

  @action
  changeName(updatedName: string) {
    this.args.onChange(
      {
        ...this.args.certificate,
        name: updatedName
      },
      this.args.index
    );
  }

  @action
  changeNumber(updatedNumber: string) {
    this.args.onChange(
      {
        ...this.args.certificate,
        number: updatedNumber
      },
      this.args.index
    );
  }

  @action
  changeExpirationDate(updatedExpirationDate: string) {
    this.args.onChange(
      {
        ...this.args.certificate,
        expirationDate: updatedExpirationDate
      },
      this.args.index
    );
  }

  @action
  removeCertificate() {
    this.args.onRemove(this.args.index);
  }
}
