// Vendor
import {helper} from '@ember/component/helper';
import {htmlSafe} from '@ember/template';

// Types
import {SafeString} from '@ember/template/-private/handlebars';

export const backgroundImageHelper = function ([url]: [string]): SafeString {
  return htmlSafe(`background-image: url(${url})`);
};

export default helper(backgroundImageHelper);
