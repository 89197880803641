// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import WebviewBridge from 'airthings/services/webview-bridge';
import StateManager from 'airthings/services/state-manager';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

export default class AppController extends Controller {
  @service('airthings/account')
  account: Account;

  @service('webview-bridge')
  webviewBridge: WebviewBridge;

  @service('state-manager')
  stateManager: StateManager;

  get shouldDisplayMenuSidebar() {
    return !this.webviewBridge.isEmbedded;
  }

  get shouldDisplayUserSidebar() {
    return !this.webviewBridge.isEmbedded;
  }

  get shouldDisplayLogAsBanner() {
    const model = this.model as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    return model.currentUser.viewer.admin;
  }

  get shouldLockContent() {
    return this.stateManager.mobileMenuIsOpened;
  }
}
