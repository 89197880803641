// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

interface Args {
  label: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => {};
  theme:
    | 'white'
    | 'gray'
    | 'yellow'
    | 'light-yellow'
    | 'light-gray'
    | 'red'
    | 'small-red'
    | 'small-green';
}

export default class Button extends Component<Args> {
  @action
  handleClick() {
    if (typeof this.args.onClick !== 'function') return;

    this.args.onClick();
  }

  get isSmall() {
    return this.args.theme.startsWith('small-');
  }

  get isDisabled() {
    return this.args.disabled || this.args.loading;
  }
}
