// Vendor
import {Ability} from 'ember-can';

// Types
import {AccountUserStruct, UserRole} from 'airthings/types/account';
import {RecordingsDeviceStruct} from 'airthings/types/recordings';
import {CalibrationType} from 'airthings/types/admin/recordings';

export default class DeviceAbility extends Ability {
  currentUser: AccountUserStruct;
  device: RecordingsDeviceStruct;

  get canList() {
    return true;
  }

  get canDelete() {
    return this.currentUser.role === UserRole.MANAGER;
  }

  get canGenerateCalibrationCertificate() {
    return (
      this.device.calibrationCertificate &&
      this.device.calibrationCertificate.uuid &&
      this.device.calibrationCertificate.calibrationMeta?.calibrationType !==
        CalibrationType.OTHER
    );
  }
}
