// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {timeout} from 'ember-concurrency';
import config from 'airthings/config/environment';
import {TaskGenerator} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

// Services
import Recordings from 'airthings/services/airthings/recordings';
import {JobState} from 'airthings/graphql/queries/recordings-crosschecks-report-generation-job';

interface Args {
  onCancel: () => {};
  deviceId: string;
}

enum ComponentState {
  LOADING,
  ERRORED,
  COMPLETED
}

export default class CrosschecksReportGeneration extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  state: ComponentState = ComponentState.LOADING;

  @tracked
  pdfUrl: string;

  get isLoading() {
    return this.state === ComponentState.LOADING;
  }

  get isErrored() {
    return this.state === ComponentState.ERRORED;
  }

  get isCompleted() {
    return this.state === ComponentState.COMPLETED;
  }

  @dropTask
  *generateReportTask(deviceId: string): TaskGenerator<string> {
    const {successful, result} =
      yield this.recordings.generateCrosschecksReport(deviceId);

    if (successful) {
      return yield perform(this.pollForPdfJobTask, result?.id);
    }

    throw new Error('Unable to generate PDF');
  }

  @restartableTask
  *pollForPdfJobTask(jobId: string): TaskGenerator<string> {
    while (true) {
      const {state, pdfUrl} =
        yield this.recordings.fetchCrosschecksReportGenerationJob(jobId);

      if (state === JobState.COMPLETED) return pdfUrl;

      if (state === JobState.ERRORED) {
        throw new Error('PDF generation errored');
      }

      yield timeout(config.APP.reportGeneration.jobPollingDelay);
    }
  }

  @action
  async generateReport() {
    this.state = ComponentState.LOADING;

    try {
      const pdfUrl = await perform(this.generateReportTask, this.args.deviceId);

      this.state = ComponentState.COMPLETED;
      this.pdfUrl = pdfUrl;
    } catch (error) {
      this.state = ComponentState.ERRORED;
    }
  }
}
