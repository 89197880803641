export default {
  "font-bold": "700",
  "container": "_container_d8fzsq _animations-fade-slide-in_7nu7fk",
  "is-large": "_is-large_d8fzsq",
  "with-yellow-theme": "_with-yellow-theme_d8fzsq",
  "header": "_header_d8fzsq",
  "with-yellow-gray-theme": "_with-yellow-gray-theme_d8fzsq",
  "with-red-theme": "_with-red-theme_d8fzsq",
  "with-gray-theme": "_with-gray-theme_d8fzsq",
  "header-centered": "_header-centered_d8fzsq",
  "header-icon": "_header-icon_d8fzsq",
  "header-title": "_header-title_d8fzsq",
  "header-sub-title": "_header-sub-title_d8fzsq",
  "body": "_body_d8fzsq"
};
