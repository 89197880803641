// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import IntlService from 'ember-intl/services/intl';
import {ChoiceButtonsOption} from 'airthings/pods/components/form/choice-buttons/component';
import {AccountUserPreferences} from 'airthings/types/account';
import {RecordingsUnitSystem} from 'airthings/types/recordings';

interface Args {
  formData: AccountUserPreferences;
  onChange: (updatedFormData: AccountUserPreferences) => void;
  errors?: object;
}

// Constants
const UNIT_SYSTEM_OPTIONS = [
  {
    labelKey: 'account.user-preferences-form.unit-system.united_states',
    value: 'UNITED_STATES'
  },
  {
    labelKey: 'account.user-preferences-form.unit-system.metric',
    value: 'METRIC'
  }
];

export default class AccountUserPreferencesForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get unitSystemOptions(): ChoiceButtonsOption[] {
    return UNIT_SYSTEM_OPTIONS.map(({labelKey, value}) => ({
      value,
      label: this.intl.t(labelKey)
    }));
  }

  @action
  changeUnitSystem(updatedUnitSystem: RecordingsUnitSystem): void {
    this.args.onChange({
      ...this.args.formData,
      unitSystem: updatedUnitSystem
    });
  }
}
