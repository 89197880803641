export default {
  "font-bold": "700",
  "container": "_container_1pb8cc",
  "success": "_success_1pb8cc",
  "badge": "_badge_1pb8cc",
  "icon": "_icon_1pb8cc",
  "message": "_message_1pb8cc",
  "import-log": "_import-log_1pb8cc",
  "log-content": "_log-content_1pb8cc",
  "inserted": "_inserted_1pb8cc",
  "errors": "_errors_1pb8cc",
  "actions": "_actions_1pb8cc",
  "action": "_action_1pb8cc"
};
