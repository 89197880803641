// Vendor
import Service, {inject as service} from '@ember/service';
import {action} from '@ember/object';
import moment from 'moment';
import {isAfter, isBefore, addDays} from 'date-fns';
import parseISO from 'date-fns/parseISO';

// Types
import {RecordingsCompanyPaginatedDatasetsResponse} from 'airthings/graphql/queries/recordings-company-paginated-datasets';
import {RecordingsCompanyDevicesResponse} from 'airthings/graphql/queries/recordings-company-devices';
import Apollo from 'airthings/services/apollo';
import RecordingsDevice from 'airthings/services/airthings/recordings/device';
import {
  DevicesSortField,
  RecordingsDatasetAttachmentStruct,
  RecordingsDeviceStruct,
  RecordingsEditDatasetStruct,
  RecordingsListDatasetStruct,
  RecordingsSampleStruct,
  RecordingsSampleUnit,
  RecordingsSampleUnitType,
  RecordingsUnitSystem
} from 'airthings/types/recordings';
import RecordingJob from 'airthings/services/airthings/recordings/job';
import RecordingsCalibrationCertificate from 'airthings/services/airthings/recordings/calibration-certificate';
import RecordingsDataset from 'airthings/services/airthings/recordings/dataset';
import RecordingsSample from 'airthings/services/airthings/recordings/sample';
import RecordingsAttachment from 'airthings/services/airthings/recordings/attachment';
import {DataProxy} from '@apollo/client/core';
import {RecordingsEditDatasetByExternalIdResponse} from 'airthings/graphql/queries/recordings-edit-dataset-by-external-id';
import {GraphqlSortDirection} from 'airthings/types/graphql';
import {RecordingsCompanyDevicesWithLocationResponse} from 'airthings/graphql/queries/recordings-company-devices-with-location';
import {RecordingsCompanyDeviceCrosschecksResponse} from 'airthings/graphql/queries/recordings-company-device-crosschecks';

const DECIMALS_COUNT_BY_SAMPLE_UNIT_TYPE = {
  [RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE]: 1,
  [RecordingsSampleUnitType.RADON_UNCERTAINTY]: 2,
  [RecordingsSampleUnitType.TEMPERATURE]: 1,
  [RecordingsSampleUnitType.PRESSURE]: 4,
  [RecordingsSampleUnitType.HUMIDITY]: 1
};

const CALIBRATION_VALIDITY_DAYS = 365;
const CALIBRATION_DUE_DAYS = 30;

interface dailyRadonTally {
  [key: string]: {date: Date; count: number; radon: number};
}

export default class Recordings extends Service {
  @service('airthings/recordings/calibration-certificate')
  calibrationCertificate: RecordingsCalibrationCertificate;

  @service('airthings/recordings/device')
  device: RecordingsDevice;

  @service('airthings/recordings/dataset')
  dataset: RecordingsDataset;

  @service('airthings/recordings/sample')
  sample: RecordingsSample;

  @service('airthings/recordings/attachment')
  attachment: RecordingsAttachment;

  @service('airthings/recordings/job')
  job: RecordingJob;

  @action
  presentRecordingsCompanyDevicesWithLocation(
    viewer: RecordingsCompanyDevicesWithLocationResponse['viewer']
  ) {
    return this.device.presentRecordingsCompanyDevicesWithLocation(viewer);
  }

  @action
  presentRecordingsCompanyDevices(
    viewer: RecordingsCompanyDevicesResponse['viewer']
  ) {
    return this.device.presentRecordingsCompanyDevices(viewer);
  }

  @action
  presentRecordingsCompanyDeviceCrosschecks(
    viewer: RecordingsCompanyDeviceCrosschecksResponse['viewer']
  ) {
    return this.device.presentRecordingsCompanyDeviceCrosschecks(viewer);
  }

  @action
  presentRecordingsCompanyPaginatedDatasets(
    viewer: RecordingsCompanyPaginatedDatasetsResponse['viewer']
  ) {
    return this.dataset.presentRecordingsCompanyPaginatedDatasets(viewer);
  }

  @action
  presentRecordingsEditDataset(
    viewer: RecordingsEditDatasetByExternalIdResponse['viewer']
  ) {
    return this.dataset.presentRecordingsEditDataset(viewer);
  }

  /**
   * @description Returns a Promise that resolves with a Report.
   */
  async fetchCalibrationCertificateByUUID(calibrationCertificateUUID: string) {
    return this.calibrationCertificate.fetchCalibrationCertificateByUUID(
      calibrationCertificateUUID
    );
  }

  /**
   * @description Returns a correctly formatted number for a sample unit
   */
  formatSampleUnit(value: number, unitType: RecordingsSampleUnitType) {
    return Intl.NumberFormat('en-us', {
      minimumFractionDigits: DECIMALS_COUNT_BY_SAMPLE_UNIT_TYPE[unitType],
      maximumFractionDigits: DECIMALS_COUNT_BY_SAMPLE_UNIT_TYPE[unitType]
    }).format(value);
  }

  /**
   * @description Returns the number of decimals that should be displayed for a sample unit type
   */
  decimalCountForSampleUnitType(unitType: RecordingsSampleUnitType) {
    return DECIMALS_COUNT_BY_SAMPLE_UNIT_TYPE[unitType];
  }

  /**
   * @description Returns an empty Device struct
   */
  deviceStruct() {
    return this.device.struct();
  }

  /**
   * @description Returns a watchQuery containing the devices of a company
   */
  async watchCompanyDevices(
    queryManager: Apollo,
    searchTerm?: string,
    sortField?: DevicesSortField,
    sortDirection?: GraphqlSortDirection
  ) {
    return this.device.watchCompanyDevices(
      queryManager,
      searchTerm,
      sortField,
      sortDirection
    );
  }

  async watchCompanyDevicesWithLocation(queryManager: Apollo) {
    return this.device.watchCompanyDevicesWithLocation(queryManager);
  }

  async watchCompanyDeviceCrosschecks(
    queryManager: Apollo,
    serialNumber: string
  ) {
    return this.device.watchCompanyDeviceCrosschecks(
      queryManager,
      serialNumber
    );
  }

  async fetchDeviceCrosschecks(uuid: string) {
    return this.device.fetchDeviceCrosschecks(uuid);
  }

  /**
   * @description Returns a watchQuery containing the paginated datasets of a company
   */
  async watchCompanyPaginatedDataset(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.dataset.watchCompanyPaginatedDatasets(
      queryManager,
      page,
      searchTerm
    );
  }

  /**
   * @description Returns a watchQuery containing the paginated datasets of a company
   */
  async fetchDuplicateDatesets(datasetId: string) {
    return this.dataset.fetchDuplicateDatesets(datasetId);
  }

  /**
   * @description Delete a device and parses the server-side validations.
   */
  async deleteDevice(device: RecordingsDeviceStruct) {
    return this.device.deleteDevice(device);
  }

  /**
   * @description Update a device and parses the server-side validations.
   */
  async updateDevice(device: RecordingsDeviceStruct) {
    return this.device.updateDevice(device);
  }

  /**
   * @description Delete a dataset attachment and parses the server-side validations.
   */
  async deleteDatasetAttachment(
    attachment: RecordingsDatasetAttachmentStruct,
    onAttachmentDelete: (store: DataProxy, attachmentId: string) => void
  ) {
    return this.attachment.deleteFromDataset(attachment, onAttachmentDelete);
  }

  /**
   * @description Validates a new device and returns a hash with errors if there’s any
   */
  validateCreateDevice(device: RecordingsDeviceStruct) {
    return this.device.validateCreate(device);
  }

  /**
   * @description Validates a device update and returns a hash with errors if there’s any
   */
  validateUpdateDevice(device: RecordingsDeviceStruct) {
    return this.device.validateUpdate(device);
  }

  /**
   * @description Creates a device and parses the server-side validations.
   */
  async createDevice(device: RecordingsDeviceStruct) {
    return this.device.createDevice(device);
  }

  /**
   * @description Returns a watchQuery containing a complete dataset by its external Id.
   */
  async watchDetailedDatasetByExternalId(
    externalId: string,
    unitSystem: RecordingsUnitSystem,
    queryManager: Apollo
  ) {
    return this.dataset.watchDetailedDatasetByExternalId(
      externalId,
      unitSystem,
      queryManager
    );
  }

  /**
   * @description Returns a dataset for edition
   */
  async watchEditDatasetByExternalId(externalId: string, queryManager: Apollo) {
    return this.dataset.watchEditDatasetByExternalId(externalId, queryManager);
  }

  /**
   * @description Delete a dataset and parses the server-side validations.
   */
  async deleteDataset(dataset: RecordingsListDatasetStruct) {
    return this.dataset.deleteDataset(dataset);
  }

  /**
   * @description Update dataset with new values
   */
  async updateDataset(dataset: RecordingsEditDatasetStruct) {
    return this.dataset.update(dataset);
  }

  /**
   * @description Validates a device and returns a hash with errors if there’s any
   */
  validateDataset(dataset: RecordingsEditDatasetStruct) {
    return this.dataset.validate(dataset);
  }

  /**
   * @description Retrieve the unit label for a given unit
   */
  sampleUnitLabel(unit: RecordingsSampleUnit) {
    return this.sample.unitLabel(unit);
  }

  /**
   * @description Transform a sample struct into a chart data point [timestamp, value]
   */
  sampleToChartPoint(
    sample: RecordingsSampleStruct,
    unitType: RecordingsSampleUnitType
  ) {
    return this.sample.sampleToChartPoint(sample, unitType);
  }

  /**
   * @description Remove a newly deleted attachment from Apollo's cache for the `EditDatasetByExternalId` query
   */
  removeDeletedAttachmentFromEditDatasetByExternalId(
    store: DataProxy,
    attachmentId: string,
    externalId: string
  ) {
    this.dataset.removeDeletedAttachmentFromEditDatasetByExternalId(
      store,
      attachmentId,
      externalId
    );
  }

  /**
   * @description Starts the dataset CSV generation on the API
   */
  async generateDatasetCSV(datasetId: string) {
    return this.dataset.generateCSV(datasetId);
  }

  /**
   * @description Starts the yearly datasets CSV generation on the API
   */
  async generateDatasetBatchCSV(from: Date, to: Date, includeUnnamed: boolean) {
    return this.dataset.generateDatasetBatchCSV(from, to, includeUnnamed);
  }

  /**
   * @description Starts the calibration certificate generation on the API
   */
  async generateCalibrationCertificate(calibrationCertificateUUID: string) {
    return this.calibrationCertificate.generatePDF(calibrationCertificateUUID);
  }

  /**
   * @description Starts the calibration certificate generation on the API
   */
  async generateCrosschecksReport(deviceId: string) {
    return this.device.generateCrosscheckReport(deviceId);
  }

  /**
   * @description Returns a Promise that resolves with a calibration certificate generation job
   */
  async fetchCrosschecksReportGenerationJob(jobId: string) {
    return this.job.fetchCrosschecksReportGenerationJob(jobId);
  }

  /**
   * @description Starts the import of the factory calibration certificate
   */
  async importFactoryCalibrationCertificates(fileUrl: string) {
    return this.calibrationCertificate.importFactory(fileUrl);
  }

  /**
   * @description Starts the import of the lab calibration certificate
   */
  async importLabCalibrationCertificates(fileUrl: string) {
    return this.calibrationCertificate.importLab(fileUrl);
  }

  /**
   * @description Starts the generation of the lab calibration certificates import PDF
   */
  async generateLabImportPdf(jobId: string) {
    return this.calibrationCertificate.generateLabImportPdf(jobId);
  }

  async fetchCalibrationCertificatesByImportJob(jobId: string) {
    return this.calibrationCertificate.fetchCalibrationCertificatesByImportJob(
      jobId
    );
  }

  /**
   * @description Returns a Promise that resolves with a dataset CSV generation job
   */
  async fetchDatasetCSVGenerationJob(jobId: string) {
    return this.job.fetchDatasetCSVGenerationJob(jobId);
  }

  /**
   * @description Returns a Promise that resolves with a dataset CSV generation job
   */
  async fetchDatasetBatchCSVGenerationJob(jobId: string) {
    return this.job.fetchDatasetBatchCSVGenerationJob(jobId);
  }

  /**
   * @description Returns a Promise that resolves with a calibration certificate generation job
   */
  async fetchCalibrationCertificateGenerationJob(jobId: string) {
    return this.job.fetchCalibrationCertificateGenerationJob(jobId);
  }

  /**
   * @description Returns a Promise that resolves with a calibration certificate import job
   */
  async fetchCalibrationCertificateImportJob(jobId: string) {
    return this.job.fetchCalibrationCertificateImportJob(jobId);
  }

  /**
   * @description Returns a Promise that resolves with a lab import pdf generation job
   */
  async fetchLabImportPdfGenerationJob(jobId: string) {
    return this.job.fetchLabImportPdfGenerationJob(jobId);
  }

  async createDatasetAttachment(
    datasetId: string,
    attachmentUrl: string,
    fileType: string
  ) {
    return this.attachment.createForDataset(datasetId, attachmentUrl, fileType);
  }

  dailyRadonAverage(samples: RecordingsSampleStruct[]) {
    const daily = samples.reduce((tally: dailyRadonTally, sample) => {
      const date = moment(sample.recordedAt).format('YYYY-MM-DD');
      tally[date] = tally[date] || {date, count: 0, radon: 0};
      tally[date].count += 1;
      tally[date].radon += sample.radonShortTermAverage;

      return tally;
    }, {});

    return Object.values(daily).map((group) => {
      return {
        recordedAt: group.date,
        radonShortTermAverage: group.radon / group.count
      };
    });
  }

  isCalibrationDue(expirationDate?: Date | string | null) {
    if (!expirationDate) return false;

    expirationDate =
      typeof expirationDate === 'string'
        ? parseISO(expirationDate)
        : expirationDate;
    const now = new Date();
    const dueDate = addDays(now, CALIBRATION_DUE_DAYS);

    return isBefore(expirationDate, dueDate) && isAfter(expirationDate, now);
  }

  isCalibrationExpired(expirationDate?: Date | string | null) {
    if (!expirationDate) return false;

    expirationDate =
      typeof expirationDate === 'string'
        ? parseISO(expirationDate)
        : expirationDate;
    const now = new Date();

    return isBefore(expirationDate, now);
  }

  computeExpirationDate(activationDate?: Date | string | null): Date | null {
    if (!activationDate) return null;

    activationDate =
      typeof activationDate === 'string'
        ? parseISO(activationDate)
        : activationDate;

    return addDays(activationDate, CALIBRATION_VALIDITY_DAYS);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/recordings': Recordings;
  }
}
