// Vendor
import Helper from '@ember/component/helper';
import {getObservable} from 'ember-apollo-client';

// Types
import Observable from 'zen-observable';

interface QueryResult {
  viewer?: any;
  admin?: any;
}

export default class extends Helper {
  observable: Observable<unknown>;

  compute(
    [queryResult]: [QueryResult],
    options: {
      with: (queryResult: QueryResult | null) => any;
      rootKey: string | undefined;
    }
  ) {
    if (!queryResult) return options.with(null);

    const rootKey = options.rootKey;
    const observable = getObservable(queryResult);

    this.setupObservable(observable);

    if (!rootKey) return options.with(queryResult);

    return options.with(queryResult[rootKey as keyof typeof queryResult]);
  }

  private setupObservable(observable: Observable<unknown>) {
    if (observable !== this.observable) {
      observable.subscribe({
        next: () => {
          this.recompute();
        }
      });

      this.observable = observable;
    }
  }
}
