// Vendor
import Service, {inject as service} from '@ember/service';

// Services
import Apollo from 'airthings/services/apollo';
import API from 'airthings/services/airthings/recordings/api';
import Recordings from 'airthings/services/airthings/recordings';

// Types
import {
  DevicesSortField,
  RecordingsDeviceStruct
} from 'airthings/types/recordings';
import Form, {Validator} from 'airthings/services/form';
import {RecordingsCompanyDevicesResponse} from 'airthings/graphql/queries/recordings-company-devices';
import {GraphqlSortDirection} from 'airthings/types/graphql';
import {RecordingsCompanyDevicesWithLocationResponse} from 'airthings/graphql/queries/recordings-company-devices-with-location';
import {RecordingsCompanyDeviceCrosschecksResponse} from 'airthings/graphql/queries/recordings-company-device-crosschecks';

export default class RecordingsDevice extends Service {
  @service('airthings/recordings/api')
  api: API;

  @service('form')
  form: Form;

  @service('airthings/recordings')
  recordings: Recordings;

  struct(
    base?: RecursivePartial<RecordingsDeviceStruct>
  ): RecordingsDeviceStruct {
    return {
      id: null,
      name: '',
      serialNumber: '',
      ...base,
      calibrationCertificate: base?.calibrationCertificate
        ? {
            ...base?.calibrationCertificate,
            isDue: this.recordings.isCalibrationDue(
              base?.calibrationCertificate?.expiredAt
            ),
            isExpired: this.recordings.isCalibrationExpired(
              base?.calibrationCertificate?.expiredAt
            )
          }
        : null
    };
  }

  async watchCompanyDevices(
    queryManager: Apollo,
    searchTerm?: string,
    sortField?: DevicesSortField,
    sortDirection?: GraphqlSortDirection
  ) {
    return this.api.watchCompanyDevices(
      queryManager,
      searchTerm,
      sortField,
      sortDirection
    );
  }

  async watchCompanyDevicesWithLocation(queryManager: Apollo) {
    return this.api.watchCompanyDevicesWithLocation(queryManager);
  }

  async watchCompanyDeviceCrosschecks(
    queryManager: Apollo,
    serialNumber: string
  ) {
    return this.api.watchCompanyDeviceCrosschecks(queryManager, serialNumber);
  }

  async fetchDeviceCrosschecks(uuid: string) {
    return this.api.fetchDeviceCrosschecks(uuid);
  }

  async deleteDevice(device: RecordingsDeviceStruct) {
    return this.api.deleteDevice(device);
  }

  async updateDevice(device: RecordingsDeviceStruct) {
    const mutationResponse = await this.api.updateDevice(device);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'recordings.device'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async generateCrosscheckReport(deviceId: string) {
    return this.api.generateCrosscheckReport(deviceId);
  }

  presentRecordingsCompanyDevices(
    viewer: RecordingsCompanyDevicesResponse['viewer']
  ) {
    return viewer.company.devices.map(this.struct.bind(this));
  }

  presentRecordingsCompanyDeviceCrosschecks(
    viewer: RecordingsCompanyDeviceCrosschecksResponse['viewer']
  ) {
    return {
      device: viewer.company.device,
      crosschecks: viewer.company.device.crosschecks
    };
  }

  presentRecordingsCompanyDevicesWithLocation(
    viewer: RecordingsCompanyDevicesWithLocationResponse['viewer']
  ) {
    return viewer.company.devicesWithLocation.map(this.struct.bind(this));
  }

  async createDevice(device: RecordingsDeviceStruct) {
    const mutationResponse = await this.api.createDevice(device);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'recordings.device'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  validateCreate(device: RecordingsDeviceStruct) {
    const validators: Array<Validator<string, 'required' | 'match'>> = [
      {
        field: 'name',
        isValid: Boolean(device.name),
        code: 'required'
      },
      {
        field: 'serial-number',
        isValid: Boolean(device.serialNumber),
        code: 'required'
      },
      {
        field: 'serial-number-confirmation',
        isValid: device.serialNumber === device.serialNumberConfirmation,
        code: 'match'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'recordings.device'
    });
  }

  validateUpdate(device: RecordingsDeviceStruct) {
    const validators: Array<Validator<string, 'required' | 'match'>> = [
      {
        field: 'name',
        isValid: Boolean(device.name),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'recordings.device'
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/recordings/device': RecordingsDevice;
  }
}
