// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {
  AccountUserStruct,
  AccountCompanyInformation,
  AccountCompanyLogo,
  AccountCompanyStruct,
  Certificate
} from 'airthings/types/account';
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import {OnboardingSectionSlug} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
  company: AccountCompanyStruct;
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageAppCompanyEdit extends Component<Args> {
  @service('airthings/account')
  account: Account;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @tracked
  companyStruct: AccountCompanyStruct = this.args.company;

  @dropTask
  *companyPersistTask(): TaskGenerator<void> {
    return yield this.account.updateCompany(this.companyStruct);
  }

  @action
  changeInformation(updatedInformation: AccountCompanyInformation) {
    this.companyStruct = {
      ...this.companyStruct,
      information: updatedInformation
    };
  }

  @action
  changeCertificates(updatedCertificates: Certificate[]) {
    this.companyStruct = {
      ...this.companyStruct,
      certificates: updatedCertificates
    };
  }

  @action
  changeLogo(updatedLogo: AccountCompanyLogo) {
    this.companyStruct = {
      ...this.companyStruct,
      logo: updatedLogo
    };
  }

  @action
  validate() {
    return this.account.validateCompany(this.companyStruct);
  }

  @action
  showFlashMessage() {
    this.flashMessages.success(this.intl.t('app-company-edit.form.success'));
  }
}
