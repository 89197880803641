// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import CommunicationBoard from 'airthings/services/airthings/communication-board';

export default class MobileMessageController extends Controller {
  @service('airthings/communication-board')
  communicationBoard: CommunicationBoard;
}
