// @ts-nocheck

// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import Highcharts from 'highcharts';

// Types
import {Chart, AxisLabelsFormatterContextObject} from 'highcharts';
import Recordings from 'airthings/services/airthings/recordings';
import IntlService from 'ember-intl/services/intl';

interface Args {
  height: number;
  icon: string;
  title: string;
  unitLabel: string;
  data: Array<[number, number]>;
  valueDecimals: number;
  xAxisMin: number;
  formatValue: (value: number) => string;
  pointFormatter?: () => string;
  onChartInitialized?: (chart: Chart) => void;
}

export default class RecordingsChart extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  get title() {
    return `${this.args.title} (${this.args.unitLabel})`;
  }

  @action
  initializeChart(chartElement: HTMLElement) {
    const format = this.args.formatValue;
    const pointFormatter = this.args.pointFormatter;
    const unitLabel = this.args.unitLabel;

    const chart = Highcharts.chart(chartElement, {
      chart: {
        type: 'line',
        marginTop: 50,
        marginLeft: 75,
        height: this.args.height
      },
      title: {
        text: undefined
      },
      xAxis: {
        crosshair: true,
        type: 'datetime',
        min: this.args.xAxisMin
      },
      yAxis: {
        title: undefined,
        labels: {
          formatter: (context: AxisLabelsFormatterContextObject) => {
            return format(context.value);
          }
        }
      },
      series: [
        {
          type: 'line',
          color: '#ffc000',
          data: this.args.data
        }
      ],
      tooltip: {
        positioner: () => ({x: 0, y: 0}),
        borderWidth: 0,
        backgroundColor: 'none',
        pointFormat: this.args.pointFormatter ? null : `{point.y} ${unitLabel}`,
        pointFormatter: this.args.pointFormatter
          ? function () {
              return pointFormatter(this);
            }
          : null,
        headerFormat: '',
        shadow: false,
        style: {
          fontSize: '18px'
        },
        valueDecimals: this.args.valueDecimals
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      time: {
        useUTC: false
      }
    });

    if (typeof this.args.onChartInitialized === 'function') {
      this.args.onChartInitialized(chart);
    }
  }
}
