export default {
  "size-2": "4px",
  "size-3": "8px",
  "size-4": "16px",
  "size-5": "32px",
  "table-row": "_table-row_16ombx",
  "report": "_report_16ombx",
  "message-container": "_message-container_16ombx",
  "title": "_title_16ombx",
  "hint": "_hint_16ombx"
};
