// Vendor
import Service, {inject as service} from '@ember/service';
import Apollo from 'airthings/services/apollo';

// Types
import API from 'airthings/services/airthings/recordings/api';
import {
  RecordingsEditDatasetStruct,
  RecordingsListDatasetStruct,
  RecordingsUnitSystem
} from 'airthings/types/recordings';
import Form, {Validator} from 'airthings/services/form';
import RecordingsDevice from 'airthings/services/airthings/recordings/device';
import {DataProxy} from '@apollo/client/core';
import {RecordingsCompanyPaginatedDatasetsResponse} from 'airthings/graphql/queries/recordings-company-paginated-datasets';
import {RecordingsEditDatasetByExternalIdResponse} from 'airthings/graphql/queries/recordings-edit-dataset-by-external-id';
import {DuplicatedCompanyDatasetResponse} from 'airthings/graphql/queries/duplicated-company-datasets';

export default class RecordingsDataset extends Service {
  @service('airthings/recordings/api')
  api: API;

  @service('airthings/recordings/device')
  device: RecordingsDevice;

  @service('form')
  form: Form;

  async watchCompanyPaginatedDatasets(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.api.watchCompanyPaginatedDatasets(
      queryManager,
      page,
      searchTerm
    );
  }

  async fetchDuplicateDatesets(datasetId: string) {
    const response: DuplicatedCompanyDatasetResponse | null =
      await this.api.fetchDuplicateDatesets(datasetId);
    if (!response) return [];

    return response.viewer.company.duplicateDatasets.map((dataset) => ({
      value: dataset.id,
      label: dataset.name,
      recordingStartedAt: dataset.recordingStartedAt,
      durationInSeconds: dataset.durationInSeconds
    }));
  }

  async watchDetailedDatasetByExternalId(
    externalId: string,
    unitSystem: RecordingsUnitSystem,
    queryManager: Apollo
  ) {
    return this.api.watchDetailedDatasetByExternalId(
      externalId,
      unitSystem,
      queryManager
    );
  }

  async watchEditDatasetByExternalId(externalId: string, queryManager: Apollo) {
    return this.api.watchEditDatasetByExternalId(externalId, queryManager);
  }

  async deleteDataset(dataset: RecordingsListDatasetStruct) {
    return this.api.deleteDataset(dataset);
  }

  async update(dataset: RecordingsEditDatasetStruct) {
    return this.api.updateDataset(dataset);
  }

  validate(dataset: RecordingsEditDatasetStruct) {
    const validators: Array<Validator<string, 'required' | 'match'>> = [
      {
        field: 'name',
        isValid: Boolean(dataset.information.name),
        code: 'required'
      },
      {
        field: 'dataset-type',
        isValid: Boolean(dataset.information.datasetType),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'recordings.dataset'
    });
  }

  presentRecordingsCompanyPaginatedDatasets(
    viewer: RecordingsCompanyPaginatedDatasetsResponse['viewer']
  ) {
    return {
      datasets: viewer.company.paginatedDatasets.entries,
      pagination: viewer.company.paginatedDatasets.metadata
    };
  }

  presentRecordingsEditDataset(
    viewer: RecordingsEditDatasetByExternalIdResponse['viewer']
  ) {
    const dataset = viewer.company.datasetByExternalId;

    if (!dataset) return null;

    return {
      information: {
        id: dataset.id,
        name: dataset.name,
        comment: dataset.comment,
        datasetType: dataset.datasetType,
        temporaryConditions: dataset.temporaryConditions,
        protocolViolations: dataset.protocolViolations
      },
      contactMetadata: {
        name: dataset.metadata.contact.name,
        phoneNumber: dataset.metadata.contact.phoneNumber
      },
      measurementMetadata: {
        address: dataset.metadata.measurement.address,
        city: dataset.metadata.measurement.city,
        country: dataset.metadata.measurement.country,
        postalCode: dataset.metadata.measurement.postalCode,
        state: dataset.metadata.measurement.state
      },
      buildingMetadata: {
        propertyName: dataset.metadata.building.propertyName,
        floor: dataset.metadata.building.floor,
        foundationType: dataset.metadata.building.foundationType,
        mitigationSystem: dataset.metadata.building.mitigationSystem,
        roomType: dataset.metadata.building.roomType,
        type: dataset.metadata.building.type,
        ventilation: dataset.metadata.building.ventilation,
        yearOfConstruction: dataset.metadata.building.yearOfConstruction
      },
      ownerMetadata: {
        address: dataset.metadata.owner.address,
        city: dataset.metadata.owner.city,
        country: dataset.metadata.owner.country,
        email: dataset.metadata.owner.email,
        name: dataset.metadata.owner.name,
        phoneNumber: dataset.metadata.owner.phoneNumber,
        postalCode: dataset.metadata.owner.postalCode,
        state: dataset.metadata.owner.state
      },
      customerMetadata: {
        address: dataset.metadata.customer.address,
        city: dataset.metadata.customer.city,
        country: dataset.metadata.customer.country,
        email: dataset.metadata.customer.email,
        name: dataset.metadata.customer.name,
        phoneNumber: dataset.metadata.customer.phoneNumber,
        postalCode: dataset.metadata.customer.postalCode,
        state: dataset.metadata.customer.state
      },
      userMetadata: {
        name: dataset.metadata.user.name,
        email: dataset.metadata.user.email,
        phoneNumber: dataset.metadata.user.phoneNumber
      },
      attachments: [...dataset.attachments]
    };
  }

  removeDeletedAttachmentFromEditDatasetByExternalId(
    store: DataProxy,
    attachmentId: string,
    externalId: string
  ) {
    this.api.removeDeletedAttachmentFromEditDatasetByExternalId(
      store,
      attachmentId,
      externalId
    );
  }

  async generateCSV(datasetId: string) {
    return this.api.generateDatasetCSV(datasetId);
  }

  async generateDatasetBatchCSV(from: Date, to: Date, includeUnnamed: boolean) {
    return this.api.generateDatasetBatchCSV(from, to, includeUnnamed);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/recordings/dataset': RecordingsDataset;
  }
}
