// Vendor
import Component from '@glimmer/component';

interface Args {
  reportData: {
    couponCode: string;
    affiliateProgramAdImageUrl: string;
  };
}

export default class ReportsBlocksAffiliateProgramAdOutput extends Component<Args> {}
