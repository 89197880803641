// Vendor
import Service, {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import Apollo from 'airthings/services/apollo';
import Form from 'airthings/services/form';
import ReportTemplate from 'airthings/services/airthings/reports/template';
import {
  ReportsTemplateStruct,
  ReportsBlockService,
  ReportsBlockStruct
} from 'airthings/types/reports';
import Report from 'airthings/services/airthings/reports/report';
import ReportJob from 'airthings/services/airthings/reports/job';
import {ReportCompanyTemplatesResponse} from 'airthings/graphql/queries/report-company-templates';
import {ReportCompanyTemplateBlocksResponse} from 'airthings/graphql/queries/report-company-template-blocks';

export default class Reports extends Service {
  @service('airthings/reports/report')
  report: Report;

  @service('form')
  form: Form;

  @service('airthings/reports/template')
  template: ReportTemplate;

  @service('airthings/reports/job')
  job: ReportJob;

  @action
  presentReportCompanyTemplateBlocks(
    viewer: ReportCompanyTemplateBlocksResponse['viewer']
  ) {
    return this.template.presentReportCompanyTemplateBlocks(viewer);
  }

  @action
  presentReportCompanyTemplates(
    viewer: ReportCompanyTemplatesResponse['viewer']
  ) {
    return this.template.presentReportCompanyTemplates(viewer);
  }

  /**
   * @description Returns an empty Template struct
   */
  templateStruct() {
    return this.template.struct();
  }

  /**
   * @description Returns a watchQuery containing the devices of a company
   */
  async watchCompanyTemplates(queryManager: Apollo) {
    return this.template.watchCompanyTemplates(queryManager);
  }

  /**
   * @description Validates a template and returns a hash with errors if there’s any
   */
  validateTemplate(template: ReportsTemplateStruct) {
    return this.template.validate(template);
  }

  /**
   * @description Creates a template and parses the server-side validations.
   */
  async createTemplate(template: ReportsTemplateStruct) {
    return this.template.createTemplate(template);
  }

  /**
   * @description Updates a template and parses the server-side validations.
   */
  async updateTemplate(template: ReportsTemplateStruct) {
    return this.template.updateTemplate(template);
  }

  /**
   * @description Deletes a report template.
   */
  async deleteTemplate(templateId: string) {
    return this.template.deleteTemplate(templateId);
  }

  /**
   * @description Duplicates a report template
   */
  async duplicateTemplate(template: ReportsTemplateStruct) {
    return this.template.duplicateTemplate(template);
  }

  /**
   * @description Creates a new block for a given template id and parses the server-side validations.
   */
  async createTemplateBlock(block: ReportsBlockStruct, templateId: string) {
    return this.template.createBlock(block, templateId);
  }

  /**
   * @description Updates a template block and parses the server-side validations.
   */
  async updateTemplateBlock(block: ReportsBlockStruct) {
    return this.template.updateBlock(block);
  }

  /**
   * @description Deletes a block from a given template id.
   */
  async deleteTemplateBlock(blockId: string) {
    return this.template.deleteBlock(blockId);
  }

  /**
   * @description Update the block ranks for a given template id and parses the server-side validations.
   */
  async reorderTemplateBlocks(
    reorderedBlocks: ReportsBlockStruct[],
    templateId: string
  ) {
    return this.template.reorderBlocks(reorderedBlocks, templateId);
  }

  /**
   * @description Create a template publication.
   */
  async publishTemplate(templateId: string) {
    return this.template.publishTemplate(templateId);
  }

  /**
   * @description Registers a block in the system. The block can still be filtered
   * out of the list if the back-end doesn’t send it as an "available block"
   */
  registerTemplateBlockService(
    blockName: string,
    service: ReportsBlockService
  ) {
    this.template.registerBlockService(blockName, service);
  }

  /**
   * @description Returns a promise that resolves with a list of groups containing
   * a list of blocks.
   */
  async listTemplateBlockGroups() {
    return this.template.listBlockGroups();
  }

  /**
   * @description Returns a block definition for a specific block.
   */
  getTemplateBlockDefinition(key: string) {
    return this.template.getBlockDefinition(key);
  }

  /**
   * @description Returns all block definitions.
   */
  getTemplateBlockDefinitions() {
    return this.template.getBlockDefinitions();
  }

  /**
   * @description Returns option objects representing report template publications.
   */
  async fetchReportTemplatePublicationOptions() {
    return this.template.fetchReportTemplatePublicationOptions();
  }

  /**
   * @description Create a report from a dataset Id and a template publication Id.
   */
  async createReport(
    datasetId: string,
    duplicateDatasetId: string | null,
    reportTemplatePublicationId: string,
    affiliateProgramAdId: string | null,
    truncatedSecondsFromStart: number,
    truncatedSecondsFromEnd: number,
    testStartedAt: Date,
    testEndedAt: Date
  ) {
    const mutationResponse = await this.template.createReport(
      datasetId,
      duplicateDatasetId,
      reportTemplatePublicationId,
      affiliateProgramAdId,
      truncatedSecondsFromStart,
      truncatedSecondsFromEnd,
      testStartedAt,
      testEndedAt
    );

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'reports.report'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  /**
   * @description Apply settings to a report block.
   */
  async configureReportBlock(reportBlockId: string, reportSettings: object) {
    return this.template.configureReportBlock(reportBlockId, reportSettings);
  }

  /**
   * @description Returns a watchQuery containing the blocks of a template
   */
  async watchCompanyTemplateBlocks(queryManager: Apollo, templateId: string) {
    return this.template.watchCompanyTemplateBlocks(queryManager, templateId);
  }

  /**
   * @description Returns a Promise that resolves with a Report.
   */
  async fetchReportByUUID(reportUUID: string) {
    return this.report.fetchReportByUUID(reportUUID);
  }

  /**
   * @description Returns a Promise that resolves with a mocked Report.
   */
  async fetchMockedReport(reportTemplateId: string) {
    return this.report.fetchMockedReport(reportTemplateId);
  }

  /**
   * @description Returns basic dataset information for report configuration.
   */
  async fetchDatasetByExternalId(datasetExternalId: string) {
    return this.report.fetchDatasetByExternalId(datasetExternalId);
  }

  /**
   * @description Starts the report generation on the API
   */
  async generateReport(reportId: string) {
    return this.report.generateReport(reportId);
  }

  /**
   * @description Returns a Promise that resolves with a report generation job
   */
  async fetchReportGenerationJob(jobId: string) {
    return this.job.fetchReportGenerationJob(jobId);
  }

  /**
   * @description Sends a command to send the report by email to the recipient provided
   */
  async sendReportByEmail(
    reportId: string,
    recipientEmails: string[],
    ccCurrentUser: boolean,
    customMessage: string,
    includeCompanyLogo: boolean
  ) {
    return this.report.sendReportByEmail(
      reportId,
      recipientEmails,
      ccCurrentUser,
      customMessage,
      includeCompanyLogo
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/reports': Reports;
  }
}
