// Vendor
import {gql} from '@apollo/client/core';

export enum JobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED'
}

export interface CalibrationCertificateGenerationJobVariables {
  jobId: string;
}

export interface CalibrationCertificateGenerationJobResponse {
  viewer: {
    id: string;

    company: {
      id: string;

      calibrationCertificateGenerationJob: {
        id: string;
        state: JobState;

        calibrationCertificate: {
          id: string;
          pdfFileUrl?: string;
        };
      };
    };
  };
}

export default gql`
  query RecordingsCalibrationCertificateGenerationJob($jobId: ID!) {
    viewer {
      id

      company {
        id

        calibrationCertificateGenerationJob(jobId: $jobId) {
          id
          state

          calibrationCertificate {
            id
            pdfFileUrl
          }
        }
      }
    }
  }
`;
