export default {
  "font-bold": "700",
  "block": "_block_r8qogc _light-box-wrapper_1ghgfm",
  "content": "_content_r8qogc _light-box-content_1ghgfm",
  "cell": "_cell_r8qogc _cell_1ghgfm",
  "key": "_key_r8qogc _key_1ghgfm",
  "value": "_value_r8qogc _value_1ghgfm",
  "header": "_header_r8qogc _dark-box-header_1ghgfm",
  "icon-wrapper": "_icon-wrapper_r8qogc _header-icon-wrapper_1ghgfm",
  "icon": "_icon_r8qogc _header-icon_1ghgfm",
  "title": "_title_r8qogc _dark-box-header-title_1ghgfm"
};
