// Vendor
import {Ability} from 'ember-can';

// Types
import {AccountUserStruct, UserRole} from 'airthings/types/account';

export default class InvitationAbility extends Ability {
  currentUser: AccountUserStruct;
  user: AccountUserStruct;

  get canSend() {
    return this.currentUser.role === UserRole.MANAGER;
  }

  get canResend() {
    return this.currentUser.role === UserRole.MANAGER;
  }

  get canDelete() {
    return this.currentUser.role === UserRole.MANAGER;
  }
}
