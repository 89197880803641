// Vendor
import Service, {inject as service} from '@ember/service';
import Apollo from 'airthings/services/apollo';

// Types
import API from 'airthings/services/airthings/reports/api';
import {
  ReportsBlockDefinition,
  ReportsBlockGroupDefinition,
  ReportsBlockService,
  ReportsBlockStruct,
  ReportsLanguage,
  ReportsTemplateStruct
} from 'airthings/types/reports';
import Form, {Validator} from 'airthings/services/form';
import IntlService from 'ember-intl/services/intl';
import {ReportCompanyTemplatesResponse} from 'airthings/graphql/queries/report-company-templates';
import {ReportCompanyPublishedTemplateNamesResponse} from 'airthings/graphql/queries/report-company-published-template-names';
import {ReportCompanyTemplateBlocksResponse} from 'airthings/graphql/queries/report-company-template-blocks';
import {formatDistance, parseISO} from 'date-fns';

const NAME_MAX_LENGTH = 255;
const DESCRIPTION_MAX_LENGTH = 255;

export default class Template extends Service {
  @service('airthings/reports/api')
  api: API;

  @service('form')
  form: Form;

  @service('intl')
  intl: IntlService;

  registeredBlockServices: {[key: string]: ReportsBlockService} = {};
  blockGroups: ReportsBlockGroupDefinition[];

  struct(
    base?: RecursivePartial<ReportsTemplateStruct>
  ): ReportsTemplateStruct {
    return {
      id: null,
      name: '',
      description: '',
      language: ReportsLanguage.EN,
      isCompanyFavorite: false,
      reportTemplatePublication: {
        id: null,
        ...base?.reportTemplatePublication
      },
      ...base
    };
  }

  async watchCompanyTemplates(queryManager: Apollo) {
    return this.api.watchCompanyReportTemplates(queryManager);
  }

  presentReportCompanyTemplates(
    viewer: ReportCompanyTemplatesResponse['viewer']
  ) {
    return viewer.company.reportTemplates.map(this.struct.bind(this));
  }

  async createTemplate(template: ReportsTemplateStruct) {
    const mutationResponse = await this.api.createReportTemplate(template);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'reports.template'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async updateTemplate(template: ReportsTemplateStruct) {
    const mutationResponse = await this.api.updateReportTemplate(template);

    if (!mutationResponse) return;

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'reports.template'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async duplicateTemplate(template: ReportsTemplateStruct) {
    const mutationResponse = await this.api.duplicateReportTemplate(template);

    if (!mutationResponse) return;

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'reports.template'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async deleteTemplate(templateId: string) {
    const mutationResponse = await this.api.deleteReportTemplate(templateId);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'reports.template'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async createBlock(block: ReportsBlockStruct, templateId: string) {
    const mutationResponse = await this.api.createReportTemplateBlock(
      block,
      templateId
    );

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'reports.block-definitions'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async updateBlock(block: ReportsBlockStruct) {
    const mutationResponse = await this.api.updateReportTemplateBlock(block);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'reports.block-definitions'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async deleteBlock(blockId: string) {
    return await this.api.deleteReportTemplateBlock(blockId);
  }

  async reorderBlocks(
    reorderedBlocks: ReportsBlockStruct[],
    templateId: string
  ) {
    return await this.api.reorderReportTemplateBlocks(
      reorderedBlocks,
      templateId
    );
  }

  async publishTemplate(templateId: string) {
    return await this.api.publishReportTemplate(templateId);
  }

  validate(template: ReportsTemplateStruct) {
    const validators: Array<Validator<string, 'required' | 'match' | 'max'>> = [
      {
        field: 'name',
        isValid: Boolean(template.name),
        code: 'required'
      },
      {
        field: 'name',
        isValid: template.name.length <= NAME_MAX_LENGTH,
        code: 'max'
      },
      {
        field: 'description',
        isValid: Boolean(template.description),
        code: 'required'
      },
      {
        field: 'description',
        isValid: template.description.length <= DESCRIPTION_MAX_LENGTH,
        code: 'max'
      },
      {
        field: 'language',
        isValid: Boolean(template.language),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'reports.template'
    });
  }

  registerBlockService(blockName: string, blockService: ReportsBlockService) {
    this.registeredBlockServices[blockName] = blockService;
  }

  async listBlockGroups() {
    if (this.blockGroups) return this.blockGroups;

    const availableBlocks = await this.api.fetchAvailableReportTemplateBlocks();

    const groupMapping = availableBlocks.reduce(
      (memo: Record<string, ReportsBlockDefinition[]>, block) => {
        const group = block.group;
        const blockKey = this.api.parseBlockType(block.type);

        if (!memo[group]) memo[group] = [];

        if (!this.registeredBlockServices[blockKey]) return memo;

        memo[group].push(this.registeredBlockServices[blockKey].definition);

        return memo;
      },
      {}
    );

    const blockGroups = Object.entries(groupMapping)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_group, blocks]) => Boolean(blocks.length))
      .map(([group, blocks]) => {
        return {
          key: group,
          name: this.intl.t(`reports.block-group-definitions.${group}`),
          blocks
        };
      });

    this.blockGroups = blockGroups;

    return blockGroups;
  }

  getBlockDefinition(key: string) {
    return this.registeredBlockServices[key].definition;
  }

  getBlockDefinitions() {
    return Object.values(this.registeredBlockServices).map(
      ({definition}) => definition
    );
  }

  async watchCompanyTemplateBlocks(queryManager: Apollo, templateId: string) {
    return this.api.watchCompanyTemplateBlocks(queryManager, templateId);
  }

  presentReportCompanyTemplateBlocks(
    viewer: ReportCompanyTemplateBlocksResponse['viewer']
  ) {
    if (!viewer.company.reportTemplate) return [];

    const blocks = viewer.company.reportTemplate.reportTemplateBlocks.slice();

    return blocks
      .sort((blockA, blockB) => blockA.rank - blockB.rank)
      .map(({blockType, id, settings}) => {
        const blockDefinition = this.getBlockDefinition(
          this.api.parseBlockType(blockType)
        );

        const templateSettingsStruct = blockDefinition.templateSettingsStruct
          ? blockDefinition.templateSettingsStruct(settings)
          : null;

        return {id, blockDefinition, templateSettingsStruct};
      });
  }

  async fetchReportTemplatePublicationOptions() {
    const response: ReportCompanyPublishedTemplateNamesResponse | null =
      await this.api.fetchCompanyPublishedTemplateNames();
    if (!response) return [];

    return response.viewer.company.reportTemplates
      .sort((firstTemplate, lastTemplate) => {
        if (!firstTemplate.reportTemplatePublication) return 1;
        if (!lastTemplate.reportTemplatePublication) return -1;

        return (
          parseISO(
            lastTemplate.reportTemplatePublication.insertedAt
          ).getTime() -
          parseISO(firstTemplate.reportTemplatePublication.insertedAt).getTime()
        );
      })
      .map((reportTemplate) => ({
        value: reportTemplate.reportTemplatePublication.id,
        isCompanyFavorite: reportTemplate.isCompanyFavorite,
        label: this.intl.t('reports.generation.initial-setup.template-option', {
          name: reportTemplate.name,
          timeAgo: formatDistance(
            parseISO(reportTemplate.reportTemplatePublication.insertedAt),
            new Date(),
            {addSuffix: true}
          )
        })
      }));
  }

  async createReport(
    datasetId: string,
    duplicateDatasetId: string | null,
    reportTemplatePublicationId: string,
    affiliateProgramAdId: string | null,
    truncatedSecondsFromStart: number,
    truncatedSecondsFromEnd: number,
    testStartedAt: Date,
    testEndedAt: Date
  ) {
    return this.api.createReport(
      datasetId,
      duplicateDatasetId,
      reportTemplatePublicationId,
      affiliateProgramAdId,
      truncatedSecondsFromStart,
      truncatedSecondsFromEnd,
      testStartedAt,
      testEndedAt
    );
  }

  async configureReportBlock(reportBlockId: string, reportSettings: object) {
    const mutationResponse = await this.api.configureReportBlock(
      reportBlockId,
      reportSettings
    );

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'reports.block-definitions'
    });

    return {
      ...mutationResponse,
      messages
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/reports/template': Template;
  }
}
