// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';

export default class LobbyCompany extends Route {
  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  async beforeModel(transition: Transition) {
    const completionStatus = await this.account.fetchAccountCompletionStatus();

    if (!completionStatus) {
      transition.abort();

      return this.account.logout();
    }

    if (!completionStatus.hasProfile) {
      transition.abort();

      this.router.replaceWith('lobby.profile');

      return;
    }

    if (completionStatus.hasCompany) {
      transition.abort();

      this.router.replaceWith('app');

      return;
    }

    if (completionStatus.isAdmin) {
      transition.abort();

      this.router.replaceWith('admin');

      return;
    }
  }
}
