// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import {ReportSettingsStruct} from 'airthings/services/airthings/reports/block-definitions/detailed-measurement-data';
import IntlService from 'ember-intl/services/intl';
import {RecordingsListDatasetStruct} from 'airthings/types/recordings';

interface Args {
  dataset: RecordingsListDatasetStruct;
  settingsStruct: ReportSettingsStruct;
  errors?: object;
  onChange: (settings: ReportSettingsStruct) => void;
}

export default class ReportsBlocksDetailedMeasurementDataReportSettings extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @action
  changeIncludeTampering(includeTampering: boolean) {
    this.args.onChange({
      ...this.args.settingsStruct,
      includeTampering
    });
  }
}
