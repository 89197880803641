// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import {
  ReportsUserRole,
  TemplateSettingsStruct
} from 'airthings/services/airthings/reports/block-definitions/user-information';
import {FormSelectOption} from 'airthings/pods/components/form/select/component';
import IntlService from 'ember-intl/services/intl';
import {ReportsTemplateSettingsComponentArgs} from 'airthings/types/reports';

export default class ReportsBlocksTitleTemplateSettings extends Component<
  ReportsTemplateSettingsComponentArgs<TemplateSettingsStruct>
> {
  @service('intl')
  intl: IntlService;

  get userRoleOptions(): FormSelectOption[] {
    return [
      {
        label: this.intl.t(
          'reports.block-definitions.user-information.template-settings.user-role.options.start-test'
        ),
        value: ReportsUserRole.StartTest
      },
      {
        label: this.intl.t(
          'reports.block-definitions.user-information.template-settings.user-role.options.pickup-device'
        ),
        value: ReportsUserRole.PickupDevice
      },
      {
        label: this.intl.t(
          'reports.block-definitions.user-information.template-settings.user-role.options.create-report'
        ),
        value: ReportsUserRole.CreateReport
      }
    ];
  }

  @action
  changeTitle(title: string) {
    this.args.onChange({...this.args.settingsStruct, title});
  }

  @action
  changeUserRole(userRole: ReportsUserRole) {
    this.args.onChange({...this.args.settingsStruct, userRole});
  }

  @action
  changeHideName(hideName: boolean) {
    this.args.onChange({...this.args.settingsStruct, hideName});
  }

  @action
  changeHideEmail(hideEmail: boolean) {
    this.args.onChange({...this.args.settingsStruct, hideEmail});
  }

  @action
  changeHidePhoneNumber(hidePhoneNumber: boolean) {
    this.args.onChange({...this.args.settingsStruct, hidePhoneNumber});
  }
}
