// Vendor
import Component from '@glimmer/component';
import {GraphqlPagination} from 'airthings/types/graphql';

// Constants
const DISPLAYED_PAGES = 9;

interface Args {
  pagination: GraphqlPagination;
  route: string;
}

export default class Pagination extends Component<Args> {
  get shouldPaginate() {
    return this.args.pagination.totalPages > 1;
  }

  get pages() {
    const {currentPage, totalPages} = this.args.pagination;
    const targetPages = Math.min(totalPages, DISPLAYED_PAGES);
    const center = Math.floor(targetPages / 2);
    let firstPage = Math.max(1, currentPage - center);

    if (firstPage + targetPages - 1 > totalPages) {
      firstPage -= firstPage + targetPages - 1 - totalPages;
    }

    return Array(targetPages)
      .fill(null)
      .map((_, index) => firstPage + index);
  }
}
