export default {
  "font-bold": "700",
  "container": "_container_1cx2mc",
  "success": "_success_1cx2mc",
  "badge": "_badge_1cx2mc",
  "icon": "_icon_1cx2mc",
  "message": "_message_1cx2mc",
  "actions": "_actions_1cx2mc",
  "action": "_action_1cx2mc",
  "download-button": "_download-button_1cx2mc"
};
