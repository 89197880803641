// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';
import {action} from '@ember/object';
import addSeconds from 'date-fns/addSeconds';
import addHours from 'date-fns/addHours';
import {TaskGenerator} from 'ember-concurrency';

// Types
import Analytics from 'airthings/services/analytics';
import {ReportsConfigurationState} from 'airthings/types/reports';
import Reports from 'airthings/services/airthings/reports';
import Form, {Validator} from 'airthings/services/form';
import {RecordingsListDatasetStruct} from 'airthings/types/recordings';
import IntlService from 'ember-intl/services/intl';
import {FormSelectOption} from 'airthings/pods/components/form/select/component';
import {AccountUserStruct} from 'airthings/types/account';
import AffiliateProgram from 'airthings/services/airthings/affiliate-program';
import Recordings from 'airthings/services/airthings/recordings';

interface Args {
  dataset: RecordingsListDatasetStruct;
  currentUser: AccountUserStruct;
  onCancel: () => void;
  onSuccess: (reportState: ReportsConfigurationState) => void;
}

// Constants
const HOUR_IN_SECONDS = 3600;

interface TemplateFormSelectOption extends FormSelectOption {
  isCompanyFavorite: boolean;
}

export default class ReportsGenerationInitialSetup extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('intl')
  intl: IntlService;

  @service('form')
  form: Form;

  @service('airthings/reports')
  reports: Reports;

  @service('airthings/affiliate-program')
  affiliateProgram: AffiliateProgram;

  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  templateOptions: TemplateFormSelectOption[];

  @tracked
  duplicateDatasets: RecordingsListDatasetStruct[];

  @tracked
  affiliateProgramAdOptions: FormSelectOption[];

  @tracked
  templatePublicationId: string;

  @tracked
  duplicateDatasetId: string;

  @tracked
  duplicateDatasetRecordingStartedAt: Date;

  @tracked
  duplicateDatasetRecordingEndedAt: Date;

  @tracked
  truncatedHoursFromStart: string = '0';

  @tracked
  truncatedHoursFromEnd: string = '0';

  @tracked
  affiliateProgramAdId: string | null = null;

  get datasetEndedAt() {
    return addSeconds(
      new Date(this.args.dataset.recordingStartedAt),
      this.args.dataset.durationInSeconds
    );
  }

  get testStartedAt() {
    if (
      this.duplicateDatasetRecordingStartedAt <
      new Date(this.args.dataset.recordingStartedAt)
    ) {
      return this.duplicateDatasetRecordingStartedAt;
    } else {
      return new Date(this.args.dataset.recordingStartedAt);
    }
  }

  get testEndedAt() {
    if (this.duplicateDatasetRecordingEndedAt > this.datasetEndedAt) {
      return this.duplicateDatasetRecordingEndedAt;
    } else {
      return this.datasetEndedAt;
    }
  }

  get reportStartedAt() {
    if (
      this.duplicateDatasetRecordingStartedAt <
      new Date(this.args.dataset.recordingStartedAt)
    ) {
      return addHours(
        this.duplicateDatasetRecordingStartedAt,
        parseInt(this.truncatedHoursFromStart, 10)
      );
    } else {
      return addHours(
        new Date(this.args.dataset.recordingStartedAt),
        parseInt(this.truncatedHoursFromStart, 10)
      );
    }
  }

  get reportEndedAt() {
    let startedAt;
    if (
      this.duplicateDatasetRecordingStartedAt <
      new Date(this.args.dataset.recordingStartedAt)
    ) {
      startedAt = this.duplicateDatasetRecordingStartedAt;
    } else {
      startedAt = new Date(this.args.dataset.recordingStartedAt);
    }
    if (
      this.duplicateDatasetRecordingEndedAt > this.datasetEndedAt &&
      startedAt < this.duplicateDatasetRecordingEndedAt
    ) {
      return addHours(
        this.duplicateDatasetRecordingEndedAt,
        parseInt(this.truncatedHoursFromEnd, 10) * -1
      );
    } else {
      return addHours(
        this.datasetEndedAt,
        parseInt(this.truncatedHoursFromEnd, 10) * -1
      );
    }
  }

  get datasetDurationInHours() {
    return this.args.dataset.durationInSeconds / HOUR_IN_SECONDS;
  }

  get truncatedHoursFromStartMax() {
    const max =
      this.datasetDurationInHours - parseInt(this.truncatedHoursFromEnd, 10);
    return Math.max(0, max);
  }

  get truncatedHoursFromEndMax() {
    const max =
      this.datasetDurationInHours - parseInt(this.truncatedHoursFromStart, 10);
    return Math.max(0, max);
  }

  @dropTask
  *fetchSetupOptionsTask() {
    this.templateOptions =
      yield this.reports.fetchReportTemplatePublicationOptions();
    this.affiliateProgramAdOptions =
      yield this.affiliateProgram.fetchAdOptions();
    this.duplicateDatasets = yield this.recordings.fetchDuplicateDatesets(
      this.args.dataset.id
    );

    const favoriteTemplateOptions = this.templateOptions.find(
      (templateOption) => templateOption.isCompanyFavorite
    );

    this.duplicateDatasetId = '';
    this.templatePublicationId =
      favoriteTemplateOptions && favoriteTemplateOptions.value
        ? favoriteTemplateOptions.value
        : '';
  }

  @dropTask
  *createReportTask(): TaskGenerator<void> {
    return yield this.reports.createReport(
      this.args.dataset.id,
      this.duplicateDatasetId,
      this.templatePublicationId,
      this.affiliateProgramAdId,
      parseInt(this.truncatedHoursFromStart, 10) * 60 * 60,
      parseInt(this.truncatedHoursFromEnd, 10) * 60 * 60,
      this.testStartedAt,
      this.testEndedAt
    );
  }

  @action
  handleReportCreation({result: report}: {result: ReportsConfigurationState}) {
    this.args.onSuccess(report);

    this.analytics.trackReportConfiguration(this.args.dataset, {
      reportConfigurationAction: 'continue'
    });
  }

  @action
  changeTemplatePublicationId(templatePublicationId: string) {
    this.templatePublicationId = templatePublicationId;
  }

  @action
  changeDuplicateDatasetId(duplicateDatasetId: string) {
    if (duplicateDatasetId === null) {
      this.duplicateDatasetId = '';
    } else {
      this.duplicateDatasetId = duplicateDatasetId;
    }
    this.changeDuplicateRecordingHours(duplicateDatasetId);
  }

  changeDuplicateRecordingHours(duplicateDatasetId: string) {
    const duplicateDataset = this.duplicateDatasets.find(
      (dataset) => dataset.value === duplicateDatasetId
    );
    if (duplicateDataset) {
      this.duplicateDatasetRecordingStartedAt = new Date(
        duplicateDataset.recordingStartedAt
      );
      this.duplicateDatasetRecordingEndedAt = addSeconds(
        new Date(duplicateDataset.recordingStartedAt),
        duplicateDataset.durationInSeconds
      );
    } else {
      this.duplicateDatasetRecordingStartedAt = new Date(
        this.args.dataset.recordingStartedAt
      );
      this.duplicateDatasetRecordingEndedAt = this.datasetEndedAt;
    }
  }

  @action
  changeTruncatedHoursFromStart(truncatedHours: string) {
    this.truncatedHoursFromStart = this.validatedTruncatedHoursValue(
      truncatedHours,
      this.truncatedHoursFromStartMax
    );
  }

  @action
  changeTruncatedHoursFromEnd(truncatedHours: string) {
    this.truncatedHoursFromEnd = this.validatedTruncatedHoursValue(
      truncatedHours,
      this.truncatedHoursFromEndMax
    );
  }

  @action
  changeAffiliateProgramAd(affiliateProgramAdId: string | null) {
    this.affiliateProgramAdId = affiliateProgramAdId;
  }

  @action
  validate() {
    const validators: Array<
      Validator<
        string,
        'required' | 'format' | 'dataset-limits' | 'dataset-range'
      >
    > = [
      {
        field: 'template-publication-id',
        isValid: Boolean(this.templatePublicationId),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'reports.generation.report'
    });
  }

  private validatedTruncatedHoursValue(rawValue: string, maximum: number) {
    if (!rawValue) return '0';

    const parsedValue = parseInt(rawValue, 10);

    if (parsedValue < 0) return '0';
    if (parsedValue > maximum) return maximum.toString();

    return rawValue;
  }
}
