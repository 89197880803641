// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import {AdminAccountUserStruct} from 'airthings/types/admin/account';
import Account from 'airthings/services/airthings/account';

interface Args {
  currentUser: AdminAccountUserStruct;
}

export default class AdminLayoutSidebar extends Component<Args> {
  @service('airthings/account')
  account: Account;

  @action
  async logout() {
    await this.account.hardLogout();
  }
}
