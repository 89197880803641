// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import AdminAccount from 'airthings/services/airthings/admin/account';

export default class AdminController extends Controller {
  @service('airthings/admin/account')
  adminAccount: AdminAccount;
}
