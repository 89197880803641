// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import API from 'airthings/services/airthings/admin/recordings/api';

export default class AdminRecordingsDevice extends Service {
  @service('airthings/admin/recordings/api')
  api: API;

  async fetchDeviceBySerialNumber(serialNumber: string) {
    return this.api.fetchDeviceBySerialNumber(serialNumber);
  }

  async moveDevices(deviceIds: string[], companyId: string) {
    return this.api.moveDevices(deviceIds, companyId);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/recordings/device': AdminRecordingsDevice;
  }
}
