// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  RecordingsSampleStruct,
  RecordingsSampleUnit,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';
import IntlService from 'ember-intl/services/intl';

// Constants
const UNIT_LABELS_MAPPING = {
  BECQUERELS_PER_CUBIC_METER:
    'recordings.unit-labels.becquerels-per-cubic-meter',
  PICOCURIES_PER_LITER: 'recordings.unit-labels.picocuries-per-liter',
  CELSIUS: 'recordings.unit-labels.celsius',
  FAHRENHEIT: 'recordings.unit-labels.fahrenheit',
  KILOPASCAL: 'recordings.unit-labels.kilopascal',
  INCHES_OF_MERCURY: 'recordings.unit-labels.inches-of-mercury',
  RELATIVE_HUMIDITY: 'recordings.unit-labels.relative-humidity',
  KILOMETERS_PER_HOUR: 'recordings.unit-labels.kilometers-per-hour',
  MILES_PER_HOUR: 'recordings.unit-labels.miles-per-hour',
  MILLIMETERS: 'recordings.unit-labels.millimeters',
  INCHES: 'recordings.unit-labels.inches'
};

export default class RecordingsSample extends Service {
  @service('intl')
  intl: IntlService;

  unitLabel(unit: RecordingsSampleUnit) {
    const translationKey = UNIT_LABELS_MAPPING[unit];
    if (!translationKey) return null;

    return this.intl.t(translationKey);
  }

  // eslint-disable-next-line complexity
  sampleToChartPoint(
    sample: RecordingsSampleStruct,
    unitType: RecordingsSampleUnitType
  ): [number, number] {
    const timeValue = new Date(sample.recordedAt).getTime();

    switch (unitType) {
      case RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE:
        return [timeValue, sample.radonShortTermAverage];
      case RecordingsSampleUnitType.RADON_UNCERTAINTY:
        return [timeValue, sample.radonUncertainty];
      case RecordingsSampleUnitType.PRESSURE:
        return [timeValue, sample.pressure];
      case RecordingsSampleUnitType.TEMPERATURE:
        return [timeValue, sample.temperature];
      case RecordingsSampleUnitType.HUMIDITY:
        return [timeValue, sample.humidity];
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/recordings/sample': RecordingsSampleStruct;
  }
}
