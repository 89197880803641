export default {
  "font-bold": "700",
  "block": "_block_yv51wz _light-box-wrapper_1ghgfm",
  "content": "_content_yv51wz _light-box-content_1ghgfm",
  "cell": "_cell_yv51wz _cell_1ghgfm",
  "key": "_key_yv51wz _key_1ghgfm",
  "value": "_value_yv51wz _value_1ghgfm",
  "header": "_header_yv51wz _dark-box-header_1ghgfm",
  "icon-wrapper": "_icon-wrapper_yv51wz _header-icon-wrapper_1ghgfm",
  "icon": "_icon_yv51wz _header-icon_1ghgfm",
  "title": "_title_yv51wz _dark-box-header-title_1ghgfm"
};
