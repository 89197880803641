// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import FastBoot from 'ember-cli-fastboot/services/fastboot';

// Types
import Analytics from 'airthings/services/analytics';
import Weather from 'airthings/services/airthings/weather';
import RouterService from '@ember/routing/router-service';

interface RouteParams {
  uuid: string;
  units: string;
}

export default class WeatherReportRoute extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('router')
  router: RouterService;

  @service('airthings/weather')
  weather: Weather;

  @service('fastboot')
  fastboot: FastBoot;

  queryParams = {
    units: {
      refreshModel: true
    }
  };

  constructor() {
    super(...arguments);

    const router = this.router;

    router.on('routeDidChange', () => {
      this.analytics.trackPageView(router.currentURL);
    });
  }

  async model(params: RouteParams) {
    if (this.fastboot.isFastBoot) return;

    return this.weather.fetchDatasetWeatherReport(
      params.uuid,
      params.units.toUpperCase()
    );
  }
}
