// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {dropTask} from 'ember-concurrency-decorators';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {
  UserRole,
  AccountUserStruct,
  AccountInvitationStruct
} from 'airthings/types/account';
import Analytics from 'airthings/services/analytics';
import Account from 'airthings/services/airthings/account';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import {OnboardingSectionSlug} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
  users: AccountUserStruct[];
  pendingInvitations: AccountInvitationStruct[];
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageUserManagementIndex extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @tracked
  invitation: AccountInvitationStruct;

  @tracked
  stagedInvitationForDeletion: AccountInvitationStruct | null = null;

  @tracked
  stagedUserForDeletion: AccountUserStruct | null = null;

  @tracked
  showInvitationModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.invitation = this.account.invitationStruct();
  }

  get roles() {
    return Object.entries(UserRole).map(([value, key]) => {
      const label = this.intl.t(`account.roles.${key}`);

      return {value, label};
    });
  }

  @dropTask
  *sendInvitationTask() {
    return this.account.createInvitation(this.invitation);
  }

  @dropTask
  *resendInvitationTask(invitation: AccountInvitationStruct) {
    const {successful, result} = yield this.account.resendInvitation(
      invitation.id
    );

    if (successful) {
      this.flashMessages.success(
        this.intl.t('account.resend-invitation.success')
      );

      this.analytics.trackInvitationResent(result);
    } else {
      this.flashMessages.danger(this.intl.t('account.resend-invitation.error'));
    }
  }

  @dropTask
  *deleteInvitationTask(): TaskGenerator<void> {
    if (!this.stagedInvitationForDeletion) return;

    const result = yield this.account.deleteInvitation(
      this.stagedInvitationForDeletion.id
    );

    this.stagedInvitationForDeletion = null;

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('app-user-management-index.delete-invitation-modal.success')
      );

      this.analytics.trackInvitationDeletion(result.result);
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *deleteUserTask(): TaskGenerator<void> {
    if (!this.stagedUserForDeletion || !this.stagedUserForDeletion.id) return;

    const result = yield this.account.deleteUser(this.stagedUserForDeletion.id);

    this.stagedUserForDeletion = null;

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('app-user-management-index.delete-user-modal.success')
      );

      this.analytics.trackUserDeletion(result.result);
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  stageInvitationForDeletion(invitation: AccountInvitationStruct) {
    this.stagedInvitationForDeletion = invitation;
  }

  @action
  stageUserForDeletion(user: AccountUserStruct) {
    this.stagedUserForDeletion = user;
  }

  @action
  initiateInviteUser() {
    this.showInvitationModal = true;
  }

  @action
  cancelInvitation() {
    this.showInvitationModal = false;
  }

  @action
  cancelInvitationDeletion() {
    this.stagedInvitationForDeletion = null;
  }

  @action
  cancelUserDeletion() {
    this.stagedUserForDeletion = null;
  }

  @action
  validate() {
    return this.account.validateInvitation(this.invitation);
  }

  @action
  onSuccess() {
    this.flashMessages.success(
      this.intl.t('app-user-management-index.invitation-modal.success', {
        email: this.invitation.email
      })
    );

    this.analytics.trackInvitationSent(this.invitation);

    this.showInvitationModal = false;
  }

  @action
  changeName(name: string) {
    this.invitation = {...this.invitation, name};
  }

  @action
  changeEmail(email: string) {
    this.invitation = {...this.invitation, email};
  }

  @action
  changeRole(role: UserRole) {
    this.invitation = {...this.invitation, role};
  }

  @action
  resetForm(closeForm: () => void) {
    this.invitation = this.account.invitationStruct();

    closeForm();
  }
}
