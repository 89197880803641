// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Analytics from 'airthings/services/analytics';
import RouterService from '@ember/routing/router-service';
import Reports from 'airthings/services/airthings/reports';
import Account from 'airthings/services/airthings/account';
import StateManager from 'airthings/services/state-manager';
import WebviewBridge from 'airthings/services/webview-bridge';

interface RouteParams {
  datasetExternalId?: string;
}

export default class MobileReportConfigurationRoute extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('state-manager')
  stateManager: StateManager;

  @service('router')
  router: RouterService;

  @service('webview-bridge')
  webviewBridge: WebviewBridge;

  @service('airthings/account')
  account: Account;

  @service('airthings/reports')
  reports: Reports;

  queryParams = {
    datasetExternalId: {}
  };

  async model(params: RouteParams) {
    const currentUser = await this.account.fetchCurrentUser();

    const dataset = params.datasetExternalId
      ? await this.reports.fetchDatasetByExternalId(params.datasetExternalId)
      : null;

    if (!dataset && !currentUser) {
      this.webviewBridge.triggerReportConfigurationCancel();
    }

    this.stateManager.setCurrentUser(currentUser);

    return {dataset, currentUser};
  }
}
