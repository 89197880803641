// Vendor
import Service, {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Config
import config from 'airthings/config/environment';

// Types
import {
  AccountCompanyStruct,
  AccountUserStruct,
  AccountDetailedUserStruct,
  Certificate,
  AccountInvitationStruct,
  AffialiateProgramSubscriptionStruct,
  ThirdPartySettings,
  PremiumFeatureSlug
} from 'airthings/types/account';
import AccountCompany from 'airthings/services/airthings/account/company';
import AccountInvitation from './account/invitation';
import AccountUser from './account/user';
import API from 'airthings/services/airthings/account/api';
import Apollo from 'airthings/services/apollo';
import Form from 'airthings/services/form';
import IntlService from 'ember-intl/services/intl';
import OAuth from './account/oauth';
import Location from 'airthings/services/location';
import RouterService from '@ember/routing/router-service';
import {AccountCompanyUsersResponse} from 'airthings/graphql/queries/account-company-users';
import AffiliateProgram from 'airthings/services/airthings/account/affiliate-program';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';
import {AccountCurrentUserCompanyResponse} from 'airthings/graphql/queries/account-current-user-company';

export default class Account extends Service {
  @service('apollo')
  apollo: Apollo;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @service('form')
  form: Form;

  @service('airthings/account/api')
  api: API;

  @service('airthings/account/affiliate-program')
  affiliateProgram: AffiliateProgram;

  @service('airthings/account/company')
  company: AccountCompany;

  @service('airthings/account/invitation')
  invitation: AccountInvitation;

  @service('airthings/account/oauth')
  oauth: OAuth;

  @service('airthings/account/user')
  user: AccountUser;

  @service('location')
  location: Location;

  /**
   * @description Returns `true` when the user has a token, `false` otherwise
   */
  get isLoggedIn() {
    return this.oauth.hasToken();
  }

  @action
  presentAccountCompanyUsers(viewer: AccountCompanyUsersResponse['viewer']) {
    return this.company.presentAccountCompanyUsers(viewer);
  }

  @action
  presentAccountUserCompany(
    viewer: AccountCurrentUserCompanyResponse['viewer']
  ) {
    return this.company.presentAccountUserCompany(viewer);
  }

  /**
   * @description Returns an empty User struct
   */
  userStruct(base?: RecursivePartial<AccountUserStruct>) {
    return this.user.struct(base);
  }

  /**
   * @description Returns an empty Company struct
   */
  companyStruct(base?: RecursivePartial<AccountCompanyStruct>) {
    return this.company.struct(base);
  }

  /**
   * @description Returns an empty Invitation struct
   */
  invitationStruct(base?: RecursivePartial<AccountInvitationStruct>) {
    return this.invitation.struct(base);
  }

  /**
   * @description Returns a empty Certificate struct
   */
  certificateStruct(base?: RecursivePartial<Certificate>): Certificate {
    return {
      name: '',
      number: '',
      expirationDate: '',
      ...base
    };
  }

  /**
   * @description Convert a currentUser watchQuery result to User struct
   */
  @action
  presentCurrentUser(user: AccountCurrentUserProfileResponse['viewer']) {
    return this.user.presentCurrentUser(user);
  }

  /**
   * @description Returns a empty AffiliateProgramRegistration struct
   */
  affiliateProgramSubscriptionStruct(
    base?: RecursivePartial<AffialiateProgramSubscriptionStruct>
  ) {
    return this.affiliateProgram.subscriptionStruct(base);
  }

  /**
   * @description Redirects the user to the Airthings login page
   */
  initiateLoginFlow() {
    this.oauth.initiateLoginFlow();
  }

  /**
   * @description Redirects the user to the Airthings subscription page
   */
  initiateSignUpFlow() {
    this.oauth.initiateSignUpFlow();
  }

  /**
   * @description Redirects the browser to the Airthings subscription page,
   * should be used only from FastBoot
   */
  redirectToSignUpFlow() {
    this.oauth.redirectToSignUpFlow();
  }

  /**
   * @description Returns the user’s access token
   */
  retrieveAccessToken() {
    return this.oauth.retrieveAccessToken();
  }

  /**
   * @description Returns the user’s refresh token
   */
  retrieveRefreshToken() {
    return this.oauth.retrieveRefreshToken();
  }

  /**
   * @description Returns the unparsed auth token
   */
  retrieveRawToken() {
    return this.oauth.retrieveRawToken();
  }

  /**
   * @description Persist a new raw auth token
   */
  persistRawToken(rawToken: string) {
    return this.oauth.persistRawToken(rawToken);
  }

  /**
   * @description Refreshes the access and refresh tokens and persists them or
   * throws an Error if it fails
   */
  async refreshToken() {
    const {accessToken, refreshToken} = await this.oauth.refreshToken();

    this.persistTokens(accessToken, refreshToken);
  }

  /**
   * @description Stores the user’s access and refresh tokens in cookies
   */
  persistTokens(accessToken: string, refreshToken: string) {
    this.oauth.persistTokens(accessToken, refreshToken);
  }

  /**
   * @description Clears the user’s token
   */
  clearTokens() {
    this.oauth.clearTokens();
  }

  /**
   * @description Clears the user’s token, empties Apollo cache and redirects
   * the user to the login page
   */
  async logout(transitionParams: object | null = null) {
    this.oauth.clearTokens();

    if (transitionParams) {
      this.router.transitionTo('login', transitionParams);
    } else {
      this.router.transitionTo('login');
    }

    await this.apollo.clearCache();
  }

  /**
   * @description Clears the user’s token, empties Apollo cache and clear user session
   * from the Airthings OAuth provider.
   */
  async hardLogout() {
    this.oauth.clearTokens();

    await this.apollo.clearCache();

    const logoutUrl = `${
      config.airthingsAuth.afterLogoutUrl
    }?${this.logoutQueryParams()}`;

    this.location.assign(logoutUrl);
  }

  /**
   * @description Updates the current user’s information and preferences and
   * parses the server-side validations.
   */
  async updateCurrentUser(user: AccountUserStruct) {
    return this.user.updateCurrentUser(user);
  }

  /**
   * @description Updates the current user’s information and preferences and
   * parses the server-side validations.
   */
  async updateUser(userId: string, user: AccountDetailedUserStruct) {
    return this.user.updateUser(userId, user);
  }

  /**
   * @description Creates a company and parses the server-side validations.
   */
  async createCompany(company: AccountCompanyStruct) {
    return this.company.createCompany(company);
  }

  /**
   * @description Update a company and parses the server-side validations.
   */
  async updateCompany(company: AccountCompanyStruct) {
    return this.company.updateCompany(company);
  }

  /**
   * @description Update a company’s dataset settings and parses the server-side validations.
   */
  async updateCompanyDatasetSettings(company: AccountCompanyStruct) {
    return this.company.updateCompanyDatasetSettings(company);
  }

  /**
   * @description Update a company’s third party settings and parses the server-side validations.
   */
  async updateThirdPartySettings(thirdPartySettings: ThirdPartySettings[]) {
    return this.user.updateThirdPartySettings(thirdPartySettings);
  }

  /**
   * @description Creates an invitation and parses the server-side validations.
   */
  async createInvitation(invitation: AccountInvitationStruct) {
    return this.invitation.createInvitation(invitation);
  }

  /**
   * @description Resends an invitation by ID
   */
  async resendInvitation(invitationId: string) {
    return this.invitation.resendInvitation(invitationId);
  }

  /**
   * @description Delete an invitation by ID
   */
  async deleteInvitation(invitationId: string) {
    return this.invitation.deleteInvitation(invitationId);
  }

  /**
   * @description Delete a user by ID
   */
  async deleteUser(userId: string) {
    return this.user.deleteUser(userId);
  }

  /**
   * @description Validates an account and returns a hash with errors if there’s any
   */
  validateUser(user: AccountUserStruct) {
    return this.user.validate(user);
  }

  /**
   * @description Validates a company and returns a hash with errors if there’s any
   */
  validateCompany(company: AccountCompanyStruct) {
    return this.company.validate(company);
  }

  /**
   * @description Validates an invitation and returns a hash with errors if there’s any
   */
  validateInvitation(invitation: AccountInvitationStruct) {
    return this.invitation.validate(invitation);
  }

  /**
   * @description Validates an invitation and returns a hash with errors if there’s any
   */
  validateAffiliateProgramSubscription(
    affiliateProgramSubscription: AffialiateProgramSubscriptionStruct
  ) {
    return this.affiliateProgram.validateSubscription(
      affiliateProgramSubscription
    );
  }

  /**
   * @description Returns a promise resolving with the account completion status
   * of the user.
   */
  async fetchAccountCompletionStatus() {
    return this.user.fetchAccountCompletionStatus();
  }

  /**
   * @description Returns a promise resolving with the current user
   */
  async fetchCurrentUser() {
    return this.user.fetchCurrentUser();
  }

  /**
   * @description Returns a promise resolving with the current user's third party settings
   */
  async fetchThirdPartySettings() {
    return this.user.fetchThirdPartySettings();
  }

  /**
   * @description Returns a watchQuery containing the current user
   */
  async watchCurrentUser(queryManager: Apollo) {
    return this.user.watchCurrentUser(queryManager);
  }

  /**
   * @description Returns a watchQuery containing the users and invitations of a company
   */
  async watchCompanyUsers(queryManager: Apollo) {
    return this.company.watchUsers(queryManager);
  }

  /**
   * @description Mark a template as company favorite
   */
  async assignFavoriteReportTemplateToCompany(reportTemplateId: string) {
    return this.company.assignFavoriteReportTemplate(reportTemplateId);
  }

  /**
   * @description Returns an InvitationStruct or null
   */
  async fetchInvitationByUUID(uuid: string) {
    return this.invitation.fetchInvitationByUUID(uuid);
  }

  /**
   * @description Returns an AccountUserStruct or null
   */
  async fetchUserById(userId: string) {
    return this.user.fetchUserById(userId);
  }

  /**
   * @description Returns a watchQuery containing the current user company
   */
  async watchCurrentUserCompany(queryManager: Apollo) {
    return this.company.watchCurrentUserCompany(queryManager);
  }

  /**
   * @description Dismiss the given slug so it will be excluded from the `onboardingStepSlugs`
   */
  async dismissOnboardingStepSlug(
    stepSlug: string,
    reminderDelayInDays: number | null
  ) {
    return this.user.dismissOnboardingStepSlug(stepSlug, reminderDelayInDays);
  }

  /**
   * @description Reset the given section slug so it will include them back into `onboardingStepSlugs`
   */
  async resetOnboardingSectionSlug(onboardingSectionSlug: string) {
    return this.user.resetOnboardingSectionSlug(onboardingSectionSlug);
  }

  /**
   * @description Dismiss the given slug so it will be excluded from the `PremiumFeatureSlugs`
   */
  async dismissPremiumFeatureSlug(featureSlug: PremiumFeatureSlug) {
    return this.user.dismissPremiumFeatureSlug(featureSlug);
  }

  /**
   * @description Joins the affiliate program and parses the server-side validations.
   */
  async subscribeToAffiliateProgram(
    affiliateProgramSubscription: AffialiateProgramSubscriptionStruct
  ) {
    return this.affiliateProgram.subscribe(affiliateProgramSubscription);
  }

  /**
   * @description Dismiss the unread messages notice for the current user
   */
  async dismissUnreadMessagesNotice() {
    return this.user.dismissUnreadMessagesNotice();
  }

  private logoutQueryParams() {
    return [
      `client_id=${encodeURIComponent(config.airthingsAuth.clientId)}`,
      `redirect_uri=${encodeURIComponent(config.airthingsAuth.redirectUri)}`,
      `scope=${encodeURIComponent(config.airthingsAuth.scope)}`
    ].join('&');
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/account': Account;
  }
}
