// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import {ReportsLanguage, ReportsTemplateStruct} from 'airthings/types/reports';

// Services
import IntlService from 'ember-intl/services/intl';

interface Args {
  formData: ReportsTemplateStruct;
  onChange: (updatedFormData: ReportsTemplateStruct) => void;
  errors?: object;
}

export default class EditForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get languageOptions() {
    return Object.values(ReportsLanguage).map((language) => ({
      value: language,
      label: this.intl.t(`general.languages.${language}`)
    }));
  }

  @action
  changeName(updatedName: string) {
    this.args.onChange({
      ...this.args.formData,
      name: updatedName
    });
  }

  @action
  changeDescription(updatedDescription: string) {
    this.args.onChange({
      ...this.args.formData,
      description: updatedDescription
    });
  }

  @action
  changeLanguage(updatedLanguage: ReportsLanguage) {
    this.args.onChange({
      ...this.args.formData,
      language: updatedLanguage
    });
  }
}
