// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';

// Constants
import {Keyboard} from 'airthings/constants/keyboard';

// Config
import config from 'airthings/config/environment';

// Types
import {AccountUserStruct} from 'airthings/types/account';

// Services
import Account from 'airthings/services/airthings/account';
import Analytics from 'airthings/services/analytics';
import StateManager from 'airthings/services/state-manager';
import RouterService from '@ember/routing/router-service';

interface Args {
  currentUser: AccountUserStruct;
  onProfileEditionClick: () => void;
}

export default class SidebarUser extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  @service('state-manager')
  stateManager: StateManager;

  @tracked
  unreadMessagesModalDismissed: boolean = false;

  helpUrl = config.APP.helpUrl;
  trainingUrl = config.APP.trainingUrl;
  appVersion = config.APP.version;

  get isOpened() {
    return this.stateManager.userSidebarIsOpened;
  }

  get currentUserName() {
    return this.args.currentUser.information.name;
  }

  @action
  handleProfileEditionClick() {
    this.stateManager.closeMobileMenu();
    this.close();
  }

  @action
  close() {
    this.stateManager.closeUserSidebar();
  }

  @action
  handleReadClick() {
    this.close();
  }

  @action
  onKeydown(event: KeyboardEvent) {
    if (event.key !== Keyboard.ESCAPE) return;

    this.close();
  }

  @action
  handleLinkClick(section: string) {
    this.analytics.trackUserSidebarNavigation(section);
  }

  @action
  async logout() {
    this.analytics.trackUserSidebarNavigation('logout');

    await this.account.hardLogout();
  }

  allowOutsideClick() {
    return true;
  }
}
