// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';

export default class ActionSheet extends Component {
  @tracked
  isOpen: boolean = false;

  @action
  close() {
    this.isOpen = false;
  }

  @action
  open() {
    this.isOpen = true;
  }
}
