// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {
  AccountDetailedUserStruct,
  AccountUserInformation,
  AccountUserPreferences,
  AccountUserStruct
} from 'airthings/types/account';
import Analytics from 'airthings/services/analytics';
import Account from 'airthings/services/airthings/account';
import {AccountUpdateCurrentUserResponse} from 'airthings/graphql/mutations/account-update-current-user';
import RouterService from '@ember/routing/router-service';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import ValidationErrors from 'airthings/services/form';
import {OnboardingSectionSlug} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
  user: AccountDetailedUserStruct;
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageProfileEdit extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @tracked
  user: AccountDetailedUserStruct = this.args.user;

  @dropTask
  *accountPersistTask(): TaskGenerator<void> {
    return yield this.account.updateCurrentUser(this.user);
  }

  @action
  changeInformation(updatedInformation: AccountUserInformation) {
    this.user = {
      ...this.user,
      information: updatedInformation
    };
  }

  @action
  changePreferences(updatedPreferences: AccountUserPreferences) {
    this.user = {
      ...this.user,
      preferences: updatedPreferences
    };
  }

  @action
  handleSuccess(
    response: AccountUpdateCurrentUserResponse['updateCurrentUserProfile']
  ) {
    this.analytics.trackProfileUpdate(response.result);

    this.showFlashMessage();
  }

  @action
  handleFailure() {
    this.analytics.trackProfileUpdateFailure(this.user);
  }

  @action
  handleValidationFailure(errors: ValidationErrors) {
    this.analytics.trackProfileUpdateValidationFailure(this.user, errors);
  }

  @action
  validate() {
    return this.account.validateUser(this.user);
  }

  @action
  showFlashMessage() {
    this.flashMessages.success(this.intl.t('app-profile-edit.form.success'));
  }
}
