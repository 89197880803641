// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';
import GraphQL from 'airthings/services/airthings/graphql';
import {AdminCommunicationBoardMessageStruct} from 'airthings/types/admin/communication-board';

// GraphQL
import adminCommunicationBoardPaginatedMessages, {
  AdminCommunicationBoardPaginatedMessagesVariables
} from 'airthings/graphql/queries/admin-communication-board-paginated-messages';
import adminCommunicationBoardEditMessageById, {
  AdminCommunicationBoardEditMessageByIdVariables
} from 'airthings/graphql/queries/admin-communication-board-edit-message-by-id';
import adminCommunicationBoardPublishMessageMutation, {
  AdminCommunicationBoardPublishMessageResponse,
  AdminCommunicationBoardPublishMessageVariables
} from 'airthings/graphql/mutations/admin-communication-board-publish-message';
import adminCommunicationBoardDeleteMessageMutation, {
  AdminCommunicationBoardDeleteMessageResponse,
  AdminCommunicationBoardDeleteMessageVariables
} from 'airthings/graphql/mutations/admin-communication-board-delete-message';
import adminCommunicationBoardCreateMessageMutation, {
  AdminCommunicationBoardCreateMessageResponse,
  AdminCommunicationBoardCreateMessageVariables
} from 'airthings/graphql/mutations/admin-communication-board-create-message';
import adminCommunicationBoardUpdateMessageMutation, {
  AdminCommunicationBoardUpdateMessageResponse,
  AdminCommunicationBoardUpdateMessageVariables
} from 'airthings/graphql/mutations/admin-communication-board-update-message';

// Constants
const MESSAGES_PER_PAGE = 20;

export default class API extends Service {
  @service('airthings/graphql')
  graphQL: GraphQL;

  @service('apollo')
  apollo: Apollo;

  async watchPaginatedMessages(queryManager: Apollo, page?: number) {
    const variables: AdminCommunicationBoardPaginatedMessagesVariables = {
      pagination: {
        page: page || 1,
        pageSize: MESSAGES_PER_PAGE
      }
    };

    return queryManager.watchQuery({
      query: adminCommunicationBoardPaginatedMessages,
      variables
    });
  }

  async watchEditMessageById(queryManager: Apollo, id: string) {
    const variables: AdminCommunicationBoardEditMessageByIdVariables = {
      messageId: id
    };

    return queryManager.watchQuery({
      query: adminCommunicationBoardEditMessageById,
      variables,
      fetchPolicy: 'no-cache'
    });
  }

  async publishMessage(messageId: string) {
    type ReturnType =
      AdminCommunicationBoardPublishMessageResponse['publishMessage'];

    const variables: AdminCommunicationBoardPublishMessageVariables = {
      messageId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminCommunicationBoardPublishMessageResponse =
        await this.apollo.mutate({
          mutation: adminCommunicationBoardPublishMessageMutation,
          variables
        });

      return response.publishMessage;
    });
  }

  async deleteMessage(messageId: string) {
    type ReturnType =
      AdminCommunicationBoardDeleteMessageResponse['deleteMessage'];

    const variables: AdminCommunicationBoardDeleteMessageVariables = {
      messageId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminCommunicationBoardDeleteMessageResponse =
        await this.apollo.mutate({
          mutation: adminCommunicationBoardDeleteMessageMutation,
          variables
        });

      return response.deleteMessage;
    });
  }

  async createMessage(message: AdminCommunicationBoardMessageStruct) {
    type ReturnType =
      AdminCommunicationBoardCreateMessageResponse['createMessage'];

    const variables: AdminCommunicationBoardCreateMessageVariables = {
      title: message.title,
      content: message.content
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminCommunicationBoardCreateMessageResponse =
        await this.apollo.mutate({
          mutation: adminCommunicationBoardCreateMessageMutation,
          variables,
          refetchQueries: ['AdminCommunicationBoardPaginatedMessages']
        });

      return response.createMessage;
    });
  }

  async updateMessage(message: AdminCommunicationBoardMessageStruct) {
    if (!message.id) return;

    type ReturnType =
      AdminCommunicationBoardUpdateMessageResponse['updateMessage'];

    const variables: AdminCommunicationBoardUpdateMessageVariables = {
      messageId: message.id,
      title: message.title,
      content: message.content
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminCommunicationBoardUpdateMessageResponse =
        await this.apollo.mutate({
          mutation: adminCommunicationBoardUpdateMessageMutation,
          variables
        });

      return response.updateMessage;
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/communication-board/api': API;
  }
}
