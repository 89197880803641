// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {
  AccountCompanyInformation,
  AccountCompanyLogo,
  AccountCompanyStruct,
  Certificate
} from 'airthings/types/account';
import Analytics from 'airthings/services/analytics';
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';
import {AccountCreateCompanyResponse} from 'airthings/graphql/mutations/account-create-company';
import ValidationErrors from 'airthings/services/form';

export default class PageLobbyCompany extends Component {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  @tracked
  companyStruct: AccountCompanyStruct;

  @tracked
  isConfirmed: boolean = false;

  get isSubmitDisabled(): boolean {
    return !this.isConfirmed;
  }

  constructor(owner: unknown, args: {}) {
    super(owner, args);

    this.companyStruct = this.account.companyStruct();
  }

  @dropTask
  *companyPersistTask(): TaskGenerator<void> {
    return yield this.account.createCompany(this.companyStruct);
  }

  @action
  changeInformation(updatedInformation: AccountCompanyInformation) {
    this.companyStruct = {
      ...this.companyStruct,
      information: updatedInformation
    };
  }

  @action
  changeCertificates(updatedCertificates: Certificate[]) {
    this.companyStruct = {
      ...this.companyStruct,
      certificates: updatedCertificates
    };
  }

  @action
  changeLogo(updatedLogo: AccountCompanyLogo) {
    this.companyStruct = {
      ...this.companyStruct,
      logo: updatedLogo
    };
  }

  @action
  validate() {
    return this.account.validateCompany(this.companyStruct);
  }

  @action
  handleCompanyPersistSuccess(
    response: AccountCreateCompanyResponse['createCompany']
  ) {
    this.analytics.trackCompanyCreation(response.result);

    this.navigateToAppDatasets();
  }

  @action
  handleCompanyPersistFailure() {
    this.analytics.trackCompanyCreationFailure();
  }

  @action
  handleCompanyPersistValidationFailure(errors: ValidationErrors) {
    this.analytics.trackCompanyCreationValidationFailure(errors);
  }

  @action
  navigateToAppDatasets() {
    this.router.transitionTo('app.datasets');
  }

  @action
  changeConfirmation(newConfirmation: boolean) {
    this.isConfirmed = newConfirmation;
  }
}
