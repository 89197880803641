// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';
import Form, {Validator} from 'airthings/services/form';

export enum ReportsUserRole {
  CreateReport = 'CREATE_REPORT',
  PickupDevice = 'PICKUP_DEVICE',
  StartTest = 'START_TEST'
}

export interface TemplateSettingsStruct {
  title: string;
  userRole: ReportsUserRole | null;
  hideName: boolean;
  hideEmail: boolean;
  hidePhoneNumber: boolean;
}

export default class UserInformationBlock
  extends Service
  implements ReportsBlockService
{
  @service('form')
  form: Form;

  @service('intl')
  intl: IntlService;

  get definition(): Readonly<ReportsBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'user-information',
      get name() {
        return intl.t('reports.block-definitions.user-information.name');
      },
      get description() {
        return intl.t('reports.block-definitions.user-information.description');
      },
      get helper() {
        return intl.t('reports.block-definitions.user-information.helper');
      },
      lightIconPath:
        '/assets/svgs/report-block-icons/user-information-light.svg',
      darkIconPath: '/assets/svgs/report-block-icons/user-information-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockUserInformation',
      componentNamespace: 'user-information',
      templateSettingsStruct: this.templateSettingsStruct.bind(this),
      templateSettingsValidate: this.templateSettingsValidate.bind(this),
      titlePreview: this.titlePreview.bind(this)
    });
  }

  private titlePreview(templateSettings: TemplateSettingsStruct) {
    return templateSettings.title;
  }

  // eslint-disable-next-line complexity
  private templateSettingsStruct(
    base: RecursivePartial<TemplateSettingsStruct>
  ): TemplateSettingsStruct {
    return {
      title:
        base.title ||
        this.intl.t(
          'reports.block-definitions.user-information.template-settings.title.default-value'
        ),
      userRole: base.userRole || null,
      hideName: base.hideName || false,
      hideEmail: base.hideEmail || false,
      hidePhoneNumber: base.hidePhoneNumber || false
    };
  }

  private templateSettingsValidate(
    templateSettings: TemplateSettingsStruct
  ): object | undefined {
    const validators: Array<Validator<string, 'required'>> = [
      {
        field: 'title',
        isValid: Boolean(templateSettings.title),
        code: 'required'
      },
      {
        field: 'user-role',
        isValid: Boolean(templateSettings.userRole),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix:
        'reports.block-definitions.user-information.template-settings'
    });
  }
}
