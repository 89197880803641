// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Utils
import parsePageParam from 'airthings/utils/parse-page-param';

// Types
import AdminCommunicationBoard from 'airthings/services/airthings/admin/communication-board';
import Apollo from 'airthings/services/apollo';

interface RouteParams {
  page?: string;
}

export default class AdminMessagesIndexRoute extends Route {
  @service('airthings/admin/communication-board')
  adminCommunicationBoard: AdminCommunicationBoard;

  @queryManager
  queryManager: Apollo;

  queryParams = {
    page: {
      refreshModel: true
    }
  };

  async model(params: RouteParams) {
    const messages = await this.adminCommunicationBoard.watchPaginatedMessages(
      this.queryManager,
      parsePageParam(params)
    );

    return {messages};
  }
}
