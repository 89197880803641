// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  AccountUserStruct,
  AccountDetailedUserStruct,
  PreferredLanguage,
  UserRole,
  ThirdPartySettings,
  ThirdPartyType,
  PremiumFeatureSlug
} from 'airthings/types/account';
import API from 'airthings/services/airthings/account/api';
import Form, {Validator} from 'airthings/services/form';
import {RecordingsUnitSystem} from 'airthings/types/recordings';
import Apollo from 'airthings/services/apollo';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

// Constants
const DEFAULT_PREFERRED_UNIT_SYSTEM: RecordingsUnitSystem =
  RecordingsUnitSystem.UNITED_STATES;
const DEFAULT_PREFERRED_LANGUAGE: PreferredLanguage = PreferredLanguage.EN;

const DEFAULT_ISN_SETTINGS = {
  id: null,
  thirdPartyType: ThirdPartyType.ISN,
  domain: '',
  companyKey: '',
  accessKey: '',
  secretKey: ''
};

export default class AccountUser extends Service {
  @service('airthings/account/api')
  api: API;

  @service('form')
  form: Form;

  // eslint-disable-next-line complexity
  struct(base?: RecursivePartial<AccountUserStruct>): AccountUserStruct {
    return {
      id: base?.id || null,
      role: base?.role || UserRole.EMPLOYEE,
      companyId: base?.companyId || null,
      companyName: base?.companyName || null,
      companyExpiredCalibrationDevicesCount:
        base?.companyExpiredCalibrationDevicesCount || null,
      isAdmin: Boolean(base?.isAdmin),
      unreadMessagesCount: base?.unreadMessagesCount || 0,
      hasUnreadMessagesNotice: Boolean(base?.hasUnreadMessagesNotice),
      information: {
        name: '',
        email: '',
        phoneNumber: '',
        ...base?.information
      },
      preferences: {
        unitSystem:
          base?.preferences?.unitSystem || DEFAULT_PREFERRED_UNIT_SYSTEM,
        language: base?.preferences?.language || DEFAULT_PREFERRED_LANGUAGE,
        datasetReportMessage: base?.preferences?.datasetReportMessage || ''
      },
      hasActiveInvitations: base?.hasActiveInvitations || false,
      affiliateProgram: {
        codes: {
          pro: base?.affiliateProgram?.codes?.pro || '',
          report: base?.affiliateProgram?.codes?.report || ''
        },
        subscribedToAffiliateProgramAt:
          base?.affiliateProgram?.subscribedToAffiliateProgramAt ?? null,
        isSubscribed: Boolean(
          base?.affiliateProgram?.subscribedToAffiliateProgramAt
        )
      },
      onboardingSteps: [],
      premiumFeatureNotices: []
    };
  }

  async deleteUser(userId: string) {
    return this.api.deleteUser(userId);
  }

  async fetchAccountCompletionStatus() {
    const user = await this.api.fetchCurrentUser();

    if (user === null) return null;

    if (!user) {
      return {
        isAdmin: false,
        hasCompany: false,
        hasProfile: false
      };
    }

    return {
      isAdmin: user.admin,
      hasCompany: user.company !== null,
      hasProfile: user.profile !== null && user.profile.completed
    };
  }

  async fetchCurrentUser(): Promise<AccountUserStruct | null> {
    const user = await this.api.fetchCurrentUser();

    if (!user) return null;

    const base = this.struct({
      id: user.id,
      companyId: user.company?.id,
      role: user.profile.role,
      hasActiveInvitations: user.profile.hasActiveInvitations,
      information: {
        name: user.profile.name,
        email: user.profile.email,
        phoneNumber: user.profile.phoneNumber
      },
      preferences: {
        unitSystem: user.profile.preferredUnitSystem,
        language: user.profile.preferredLanguage,
        datasetReportMessage: user.profile.datasetReportMessage
      },
      affiliateProgram: {
        subscribedToAffiliateProgramAt:
          user.profile.subscribedToAffiliateProgramAt,
        codes: user.profile.affiliateProgramCouponCodes
      }
    });

    return {
      ...base,
      onboardingSteps: user.profile.onboardingSteps,
      premiumFeatureNotices: user.profile.premiumFeatureNotices
    };
  }

  presentCurrentUser(
    user: AccountCurrentUserProfileResponse['viewer']
  ): AccountUserStruct {
    const base = this.struct({
      id: user.id,
      companyId: user.company?.id,
      companyName: user.company?.name,
      companyExpiredCalibrationDevicesCount:
        user.company?.expiredCalibrationDevicesCount,
      role: user.profile.role,
      isAdmin: user.admin,
      unreadMessagesCount: user.unreadMessagesCount,
      hasUnreadMessagesNotice: user.profile.hasUnreadMessagesNotice,
      information: {
        name: user.profile.name,
        email: user.profile.email,
        phoneNumber: user.profile.phoneNumber
      },
      preferences: {
        unitSystem: user.profile.preferredUnitSystem,
        language: user.profile.preferredLanguage,
        datasetReportMessage: user.profile.datasetReportMessage
      },
      affiliateProgram: {
        subscribedToAffiliateProgramAt:
          user.profile.subscribedToAffiliateProgramAt,
        codes: user.profile.affiliateProgramCouponCodes
      }
    });

    return {
      ...base,
      onboardingSteps: user.profile.onboardingSteps,
      premiumFeatureNotices: user.profile.premiumFeatureNotices
    };
  }

  async watchCurrentUser(queryManager: Apollo) {
    return this.api.watchCurrentUser(queryManager);
  }

  async fetchUserById(userId: string) {
    const user = await this.api.fetchUserById(userId);

    if (!user) return null;

    const base = this.struct({
      id: user.id,
      role: user.profile.role,
      information: {
        name: user.profile.name,
        email: user.profile.email,
        phoneNumber: user.profile.phoneNumber
      },
      preferences: {
        unitSystem: user.profile.preferredUnitSystem,
        language: user.profile.preferredLanguage
      }
    });

    return {
      ...base,
      certificates: user.profile.certificates.map((certificate) => ({
        name: certificate.name,
        number: certificate.number,
        expirationDate: certificate.expirationDate
      }))
    };
  }

  async updateCurrentUser(user: AccountUserStruct) {
    const mutationResponse = await this.api.updateCurrentUser(user);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'account.user-information-form'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async updateUser(userId: string, user: AccountDetailedUserStruct) {
    const mutationResponse = await this.api.updateUser(userId, user);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'account.user-information-form'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async updateThirdPartySettings(thirdPartySettings: ThirdPartySettings[]) {
    return this.api.updateThirdPartySettings(thirdPartySettings);
  }

  async fetchIsnAccount(
    domain: string,
    companyKey: string,
    accessKey: string,
    secretKey: string
  ) {
    return this.api.fetchIsnAccount(domain, companyKey, accessKey, secretKey);
  }

  async dismissOnboardingStepSlug(
    stepSlug: string,
    reminderDelayInDays: number | null
  ) {
    return this.api.dismissOnboardingStepSlug(stepSlug, reminderDelayInDays);
  }

  async resetOnboardingSectionSlug(onboardingSectionSlug: string) {
    return this.api.resetOnboardingSectionSlug(onboardingSectionSlug);
  }

  async dismissPremiumFeatureSlug(featureSlug: PremiumFeatureSlug) {
    return this.api.dismissPremiumFeatureSlug(featureSlug);
  }

  async fetchThirdPartySettings(): Promise<ThirdPartySettings[]> {
    const thirdPartySettings = await this.api.fetchThirdPartySettings();

    return this.withThirdPartyDefaults(thirdPartySettings || []);
  }

  async dismissUnreadMessagesNotice() {
    return this.api.dismissUnreadMessagesNotice();
  }

  withThirdPartyDefaults(settings: ThirdPartySettings[]) {
    const withDefaults = [
      settings.find((entry) => entry.thirdPartyType === ThirdPartyType.ISN) ||
        DEFAULT_ISN_SETTINGS
    ];

    return withDefaults.map((entry) => ({
      thirdPartyType: entry.thirdPartyType,
      domain: entry.domain,
      companyKey: entry.companyKey,
      accessKey: entry.accessKey,
      secretKey: entry.secretKey
    }));
  }

  validate(user: AccountUserStruct) {
    let validators: Array<Validator<string, 'required'>> = [
      {
        field: 'role',
        isValid: Boolean(user.role),
        code: 'required'
      }
    ];

    if (user.information) {
      validators = validators.concat([
        {
          field: 'phone-number',
          isValid: Boolean(user.information.phoneNumber),
          code: 'required'
        }
      ]);
    }

    return this.form.validate(validators, {
      translationKeyPrefix: 'account.user-information-form'
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/account/user': AccountUser;
  }
}
