// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import * as Sentry from '@sentry/browser';
import {queryManager} from 'ember-apollo-client';

// Types
import Account from 'airthings/services/airthings/account';
import Analytics from 'airthings/services/analytics';
import RouterService from '@ember/routing/router-service';
import StateManager from 'airthings/services/state-manager';
import Transition from '@ember/routing/-private/transition';
import Apollo from 'airthings/services/apollo';

export default class Lobby extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('state-manager')
  stateManager: StateManager;

  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  @queryManager
  queryManager: Apollo;

  constructor() {
    super(...arguments);

    const router = this.router;

    router.on('routeDidChange', () => {
      this.analytics.trackPageView(router.currentURL);
    });
  }

  async beforeModel(transition: Transition) {
    const currentUser = await this.account.watchCurrentUser(this.queryManager);

    if (!currentUser || !currentUser.viewer) {
      transition.abort();

      this.router.replaceWith('login');
    }

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: currentUser.viewer.id,
        email: currentUser.viewer.profile.email
      });
    });

    this.stateManager.setCurrentUser(
      this.account.presentCurrentUser(currentUser.viewer)
    );
  }
}
