export default {
  "font-bold": "700",
  "font-medium": "500",
  "size-4": "16px",
  "size-5": "32px",
  "container": "_container_17sphm",
  "top-row": "_top-row_17sphm",
  "bottom-row": "_bottom-row_17sphm",
  "back-button": "_back-button_17sphm",
  "onboarding-button": "_onboarding-button_17sphm",
  "title": "_title_17sphm",
  "sub-title": "_sub-title_17sphm",
  "actions": "_actions_17sphm",
  "sidebar-user-button": "_sidebar-user-button_17sphm"
};
