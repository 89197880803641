export default {
  "link": "_link_1sfksb",
  "user-title": "_user-title_1sfksb",
  "errors": "_errors_1sfksb",
  "delete-confirmation": "_delete-confirmation_1sfksb",
  "delete-actions": "_delete-actions_1sfksb",
  "delete-action": "_delete-action_1sfksb",
  "create-actions": "_create-actions_1sfksb",
  "create-action": "_create-action_1sfksb",
  "form-select": "_form-select_1sfksb",
  "invitation-table-row": "_invitation-table-row_1sfksb",
  "user-table-row": "_user-table-row_1sfksb",
  "user-actions": "_user-actions_1sfksb",
  "invitation-actions": "_invitation-actions_1sfksb"
};
