// Vendor
import Service, {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import {AdminRecordingsEditCalibrationCertificateByIdResponse} from 'airthings/graphql/queries/admin-recordings-edit-calibration-certificate-by-id';
import {AdminRecordingsPaginatedCalibrationCertificatesResponse} from 'airthings/graphql/queries/admin-recordings-paginated-calibration-certificates';
import {
  AdminRecordingsCalibrationCertificateStruct,
  ComparisonDevice
} from 'airthings/types/admin/recordings';
import AdminRecordingsCalibrationCertificate from 'airthings/services/airthings/admin/recordings/calibration-certificate';
import Apollo from 'airthings/services/apollo';
import AdminRecordingJob from 'airthings/services/airthings/admin/recordings/job';

export default class AdminRecordings extends Service {
  @service('airthings/admin/recordings/calibration-certificate')
  adminCalibrationCertificate: AdminRecordingsCalibrationCertificate;

  @service('airthings/admin/recordings/job')
  job: AdminRecordingJob;

  @action
  presentAdminRecordingsPaginatedCalibrationCertificates(
    admin: AdminRecordingsPaginatedCalibrationCertificatesResponse['admin']
  ) {
    return this.adminCalibrationCertificate.presentAccountPaginatedCalibrationCertificates(
      admin
    );
  }

  @action
  presentAdminRecordingsEditCalibrationCertificateById(
    admin: AdminRecordingsEditCalibrationCertificateByIdResponse['admin']
  ) {
    return this.adminCalibrationCertificate.presentAccountEditCalibrationCertificateById(
      admin
    );
  }

  /**
   * @description Returns a empty ComparisonDevice struct
   */
  comparisonDeviceStruct(
    base?: RecursivePartial<ComparisonDevice>
  ): ComparisonDevice {
    return {
      serialNumber: '',
      calibrationDate: '',
      ...base
    };
  }

  /**
   * @description Creates a calibration certificate and parses the server-side validations.
   */
  async createCalibrationCertificate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    return this.adminCalibrationCertificate.createCalibrationCertificate(
      calibrationCertificate
    );
  }

  /**
   * @description Returns a watchQuery containing paginated admin calibration certificates
   */
  async watchPaginatedCalibrationCertificates(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.adminCalibrationCertificate.watchPaginatedCalibrationCertificates(
      queryManager,
      page,
      searchTerm
    );
  }

  /**
   * @description Updates a calibration certificate and parses the server-side validations.
   */
  async updateCalibrationCertificate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    return this.adminCalibrationCertificate.updateCalibrationCertificate(
      calibrationCertificate
    );
  }

  /**
   * @description Returns a watchQuery containing a single calibration certificate
   */
  async watchEditCalibrationCertificateById(queryManager: Apollo, id: string) {
    return this.adminCalibrationCertificate.watchEditCalibrationCertificateById(
      queryManager,
      id
    );
  }

  /**
   * @description Delete a calibration certificate.
   */
  async deleteCalibrationCertificate(calibrationCertificateId: string) {
    return this.adminCalibrationCertificate.deleteCalibrationCertificate(
      calibrationCertificateId
    );
  }

  /**
   * @description Toggle a calibration certificate.
   */
  async toggleCalibrationCertificate(calibrationCertificateId: string) {
    return this.adminCalibrationCertificate.toggleCalibrationCertificate(
      calibrationCertificateId
    );
  }

  /**
   * @description Returns a Promise that resolves with a calibration certificate generation job
   */
  async fetchCalibrationCertificateGenerationJob(jobId: string) {
    return this.job.fetchCalibrationCertificateGenerationJob(jobId);
  }

  /**
   * @description Validates a new calibration certificate and returns a hash with errors if there’s any
   */
  validateCreateCalibrationCertificate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    return this.adminCalibrationCertificate.validateCreate(
      calibrationCertificate
    );
  }

  /**
   * @description Validates a persisted calibration certificate and returns a hash with errors if there’s any
   */
  validateUpdateCalibrationCertificate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    return this.adminCalibrationCertificate.validateUpdate(
      calibrationCertificate
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/recordings': AdminRecordings;
  }
}
