// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {RecordingsDatasetContactMetadata} from 'airthings/types/recordings';

interface Args {
  formData: RecordingsDatasetContactMetadata;
  onChange: (updatedFormData: RecordingsDatasetContactMetadata) => void;
  errors?: object;
}

export default class DatasetContactForm extends Component<Args> {
  @action
  changeName(updateName: string) {
    this.args.onChange({
      ...this.args.formData,
      name: updateName
    });
  }

  @action
  changePhoneNumber(updatePhoneNumber: string) {
    this.args.onChange({
      ...this.args.formData,
      phoneNumber: updatePhoneNumber
    });
  }
}
