// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Services
import AdminRecordings from 'airthings/services/airthings/admin/recordings';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';

// Services
import RouterService from '@ember/routing/router-service';

// Types
import {AdminRecordingsListCalibrationCertificateStruct} from 'airthings/types/admin/recordings';
import {GraphqlPagination} from 'airthings/types/graphql';

interface Args {
  calibrationCertificates: AdminRecordingsListCalibrationCertificateStruct[];
  pagination: GraphqlPagination;
  searchTerm?: string;
}

export default class PageAdminCalibrationCertificatesIndex extends Component<Args> {
  @service('router')
  router: RouterService;

  @service('airthings/admin/recordings')
  adminRecordings: AdminRecordings;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @tracked
  showFactoryImportModal: Boolean = false;

  @tracked
  showLabImportModal: Boolean = false;

  @tracked
  stagedUUIDForCalibrationCertificateGeneration: string | null = null;

  @action
  handleFactoryImport() {
    this.showFactoryImportModal = true;
  }

  @action
  handleLabImport() {
    this.showLabImportModal = true;
  }

  @action
  cancelFactoryImport() {
    this.showFactoryImportModal = false;
  }

  @action
  cancelLabImport() {
    this.showLabImportModal = false;
  }

  @action
  closeLabImport() {
    window.location.reload();
  }

  @action
  closeFactoryImport() {
    window.location.reload();
  }

  @action
  search(term: string) {
    this.router.replaceWith('admin.calibration-certificates', {
      queryParams: {q: term}
    });
  }

  @action
  clearSearch() {
    this.router.replaceWith('admin.calibration-certificates', {
      queryParams: {q: null}
    });
  }

  @action
  initiateCalibrationCertificateGeneration(
    certificate: AdminRecordingsListCalibrationCertificateStruct
  ) {
    this.stagedUUIDForCalibrationCertificateGeneration = certificate.uuid;
  }

  @action
  cancelCalibrationCertificateGeneration() {
    this.stagedUUIDForCalibrationCertificateGeneration = null;
  }

  @dropTask
  *deleteCalibrationCertificate(
    calibrationCertificate: AdminRecordingsListCalibrationCertificateStruct
  ): TaskGenerator<void> {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        this.intl.t('admin-calibration-certificates-index.delete.confirm')
      )
    )
      return;

    const result = yield this.adminRecordings.deleteCalibrationCertificate(
      calibrationCertificate.id
    );

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('admin-calibration-certificates-index.delete.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *toggleCalibrationCertificate(
    calibrationCertificate: AdminRecordingsListCalibrationCertificateStruct
  ): TaskGenerator<void> {
    const alreadyActivated = Boolean(calibrationCertificate.activatedAt);

    const result = yield this.adminRecordings.toggleCalibrationCertificate(
      calibrationCertificate.id
    );

    if (result.successful) {
      if (alreadyActivated) {
        this.flashMessages.success(
          this.intl.t('admin-calibration-certificates-index.deactivate.success')
        );
      } else {
        this.flashMessages.success(
          this.intl.t('admin-calibration-certificates-index.activate.success')
        );
      }
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }
}
