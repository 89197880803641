// Vendor
import Service, {inject as service} from '@ember/service';
import API from 'airthings/services/airthings/recordings/api';

export default class RecordingJob extends Service {
  @service('airthings/recordings/api')
  api: API;

  async fetchDatasetCSVGenerationJob(jobId: string) {
    return this.api.fetchDatasetCSVGenerationJob(jobId);
  }

  async fetchDatasetBatchCSVGenerationJob(jobId: string) {
    return this.api.fetchDatasetBatchCSVGenerationJob(jobId);
  }

  async fetchDatasetWeatherReportGenerationJob(jobId: string) {
    return this.api.fetchDatasetWeatherReportGenerationJob(jobId);
  }

  async fetchCalibrationCertificateGenerationJob(jobId: string) {
    return this.api.fetchCalibrationCertificateGenerationJob(jobId);
  }

  async fetchCalibrationCertificateImportJob(jobId: string) {
    return this.api.fetchCalibrationCertificateImportJob(jobId);
  }

  async fetchLabImportPdfGenerationJob(jobId: string) {
    return this.api.fetchLabImportPdfGenerationJob(jobId);
  }

  async fetchCrosschecksReportGenerationJob(jobId: string) {
    return this.api.fetchCrosschecksReportGenerationJob(jobId);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/recordings/job': RecordingJob;
  }
}
