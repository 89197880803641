export default {
  "font-bold": "700",
  "font-medium": "500",
  "block": "_block_1l6u23 _light-box-wrapper_vbfmil",
  "content": "_content_1l6u23 _light-box-content_vbfmil",
  "header": "_header_1l6u23 _light-box-header_vbfmil",
  "title": "_title_1l6u23 _light-box-header-title_vbfmil",
  "with-block-definition": "_with-block-definition_1l6u23",
  "icon": "_icon_1l6u23 _header-icon_vbfmil",
  "note": "_note_1l6u23",
  "columns": "_columns_1l6u23",
  "column": "_column_1l6u23",
  "cell": "_cell_1l6u23",
  "small": "_small_1l6u23",
  "with-value": "_with-value_1l6u23",
  "row-number": "_row-number_1l6u23",
  "is-empty": "_is-empty_1l6u23",
  "column-header": "_column-header_1l6u23",
  "value": "_value_1l6u23",
  "is-datetime": "_is-datetime_1l6u23",
  "with-unit": "_with-unit_1l6u23"
};
