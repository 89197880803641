// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';
import GraphQL from 'airthings/services/airthings/graphql';

// GraphQL
import {GraphqlPaginationInput} from 'airthings/types/graphql';
import communicationBoardPaginatedMessages, {
  CommunicationBoardPaginatedMessagesResponse,
  CommunicationBoardPaginatedMessagesVariables
} from 'airthings/graphql/queries/communication-board-paginated-messages';
import communicationBoardMessageById, {
  CommunicationBoardMessageByIdVariables
} from 'airthings/graphql/queries/communication-board-message-by-id';
import communicationBoardMarkMessageAsRead, {
  CommunicationBoardMarkMessageAsReadResponse,
  CommunicationBoardMarkMessageAsReadVariables
} from 'airthings/graphql/mutations/communication-board-mark-message-as-read';
import communicationBoardMarkMessageAsUnread, {
  CommunicationBoardMarkMessageAsUnreadResponse,
  CommunicationBoardMarkMessageAsUnreadVariables
} from 'airthings/graphql/mutations/communication-board-mark-message-as-unread';
import communicationBoardMarkMessageAsDeleted, {
  CommunicationBoardMarkMessageAsDeletedResponse,
  CommunicationBoardMarkMessageAsDeletedVariables
} from 'airthings/graphql/mutations/communication-board-mark-message-as-deleted';

const MESSAGES_PER_PAGE = 10;

export default class API extends Service {
  @service('apollo')
  apollo: Apollo;

  @service('airthings/graphql')
  graphQL: GraphQL;

  async fetchPaginatedMessages(pagination: GraphqlPaginationInput) {
    const variables: CommunicationBoardPaginatedMessagesVariables = {
      pagination
    };

    const response: CommunicationBoardPaginatedMessagesResponse =
      await this.apollo.query({
        query: communicationBoardPaginatedMessages,
        variables
      });

    return response.viewer.paginatedMessages;
  }

  async watchPaginatedMessages(queryManager: Apollo, page?: number) {
    const variables: CommunicationBoardPaginatedMessagesVariables = {
      pagination: {
        page: page || 1,
        pageSize: MESSAGES_PER_PAGE
      }
    };

    return queryManager.watchQuery({
      query: communicationBoardPaginatedMessages,
      variables
    });
  }

  async watchMessage(queryManager: Apollo, messageId: string) {
    const variables: CommunicationBoardMessageByIdVariables = {
      messageId
    };

    return queryManager.watchQuery({
      query: communicationBoardMessageById,
      variables
    });
  }

  async markMessageAsRead(messageId: string) {
    type ReturnType =
      CommunicationBoardMarkMessageAsReadResponse['markMessageAsRead'];

    const variables: CommunicationBoardMarkMessageAsReadVariables = {messageId};

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: CommunicationBoardMarkMessageAsReadResponse =
        await this.apollo.mutate({
          mutation: communicationBoardMarkMessageAsRead,
          variables,
          refetchQueries: ['AccountCurrentUserProfile']
        });

      return response.markMessageAsRead;
    });
  }

  async markMessageAsUnread(messageId: string) {
    type ReturnType =
      CommunicationBoardMarkMessageAsUnreadResponse['markMessageAsUnread'];

    const variables: CommunicationBoardMarkMessageAsUnreadVariables = {
      messageId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: CommunicationBoardMarkMessageAsUnreadResponse =
        await this.apollo.mutate({
          mutation: communicationBoardMarkMessageAsUnread,
          variables,
          refetchQueries: ['AccountCurrentUserProfile']
        });

      return response.markMessageAsUnread;
    });
  }

  async markMessageAsDeleted(messageId: string) {
    type ReturnType =
      CommunicationBoardMarkMessageAsDeletedResponse['markMessageAsDeleted'];

    const variables: CommunicationBoardMarkMessageAsDeletedVariables = {
      messageId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: CommunicationBoardMarkMessageAsDeletedResponse =
        await this.apollo.mutate({
          mutation: communicationBoardMarkMessageAsDeleted,
          variables,
          refetchQueries: ['AccountCurrentUserProfile']
        });

      return response.markMessageAsDeleted;
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/communication-board/api': API;
  }
}
