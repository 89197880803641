// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

// Services
import Recordings from 'airthings/services/airthings/recordings';

// Types
import {RecordingsCrosscheck} from 'airthings/types/recordings';

interface Args {
  device: {
    uuid: string;
  };
  crosschecks: RecordingsCrosscheck[];
}

const COV_FACTOR = 1.414;

export default class PageAppDevicesCrosschecks extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  crosscheckModalVisible: boolean = false;

  @tracked
  crosschecksReportModalVisible: boolean = false;

  get averageRpd() {
    if (this.args.crosschecks.length === 0) return 0;

    const sum = this.args.crosschecks.reduce(
      (value: number, crosscheck: RecordingsCrosscheck): number =>
        value + this.rpd(crosscheck),
      0
    );

    return sum / this.args.crosschecks.length;
  }

  get averageRpdPercentage() {
    return this.pct(this.averageRpd);
  }

  get averageCov() {
    return this.averageRpd / COV_FACTOR;
  }

  get averageCovPercentage() {
    return this.pct(this.averageCov);
  }

  rpd = (crosscheck: RecordingsCrosscheck) => {
    const a = crosscheck.dataset.aggregatedSamples.averageRadon;
    const b = crosscheck.pairedDataset.aggregatedSamples.averageRadon;
    const avg = (a + b) / 2;

    return Math.abs(a - b) / avg;
  };

  pct = (value: number) => Math.round(value * 100);

  @action
  generateCrosschecksReport() {
    this.crosschecksReportModalVisible = true;
  }

  @action
  cancelCrosschecksReport() {
    this.crosschecksReportModalVisible = false;
  }

  @action
  showCrosscheckModal() {
    this.crosscheckModalVisible = true;
  }

  @action
  dismissCrosscheckModal() {
    this.crosscheckModalVisible = false;
  }
}
