// Vendor
import {gql} from '@apollo/client/core';

export enum JobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED'
}

export interface DatasetWeatherReportGenerationJobVariables {
  jobId: string;
}

export interface DatasetWeatherReportGenerationJobResponse {
  viewer: {
    id: string;

    company: {
      id: string;

      datasetWeatherReportGenerationJob: {
        id: string;
        state: JobState;
        dataset: {
          weatherReportUrl: string;
        };
      };
    };
  };
}

export default gql`
  query RecordingsDatasetWeatherReportGenerationJob($jobId: ID!) {
    viewer {
      id

      company {
        id

        datasetWeatherReportGenerationJob(jobId: $jobId) {
          id
          state

          dataset {
            weatherReportUrl
          }
        }
      }
    }
  }
`;
