export default {
  "font-bold": "700",
  "container": "_container_zreuo0",
  "error": "_error_zreuo0",
  "badge": "_badge_zreuo0",
  "icon": "_icon_zreuo0",
  "message": "_message_zreuo0",
  "actions": "_actions_zreuo0",
  "action": "_action_zreuo0"
};
