// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

// Constants
import {Keyboard} from 'airthings/constants/keyboard';

export default class PopoverMenu extends Component {
  @tracked
  isOpen: boolean = false;

  @action
  allowOutsideClick() {
    return true;
  }

  @action
  openPopover() {
    this.isOpen = true;
  }

  @action
  closePopover() {
    this.isOpen = false;
  }

  @action
  onKeydown(event: KeyboardEvent) {
    if (event.key !== Keyboard.ESCAPE) return;

    this.closePopover();
  }
}
