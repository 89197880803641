export default {
  "font-bold": "700",
  "container": "_container_1di8q7",
  "success": "_success_1di8q7",
  "badge": "_badge_1di8q7",
  "icon": "_icon_1di8q7",
  "message": "_message_1di8q7",
  "import-log": "_import-log_1di8q7",
  "log-content": "_log-content_1di8q7",
  "inserted": "_inserted_1di8q7",
  "errors": "_errors_1di8q7",
  "actions": "_actions_1di8q7",
  "action": "_action_1di8q7",
  "download-button": "_download-button_1di8q7"
};
