// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import API from 'airthings/services/airthings/recordings/api';
import {
  RecordingsAttachmentType,
  RecordingsDatasetAttachmentStruct
} from 'airthings/types/recordings';
import {DataProxy} from '@apollo/client/core';

export default class RecordingsAttachment extends Service {
  @service('airthings/recordings/api')
  api: API;

  async deleteFromDataset(
    attachment: RecordingsDatasetAttachmentStruct,
    onAttachmentDelete: (store: DataProxy, attachmentId: string) => void
  ) {
    return this.api.deleteDatasetAttachment(attachment, onAttachmentDelete);
  }

  async createForDataset(
    datasetId: string,
    attachmentUrl: string,
    fileType: string
  ) {
    return this.api.createDatasetAttachment(
      datasetId,
      attachmentUrl,
      fileType === 'application/pdf'
        ? RecordingsAttachmentType.PDF
        : RecordingsAttachmentType.IMAGE
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/recordings/attachment': RecordingsAttachment;
  }
}
