// Vendor
import Component from '@glimmer/component';

// Types
import {
  RecordingsAttachmentType,
  RecordingsDatasetAttachmentStruct
} from 'airthings/types/recordings';

interface Args {
  attachment: RecordingsDatasetAttachmentStruct;
}

export default class RecordingsAttachmentsPreview extends Component<Args> {
  get isPdf() {
    return this.args.attachment.type === RecordingsAttachmentType.PDF;
  }

  get isImage() {
    return this.args.attachment.type === RecordingsAttachmentType.IMAGE;
  }
}
