// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import FastBoot from 'ember-cli-fastboot/services/fastboot';

// Types
import Analytics from 'airthings/services/analytics';
import Recordings from 'airthings/services/airthings/recordings';
import RouterService from '@ember/routing/router-service';

interface RouteParams {
  uuid: string;
}

export default class CalibrationCertificateRoute extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('router')
  router: RouterService;

  @service('airthings/recordings')
  recordings: Recordings;

  @service('fastboot')
  fastboot: FastBoot;

  constructor() {
    super(...arguments);

    const router = this.router;

    router.on('routeDidChange', () => {
      this.analytics.trackPageView(router.currentURL);
    });
  }

  async model(params: RouteParams) {
    return this.recordings.fetchCalibrationCertificateByUUID(params.uuid);
  }
}
