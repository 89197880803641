// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

interface Args {
  label: string;
  onClick?: () => {};
  onClose: () => {};
  isDangerous?: boolean;
  route?: string;
  model?: object;
}

export default class PopoverMenuItem extends Component<Args> {
  @action
  handleClick() {
    this.args.onClose();

    if (typeof this.args.onClick !== 'function') return;

    this.args.onClick();
  }
}
