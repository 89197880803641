export default {
  "size-5": "32px",
  "page-wrapper": "_page-wrapper_59h9n7",
  "header": "_header_59h9n7",
  "errors": "_errors_59h9n7",
  "actions": "_actions_59h9n7",
  "cancel-button": "_cancel-button_59h9n7 _button_znm4p3 _is-light-gray_znm4p3",
  "row": "_row_59h9n7",
  "column": "_column_59h9n7"
};
