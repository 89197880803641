// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Types
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Apollo from 'airthings/services/apollo';
import AdminAffiliateProgram from 'airthings/services/airthings/admin/affiliate-program';
import {AdminAffiliateProgramAdStruct} from 'airthings/types/admin/affiliate-program';

interface RouteParams {
  id: string;
}

type RouteModel = AdminAffiliateProgramAdStruct | null;

export default class AppAffiliateProgramAdsEditRoute extends Route {
  @service('airthings/admin/affiliate-program')
  adminAffiliateProgram: AdminAffiliateProgram;

  @service('router')
  router: RouterService;

  @queryManager
  queryManager: Apollo;

  async model(params: RouteParams): Promise<RouteModel> {
    return this.adminAffiliateProgram.watchEditAdById(
      params.id,
      this.queryManager
    );
  }

  afterModel(model: RouteModel, transition: Transition) {
    if (model) return;

    transition.abort();
    this.router.replaceWith('admin.affiliate-program-ads');
  }
}
