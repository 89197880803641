// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import Analytics from 'airthings/services/analytics';

interface GeneratedReport {
  id: string;
  pdfFileUrl?: string;
  dataset: {
    id: string;
  };
}

interface Args {
  report: GeneratedReport;
  onClose: () => void;
  onSendReportByEmail: () => void;
}

export default class ReportsGenerationConfigurationSuccess extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @action
  handleDidInsert() {
    this.analytics.trackReportConfiguration(this.args.report.dataset, {
      reportConfigurationAction: 'finish'
    });
  }
}
