// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';
import AffiliateProgram from 'airthings/services/airthings/affiliate-program';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

export default class AppCompanyShowRoute extends Route {
  @service('airthings/account')
  account: Account;

  @service('airthings/affiliate-program')
  affiliateProgram: AffiliateProgram;

  beforeModel() {
    hideIntercom();
  }

  async model() {
    const appModel = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const ads = await this.affiliateProgram.fetchAds();

    return {currentUser: appModel.currentUser, ads};
  }
}
