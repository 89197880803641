// @ts-nocheck

// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import {
  WeatherSampleStruct,
  WeatherSampleUnitType,
  WeatherSampleUnit
} from 'airthings/types/weather';
import {Chart} from 'highcharts';
import Weather from 'airthings/services/airthings/weather';
import IntlService from 'ember-intl/services/intl';

// Constants
const TITLE_MAPPING = {
  TEMPERATURE: 'weather.chart.title.temperature',
  PRESSURE: 'weather.chart.title.pressure',
  WIND_SPEED: 'weather.chart.title.wind-speed',
  PRECIPITATIONS: 'weather.chart.title.precipitations'
};

interface Args {
  height: number;
  samples: WeatherSampleStruct[];
  sampleType: WeatherSampleUnitType;
  sampleUnit: WeatherSampleUnit;
  onChartInitialized?: (chart: Chart) => void;
  pointFormatter?: () => string;
}

export default class WeatherChart extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @service('airthings/weather')
  weather: Weather;

  get sampleTypeIcon() {
    return this.args.sampleType.toLowerCase().replace(/_/g, '-');
  }

  get title() {
    return this.intl.t(TITLE_MAPPING[this.args.sampleType]);
  }

  get unitLabel() {
    return this.weather.sampleUnitLabel(this.args.sampleUnit);
  }

  get valueDecimals() {
    return this.weather.decimalCountForSampleUnitType(this.args.sampleType);
  }

  get data() {
    return this.args.samples.map((sample: WeatherSampleStruct) => {
      return this.weather.sampleToChartPoint(sample, this.args.sampleType);
    });
  }

  @action
  valueFormatter(value: number) {
    return this.weather.formatSampleUnit(value, this.args.sampleType);
  }
}
