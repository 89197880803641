export default {
  "font-bold": "700",
  "container": "_container_f4be5r",
  "success": "_success_f4be5r",
  "badge": "_badge_f4be5r",
  "icon": "_icon_f4be5r",
  "message": "_message_f4be5r",
  "actions": "_actions_f4be5r",
  "action": "_action_f4be5r",
  "download-button": "_download-button_f4be5r"
};
