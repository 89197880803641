export default {
  "font-bold": "700",
  "block": "_block_13qal2 _light-box-wrapper_1ghgfm",
  "content": "_content_13qal2 _light-box-content_1ghgfm",
  "cell": "_cell_13qal2 _cell_1ghgfm",
  "key": "_key_13qal2 _key_1ghgfm",
  "value": "_value_13qal2 _value_1ghgfm",
  "header": "_header_13qal2 _dark-box-header_1ghgfm",
  "icon-wrapper": "_icon-wrapper_13qal2 _header-icon-wrapper_1ghgfm",
  "icon": "_icon_13qal2 _header-icon_1ghgfm",
  "title": "_title_13qal2 _dark-box-header-title_1ghgfm"
};
