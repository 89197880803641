// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import CommunicationBoard from 'airthings/services/airthings/communication-board';
import {dropTask} from 'ember-concurrency-decorators';
import fade from 'ember-animated/transitions/fade';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {CommunicationBoardMessageStruct} from 'airthings/types/communication-board';

interface Args {
  onReadClick: () => void;
}

const PAGE_SIZE = 3;

export default class CommunicationBoardMessagesLatest extends Component<Args> {
  @service('airthings/communication-board')
  communicationBoard: CommunicationBoard;

  @tracked
  messages: CommunicationBoardMessageStruct[];

  transition = fade;

  @dropTask
  *fetchPaginatedMessagesTask(): TaskGenerator<void> {
    const paginatedMessages =
      yield this.communicationBoard.fetchPaginatedMessages({
        page: 1,
        pageSize: PAGE_SIZE
      });

    this.messages = paginatedMessages.entries;
  }
}
