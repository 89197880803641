export default {
  "font-bold": "700",
  "container": "_container_3ox8gw",
  "success": "_success_3ox8gw",
  "badge": "_badge_3ox8gw",
  "icon": "_icon_3ox8gw",
  "message": "_message_3ox8gw",
  "actions": "_actions_3ox8gw",
  "action": "_action_3ox8gw",
  "download-button": "_download-button_3ox8gw"
};
