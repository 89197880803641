// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {CommunicationBoardMessageStruct} from 'airthings/types/communication-board';
import CommunicationBoard from 'airthings/services/airthings/communication-board';

interface Args {
  message: CommunicationBoardMessageStruct | null;
}

export default class PageAppMessagesIndex extends Component<Args> {
  @service('airthings/communication-board')
  communicationBoard: CommunicationBoard;

  @dropTask
  *markMessageAsReadTask(
    message: CommunicationBoardMessageStruct
  ): TaskGenerator<void> {
    return yield this.communicationBoard.markMessageAsRead(message.id);
  }
}
