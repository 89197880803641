// Vendor
import Service, {inject as service} from '@ember/service';
import window from 'ember-window-mock';

// Types
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import WebviewBridgeIos from 'airthings/services/webview-bridge/ios';
import WebviewBridgeAndroid from 'airthings/services/webview-bridge/android';
import WebviewBridgeDefault from 'airthings/services/webview-bridge/default';
import Account from 'airthings/services/airthings/account';

// Constants
const MOBILE_USER_AGENT_REGEX = /airthings-mobile-app/;

export interface TriggerReportConfigurationCompletedParams {
  reportId: string;
  accessToken: string;
  refreshToken: string;
}

export interface WebviewBridgeInterface {
  triggerAnalyticsEvent: (params: object) => void;
  triggerReportConfigurationCompleted: (
    params: TriggerReportConfigurationCompletedParams
  ) => void;
  triggerReportConfigurationCancel: () => void;
}

export default class WebviewBridge extends Service {
  @service('fastboot')
  fastboot: FastBoot;

  @service('webview-bridge/default')
  webviewBridgeDefault: WebviewBridgeDefault;

  @service('webview-bridge/ios')
  webviewBridgeIos: WebviewBridgeIos;

  @service('webview-bridge/android')
  webviewBridgeAndroid: WebviewBridgeAndroid;

  @service('airthings/account')
  account: Account;

  get bridgeService(): WebviewBridgeInterface {
    if (window.AirthingsMobile) return this.webviewBridgeAndroid;

    if (window.webkit?.messageHandlers) {
      return this.webviewBridgeIos;
    }

    return this.webviewBridgeDefault;
  }

  get isEmbedded() {
    return MOBILE_USER_AGENT_REGEX.test(this.getUserAgent());
  }

  triggerAnalyticsEvent(params: object) {
    this.bridgeService.triggerAnalyticsEvent(params);
  }

  triggerReportConfigurationCompleted(reportId: string) {
    const accessToken = this.account.retrieveAccessToken();
    const refreshToken = this.account.retrieveRefreshToken();

    if (!accessToken || !refreshToken) {
      throw new Error('WebviewBridge expected the user to have its tokens.');
    }

    this.bridgeService.triggerReportConfigurationCompleted({
      reportId,
      accessToken,
      refreshToken
    });
  }

  triggerReportConfigurationCancel() {
    this.bridgeService.triggerReportConfigurationCancel();
  }

  private getUserAgent() {
    if (!this.fastboot.isFastBoot) return window.navigator.userAgent;

    return this.fastboot.request.headers.get('User-Agent') || '';
  }
}

declare module '@ember/service' {
  interface Registry {
    'webview-bridge': WebviewBridge;
  }
}
