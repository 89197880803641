// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';

export default class AppAffiliateProgramController extends Controller {
  @service('airthings/account')
  account: Account;
}
