// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Analytics from 'airthings/services/analytics';
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import RouterService from '@ember/routing/router-service';

export default class NotFound extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('router')
  router: RouterService;

  @service('fastboot')
  fastboot: FastBoot;

  constructor() {
    super(...arguments);

    const router = this.router;

    router.on('routeDidChange', () => {
      this.analytics.trackPageView(router.currentURL);
    });
  }

  beforeModel() {
    if (!this.fastboot.isFastBoot) return;

    this.fastboot.response.statusCode = 404;
  }
}
