// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';
import Form, {Validator} from 'airthings/services/form';

export interface TemplateSettingsStruct {
  title: string;
}

export default class PropertyInformationBlock
  extends Service
  implements ReportsBlockService
{
  @service('form')
  form: Form;

  @service('intl')
  intl: IntlService;

  get definition(): Readonly<ReportsBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'property-information',
      get name() {
        return intl.t('reports.block-definitions.property-information.name');
      },
      get description() {
        return intl.t(
          'reports.block-definitions.property-information.description'
        );
      },
      get helper() {
        return intl.t('reports.block-definitions.property-information.helper');
      },
      componentNamespace: 'property-information',
      lightIconPath:
        '/assets/svgs/report-block-icons/property-information-light.svg',
      darkIconPath:
        '/assets/svgs/report-block-icons/property-information-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockPropertyInformation',
      templateSettingsStruct: this.templateSettingsStruct.bind(this),
      templateSettingsValidate: this.templateSettingsValidate.bind(this),
      titlePreview: this.titlePreview.bind(this)
    });
  }

  private titlePreview(templateSettings: TemplateSettingsStruct) {
    return templateSettings.title;
  }

  private templateSettingsStruct(
    base: RecursivePartial<TemplateSettingsStruct>
  ): TemplateSettingsStruct {
    return {
      title:
        base.title ||
        this.intl.t(
          'reports.block-definitions.property-information.template-settings.title.default-value'
        )
    };
  }

  private templateSettingsValidate(
    templateSettings: TemplateSettingsStruct
  ): object | undefined {
    const validators: Array<Validator<string, 'required'>> = [
      {
        field: 'title',
        isValid: Boolean(templateSettings.title),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix:
        'reports.block-definitions.property-information.template-settings'
    });
  }
}
