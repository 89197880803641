// Vendor
import Service, {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import {AdminCommunicationBoardMessageStruct} from 'airthings/types/admin/communication-board';
import {AdminCommunicationBoardEditMessageByIdResponse} from 'airthings/graphql/queries/admin-communication-board-edit-message-by-id';
import {AdminCommunicationBoardPaginatedMessagesResponse} from 'airthings/graphql/queries/admin-communication-board-paginated-messages';
import AdminCommunicationBoardMessage from 'airthings/services/airthings/admin/communication-board/message';
import Apollo from 'airthings/services/apollo';

export default class AdminCommunicationBoard extends Service {
  @service('airthings/admin/communication-board/message')
  adminMessage: AdminCommunicationBoardMessage;

  @action
  presentAdminCommunicationBoardPaginatedMessages(
    admin: AdminCommunicationBoardPaginatedMessagesResponse['admin']
  ) {
    return this.adminMessage.presentCommunicationBoardPaginatedMessages(admin);
  }

  @action
  presentAdminCommunicationBoardEditMessageById(
    admin: AdminCommunicationBoardEditMessageByIdResponse['admin']
  ) {
    return this.adminMessage.presentCommunicationBoardEditMessageById(admin);
  }

  /**
   * @description Returns a watchQuery containing paginated admin messages
   */
  async watchPaginatedMessages(queryManager: Apollo, page?: number) {
    return this.adminMessage.watchPaginatedMessages(queryManager, page);
  }

  /**
   * @description Returns a watchQuery containing the message being edited.
   */
  async watchEditMessageById(id: string, queryManager: Apollo) {
    return this.adminMessage.watchEditMessageById(queryManager, id);
  }

  /**
   * @description Publish a message.
   */
  async publishMessage(messageId: string) {
    return this.adminMessage.publishMessage(messageId);
  }

  /**
   * @description Delete a message.
   */
  async deleteMessage(messageId: string) {
    return this.adminMessage.deleteMessage(messageId);
  }

  /**
   * @description Create a new message.
   */
  async createMessage(message: AdminCommunicationBoardMessageStruct) {
    return this.adminMessage.createMessage(message);
  }

  /**
   * @description Update a message.
   */
  async updateMessage(message: AdminCommunicationBoardMessageStruct) {
    return this.adminMessage.updateMessage(message);
  }

  /**
   * @description Validates a message creation and returns a hash with errors if there’s any
   */
  validateCreateMessage(message: AdminCommunicationBoardMessageStruct) {
    return this.adminMessage.validateCreate(message);
  }

  /**
   * @description Validates a message update and returns a hash with errors if there’s any
   */
  validateUpdateMessage(message: AdminCommunicationBoardMessageStruct) {
    return this.adminMessage.validateUpdate(message);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/communication-board': AdminCommunicationBoard;
  }
}
