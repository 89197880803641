// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';
import {action} from '@ember/object';
import window from 'ember-window-mock';

// Types
import Can from 'ember-can/services/can';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import {AccountUserStruct} from 'airthings/types/account';
import Account from 'airthings/services/airthings/account';
import Recordings from 'airthings/services/airthings/recordings';
import Apollo from 'airthings/services/apollo';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import AppDatasetsEditController from 'airthings/pods/app/datasets/edit/controller';
import IntlService from 'ember-intl/services/intl';
import {RecordingsEditDatasetByExternalIdResponse} from 'airthings/graphql/queries/recordings-edit-dataset-by-external-id';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

// Config
import config from 'airthings/config/environment';

interface RouteParams {
  externalId: string;
}

interface RouteModel {
  currentUser: AccountUserStruct;
  dataset: RecordingsEditDatasetByExternalIdResponse | null;
  externalId: string;
}

export default class AppDatasetsEditRoute extends Route {
  controller: AppDatasetsEditController;

  @service('intl')
  intl: IntlService;

  @service('fastboot')
  fastboot: FastBoot;

  @service('airthings/account')
  account: Account;

  @service('can')
  can: Can;

  @service('router')
  router: RouterService;

  @service('airthings/recordings')
  recordings: Recordings;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @queryManager
  queryManager: Apollo;

  get canLeaveRoute() {
    return !this.controller.hasUploadingAttachments;
  }

  beforeModel(transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    if (this.can.cannot('edit dataset', null, {currentUser})) {
      transition.abort();

      this.router.replaceWith('app');
    }

    hideIntercom();
  }

  async model(params: RouteParams): Promise<RouteModel> {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    const dataset = await this.recordings.watchEditDatasetByExternalId(
      params.externalId,
      this.queryManager
    );

    return {
      currentUser,
      dataset,
      externalId: params.externalId
    };
  }

  afterModel(model: RouteModel, transition: Transition) {
    if (model.dataset) return;

    transition.abort();
    this.router.replaceWith('app.datasets');
  }

  @action
  didTransition(): void {
    if (this.fastboot.isFastBoot || !config.APP.handleBeforeUnloadEvents) {
      return;
    }

    window.addEventListener(
      'beforeunload',
      this.promptUnsavedAttachmentsConfirmation.bind(this)
    );
  }

  @action
  deactivate(): void {
    if (this.fastboot.isFastBoot || !config.APP.handleBeforeUnloadEvents) {
      return;
    }

    window.removeEventListener(
      'beforeunload',
      this.promptUnsavedAttachmentsConfirmation.bind(this)
    );
  }

  @action
  willTransition(transition: Transition): void {
    if (this.canLeaveRoute) return;

    const confirmationMessage = this.intl.t(
      'app-datasets-edit.uploading-attachments-leave-confirmation'
    );

    if (window.confirm(confirmationMessage)) return;

    transition.abort();
  }

  private promptUnsavedAttachmentsConfirmation(event: Event) {
    if (this.canLeaveRoute) return;

    event.preventDefault();
    event.returnValue = window.confirm(
      this.intl.t('app-datasets-edit.uploading-attachments-leave-confirmation')
    );
  }
}
