export default {
  "font-bold": "700",
  "container": "_container_1jwwb6",
  "error": "_error_1jwwb6",
  "badge": "_badge_1jwwb6",
  "icon": "_icon_1jwwb6",
  "message": "_message_1jwwb6",
  "actions": "_actions_1jwwb6",
  "action": "_action_1jwwb6"
};
