export default {
  "font-medium": "500",
  "wrapper": "_wrapper_152x2d",
  "filled": "_filled_152x2d",
  "errored": "_errored_152x2d",
  "label": "_label_152x2d",
  "input": "_input_152x2d",
  "inline": "_inline_152x2d",
  "disabled": "_disabled_152x2d",
  "required": "_required_152x2d",
  "popover": "_popover_152x2d",
  "popover-content": "_popover-content_152x2d",
  "button": "_button_152x2d"
};
