// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';

// Types
import {
  DynamicParagraphTemplateSettingsOptionStruct,
  ReportsDynamicParagraphBlockDefinition,
  TemplateSettingsStruct
} from 'airthings/services/airthings/reports/block-definitions/dynamic-paragraph';
import {ReportsTemplateSettingsComponentArgs} from 'airthings/types/reports';
import Reports from 'airthings/services/airthings/reports';

export default class ReportsBlocksDynamicParagraphTemplateSettings extends Component<
  ReportsTemplateSettingsComponentArgs<TemplateSettingsStruct>
> {
  @service('airthings/reports')
  reports: Reports;

  @tracked
  expandedOptionIndex: number | null = 0;

  get blockDefinition() {
    return this.reports.getTemplateBlockDefinition(
      'dynamic-paragraph'
    ) as ReportsDynamicParagraphBlockDefinition;
  }

  @action
  changeOption(
    index: number,
    updatedOption: DynamicParagraphTemplateSettingsOptionStruct
  ) {
    const options = this.args.settingsStruct.options;
    options[index] = updatedOption;

    this.args.onChange({options});
  }

  @action
  addOption() {
    const newOption = this.blockDefinition.templateSettingsOptionStruct({});
    const options = [...this.args.settingsStruct.options, newOption];

    this.args.onChange({options});
    this.args.dismissErrors();
    this.expandedOptionIndex = options.length - 1;
  }

  @action
  removeOption(index: number) {
    if (this.expandedOptionIndex === index) {
      this.expandedOptionIndex = null;
    } else if (this.expandedOptionIndex && this.expandedOptionIndex > index) {
      this.expandedOptionIndex -= 1;
    }

    const options = [
      ...this.args.settingsStruct.options.slice(0, index),
      ...this.args.settingsStruct.options.slice(index + 1)
    ];

    this.args.onChange({options});
    this.args.dismissErrors();
  }

  @action
  toggleOption(index: number) {
    this.expandedOptionIndex =
      index === this.expandedOptionIndex ? null : index;
  }
}
