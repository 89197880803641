// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';

export default class LobbyProfile extends Route {
  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  async beforeModel(transition: Transition) {
    const completionStatus = await this.account.fetchAccountCompletionStatus();

    if (!completionStatus) {
      transition.abort();

      this.router.replaceWith('login');

      return;
    }

    if (completionStatus.hasProfile) {
      transition.abort();

      this.router.replaceWith('lobby.company');

      return;
    }
  }

  async model() {
    const currentUser = await this.account.fetchCurrentUser();

    if (currentUser) return currentUser;

    return this.account.userStruct();
  }
}
