// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {bool} from '@ember/object/computed';
import Inputmask from 'inputmask';

// Constants
const DEFAULT_INPUT_TYPE = 'text';

interface Args {
  disabled?: boolean;
  error?: string;
  label: string;
  onChange: (changedValue: string) => void;
  didInsert?: (inputElement: HTMLInputElement) => void;
  onKeydown?: (event: KeyboardEvent) => void;
  willDestroy?: () => void;
  required?: boolean;
  value: string;
  helper?: string;
  type?: string;
  placeholder?: string;
  inline?: boolean;
  autofocus?: boolean;
  inputMask?: object;
  button?: string;
  buttonTheme?: string;
  onButtonClick?: () => void;
}

export default class FormInput extends Component<Args> {
  @bool('args.error')
  isErrored: boolean;

  @bool('args.value')
  hasValue: boolean;

  get disabled() {
    if (typeof this.args.onChange !== 'function') return true;

    return this.args.disabled;
  }

  get type() {
    return this.args.type || DEFAULT_INPUT_TYPE;
  }

  get placeholder() {
    return this.args.placeholder || '';
  }

  get inline() {
    return this.args.inline || false;
  }

  @action
  handleInput(event: KeyboardEvent) {
    if (typeof this.args.onChange !== 'function') return;

    this.args.onChange((event.target as HTMLInputElement).value);
  }

  @action
  handleKeydown(event: KeyboardEvent) {
    if (typeof this.args.onKeydown !== 'function') return;

    this.args.onKeydown(event);
  }

  @action
  handleDidInsert(inputElement: HTMLInputElement) {
    if (this.args.autofocus) inputElement.focus();
    if (this.args.inputMask) {
      Inputmask(this.args.inputMask).mask(inputElement);
    }

    if (typeof this.args.didInsert !== 'function') return;

    this.args.didInsert(inputElement);
  }

  @action
  handleWillDestroy(): void {
    if (typeof this.args.willDestroy !== 'function') return;

    this.args.willDestroy();
  }
}
