// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {dropTask} from 'ember-concurrency-decorators';
import {inject as service} from '@ember/service';
import parseISO from 'date-fns/parseISO';
import {differenceInCalendarDays} from 'date-fns';
import {TaskGenerator} from 'ember-concurrency';

// Services
import Analytics from 'airthings/services/analytics';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';
import RouterService from '@ember/routing/router-service';
import ValidationErrors from 'airthings/services/form';

// Types
import {AccountUserStruct} from 'airthings/types/account';
import {
  RecordingsDeviceStruct,
  RecordingsDeviceCalibrationCertificateStruct,
  DevicesSortField
} from 'airthings/types/recordings';
import {RecordingsCreateDeviceResponse} from 'airthings/graphql/mutations/recordings-create-device';

interface Args {
  currentUser: AccountUserStruct;
  devices: RecordingsDeviceStruct[];
}

export default class PageAppDevicesIndex extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @service('router')
  router: RouterService;

  @tracked
  deletingDevice: RecordingsDeviceStruct | null = null;

  @tracked
  editingDevice: RecordingsDeviceStruct | null = null;

  @tracked
  creatingDevice: RecordingsDeviceStruct;

  @tracked
  stagedDeviceForCalibrationCertificateGeneration: RecordingsDeviceStruct | null =
    null;

  @tracked
  stagedDueCalibrationCertificate: RecordingsDeviceCalibrationCertificateStruct | null =
    null;

  @tracked
  stagedExpiredCalibrationCertificate: RecordingsDeviceCalibrationCertificateStruct | null =
    null;

  @tracked
  serialNumberConfirmation: string = '';

  @tracked
  showCreationModal = false;

  @tracked
  showCreationConfirmationModal: boolean = false;

  @tracked
  stagedDeviceForCrosscheckModal: RecordingsDeviceStruct | null = null;

  get stagedDueCalibrationCertificateDays() {
    if (!this.stagedDueCalibrationCertificate?.expiredAt) return 0;

    const date = parseISO(this.stagedDueCalibrationCertificate.expiredAt);
    const now = new Date();

    return differenceInCalendarDays(date, now);
  }

  get stagedCrosscheckDue() {
    return (
      this.stagedDeviceForCrosscheckModal?.datasetsUntilCrosscheck !==
        undefined &&
      this.stagedDeviceForCrosscheckModal?.datasetsUntilCrosscheck <= 0
    );
  }

  get stagedCrosscheckDueMessage() {
    if (this.stagedCrosscheckDue) {
      return this.intl.t('app-devices-index.crosscheck-modal.due-now-message');
    }

    return this.intl.t('app-devices-index.crosscheck-modal.due-in-message', {
      count: this.stagedDeviceForCrosscheckModal?.datasetsUntilCrosscheck
    });
  }

  readonly nameSortField = DevicesSortField.NAME;
  readonly serialNumberSortField = DevicesSortField.SERIAL_NUMBER;
  readonly calibrationActivatedAtSortField =
    DevicesSortField.CALIBRATION_ACTIVATED_AT;
  readonly crosscheckSortField = DevicesSortField.DATASETS_SINCE_CROSSCHECK;
  readonly calibrationStatusSortField = DevicesSortField.CALIBRATION_STATUS;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.creatingDevice = this.recordings.deviceStruct();
  }

  @dropTask
  *deleteDeviceTask(): TaskGenerator<void> {
    if (!this.deletingDevice) return;

    const result = yield this.recordings.deleteDevice(this.deletingDevice);

    this.deletingDevice = null;

    if (result.successful) {
      this.analytics.trackDeviceDeletion(result.result);

      this.flashMessages.success(
        this.intl.t('app-devices-index.delete-modal.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *createDeviceTask() {
    return this.recordings.createDevice(this.creatingDevice);
  }

  @dropTask
  *editDeviceTask(): TaskGenerator<void> {
    if (!this.editingDevice) return;

    return yield this.recordings.updateDevice(this.editingDevice);
  }

  @action
  initiateDeleteDevice(device: RecordingsDeviceStruct) {
    this.deletingDevice = device;
  }

  @action
  initiateEditDevice(device: RecordingsDeviceStruct) {
    this.editingDevice = device;
  }

  @action
  initiateCreateDevice() {
    this.showCreationModal = true;
  }

  @action
  initiateCalibrationCertificateGeneration(device: RecordingsDeviceStruct) {
    this.stagedDeviceForCalibrationCertificateGeneration = device;
  }

  @action
  initiateDueCalibrationCertificateModal(
    certificate: RecordingsDeviceCalibrationCertificateStruct
  ) {
    this.stagedDueCalibrationCertificate = certificate;
  }

  @action
  initiateExpiredCalibrationCertificateModal(
    certificate: RecordingsDeviceCalibrationCertificateStruct
  ) {
    this.stagedExpiredCalibrationCertificate = certificate;
  }

  @action
  cancelDeleteDevice() {
    this.deletingDevice = null;
  }

  @action
  cancelEditDevice() {
    this.editingDevice = null;
  }

  @action
  cancelCalibrationCertificateGeneration() {
    this.stagedDeviceForCalibrationCertificateGeneration = null;
  }

  @action
  cancelDueCalibrationCertificateModal() {
    this.stagedDueCalibrationCertificate = null;
  }

  @action
  cancelExpiredCalibrationCertificateModal() {
    this.stagedExpiredCalibrationCertificate = null;
  }

  @action
  createValidate() {
    return this.recordings.validateCreateDevice(this.creatingDevice);
  }

  @action
  editValidate() {
    if (!this.editingDevice) return;

    return this.recordings.validateUpdateDevice(this.editingDevice);
  }

  @action
  handleCreateDeviceChange(updatedDevice: RecordingsDeviceStruct) {
    this.creatingDevice = updatedDevice;
  }

  @action
  handleEditDeviceChange(updatedDevice: RecordingsDeviceStruct) {
    this.editingDevice = updatedDevice;
  }

  @action
  handleCreationSuccess(
    response: RecordingsCreateDeviceResponse['createDevice']
  ) {
    this.analytics.trackDeviceCreation(response.result);

    this.flashMessages.success(
      this.intl.t('app-devices-index.creation-modal.success')
    );

    this.showCreationModal = false;
    this.showCreationConfirmationModal = true;
  }

  @action
  handleCreationFailure() {
    this.analytics.trackDeviceCreationFailure();
  }

  @action
  handleCreationValidationFailure(errors: ValidationErrors) {
    this.analytics.trackDeviceCreationValidationFailure(errors);
  }

  @action
  handleEditionSuccess() {
    this.flashMessages.success(
      this.intl.t('app-devices-index.edition-modal.success')
    );

    this.editingDevice = null;
  }

  @action
  dismissCreationConfirmationModal() {
    this.showCreationConfirmationModal = false;
  }

  @action
  initiateCrosscheckModal(device: RecordingsDeviceStruct) {
    this.stagedDeviceForCrosscheckModal = device;
  }

  @action
  dismissCrosscheckModal() {
    this.stagedDeviceForCrosscheckModal = null;
  }

  @action
  cancelCreateDevice() {
    this.creatingDevice = this.recordings.deviceStruct();
    this.showCreationModal = false;
  }

  @action
  search(term: string) {
    this.router.replaceWith('app.devices.index', {queryParams: {q: term}});
  }

  @action
  clearSearch() {
    this.router.replaceWith('app.devices.index', {
      queryParams: {q: null}
    });
  }
}
