// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import {AccountCompanyDatasetSettings} from 'airthings/types/account';
import IntlService from 'ember-intl/services/intl';

interface Args {
  formData: AccountCompanyDatasetSettings;
  onChange: (updatedFormData: AccountCompanyDatasetSettings) => void;
  errors?: object;
}

export default class AccountCompanyDatasetSettingsForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get defaultMeasurementTypeOptions() {
    return [
      {
        value: 'INITIAL',
        label: this.intl.t('recordings.dataset-types.initial')
      },
      {
        value: 'FOLLOW_UP',
        label: this.intl.t('recordings.dataset-types.follow-up')
      },
      {
        value: 'DUPLICATE',
        label: this.intl.t('recordings.dataset-types.duplicate')
      },
      {
        value: 'CROSSCHECK',
        label: this.intl.t('recordings.dataset-types.crosscheck')
      },
      {
        value: 'POST_MITIGATION',
        label: this.intl.t('recordings.dataset-types.post-mitigation')
      },
      {
        value: 'REAL_ESTATE_TRANSACTION',
        label: this.intl.t('recordings.dataset-types.real-estate-transaction')
      }
    ];
  }

  get defaultDurationInSecondsOptions() {
    return [
      {
        value: 86400,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.24h'
        )
      },
      {
        value: 172800,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.48h'
        )
      },
      {
        value: 259200,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.72h'
        )
      },
      {
        value: 345600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.96h'
        )
      },
      {
        value: 604800,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.7d'
        )
      },
      {
        value: 1209600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.14d'
        )
      },
      {
        value: 2419200,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.4w'
        )
      },
      {
        value: 4838400,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.8w'
        )
      },
      {
        value: 7257600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.12w'
        )
      },
      {
        value: 9676800,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.16w'
        )
      },
      {
        value: 12096000,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.20w'
        )
      },
      {
        value: 14515200,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.24w'
        )
      },
      {
        value: 19353600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.32w'
        )
      },
      {
        value: 25401600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.42w'
        )
      },
      {
        value: 31449600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.duration-in-seconds.options.52w'
        )
      }
    ];
  }

  get defaultDelayInSecondsOptions() {
    return [
      {
        value: 3600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.1h'
        )
      },
      {
        value: 7200,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.2h'
        )
      },
      {
        value: 10800,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.3h'
        )
      },
      {
        value: 14400,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.4h'
        )
      },
      {
        value: 21600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.6h'
        )
      },
      {
        value: 28800,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.8h'
        )
      },
      {
        value: 36000,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.10h'
        )
      },
      {
        value: 43200,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.12h'
        )
      },
      {
        value: 50400,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.14h'
        )
      },
      {
        value: 57600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.16h'
        )
      },
      {
        value: 64800,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.18h'
        )
      },
      {
        value: 72000,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.20h'
        )
      },
      {
        value: 86400,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.24h'
        )
      },
      {
        value: 129600,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.36h'
        )
      },
      {
        value: 172800,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.48h'
        )
      },
      {
        value: 259200,
        label: this.intl.t(
          'account.company.dataset-settings.default-options.delay-in-seconds.options.72h'
        )
      }
    ];
  }

  @action
  changeDefaultDurationInSeconds(rawDefaultDurationInSeconds: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultDurationInSeconds: rawDefaultDurationInSeconds
        ? parseInt(rawDefaultDurationInSeconds, 10)
        : null
    });
  }

  @action
  changeDefaultDelayInSeconds(rawDefaultDelayInSeconds: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultDelayInSeconds: rawDefaultDelayInSeconds
        ? parseInt(rawDefaultDelayInSeconds, 10)
        : null
    });
  }

  @action
  changeDefaultMeasurementType(defaultMeasurementType: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultMeasurementType
    });
  }

  @action
  changeRoomTypeOptions(roomTypeOptions: string[]) {
    let defaultRoomType = this.args.formData.defaultRoomType;

    if (defaultRoomType && !roomTypeOptions.includes(defaultRoomType)) {
      defaultRoomType = null;
    }

    this.args.onChange({
      ...this.args.formData,
      defaultRoomType,
      roomTypeOptions
    });
  }

  @action
  changeDefaultRoomType(defaultRoomType: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultRoomType
    });
  }

  @action
  changeFloorOptions(floorOptions: string[]) {
    let defaultFloor = this.args.formData.defaultFloor;

    if (defaultFloor && !floorOptions.includes(defaultFloor)) {
      defaultFloor = null;
    }

    this.args.onChange({
      ...this.args.formData,
      defaultFloor,
      floorOptions
    });
  }

  @action
  changeDefaultFloor(defaultFloor: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultFloor
    });
  }

  @action
  changeBuildingTypeOptions(buildingTypeOptions: string[]) {
    let defaultBuildingType = this.args.formData.defaultFloor;

    if (
      defaultBuildingType &&
      !buildingTypeOptions.includes(defaultBuildingType)
    ) {
      defaultBuildingType = null;
    }

    this.args.onChange({
      ...this.args.formData,
      defaultBuildingType,
      buildingTypeOptions
    });
  }

  @action
  changeDefaultBuildingType(defaultBuildingType: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultBuildingType
    });
  }

  @action
  changeFoundationTypeOptions(foundationTypeOptions: string[]) {
    let defaultFoundationType = this.args.formData.defaultFoundationType;

    if (
      defaultFoundationType &&
      !foundationTypeOptions.includes(defaultFoundationType)
    ) {
      defaultFoundationType = null;
    }

    this.args.onChange({
      ...this.args.formData,
      defaultFoundationType,
      foundationTypeOptions
    });
  }

  @action
  changeFoundationType(defaultFoundationType: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultFoundationType
    });
  }

  @action
  changeVentilationOptions(ventilationOptions: string[]) {
    let defaultVentilation = this.args.formData.defaultFoundationType;

    if (
      defaultVentilation &&
      !ventilationOptions.includes(defaultVentilation)
    ) {
      defaultVentilation = null;
    }

    this.args.onChange({
      ...this.args.formData,
      defaultVentilation,
      ventilationOptions
    });
  }

  @action
  changeDefaultVentilation(defaultVentilation: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultVentilation
    });
  }

  @action
  changeMitigationSystemOptions(mitigationSystemOptions: string[]) {
    let defaultMitigationSystem = this.args.formData.defaultFoundationType;

    if (
      defaultMitigationSystem &&
      !mitigationSystemOptions.includes(defaultMitigationSystem)
    ) {
      defaultMitigationSystem = null;
    }

    this.args.onChange({
      ...this.args.formData,
      defaultMitigationSystem,
      mitigationSystemOptions
    });
  }

  @action
  changeDefaultMitigationSystem(defaultMitigationSystem: string) {
    this.args.onChange({
      ...this.args.formData,
      defaultMitigationSystem
    });
  }
}
