// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {
  DatasetInformation,
  RecordingsDatasetBuildingMetadata,
  RecordingsDatasetContactMetadata,
  RecordingsDatasetCustomerMetadata,
  RecordingsDatasetMeasurementMetadata,
  RecordingsDatasetOwnerMetadata,
  RecordingsEditDatasetStruct
} from 'airthings/types/recordings';
import Recordings from 'airthings/services/airthings/recordings';
import {dropTask} from 'ember-concurrency-decorators';
import {AccountUserStruct} from 'airthings/types/account';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import {DataProxy} from '@apollo/client/core';

interface Args {
  currentUser: AccountUserStruct;
  dataset: RecordingsEditDatasetStruct;
  externalId: string;
  onIncrementUploadingAttachmentsCountChange: () => void;
  onDecrementUploadingAttachmentsCountChange: () => void;
}

export default class PageAppDatasetsEdit extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @tracked
  dataset: RecordingsEditDatasetStruct = this.args.dataset;

  @action
  removeDeletedAttachmentFromCache(store: DataProxy, attachmentId: string) {
    this.recordings.removeDeletedAttachmentFromEditDatasetByExternalId(
      store,
      attachmentId,
      this.args.externalId
    );
  }

  @dropTask
  *datasetPersistTask(): TaskGenerator<void> {
    return yield this.recordings.updateDataset(this.dataset);
  }

  @action
  changeInformation(updatedInformation: DatasetInformation) {
    this.dataset = {
      ...this.dataset,
      information: updatedInformation
    };
  }

  @action
  changeMeasurementMetadata(
    updatedMeasurementMetadata: RecordingsDatasetMeasurementMetadata
  ) {
    this.dataset = {
      ...this.dataset,
      measurementMetadata: updatedMeasurementMetadata
    };
  }

  @action
  changeBuildingMetadata(
    updatedBuildingMetadata: RecordingsDatasetBuildingMetadata
  ) {
    this.dataset = {
      ...this.dataset,
      buildingMetadata: updatedBuildingMetadata
    };
  }

  @action
  changeContactMetadata(
    updatedContactMetadata: RecordingsDatasetContactMetadata
  ) {
    this.dataset = {
      ...this.dataset,
      contactMetadata: updatedContactMetadata
    };
  }

  @action
  changeOwnerMetadata(updatedOwnerMetadata: RecordingsDatasetOwnerMetadata) {
    this.dataset = {
      ...this.dataset,
      ownerMetadata: updatedOwnerMetadata
    };
  }

  @action
  changeCustomerMetadata(
    updatedCustomerMetadata: RecordingsDatasetCustomerMetadata
  ) {
    this.dataset = {
      ...this.dataset,
      customerMetadata: updatedCustomerMetadata
    };
  }

  @action
  validate(): object | undefined {
    return this.recordings.validateDataset(this.dataset);
  }

  @action
  showFlashMessage() {
    this.flashMessages.success(this.intl.t('app-datasets-edit.form.success'));
  }
}
