// Vendor
import Service from '@ember/service';
import window from 'ember-window-mock';

// Types
import {
  TriggerReportConfigurationCompletedParams,
  WebviewBridgeInterface
} from 'airthings/services/webview-bridge';

type EventHandler = (params?: string) => void;

export default class WebviewBridgeIos
  extends Service
  implements WebviewBridgeInterface
{
  triggerAnalyticsEvent(params: object) {
    const eventHandler = this.getEventHandler('trackAnalyticsEvent');

    eventHandler(JSON.stringify(params));
  }

  triggerReportConfigurationCompleted(
    params: TriggerReportConfigurationCompletedParams
  ) {
    const eventHandler = this.getEventHandler('onReportConfigurationCompleted');

    eventHandler(JSON.stringify(params));
  }

  triggerReportConfigurationCancel() {
    const eventHandler = this.getEventHandler('onReportConfigurationCancel');

    // iOS does not support having no params
    eventHandler('');
  }

  private getEventHandler(eventName: string): EventHandler {
    if (!window.webkit || !window.webkit.messageHandlers) {
      throw new Error(
        `WebviewBridge[iOS] expected window.webkit.messageHandler to be defined.`
      );
    }

    const event = window.webkit.messageHandlers[eventName];
    if (typeof event.postMessage !== 'function') {
      throw new Error(
        `WebviewBridge[iOS] expected window.webkit.messageHandler.${eventName}.postMessage to be defined.`
      );
    }

    return event.postMessage.bind(event);
  }
}

declare module '@ember/service' {
  interface Registry {
    'webview-bridge/ios': WebviewBridgeIos;
  }
}
