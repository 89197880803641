// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';

// Types
import {AdminAccountListUserStruct} from 'airthings/types/admin/account';
import {GraphqlPagination} from 'airthings/types/graphql';
import AdminAccount from 'airthings/services/airthings/admin/account';
import RouterService from '@ember/routing/router-service';
import IntlService from 'ember-intl/services/intl';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import {UserRole} from 'airthings/types/account';
import {TaskGenerator} from 'ember-concurrency';

interface MoveCompany {
  id: string;
  name: string;
}

interface Args {
  users: AdminAccountListUserStruct[];
  companies: MoveCompany[];
  pagination: GraphqlPagination;
  searchTerm?: string;
}

export default class PageAdminUsersIndex extends Component<Args> {
  @service('airthings/admin/account')
  adminAccount: AdminAccount;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @tracked
  stagedUserToMove: AdminAccountListUserStruct | null = null;

  @tracked
  stagedUserToAssignAffiliateProgramCouponCodes: AdminAccountListUserStruct | null =
    null;

  @tracked
  selectedMoveCompanyId: string | null = null;

  @tracked
  selectedMoveRole: UserRole = UserRole.EMPLOYEE;

  @tracked
  proCouponCode: string | null = null;

  @tracked
  reportCouponCode: string | null = null;

  get moveCompanyOptions() {
    return this.args.companies.map((company) => {
      return {
        value: company.id,
        label: company.name
      };
    });
  }

  get moveRoleOptions() {
    return [
      {
        value: UserRole.EMPLOYEE,
        label: this.intl.t('account.user-role-form.role.employee')
      },
      {
        value: UserRole.MANAGER,
        label: this.intl.t('account.user-role-form.role.manager')
      }
    ];
  }

  @action
  changeCompany(selectedCompanyId: string) {
    this.selectedMoveCompanyId = selectedCompanyId;
  }

  @action
  changeRole(selectedRole: UserRole) {
    this.selectedMoveRole = selectedRole;
  }

  @action
  changeProCouponCode(code: string) {
    this.proCouponCode = code;
  }

  @action
  changeReportCouponCode(code: string) {
    this.reportCouponCode = code;
  }

  @action
  search(term: string) {
    this.router.replaceWith('admin.users', {queryParams: {q: term}});
  }

  @dropTask
  *moveUserTask(): TaskGenerator<void> {
    if (!this.stagedUserToMove) return;
    if (!this.selectedMoveCompanyId) return;

    const result = yield this.adminAccount.moveUser(
      this.stagedUserToMove.id,
      this.selectedMoveCompanyId,
      this.selectedMoveRole
    );

    if (result.successful) {
      this.stagedUserToMove = null;
      this.selectedMoveCompanyId = null;

      this.flashMessages.success(
        this.intl.t('admin-users-index.move-modal.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *assignAffiliateProgramCouponCodesTask(): TaskGenerator<void> {
    if (!this.stagedUserToAssignAffiliateProgramCouponCodes) return;

    const result = yield this.adminAccount.assignAffiliateProgramCouponCodes(
      this.stagedUserToAssignAffiliateProgramCouponCodes.id,
      this.proCouponCode || '',
      this.reportCouponCode || ''
    );

    if (result.successful) {
      this.stagedUserToAssignAffiliateProgramCouponCodes = null;
      this.proCouponCode = null;
      this.reportCouponCode = null;

      this.flashMessages.success(
        this.intl.t(
          'admin-users-index.assign-affiliate-program-coupon-codes-modal.success'
        )
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *logAsTask(user: AdminAccountListUserStruct, role: UserRole) {
    const {successful} = yield this.adminAccount.logAs(user.company.id, role);

    if (successful) {
      window.location.reload();
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  cancelMove() {
    this.stagedUserToMove = null;
    this.selectedMoveCompanyId = null;
  }

  @action
  cancelAssignAffiliateProgramCouponCodes() {
    this.stagedUserToAssignAffiliateProgramCouponCodes = null;
    this.proCouponCode = null;
    this.reportCouponCode = null;
  }

  @action
  clearSearch() {
    this.router.replaceWith('admin.users', {
      queryParams: {q: null}
    });
  }

  @action
  initiateMoveUser(user: AdminAccountListUserStruct) {
    this.stagedUserToMove = user;
  }

  @action
  initiateAssignAffiliateProgramCouponCodes(user: AdminAccountListUserStruct) {
    this.stagedUserToAssignAffiliateProgramCouponCodes = user;

    const couponCodes = user.profile.affiliateProgramCouponCodes;

    this.proCouponCode = couponCodes ? couponCodes.pro : null;
    this.reportCouponCode = couponCodes ? couponCodes.report : null;
  }
}
