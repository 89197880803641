// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';

// Services
import IntlService from 'ember-intl/services/intl';

// Types
import {AdminAccountListCompanyStruct} from 'airthings/types/admin/account';
import {
  AdminRecordingsDevicesMoveResultStruct,
  AdminRecordingsDeviceStruct
} from 'airthings/types/admin/recordings';

interface Args {
  devices: Array<RecursivePartial<AdminRecordingsDeviceStruct>>;
  moveResult: AdminRecordingsDevicesMoveResultStruct;
  company: AdminAccountListCompanyStruct;
}

export default class DevicesMoveCompleted extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get companyLabel() {
    return `${this.args.company.name} (${this.args.company.email})`;
  }

  get shouldDisplayErrors() {
    return this.args.moveResult.errors.length > 0;
  }

  get moveCount() {
    return this.args.moveResult.devices.filter(
      ({deletedAt}) => !Boolean(deletedAt)
    ).length;
  }

  get moveErrors() {
    return this.args.moveResult.errors.map(({deviceId, error}) => {
      const serialNumber = this.args.devices.find(
        (device) => device.id === deviceId
      )?.serialNumber;

      return {
        serialNumber,
        message: this.intl.t(`admin-devices-move.errors.${error}`)
      };
    });
  }
}
