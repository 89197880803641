// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

// Services
import Recordings from 'airthings/services/airthings/recordings';
import Weather from 'airthings/services/airthings/weather';
import {
  WeatherSampleStruct,
  WeatherSuccessMetadataStruct
} from 'airthings/types/weather';
import {
  RecordingsSamplesMetadata,
  RecordingsSampleStruct
} from 'airthings/types/recordings';

interface Args {
  timeZone: string;
  radonSamples: RecordingsSampleStruct[];
  radonMetadata: RecordingsSamplesMetadata;
  weatherSamples: WeatherSampleStruct[];
  weatherMetadata: WeatherSuccessMetadataStruct;
}

export default class WeatherReportTabulatedData extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @service('airthings/weather')
  weather: Weather;

  get timeValues() {
    return this.args.weatherSamples.map((sample) => sample.timestamp);
  }

  get temperatureValues() {
    return this.args.weatherSamples.map(
      (sample) => `${sample.temperature} ${this.temperatureUnit}`
    );
  }

  get pressureValues() {
    return this.args.weatherSamples.map(
      (sample) => `${sample.pressure} ${this.pressureUnit}`
    );
  }

  get windSpeedValues() {
    return this.args.weatherSamples.map(
      (sample) =>
        `${sample.windSpeed} ${this.windSpeedUnit} | ${sample.windDirection}`
    );
  }

  get precipitationsValues() {
    return this.args.weatherSamples.map((sample) =>
      sample.precipitations > 0
        ? `${sample.precipitations} ${this.precipitationsUnit}`
        : '-'
    );
  }

  get radonValues() {
    return this.args.radonSamples.map((sample) => sample.radonShortTermAverage);
  }

  get temperatureUnit() {
    return this.weather.sampleUnitLabel(
      this.args.weatherMetadata.temperatureUnit
    );
  }

  get pressureUnit() {
    return this.weather.sampleUnitLabel(this.args.weatherMetadata.pressureUnit);
  }

  get windSpeedUnit() {
    return this.weather.sampleUnitLabel(
      this.args.weatherMetadata.windSpeedUnit
    );
  }

  get precipitationsUnit() {
    return this.weather.sampleUnitLabel(
      this.args.weatherMetadata.precipitationsUnit
    );
  }

  get radonUnit() {
    return this.recordings.sampleUnitLabel(this.args.radonMetadata.radonUnit);
  }
}
