// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

interface Args {
  label: string;
  value?: any;
  isChecked: boolean;
  onChange: (newIsChecked: boolean, value?: any) => void;
}

export default class FormCheckbox extends Component<Args> {
  @action
  handleClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (typeof this.args.onChange !== 'function') return;

    this.args.onChange(!this.args.isChecked, this.args.value);
  }
}
