import {WeatherStruct} from './weather';
import {CalibrationType} from 'airthings/types/admin/recordings';

export enum RecordingsUnitSystem {
  UNITED_STATES = 'UNITED_STATES',
  METRIC = 'METRIC'
}

export enum RecordingsSampleUnitType {
  RADON_SHORT_TERM_AVERAGE = 'RADON_SHORT_TERM_AVERAGE',
  RADON_UNCERTAINTY = 'RADON_UNCERTAINTY',
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  HUMIDITY = 'HUMIDITY'
}

export type RecordingsSampleUnit =
  | RecordingsRadonShortTimeAverageUnit
  | RecordingsTemperatureUnit
  | RecordingsHumidityUnit
  | RecordingsPressureUnit;

export enum RecordingsRadonShortTimeAverageUnit {
  BECQUERELS_PER_CUBIC_METER = 'BECQUERELS_PER_CUBIC_METER',
  PICOCURIES_PER_LITER = 'PICOCURIES_PER_LITER'
}

export enum RecordingsTemperatureUnit {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum RecordingsPressureUnit {
  KILOPASCAL = 'KILOPASCAL',
  INCHES_OF_MERCURY = 'INCHES_OF_MERCURY'
}

export enum RecordingsHumidityUnit {
  RELATIVE_HUMIDITY = 'RELATIVE_HUMIDITY'
}

export enum RecordingsAttachmentType {
  IMAGE = 'IMAGE',
  PDF = 'PDF'
}

export interface RecordingsDeviceStruct {
  id: string | null;
  name: string;
  serialNumber: string;
  serialNumberConfirmation?: string;
  calibrationCertificate?: RecordingsDeviceCalibrationCertificateStruct | null;
  datasetsUntilCrosscheck?: number;
}

export interface RecordingsDeviceCalibrationCertificateStruct {
  id?: string | null;
  uuid?: string | null;
  activatedAt?: string | null;
  expiredAt?: string | null;
  isDue?: boolean | null;
  isExpired?: boolean | null;
  calibrationMeta?: {
    calibrationType?: CalibrationType;
  };
}

export interface RecordingsDatasetDeviceStruct {
  id: string;
  name: string;
  serialNumber: string;
}

export interface RecordingsListDatasetStruct {
  value: string;
  id: string;
  name: string;
  recordingStartedAt: string;
  durationInSeconds: number;
  delayInSeconds: number;
  insertedAt: string;
  deletedAt: string;
  potentialTamperingDetected: boolean;
  device: RecordingsDatasetDeviceStruct;
  metadata: {
    customer: {
      name: string;
    };
  };
}

export interface RecordingsDatasetCustomerMetadata {
  address: string;
  city: string;
  country: string;
  email: string;
  name: string;
  phoneNumber: string;
  postalCode: string;
  state: string;
}

export interface RecordingsDatasetOwnerMetadata {
  address: string;
  city: string;
  country: string;
  email: string;
  name: string;
  phoneNumber: string;
  postalCode: string;
  state: string;
}

export interface RecordingsDatasetUserProfile {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
}

export interface RecordingsDatasetUser {
  id: string;
  profile: RecordingsDatasetUserProfile;
}

export interface RecordingsDatasetBuildingMetadata {
  propertyName: string;
  floor: string;
  foundationType: string;
  mitigationSystem: string;
  roomType: string;
  type: string;
  ventilation: string;
  yearOfConstruction: string;
}

export interface RecordingsDatasetContactMetadata {
  name: string;
  phoneNumber: string;
}

export interface RecordingsDatasetMeasurementMetadata {
  address: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
}

export interface RecordingsSampleStruct {
  recordedAt: string;
  radonShortTermAverage: number;
  radonUncertainty: number;
  temperature: number;
  humidity: number;
  pressure: number;
}

export interface RecordingsSamplesMetadata {
  pressureUnit: RecordingsPressureUnit;
  temperatureUnit: RecordingsTemperatureUnit;
  radonUnit: RecordingsRadonShortTimeAverageUnit;
  humidityUnit: RecordingsHumidityUnit;
}

export interface RecordingsDetailedDatasetStruct {
  id: string;
  deviceCalibratedAt: string;
  deviceCalibrationExpiresAt: string;
  name: string;
  comment: string;
  externalId: string;
  device: RecordingsDatasetDeviceStruct;
  creatorUser: RecordingsDatasetUser;
  commenterUser: RecordingsDatasetUser;
  recordingStartedAt: string;
  recordingEndedAt: string;
  durationInSeconds: number;
  reports: Array<{
    id: string;
    configuredAt: string;
    user: {
      id: string;
      profile: {
        id: string;
        name: string;
      };
    };
  }>;
  metadata: {
    building: RecordingsDatasetBuildingMetadata;
    customer: RecordingsDatasetCustomerMetadata;
    measurement: RecordingsDatasetMeasurementMetadata;
    owner: RecordingsDatasetOwnerMetadata;
  };
  hourlySamples: {
    metadata: RecordingsSamplesMetadata;
    entries: RecordingsSampleStruct[];
  };
  tamperingEvents: string[];
  weatherData: WeatherStruct;
  weatherReportUrl: string;
}

export interface RecordingsEditDatasetStruct {
  information: DatasetInformation;
  measurementMetadata: RecordingsDatasetMeasurementMetadata;
  buildingMetadata: RecordingsDatasetBuildingMetadata;
  ownerMetadata: RecordingsDatasetOwnerMetadata;
  contactMetadata: RecordingsDatasetContactMetadata;
  customerMetadata: RecordingsDatasetCustomerMetadata;
  userMetadata: RecordingsDatasetUserProfile;
  attachments: RecordingsDatasetAttachmentStruct[];
}

export interface DatasetInformation {
  id: string;
  name: string;
  comment: string | null;
  datasetType: DatasetType;
  temporaryConditions: string | null;
  protocolViolations: string | null;
}

export interface DatasetMetadata {
  building: RecordingsDatasetBuildingMetadata;
  customer: RecordingsDatasetCustomerMetadata;
  measurement: RecordingsDatasetMeasurementMetadata;
  owner: RecordingsDatasetOwnerMetadata;
}

export enum DatasetType {
  CROSSCHECK = 'CROSSCHECK',
  DUPLICATE = 'DUPLICATE',
  FOLLOW_UP = 'FOLLOW_UP',
  INITIAL = 'INITIAL',
  NONE = 'NONE',
  POST_MITIGATION = 'POST_MITIGATION',
  REAL_ESTATE_TRANSACTION = 'REAL_ESTATE_TRANSACTION'
}

export interface RecordingsAggregatedSamples {
  metadata: RecordingsSamplesMetadata;

  minimumRadon: number;
  averageRadon: number;
  averageRadonUncertainty: number;
  maximumRadon: number;
  minimumTemperature: number;
  averageTemperature: number;
  maximumTemperature: number;
  minimumHumidity: number;
  averageHumidity: number;
  maximumHumidity: number;
  minimumPressure: number;
  averagePressure: number;
  maximumPressure: number;
}

export interface RecordingsDatasetAttachmentStruct {
  id: string;
  name: string;
  type: RecordingsAttachmentType;
  url: string;
  insertedAt: string;
  creatorUser: RecordingsDatasetUser;
}

export interface DeviceWithLocationStruct {
  id: string;
  name: string;
  serialNumber: string;
  latitude: number;
  longitude: number;
  address: string;
}

export interface RecordingsCrosscheck {
  dataset: RecordingsCrosscheckDataset;
  pairedDataset: RecordingsCrosscheckPairedDataset;
}

export interface RecordingsCrosscheckDataset {
  recordingStartedAt: string;
  timeZone: string;
  aggregatedSamples: {
    averageRadon: number;
  };
}

export interface RecordingsCrosscheckPairedDataset {
  device: {
    serialNumber: string;
  };
  aggregatedSamples: {
    averageRadon: number;
  };
}

export enum DevicesSortField {
  NAME = 'NAME',
  SERIAL_NUMBER = 'SERIAL_NUMBER',
  CALIBRATION_ACTIVATED_AT = 'CALIBRATION_ACTIVATED_AT',
  CALIBRATION_EXPIRED_AT = 'CALIBRATION_EXPIRED_AT',
  CALIBRATION_STATUS = 'CALIBRATION_STATUS',
  DATASETS_SINCE_CROSSCHECK = 'DATASETS_SINCE_CROSSCHECK'
}
