export default {
  "font-bold": "700",
  "container": "_container_hbtqkb",
  "error": "_error_hbtqkb",
  "badge": "_badge_hbtqkb",
  "icon": "_icon_hbtqkb",
  "message": "_message_hbtqkb",
  "actions": "_actions_hbtqkb",
  "action": "_action_hbtqkb"
};
