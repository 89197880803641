// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import Quill, {RangeStatic} from 'quill';

interface Args {
  disabled?: boolean;
  error?: string;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
}

export default class FormRichTextEditor extends Component<Args> {
  editorRootElement: HTMLElement;
  quillInstance: Quill;

  @action
  initializeEditor(editorRootElement: HTMLElement) {
    const editor = this;

    this.editorRootElement = editorRootElement;

    const toolbarElement = editorRootElement.querySelector(
      '[data-quill="toolbar"]'
    );

    const editorElement = editorRootElement.querySelector(
      '[data-quill="editor"]'
    );

    if (!toolbarElement || !editorElement) return;

    if (this.args.value) {
      editorElement.innerHTML = this.args.value;
    }

    this.quillInstance = new Quill(editorElement, {
      placeholder: this.args.placeholder,
      readOnly: this.args.disabled,
      modules: {
        toolbar: {
          container: toolbarElement,
          handlers: {
            undo() {
              // The history is injected via a Quill plugin
              // @ts-ignore
              this.quill.history.undo();
            },
            redo() {
              // The history is injected via a Quill plugin
              // @ts-ignore
              this.quill.history.redo();
            },
            link() {
              // Improves default Quill link handling
              // @ts-ignore
              editor.handleLink(this.quill);
            }
          }
        }
      }
    });

    this.quillInstance.on('editor-change', this.handleChange.bind(this));
  }

  private handleChange() {
    const editorNode = this.editorRootElement.querySelector('.ql-editor');
    if (!editorNode) return;

    if (!editorNode.textContent || editorNode.textContent.trim() === '') {
      this.args.onChange('');
      return;
    }

    this.args.onChange(editorNode.innerHTML.trim());
  }

  private handleLink(quill: Quill) {
    const selection = quill.getSelection();

    if (!selection) return;

    const format = quill.getFormat(selection);
    const value = prompt(`Enter URL`, format.link); // eslint-disable-line no-alert

    if (!value) return;

    this.appendLink(quill, selection, value, format.link);
  }

  private selectLink(quill: Quill, selection: RangeStatic, link: string) {
    let {index, length} = selection;

    while (quill.getFormat(index - 1, 1).link === link) index--;
    while (quill.getFormat(index + length, 1).link === link) length++;

    quill.setSelection(index, length);
  }

  private appendLink(
    quill: Quill,
    selection: RangeStatic,
    value: string,
    currentLink: string
  ) {
    if (currentLink) {
      // selection is already a link (or part of a link), expand and reformat
      this.selectLink(quill, selection, currentLink);
      quill.format('link', value, 'user');
    } else if (selection.length > 0) {
      // selection is not a link, format using link
      quill.format('link', value, 'user');
    } else {
      // no selection, insert text with link format
      quill.insertText(selection.index, value, 'link', value, 'user');
      quill.setSelection(selection.index + value.length, 0);
    }
  }
}
