export default {
  "font-bold": "700",
  "font-normal": "400",
  "sidebar": "_sidebar_1725ch",
  "is-opened": "_is-opened_1725ch",
  "is-closed": "_is-closed_1725ch",
  "header": "_header_1725ch",
  "header-button": "_header-button_1725ch",
  "button": "_button_1725ch",
  "button-icon": "_button-icon_1725ch",
  "with-help": "_with-help_1725ch",
  "with-training": "_with-training_1725ch",
  "with-logout": "_with-logout_1725ch",
  "button-label": "_button-label_1725ch",
  "user": "_user_1725ch",
  "user-icon-wrapper": "_user-icon-wrapper_1725ch",
  "user-icon": "_user-icon_1725ch",
  "user-name": "_user-name_1725ch",
  "links": "_links_1725ch",
  "messages": "_messages_1725ch",
  "actions": "_actions_1725ch",
  "footer": "_footer_1725ch",
  "edit-link": "_edit-link_1725ch",
  "edit-icon": "_edit-icon_1725ch"
};
