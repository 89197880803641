// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';
import Form, {Validator} from 'airthings/services/form';
import {
  RecordingsRadonShortTimeAverageUnit,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';

export interface GraphTemplateSettingsActionLevelStruct {
  id?: string;
  label: string;
  threshold: number;
  unitType: RecordingsSampleUnitType;
  unit: RecordingsRadonShortTimeAverageUnit | null;
}

export interface TemplateSettingsStruct {
  title: string;
  selectedUnitTypes: RecordingsSampleUnitType[];
  actionLevels: GraphTemplateSettingsActionLevelStruct[];
}

export interface ReportsGraphBlockDefinition extends ReportsBlockDefinition {
  templateSettingsActionLevelStruct: (
    base: Partial<GraphTemplateSettingsActionLevelStruct>
  ) => GraphTemplateSettingsActionLevelStruct;
}

// Constants
const TEMPORARY_ID_PREFIX = 'tmp-';

export default class GraphBlock extends Service implements ReportsBlockService {
  @service('form')
  form: Form;

  @service('intl')
  intl: IntlService;

  private temporaryId = 0;

  get definition(): Readonly<ReportsBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'graph',
      get name() {
        return intl.t('reports.block-definitions.graph.name');
      },
      get description() {
        return intl.t('reports.block-definitions.graph.description');
      },
      get helper() {
        return intl.t('reports.block-definitions.graph.helper');
      },
      componentNamespace: 'graph',
      lightIconPath: '/assets/svgs/report-block-icons/graph-light.svg',
      darkIconPath: '/assets/svgs/report-block-icons/graph-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockGraph',
      needsLargeTemplateSettingsModal: true,
      templateSettingsStruct: this.templateSettingsStruct.bind(this),
      templateSettingsValidate: this.templateSettingsValidate.bind(this),
      templateSettingsSanitize: this.templateSettingsSanitize.bind(this),
      templateSettingsActionLevelStruct:
        this.templateSettingsActionLevelStruct.bind(this),
      titlePreview: this.titlePreview.bind(this)
    });
  }

  private titlePreview(templateSettings: TemplateSettingsStruct) {
    return templateSettings.title;
  }

  private templateSettingsSanitize(
    templateSettings: TemplateSettingsStruct
  ): TemplateSettingsStruct {
    return {
      ...templateSettings,
      actionLevels: templateSettings.actionLevels.map((actionLevel) => ({
        ...actionLevel,
        id:
          !actionLevel.id || actionLevel.id.startsWith(TEMPORARY_ID_PREFIX)
            ? undefined
            : actionLevel.id
      }))
    };
  }

  private templateSettingsStruct(
    base: Partial<TemplateSettingsStruct>
  ): TemplateSettingsStruct {
    return {
      title:
        base.title ||
        this.intl.t(
          'reports.block-definitions.graph.template-settings.title.default-value'
        ),
      selectedUnitTypes: base.selectedUnitTypes || [],
      actionLevels: base.actionLevels || []
    };
  }

  private templateSettingsActionLevelStruct(
    base: Partial<GraphTemplateSettingsActionLevelStruct>
  ): GraphTemplateSettingsActionLevelStruct {
    return {
      id: `${TEMPORARY_ID_PREFIX}${this.temporaryId++}`,
      threshold: 0,
      label: '',
      unitType: RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE,
      unit: null,
      ...base
    };
  }

  private templateSettingsValidate(
    templateSettings: TemplateSettingsStruct
  ): object | undefined {
    let validators: Array<Validator<string, string>> = [
      {
        field: 'title',
        isValid: Boolean(templateSettings.title),
        code: 'required'
      },
      {
        field: 'selected-unit-types',
        isValid: Boolean(templateSettings.selectedUnitTypes),
        code: 'required'
      },
      {
        field: 'selected-unit-types',
        isValid: templateSettings.selectedUnitTypes.length >= 1,
        code: 'min'
      },
      {
        field: 'selected-unit-types',
        isValid: templateSettings.selectedUnitTypes.length <= 2,
        code: 'max'
      }
    ];

    templateSettings.actionLevels.forEach((actionLevel, index) => {
      validators = validators.concat([
        {
          field: `action-levels.${index}.threshold`,
          isValid: Boolean(actionLevel.threshold),
          code: 'required'
        },
        {
          field: `action-levels.${index}.label`,
          isValid: Boolean(actionLevel.label),
          code: 'required'
        },
        {
          field: `action-levels.${index}.unit`,
          isValid: Boolean(actionLevel.unit),
          code: 'required'
        }
      ]);
    });

    return this.form.validate(validators, {
      translationKeyPrefix: 'reports.block-definitions.graph.template-settings'
    });
  }
}
