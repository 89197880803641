export default {
  "size-2": "4px",
  "size-3": "8px",
  "size-4": "16px",
  "page": "_page_g1fmls",
  "header": "_header_g1fmls",
  "header-logo-wrapper": "_header-logo-wrapper_g1fmls",
  "header-logo": "_header-logo_g1fmls",
  "beta-tag": "_beta-tag_g1fmls",
  "title": "_title_g1fmls",
  "monitor-name": "_monitor-name_g1fmls",
  "block": "_block_g1fmls",
  "block-header": "_block-header_g1fmls",
  "block-content": "_block-content_g1fmls",
  "block-rows": "_block-rows_g1fmls",
  "block-labels": "_block-labels_g1fmls",
  "block-values": "_block-values_g1fmls",
  "table-wrapper": "_table-wrapper_g1fmls"
};
