// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {RecordingsDatasetBuildingMetadata} from 'airthings/types/recordings';

interface Args {
  formData: RecordingsDatasetBuildingMetadata;
  onChange: (updatedFormData: RecordingsDatasetBuildingMetadata) => void;
  errors?: object;
}

export default class DatasetMeasurementForm extends Component<Args> {
  propertyName: string;
  floor: string;
  foundationType: string;
  mitigationSystem: string;
  roomType: string;
  type: string;
  ventilation: string;
  yearOfConstruction: string;

  @action
  changePropertyName(updatePropertyName: string) {
    this.args.onChange({
      ...this.args.formData,
      propertyName: updatePropertyName
    });
  }

  @action
  changeFloor(updateFloor: string) {
    this.args.onChange({
      ...this.args.formData,
      floor: updateFloor
    });
  }

  @action
  changeBuildingType(updateBuildingType: string) {
    this.args.onChange({
      ...this.args.formData,
      type: updateBuildingType
    });
  }

  @action
  changeFoundationType(updateFoundationType: string) {
    this.args.onChange({
      ...this.args.formData,
      foundationType: updateFoundationType
    });
  }

  @action
  changeMitigationSystem(updateMitigationSystem: string) {
    this.args.onChange({
      ...this.args.formData,
      mitigationSystem: updateMitigationSystem
    });
  }

  @action
  changeRoomType(updateRoomType: string) {
    this.args.onChange({
      ...this.args.formData,
      roomType: updateRoomType
    });
  }

  @action
  changeVentilation(updateVentilation: string) {
    this.args.onChange({
      ...this.args.formData,
      ventilation: updateVentilation
    });
  }

  @action
  changeYearOfConstruction(updateYearOfConstruction: string) {
    this.args.onChange({
      ...this.args.formData,
      yearOfConstruction: updateYearOfConstruction
    });
  }
}
