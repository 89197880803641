// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {RecordingsDatasetOwnerMetadata} from 'airthings/types/recordings';

interface Args {
  formData: RecordingsDatasetOwnerMetadata;
  onChange: (updatedFormData: RecordingsDatasetOwnerMetadata) => void;
  errors?: object;
}

export default class DatasetOwnerForm extends Component<Args> {
  @action
  changeAddress(updateAddress: string) {
    this.args.onChange({
      ...this.args.formData,
      address: updateAddress
    });
  }

  @action
  changeCity(updateCity: string) {
    this.args.onChange({
      ...this.args.formData,
      city: updateCity
    });
  }

  @action
  changeCountry(updateCountry: string) {
    this.args.onChange({
      ...this.args.formData,
      country: updateCountry
    });
  }

  @action
  changePostalCode(updatePostalCode: string) {
    this.args.onChange({
      ...this.args.formData,
      postalCode: updatePostalCode
    });
  }

  @action
  changeState(updateState: string) {
    this.args.onChange({
      ...this.args.formData,
      state: updateState
    });
  }

  @action
  changeName(updateName: string) {
    this.args.onChange({
      ...this.args.formData,
      name: updateName
    });
  }

  @action
  changeEmail(updateEmail: string) {
    this.args.onChange({
      ...this.args.formData,
      email: updateEmail
    });
  }

  @action
  changePhoneNumber(updatePhoneNumber: string) {
    this.args.onChange({
      ...this.args.formData,
      phoneNumber: updatePhoneNumber
    });
  }
}
