// Vendor
import Helper from '@ember/component/helper';
import {inject as service} from '@ember/service';

// Types
import IntlService, {FormatTimeOptions} from 'ember-intl/services/intl';

export default class extends Helper {
  @service('intl')
  intl: IntlService;

  // eslint-disable-next-line complexity
  compute(posArgs: [string | null], namedArgs?: FormatTimeOptions) {
    if (!posArgs.length || !posArgs[0]) return '';

    const [value] = posArgs;
    const formatOptions = namedArgs ?? {};

    // WARNING: since the timezone timezone may come from some external source,
    // and this code can run server side (thanks Fastboot), we have to be careful
    // on the following function call as it may break if the timezone is not supported
    try {
      return this.intl.formatTime(value, {
        ...formatOptions,
        timeZoneName:
          formatOptions.hour || formatOptions.minute ? 'short' : undefined
      });
    } catch {
      return '';
    }
  }
}
