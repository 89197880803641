export default {
  "font-bold": "700",
  "container": "_container_zof4ij",
  "success": "_success_zof4ij",
  "badge": "_badge_zof4ij",
  "icon": "_icon_zof4ij",
  "message": "_message_zof4ij",
  "actions": "_actions_zof4ij",
  "action": "_action_zof4ij",
  "download-button": "_download-button_zof4ij"
};
