// Vendor
import Component from '@glimmer/component';

// Types
import {CommunicationBoardMessageStruct} from 'airthings/types/communication-board';

interface Args {
  message: CommunicationBoardMessageStruct;
  onReadClick: () => void;
}

const MESSAGE_EXCERPT_LENGTH = 150;

export default class CommunicationBoardMessagesLatestMessage extends Component<Args> {
  messageExcerptLength = MESSAGE_EXCERPT_LENGTH;
}
