// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import move from 'ember-animated/motions/move';
import {fadeIn, fadeOut} from 'ember-animated/motions/opacity';
import fade from 'ember-animated/transitions/fade';

// Types
import {
  GraphTemplateSettingsActionLevelStruct,
  ReportsGraphBlockDefinition,
  TemplateSettingsStruct
} from 'airthings/services/airthings/reports/block-definitions/graph';
import {ReportsTemplateSettingsComponentArgs} from 'airthings/types/reports';
import {RecordingsSampleUnitType} from 'airthings/types/recordings';
import Reports from 'airthings/services/airthings/reports';
import {Sprite} from 'ember-animated';

// Constants
const MAXIMUM_SELECTED_UNIT_TYPES = 2;
const MAXIMUM_ACTION_LEVELS = 3;

interface TransitionParams {
  keptSprites: Sprite[];
  insertedSprites: Sprite[];
  removedSprites: Sprite[];
}

export default class ReportsBlocksGraphTemplateSettings extends Component<
  ReportsTemplateSettingsComponentArgs<TemplateSettingsStruct>
> {
  @service('airthings/reports')
  reports: Reports;

  fadeTransition = fade;

  radonShortTimeAverageUnitType =
    RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE;
  temperatureUnitType = RecordingsSampleUnitType.TEMPERATURE;
  pressureUnitType = RecordingsSampleUnitType.PRESSURE;
  humidityUnitType = RecordingsSampleUnitType.HUMIDITY;

  *transition({
    keptSprites,
    insertedSprites,
    removedSprites
  }: TransitionParams) {
    for (const sprite of keptSprites) {
      move(sprite);
    }

    for (const sprite of insertedSprites) {
      fadeIn(sprite);
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite);
    }
  }

  get blockDefinition() {
    return this.reports.getTemplateBlockDefinition(
      'graph'
    ) as ReportsGraphBlockDefinition;
  }

  get isRadonShortTimeAverageSelected() {
    return this.args.settingsStruct.selectedUnitTypes.includes(
      RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE
    );
  }

  get isTemperatureSelected() {
    return this.args.settingsStruct.selectedUnitTypes.includes(
      RecordingsSampleUnitType.TEMPERATURE
    );
  }

  get isPressureSelected() {
    return this.args.settingsStruct.selectedUnitTypes.includes(
      RecordingsSampleUnitType.PRESSURE
    );
  }

  get isHumiditySelected() {
    return this.args.settingsStruct.selectedUnitTypes.includes(
      RecordingsSampleUnitType.HUMIDITY
    );
  }

  get canAddActionLevel() {
    return this.args.settingsStruct.actionLevels.length < MAXIMUM_ACTION_LEVELS;
  }

  get actionLevels() {
    return this.args.settingsStruct.actionLevels;
  }

  @action
  addActionLevel() {
    const newActionLevel =
      this.blockDefinition.templateSettingsActionLevelStruct({});

    const actionLevels = [
      ...this.args.settingsStruct.actionLevels,
      newActionLevel
    ];

    this.args.onChange({...this.args.settingsStruct, actionLevels});
    this.args.dismissErrors();
  }

  @action
  removeActionLevel(index: number) {
    const actionLevels = [
      ...this.args.settingsStruct.actionLevels.slice(0, index),
      ...this.args.settingsStruct.actionLevels.slice(index + 1)
    ];

    this.args.onChange({...this.args.settingsStruct, actionLevels});
    this.args.dismissErrors();
  }

  @action
  changeActionLevel(
    index: number,
    updatedActionLevel: GraphTemplateSettingsActionLevelStruct
  ) {
    const actionLevels = this.args.settingsStruct.actionLevels;
    actionLevels[index] = updatedActionLevel;

    this.args.onChange({
      ...this.args.settingsStruct,
      actionLevels: [...actionLevels]
    });
  }

  @action
  changeTitle(title: string) {
    this.args.onChange({...this.args.settingsStruct, title});
  }

  @action
  toggleUnitType(isSelected: boolean, unitType: RecordingsSampleUnitType) {
    const selectedUnitTypes = this.args.settingsStruct.selectedUnitTypes;

    if (isSelected) {
      this.args.onChange({
        ...this.args.settingsStruct,
        selectedUnitTypes: [
          ...(selectedUnitTypes.length === MAXIMUM_SELECTED_UNIT_TYPES
            ? selectedUnitTypes.slice(1)
            : selectedUnitTypes),
          unitType
        ]
      });
    } else {
      const index = selectedUnitTypes.indexOf(unitType);

      this.args.onChange({
        ...this.args.settingsStruct,
        selectedUnitTypes: [
          ...selectedUnitTypes.slice(0, index),
          ...selectedUnitTypes.slice(index + 1)
        ]
      });
    }

    // When radon average is deselected, reset levels to prevent form validation errors
    if (
      !this.args.settingsStruct.selectedUnitTypes.includes(
        RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE
      )
    ) {
      this.args.onChange({...this.args.settingsStruct, actionLevels: []});
    }
  }
}
