// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Utils
import parsePageParam from 'airthings/utils/parse-page-param';
import {hideIntercom} from 'airthings/utils/intercom';

// Types
import Can from 'ember-can/services/can';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Account from 'airthings/services/airthings/account';
import CommunicationBoard from 'airthings/services/airthings/communication-board';
import Apollo from 'airthings/services/apollo';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

interface RouteParams {
  page?: string;
  messageId?: string;
}

export default class AppDatasetsIndexRoute extends Route {
  @service('airthings/account')
  account: Account;

  @service('can')
  can: Can;

  @service('router')
  router: RouterService;

  @service('airthings/communication-board')
  communicationBoard: CommunicationBoard;

  @queryManager
  queryManager: Apollo;

  queryParams = {
    page: {
      refreshModel: true
    },
    messageId: {
      refreshModel: true
    }
  };

  beforeModel(transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    if (this.can.cannot('list messages', null, {currentUser})) {
      transition.abort();

      this.router.replaceWith('app');
    }

    hideIntercom();
  }

  async model(params: RouteParams) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    const messages = await this.communicationBoard.watchPaginatedMessages(
      this.queryManager,
      parsePageParam(params)
    );

    let currentMessage = null;

    if (params.messageId) {
      currentMessage = await this.communicationBoard.watchMessage(
        this.queryManager,
        params.messageId
      );
    }

    return {
      messages,
      currentMessage,
      currentUser
    };
  }
}
