// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {AccountCompanyInformation, Address} from 'airthings/types/account';

interface Args {
  formData: AccountCompanyInformation;
  onChange: (updatedFormData: AccountCompanyInformation) => void;
  errors?: object;
}

export default class AccountCompanyInformationForm extends Component<Args> {
  @action
  changeName(updatedName: string): void {
    this.args.onChange({
      ...this.args.formData,
      name: updatedName
    });
  }

  @action
  changeEmail(updatedEmail: string): void {
    this.args.onChange({
      ...this.args.formData,
      email: updatedEmail
    });
  }

  @action
  changePhoneNumber(updatedPhoneNumber: string): void {
    this.args.onChange({
      ...this.args.formData,
      phoneNumber: updatedPhoneNumber
    });
  }

  @action
  changeAddress(updatedAddress: Address): void {
    this.args.onChange({
      ...this.args.formData,
      address: updatedAddress
    });
  }
}
