// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Services
import Account from 'airthings/services/airthings/account';
import FastBoot from 'ember-cli-fastboot/services/fastboot';

// Types
import {OnboardingSectionSlug} from 'airthings/types/account';

export default class AppDatasetsShowController extends Controller {
  @service('airthings/account')
  account: Account;

  @service('fastboot')
  fastboot: FastBoot;

  onboardingSectionSlugs = OnboardingSectionSlug;
}
