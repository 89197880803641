// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {perform} from 'ember-concurrency-ts';
import config from 'airthings/config/environment';
import {TaskGenerator, timeout} from 'ember-concurrency';

// Types
import AdminRecordings from 'airthings/services/airthings/admin/recordings';
import Recordings from 'airthings/services/airthings/recordings';
import {JobState} from 'airthings/graphql/queries/recordings-calibration-certificate-generation-job';
import {JobCalibrationCertificate} from 'airthings/graphql/queries/admin-recordings-calibration-certificate-generation-job';

interface Args {
  calibrationCertificateUUID: string;
  onCancel: () => {};
}

interface CalibrationCertificate {
  id: string;
  pdfFileUrl?: string;
}

enum ComponentState {
  LOADING,
  ERRORED,
  COMPLETED
}

export default class AdminCalibrationCertificateGeneration extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @service('airthings/admin/recordings')
  adminRecordings: AdminRecordings;

  @tracked
  state: ComponentState = ComponentState.LOADING;

  @tracked
  calibrationCertificate: CalibrationCertificate;

  get isLoading() {
    return this.state === ComponentState.LOADING;
  }

  get isErrored() {
    return this.state === ComponentState.ERRORED;
  }

  get isCompleted() {
    return this.state === ComponentState.COMPLETED;
  }

  @dropTask
  *generateCalibrationCertificateTask(): TaskGenerator<JobCalibrationCertificate> {
    if (!this.args.calibrationCertificateUUID)
      throw new Error('Missing calibration certificate UUID');

    const {
      successful,
      result
    }: {
      successful: boolean;
      result: {
        id: string;
      };
    } = yield this.recordings.generateCalibrationCertificate(
      this.args.calibrationCertificateUUID
    );

    if (successful && result?.id) {
      return yield perform(
        this.pollForCalibrationCertificateGenerationJobTask,
        result.id
      );
    }

    throw new Error('Unable to generate calibration certificate');
  }

  @restartableTask
  *pollForCalibrationCertificateGenerationJobTask(
    jobId: string
  ): TaskGenerator<JobCalibrationCertificate> {
    while (true) {
      const {
        state,
        calibrationCertificate
      }: {
        state: JobState;
        calibrationCertificate: JobCalibrationCertificate;
      } = yield this.adminRecordings.fetchCalibrationCertificateGenerationJob(
        jobId
      );

      if (state === JobState.COMPLETED) return calibrationCertificate;

      if (state === JobState.ERRORED) {
        throw new Error('Calibration certificate generation errored');
      }

      yield timeout(
        config.APP.calibrationCertificateGeneration.jobPollingDelay
      );
    }
  }

  @action
  async generateCalibrationCertificate() {
    this.state = ComponentState.LOADING;

    try {
      const calibrationCertificate = await perform(
        this.generateCalibrationCertificateTask
      );

      this.state = ComponentState.COMPLETED;
      this.calibrationCertificate = calibrationCertificate;
    } catch (error) {
      this.state = ComponentState.ERRORED;
    }
  }
}
