// Vendor
import Component from '@glimmer/component';

interface Args {
  label: string;
  displayValue: string;
  showEmpty?: boolean;
}

export default class InformationRow extends Component<Args> {
  get shouldDisplay(): boolean {
    if (this.args.displayValue) return true;

    return this.args.showEmpty || false;
  }
}
