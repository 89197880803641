export default {
  "font-medium": "500",
  "sidebar-width-closed": "90px",
  "sidebar-width-opened": "320px",
  "sidebar": "_sidebar_1tw6xn",
  "is-opened": "_is-opened_1tw6xn",
  "fading-label": "_fading-label_1tw6xn",
  "is-closed": "_is-closed_1tw6xn",
  "button": "_button_1tw6xn",
  "burger-button-container": "_burger-button-container_1tw6xn",
  "navigation": "_navigation_1tw6xn",
  "with-affiliate-program": "_with-affiliate-program_1tw6xn",
  "button-icon": "_button-icon_1tw6xn",
  "with-datasets": "_with-datasets_1tw6xn",
  "with-templates": "_with-templates_1tw6xn",
  "with-devices": "_with-devices_1tw6xn",
  "with-company": "_with-company_1tw6xn",
  "with-dataset-settings": "_with-dataset-settings_1tw6xn",
  "with-user-management": "_with-user-management_1tw6xn",
  "with-burger": "_with-burger_1tw6xn",
  "button-notification-icon": "_button-notification-icon_1tw6xn",
  "with-help": "_with-help_1tw6xn",
  "with-training": "_with-training_1tw6xn",
  "with-logout": "_with-logout_1tw6xn",
  "logo": "_logo_1tw6xn",
  "bottom-sidebar-container": "_bottom-sidebar-container_1tw6xn",
  "app-version": "_app-version_1tw6xn"
};
