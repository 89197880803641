// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import fade from 'ember-animated/transitions/fade';

// Types
import {DynamicParagraphTemplateSettingsOptionStruct} from 'airthings/services/airthings/reports/block-definitions/dynamic-paragraph';

interface Errors {
  [field: string]: string;
}

interface Args {
  isExpanded: boolean;
  option: DynamicParagraphTemplateSettingsOptionStruct;
  index: number;
  errors?: Errors;
  onToggle: () => void;
  onDelete: () => void;
  onChange: (option: DynamicParagraphTemplateSettingsOptionStruct) => void;
}

export default class ReportsBlocksDynamicParagraphTemplateSettingsOptionStruct extends Component<Args> {
  fadeTransition = fade;

  get optionNumber() {
    return this.args.index + 1;
  }

  get titleError() {
    if (!this.args.errors) return;
    return this.args.errors[`options.${this.args.index}.title`];
  }

  get bodyError() {
    if (!this.args.errors) return;
    return this.args.errors[`options.${this.args.index}.body`];
  }

  get hasError() {
    return this.titleError || this.bodyError;
  }

  @action
  changeTitle(title: string) {
    this.args.onChange({...this.args.option, title});
  }

  @action
  changeBody(body: string) {
    this.args.onChange({...this.args.option, body});
  }

  @action
  handleDelete(event: Event) {
    event.stopPropagation();
    this.args.onDelete();
  }
}
