// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import AdminAffiliateProgram from 'airthings/services/airthings/admin/affiliate-program';
import {AdminAffiliateProgramAdStruct} from 'airthings/types/admin/affiliate-program';

interface Args {
  ad: AdminAffiliateProgramAdStruct;
}

export default class PageAdminAffiliateProgramAdsEdit extends Component<Args> {
  @service('airthings/admin/affiliate-program')
  adminAffiliateProgram: AdminAffiliateProgram;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @tracked
  ad: AdminAffiliateProgramAdStruct = this.args.ad;

  @dropTask
  *adUpdateTask(): TaskGenerator<void> {
    return yield this.adminAffiliateProgram.updateAd(this.ad);
  }

  @action
  handleChange(updatedAd: AdminAffiliateProgramAdStruct) {
    this.ad = updatedAd;
  }

  @action
  validate(): object | undefined {
    return this.adminAffiliateProgram.validateAd(this.ad);
  }

  @action
  handleSuccess() {
    this.flashMessages.success(
      this.intl.t('admin-affiliate-program-ads-edit.form.success')
    );
  }
}
