// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {inject as service} from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FlashMessages from 'ember-cli-flash/services/flash-messages';

// Types
import {UserRole} from 'airthings/types/account';
import {AdminAccountListCompanyStruct} from 'airthings/types/admin/account';
import {GraphqlPagination} from 'airthings/types/graphql';
import AdminAccount from 'airthings/services/airthings/admin/account';
import RouterService from '@ember/routing/router-service';

interface Args {
  companies: AdminAccountListCompanyStruct[];
  pagination: GraphqlPagination;
  searchTerm?: string;
}

export default class PageAdminCompaniesIndex extends Component<Args> {
  @service('airthings/admin/account')
  adminAccount: AdminAccount;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @dropTask
  *logAsTask(company: AdminAccountListCompanyStruct, role: UserRole) {
    const {successful} = yield this.adminAccount.logAs(company.id, role);

    if (successful) {
      window.location.reload();
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *deleteTask(company: AdminAccountListCompanyStruct) {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(this.intl.t('admin-companies-index.delete.confirm'))
    )
      return;

    const {successful} = yield this.adminAccount.deleteCompany(company.id);

    if (successful) {
      window.location.reload();
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  search(term: string) {
    this.router.replaceWith('admin.companies', {queryParams: {q: term}});
  }

  @action
  clearSearch() {
    this.router.replaceWith('admin.companies', {
      queryParams: {q: null}
    });
  }
}
