export default {
  "font-bold": "700",
  "container": "_container_14vlql",
  "error": "_error_14vlql",
  "badge": "_badge_14vlql",
  "icon": "_icon_14vlql",
  "message": "_message_14vlql",
  "actions": "_actions_14vlql",
  "action": "_action_14vlql"
};
