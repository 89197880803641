export default {
  "font-bold": "700",
  "font-medium": "500",
  "blocks-sidebar-width": "325px",
  "blocks-sidebar": "_blocks-sidebar_1wjq7f",
  "blocks-sidebar-title": "_blocks-sidebar-title_1wjq7f",
  "block-list-title": "_block-list-title_1wjq7f",
  "main-container": "_main-container_1wjq7f",
  "delete-block-confirmation": "_delete-block-confirmation_1wjq7f",
  "delete-block-confirmation-content": "_delete-block-confirmation-content_1wjq7f",
  "delete-block-title": "_delete-block-title_1wjq7f",
  "delete-block-confirmation-actions": "_delete-block-confirmation-actions_1wjq7f",
  "delete-block-action-button": "_delete-block-action-button_1wjq7f",
  "language-info": "_language-info_1wjq7f",
  "language-name": "_language-name_1wjq7f",
  "language-tip": "_language-tip_1wjq7f",
  "infos": "_infos_1wjq7f",
  "description": "_description_1wjq7f",
  "description-header": "_description-header_1wjq7f",
  "description-body": "_description-body_1wjq7f",
  "block-list-wrapper": "_block-list-wrapper_1wjq7f",
  "delete-confirmation": "_delete-confirmation_1wjq7f",
  "delete-explanation": "_delete-explanation_1wjq7f",
  "delete-report-template": "_delete-report-template_1wjq7f",
  "delete-actions": "_delete-actions_1wjq7f",
  "delete-action": "_delete-action_1wjq7f",
  "actions": "_actions_1wjq7f",
  "action-button": "_action-button_1wjq7f"
};
