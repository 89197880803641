// Vendor
import Component from '@glimmer/component';
import {computed} from '@ember/object';
import {gt} from '@ember/object/computed';

// Types
import {ComparisonDevice} from 'airthings/types/admin/recordings';

interface Args {
  calibrationCertificate: {
    comparisonDevices: ComparisonDevice[];
  };
}

const MAXIMUM_NUMBER_OF_DEVICES = 5;

export default class FactoryCalibrationCertificate extends Component<Args> {
  @gt('args.calibrationCertificate.comparisonDevices.length', 0)
  hasComparisonDevices: boolean;

  @computed('args.calibrationCertificate.comparisonDevices[]', function () {
    return this.args.calibrationCertificate.comparisonDevices.slice(
      0,
      MAXIMUM_NUMBER_OF_DEVICES
    );
  })
  comparisonDevices: ComparisonDevice[];
}
