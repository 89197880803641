// Vendor
import {gql} from '@apollo/client/core';

export enum JobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED'
}

export interface JobCalibrationCertificate {
  id: string;
  pdfFileUrl?: string;
}

export interface AdminRecordingsCalibrationCertificateGenerationJobVariables {
  jobId: string;
}

export interface AdminRecordingsCalibrationCertificateGenerationJobResponse {
  calibrationCertificateGenerationJob: {
    id: string;
    state: JobState;
    calibrationCertificate: JobCalibrationCertificate;
  };
}

export default gql`
  query CalibrationCertificateGenerationJob($jobId: ID!) {
    calibrationCertificateGenerationJob(jobId: $jobId) {
      id
      state

      calibrationCertificate {
        id
        pdfFileUrl
      }
    }
  }
`;
