// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {timeout} from 'ember-concurrency';
import config from 'airthings/config/environment';
import {TaskGenerator} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

// Types
import Recordings from 'airthings/services/airthings/recordings';
import {JobState} from 'airthings/graphql/queries/reports-report-generation-job';
import {RecordingsListDatasetStruct} from 'airthings/types/recordings';
import {AccountUserStruct} from 'airthings/types/account';

interface Args {
  dataset: RecordingsListDatasetStruct;
  currentUser: AccountUserStruct;
  onCancel: () => {};
}

interface Dataset {
  id: string;
  csvUrl?: string;
}

enum ComponentState {
  LOADING,
  ERRORED,
  COMPLETED
}

export default class DatasetsCSVGenerationConfiguration extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  state: ComponentState = ComponentState.LOADING;

  @tracked
  dataset: Dataset;

  get isLoading() {
    return this.state === ComponentState.LOADING;
  }

  get isErrored() {
    return this.state === ComponentState.ERRORED;
  }

  get isCompleted() {
    return this.state === ComponentState.COMPLETED;
  }

  @dropTask
  *generateCSVTask(datasetId: string): TaskGenerator<Dataset> {
    const {successful, result} = yield this.recordings.generateDatasetCSV(
      datasetId
    );

    if (successful) {
      return yield perform(this.pollForCSVGenerationJobTask, result?.id);
    }

    throw new Error('Unable to generate CSV');
  }

  @restartableTask
  *pollForCSVGenerationJobTask(jobId: string): TaskGenerator<Dataset> {
    while (true) {
      const {state, dataset} =
        yield this.recordings.fetchDatasetCSVGenerationJob(jobId);

      if (state === JobState.COMPLETED) return dataset;

      if (state === JobState.ERRORED) {
        throw new Error('CSV generation errored');
      }

      yield timeout(config.APP.csvGeneration.jobPollingDelay);
    }
  }

  @action
  async generateCSV() {
    this.state = ComponentState.LOADING;

    try {
      const dataset: Dataset = await perform(
        this.generateCSVTask,
        this.args.dataset.id
      );

      this.state = ComponentState.COMPLETED;
      this.dataset = dataset;
    } catch (error) {
      this.state = ComponentState.ERRORED;
    }
  }
}
