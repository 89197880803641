export default {
  "font-medium": "500",
  "container": "_container_2jq97y",
  "content-panel": "_content-panel_2jq97y",
  "logo": "_logo_2jq97y",
  "headline": "_headline_2jq97y",
  "login-button": "_login-button_2jq97y",
  "sign-up-button": "_sign-up-button_2jq97y",
  "separator": "_separator_2jq97y",
  "warning": "_warning_2jq97y",
  "warning-icon-wrapper": "_warning-icon-wrapper_2jq97y",
  "warning-icon": "_warning-icon_2jq97y",
  "warning-message": "_warning-message_2jq97y",
  "signup-warning": "_signup-warning_2jq97y",
  "signup-warning-icon": "_signup-warning-icon_2jq97y",
  "signup-warning-icon-title": "_signup-warning-icon-title_2jq97y",
  "signup-warning-icon-explanation": "_signup-warning-icon-explanation_2jq97y",
  "signup-warning-actions": "_signup-warning-actions_2jq97y",
  "signup-warning-action": "_signup-warning-action_2jq97y"
};
