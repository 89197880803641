// Vendor
import Service, {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import CommunicationBoardMessage from 'airthings/services/airthings/communication-board/message';
import {GraphqlPaginationInput} from 'airthings/types/graphql';
import Apollo from 'airthings/services/apollo';
import {CommunicationBoardPaginatedMessagesResponse} from 'airthings/graphql/queries/communication-board-paginated-messages';
import {CommunicationBoardMessageByIdResponse} from 'airthings/graphql/queries/communication-board-message-by-id';

export default class CommunicationBoard extends Service {
  @service('airthings/communication-board/message')
  message: CommunicationBoardMessage;

  @action
  presentCommunicationBoardPaginatedMessages(
    viewer: CommunicationBoardPaginatedMessagesResponse['viewer']
  ) {
    return this.message.presentCommunicationBoardPaginatedMessages(viewer);
  }

  @action
  presentCommunicationBoardMessageById(
    viewer?: CommunicationBoardMessageByIdResponse['viewer']
  ) {
    if (!viewer) return null;

    return this.message.presentCommunicationBoardMessageById(viewer);
  }

  /**
   * @description Mark a message as read.
   */
  async markMessageAsRead(messageId: string) {
    return this.message.markMessageAsRead(messageId);
  }

  /**
   * @description Mark a message as unread.
   */
  async markMessageAsUnread(messageId: string) {
    return this.message.markMessageAsUnread(messageId);
  }

  /**
   * @description Mark a message as deleted.
   */
  async markMessageAsDeleted(messageId: string) {
    return this.message.markMessageAsDeleted(messageId);
  }

  /**
   * @description Returns a promise that resolves with a list of messages.
   */
  async fetchPaginatedMessages(pagination: GraphqlPaginationInput) {
    return this.message.fetchPaginatedMessages(pagination);
  }

  /**
   * @description Returns a watchQuery containing the paginated messages.
   */
  async watchPaginatedMessages(queryManager: Apollo, page?: number) {
    return this.message.watchPaginatedMessages(queryManager, page);
  }

  /**
   * @description Returns a watchQuery containing the message.
   */
  async watchMessage(queryManager: Apollo, messageId: string) {
    return this.message.watchMessage(queryManager, messageId);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/communication-board': CommunicationBoard;
  }
}
