// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

export default class Toolbar extends Component {
  @tracked
  hasFocus = true;

  @action
  onFocusIn() {
    this.hasFocus = true;
  }

  @action
  onFocusOut() {
    this.hasFocus = false;
  }

  @action
  // eslint-disable-next-line complexity
  onKeyUp(event: KeyboardEvent) {
    if (!this.hasFocus) return;
    if (!document.activeElement) return;

    let element: Element | null = null;
    const previouslyFocusedElement = document.activeElement;

    switch (event.key) {
      case 'ArrowLeft':
        element = previouslyFocusedElement.previousElementSibling;
        break;
      case 'ArrowRight':
        element = previouslyFocusedElement.nextElementSibling;
        break;
    }

    if (!element) return;

    element.setAttribute('tabindex', '0');
    (element as HTMLElement).focus();
    previouslyFocusedElement.setAttribute('tabindex', '-1');
  }
}
