export default {
  "font-bold": "700",
  "block": "_block_wuuyqf _light-box-wrapper_1ghgfm",
  "content": "_content_wuuyqf _light-box-content_1ghgfm",
  "cell": "_cell_wuuyqf _cell_1ghgfm",
  "key": "_key_wuuyqf _key_1ghgfm",
  "value": "_value_wuuyqf _value_1ghgfm",
  "header": "_header_wuuyqf _dark-box-header_1ghgfm",
  "icon-wrapper": "_icon-wrapper_wuuyqf _header-icon-wrapper_1ghgfm",
  "icon": "_icon_wuuyqf _header-icon_1ghgfm",
  "title": "_title_wuuyqf _dark-box-header-title_1ghgfm"
};
