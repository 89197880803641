// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {RecordingsDatasetMeasurementMetadata} from 'airthings/types/recordings';

interface Args {
  formData: RecordingsDatasetMeasurementMetadata;
  onChange: (updatedFormData: RecordingsDatasetMeasurementMetadata) => void;
  errors?: object;
}

export default class DatasetMeasurementForm extends Component<Args> {
  @action
  changeAddress(updateAddress: string) {
    this.args.onChange({
      ...this.args.formData,
      address: updateAddress
    });
  }

  @action
  changeCity(updateCity: string) {
    this.args.onChange({
      ...this.args.formData,
      city: updateCity
    });
  }

  @action
  changeCountry(updateCountry: string) {
    this.args.onChange({
      ...this.args.formData,
      country: updateCountry
    });
  }

  @action
  changePostalCode(updatePostalCode: string) {
    this.args.onChange({
      ...this.args.formData,
      postalCode: updatePostalCode
    });
  }

  @action
  changeState(updateState: string) {
    this.args.onChange({
      ...this.args.formData,
      state: updateState
    });
  }
}
