export default {
  "size-2": "4px",
  "size-3": "8px",
  "size-4": "16px",
  "size-5": "32px",
  "size-6": "64px",
  "header": "_header_1hockw",
  "left-pane": "_left-pane_1hockw",
  "monitor-info": "_monitor-info_1hockw",
  "beta-tag": "_beta-tag_1hockw",
  "monitor-name": "_monitor-name_1hockw",
  "monitor-serial-number": "_monitor-serial-number_1hockw",
  "crosscheck-modal": "_crosscheck-modal_1hockw",
  "modal-icon": "_modal-icon_1hockw"
};
