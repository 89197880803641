export default {
  "font-bold": "700",
  "page-wrapper": "_page-wrapper_1hwgr3",
  "page": "_page_1hwgr3",
  "header": "_header_1hwgr3",
  "header-logo": "_header-logo_1hwgr3",
  "header-title": "_header-title_1hwgr3",
  "header-subtitle": "_header-subtitle_1hwgr3",
  "licenses": "_licenses_1hwgr3",
  "nsrb": "_nsrb_1hwgr3",
  "nrpp": "_nrpp_1hwgr3",
  "disclaimer": "_disclaimer_1hwgr3",
  "authority": "_authority_1hwgr3",
  "authority-role": "_authority-role_1hwgr3",
  "signature": "_signature_1hwgr3",
  "signature-title": "_signature-title_1hwgr3",
  "signature-line": "_signature-line_1hwgr3",
  "signature-line-signature": "_signature-line-signature_1hwgr3",
  "date": "_date_1hwgr3",
  "date-title": "_date-title_1hwgr3",
  "date-line": "_date-line_1hwgr3",
  "date-line-date": "_date-line-date_1hwgr3",
  "footer": "_footer_1hwgr3",
  "brand": "_brand_1hwgr3",
  "brand-name": "_brand-name_1hwgr3",
  "brand-address": "_brand-address_1hwgr3"
};
