// Vendor
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import Route from '@ember/routing/route';

// Types
import Apollo from 'airthings/services/apollo';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import ServiceWorker from 'airthings/services/service-worker';
import Shoebox from 'airthings/services/shoebox';
import Translations, {AvailableLocale} from 'airthings/services/translations';

// Config
import config from 'airthings/config/environment';

export default class ApplicationRoute extends Route {
  @service('apollo')
  apollo: Apollo;

  @service('intl')
  intl: IntlService;

  @service('service-worker')
  serviceWorker: ServiceWorker;

  @service('router')
  router: RouterService;

  @service('shoebox')
  shoebox: Shoebox;

  @service('translations')
  translations: Translations;

  async beforeModel() {
    const locale = this.determineLocale();

    this.intl.setLocale([locale]);

    if (config.intl.ASYNC_TRANSLATIONS) {
      await this.translations.loadForLocale(locale);
    }

    // By default whenever an update is ready, we install it immediately.
    // Note that the `update` call could be made later. We could prompt the
    // user to update, then install the update only when they accept.
    this.serviceWorker.register().onUpdateReady(() => {
      this.serviceWorker.update();
    });
  }

  @action
  didTransition() {
    this.shoebox.write(config.apollo.SSR_CACHE_KEY, this.apollo.extractCache());
  }

  private determineLocale(): AvailableLocale {
    return 'en-ca';
  }
}
