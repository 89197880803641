export default {
  "font-medium": "500",
  "font-demi-bold": "600",
  "font-bold": "700",
  "white-box": "_white-box_vbfmil",
  "white-box-title": "_white-box-title_vbfmil",
  "gray-box": "_gray-box_vbfmil",
  "gray-box-title": "_gray-box-title_vbfmil",
  "dark-box-header": "_dark-box-header_vbfmil",
  "dark-box-header-title": "_dark-box-header-title_vbfmil",
  "light-box-wrapper": "_light-box-wrapper_vbfmil",
  "light-box-header": "_light-box-header_vbfmil",
  "light-box-header-title": "_light-box-header-title_vbfmil",
  "header-icon": "_header-icon_vbfmil",
  "light-box-content": "_light-box-content_vbfmil",
  "separator-over": "_separator-over_vbfmil",
  "separator-under": "_separator-under_vbfmil",
  "two-column-table": "_two-column-table_vbfmil",
  "certification-content": "_certification-content_vbfmil",
  "certification-item": "_certification-item_vbfmil",
  "certification-column": "_certification-column_vbfmil",
  "certification-item-label": "_certification-item-label_vbfmil",
  "certification-item-value": "_certification-item-value_vbfmil",
  "pill-label": "_pill-label_vbfmil"
};
