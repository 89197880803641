// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import FastBoot from 'ember-cli-fastboot/services/fastboot';

export default class WeatherReportController extends Controller {
  @service('fastboot')
  fastboot: FastBoot;
}
