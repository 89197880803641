export default {
  "content-field": "_content-field_i4gsd",
  "row": "_row_i4gsd",
  "column": "_column_i4gsd",
  "expiration-date": "_expiration-date_i4gsd",
  "label": "_label_i4gsd",
  "calibration-icon": "_calibration-icon_i4gsd",
  "calibration-due-icon": "_calibration-due-icon_i4gsd",
  "calibration-expired-icon": "_calibration-expired-icon_i4gsd"
};
