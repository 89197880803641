// Vendor
import Component from '@glimmer/component';
import parseISO from 'date-fns/parseISO';
import addHours from 'date-fns/addHours';

// Types
import {ComparisonDevice} from 'airthings/types/admin/recordings';

interface Args {
  calibrationCertificate: {
    activatedAt: string;
    comparisonDevices: ComparisonDevice[];
  };
}

const EXPOSURE_PERIOD_HOURS = 168;

export default class FactoryCalibrationCertificate extends Component<Args> {
  get calibrationStartedAt() {
    if (!this.args.calibrationCertificate.activatedAt) return null;

    const date = parseISO(this.args.calibrationCertificate.activatedAt);

    return addHours(date, -EXPOSURE_PERIOD_HOURS);
  }
}
