// Vendor
import Service, {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import {AdminAccountPaginatedCompaniesResponse} from 'airthings/graphql/queries/admin-account-paginated-companies';
import {AdminAccountPaginatedDevicesResponse} from 'airthings/graphql/queries/admin-account-paginated-devices';
import {AdminAccountPaginatedUsersResponse} from 'airthings/graphql/queries/admin-account-paginated-users';
import {AdminAccountCurrentUserResponse} from 'airthings/graphql/queries/admin-account-current-user';
import AdminAccountCompany from 'airthings/services/airthings/admin/account/company';
import AdminAccountUser from 'airthings/services/airthings/admin/account/user';
import Apollo from 'airthings/services/apollo';
import {UserRole} from 'airthings/types/account';

export default class AdminAccount extends Service {
  @service('airthings/admin/account/company')
  adminCompany: AdminAccountCompany;

  @service('airthings/admin/account/user')
  adminUser: AdminAccountUser;

  /**
   * @description Convert a currentUser watchQuery result to AdminUser struct
   */
  @action
  presentCurrentUser(response: AdminAccountCurrentUserResponse) {
    return this.adminUser.presentCurrentUser(response);
  }

  @action
  presentAdminAccountPaginatedCompanies(
    admin: AdminAccountPaginatedCompaniesResponse['admin']
  ) {
    return this.adminCompany.presentAccountPaginatedCompanies(admin);
  }

  @action
  presentAdminAccountPaginatedUsers(
    admin: AdminAccountPaginatedUsersResponse['admin']
  ) {
    return this.adminCompany.presentAccountPaginatedUsers(admin);
  }

  @action
  presentAdminAccountPaginatedDevices(
    admin: AdminAccountPaginatedDevicesResponse['admin']
  ) {
    return this.adminCompany.presentAccountPaginatedDevices(admin);
  }

  /**
   * @description Returns a watchQuery containing paginated admin companies
   */
  async watchPaginatedCompanies(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.adminCompany.watchPaginatedCompanies(
      queryManager,
      page,
      searchTerm
    );
  }

  /**
   * @description Returns a watchQuery containing paginated admin users
   */
  async watchPaginatedUsers(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.adminCompany.watchPaginatedUsers(
      queryManager,
      page,
      searchTerm
    );
  }

  /**
   * @description Returns a watchQuery containing paginated admin devices
   */
  async watchPaginatedDevices(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.adminCompany.watchPaginatedDevices(
      queryManager,
      page,
      searchTerm
    );
  }

  /**
   * @description Returns a watchQuery containing the current admin user
   */
  async watchCurrentUser(queryManager: Apollo) {
    return this.adminUser.watchCurrentUser(queryManager);
  }

  /**
   * @description Log user into a company
   */
  async logAs(companyId: string, role: UserRole) {
    return this.adminUser.logAs(companyId, role);
  }

  /**
   * @description Logout/remove user from its current company
   */
  async logoutFrom(companyId: string) {
    return this.adminUser.logoutFrom(companyId);
  }

  /**
   * @description Move a user to another company
   */
  async moveUser(userId: string, companyId: string, role: UserRole) {
    return this.adminUser.moveUser(userId, companyId, role);
  }

  /**
   * @description Delete an existing company
   */
  async deleteCompany(companyId: string) {
    return this.adminCompany.deleteCompany(companyId);
  }

  /**
   * @description Assign affiliate program coupon codes to a user
   */
  async assignAffiliateProgramCouponCodes(
    userId: string,
    proCouponCode: string,
    reportCouponCode: string
  ) {
    return this.adminUser.assignAffiliateProgramCouponCodes(
      userId,
      proCouponCode,
      reportCouponCode
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/account': AdminAccount;
  }
}
