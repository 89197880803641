// Vendor
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import fetch from 'fetch';
import IntlService from 'ember-intl/services/intl';
import Service, {inject as service} from '@ember/service';
import Shoebox from 'airthings/services/shoebox';

// Config
import config from 'airthings/config/environment';

export type AvailableLocale =
  | 'da'
  | 'de'
  | 'en-ca'
  | 'es'
  | 'fi'
  | 'fr'
  | 'it'
  | 'no'
  | 'sv';

const pathForLocale: Record<AvailableLocale, string> = {
  'da': '/assets/translations/da.json',
  'de': '/assets/translations/de.json',
  'en-ca': '/assets/translations/en-ca.json',
  'es': '/assets/translations/es.json',
  'fi': '/assets/translations/fi.json',
  'fr': '/assets/translations/fr.json',
  'it': '/assets/translations/it.json',
  'no': '/assets/translations/no.json',
  'sv': '/assets/translations/sv.json'
};

export default class Translations extends Service {
  @service('fastboot')
  fastboot: FastBoot;

  @service('intl')
  intl: IntlService;

  @service('shoebox')
  shoebox: Shoebox;

  async loadForLocale(locale: AvailableLocale) {
    const translations = await this.fetchTranslations(locale);

    this.intl.addTranslations(locale, translations);
  }

  private async fetchTranslations(locale: AvailableLocale): Promise<object> {
    if (this.fastboot.isFastBoot) {
      return await this.fetchTranslationsFromNetwork(locale);
    }

    const translations = this.fetchTranslationsFromShoebox(locale);

    if (translations && Object.keys(translations).length > 0) {
      return translations;
    }

    return this.fetchTranslationsFromNetwork(locale);
  }

  private async fetchTranslationsFromNetwork(
    locale: AvailableLocale
  ): Promise<object> {
    const translationsURL = pathForLocale[locale];

    const response = await fetch(translationsURL);

    const translations = await response.json();

    if (this.fastboot.isFastBoot) {
      this.shoebox.write(
        `${config.intl.TRANSLATIONS_CACHE_KEY}-${locale}`,
        translations
      );
    }

    return translations;
  }

  private fetchTranslationsFromShoebox(locale: AvailableLocale): object {
    const translations = this.shoebox.read(
      `${config.intl.TRANSLATIONS_CACHE_KEY}-${locale}`
    ) as object;

    return translations;
  }
}

declare module '@ember/service' {
  interface Registry {
    translations: Translations;
  }
}
