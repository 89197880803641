export default {
  "font-bold": "700",
  "block": "_block_wne9sp _light-box-wrapper_vbfmil",
  "content": "_content_wne9sp _light-box-content_vbfmil",
  "header": "_header_wne9sp _light-box-header_vbfmil",
  "with-block-definition": "_with-block-definition_wne9sp",
  "icon": "_icon_wne9sp _header-icon_vbfmil",
  "title": "_title_wne9sp _light-box-header-title_vbfmil",
  "two-column-table": "_two-column-table_wne9sp _two-column-table_vbfmil"
};
