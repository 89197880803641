// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {AdminRecordingsCalibrationCertificateStruct} from 'airthings/types/admin/recordings';
import AdminRecordings from 'airthings/services/airthings/admin/recordings';
import {dropTask} from 'ember-concurrency-decorators';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';

interface Args {
  calibrationCertificate: AdminRecordingsCalibrationCertificateStruct;
}

export default class PageAdminMessagesNew extends Component<Args> {
  @service('airthings/admin/recordings')
  adminRecordings: AdminRecordings;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @tracked
  calibrationCertificate: AdminRecordingsCalibrationCertificateStruct =
    this.args.calibrationCertificate;

  @dropTask
  *calibrationCertificateUpdateTask(): TaskGenerator<void> {
    return yield this.adminRecordings.updateCalibrationCertificate(
      this.calibrationCertificate
    );
  }

  @action
  handleChange(
    updatedCalibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    this.calibrationCertificate = updatedCalibrationCertificate;
  }

  @action
  validate(): object | undefined {
    return this.adminRecordings.validateUpdateCalibrationCertificate(
      this.calibrationCertificate
    );
  }

  @action
  handleSuccess() {
    this.flashMessages.success(
      this.intl.t('admin-calibration-certificates-edit.form.success')
    );

    this.navigateToAdminCalibrationCertificatesIndex();
  }

  @action
  navigateToAdminCalibrationCertificatesIndex() {
    this.router.transitionTo('admin.calibration-certificates.index');
  }
}
