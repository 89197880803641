// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';
import {action} from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import {perform} from 'ember-concurrency-ts';
import {TaskGenerator} from 'ember-concurrency';

// GraphQL
import {MutationResponse} from 'airthings/services/airthings/graphql';

// Services
import Account from 'airthings/services/airthings/account';
import Reports from 'airthings/services/airthings/reports';

// Types
import {ModalSize} from 'airthings/types/modal';
import {AccountUserStruct} from 'airthings/types/account';

interface Args {
  report: {
    id: string;
    pdfFileUrl: string;
    dataset: {
      device: {
        company: {
          name: string;
        };
      };
      metadata: {
        customer: {
          email: string;
        };
        measurement: {
          address: string;
          city: string;
          state: string;
          postalCode: string;
          country: string;
        };
      };
    };
  };
  currentUser: AccountUserStruct;
  onCancel: () => void;
  onClose: () => void;
  onSetModalSize?: (size: ModalSize) => void;
}

enum ComponentState {
  WAITING,
  LOADING,
  ERRORED,
  COMPLETED
}

export default class EmailDelivery extends Component<Args> {
  @service('airthings/account')
  account: Account;

  @service('intl')
  intl: IntlService;

  @service('airthings/reports')
  reports: Reports;

  @tracked
  state: ComponentState = ComponentState.WAITING;

  @tracked
  recipientEmails: string[] = this.initialRecipientEmails;

  @tracked
  ccCurrentUser: boolean = false;

  @tracked
  customMessage: string =
    this.args.currentUser.preferences.datasetReportMessage;

  @tracked
  includeCompanyLogo: boolean = false;

  @tracked
  saveMessageLoading: boolean = false;

  @tracked
  saveMessageSuccess: boolean = false;

  get isWaiting() {
    return this.state === ComponentState.WAITING;
  }

  get isLoading() {
    return this.state === ComponentState.LOADING;
  }

  get hasErrored() {
    return this.state === ComponentState.ERRORED;
  }

  get isCompleted() {
    return this.state === ComponentState.COMPLETED;
  }

  get initialRecipientEmails() {
    if (!this.args.report.dataset.metadata.customer.email) return [];

    return [this.args.report.dataset.metadata.customer.email];
  }

  get customMessageHasChanged() {
    return (
      this.customMessage !==
      this.args.currentUser.preferences.datasetReportMessage
    );
  }

  get customMessageSaveLabel() {
    return Boolean(this.args.currentUser.preferences.datasetReportMessage)
      ? this.intl.t(
          'reports.generation.confirmation.email-delivery.form.update-message'
        )
      : this.intl.t(
          'reports.generation.confirmation.email-delivery.form.save-message'
        );
  }

  @dropTask
  *sendReportByEmailTask(
    reportId: string,
    recipientEmails: string[],
    ccCurrentUser: boolean,
    customMessage: string,
    includeCompanyLogo: boolean
  ): TaskGenerator<void> {
    const {successful} = yield this.reports.sendReportByEmail(
      reportId,
      recipientEmails,
      ccCurrentUser,
      customMessage,
      includeCompanyLogo
    );

    if (successful) return;

    throw new Error('Unable to send report by email');
  }

  @action
  async sendReportByEmail(event?: Event) {
    if (event) event.preventDefault();

    this.state = ComponentState.LOADING;

    try {
      await perform(
        this.sendReportByEmailTask,
        this.args.report.id,
        this.recipientEmails,
        this.ccCurrentUser,
        this.customMessage,
        this.includeCompanyLogo
      );

      this.state = ComponentState.COMPLETED;
    } catch (error) {
      this.state = ComponentState.ERRORED;
    }
  }

  @action
  showEmailDeliveryForm() {
    this.recipientEmails = this.initialRecipientEmails;
    this.ccCurrentUser = false;
    this.customMessage = this.args.currentUser.preferences.datasetReportMessage;
    this.includeCompanyLogo = false;

    this.state = ComponentState.WAITING;
  }

  @action
  changeRecipientEmails(recipientEmails: string[]) {
    this.recipientEmails = recipientEmails;
  }

  @action
  changeCcCurrentUser(ccCurrentUser: boolean) {
    this.ccCurrentUser = ccCurrentUser;
  }

  @action
  changeCustomMessage(customMessage: string) {
    this.customMessage = customMessage;
    this.saveMessageSuccess = false;
  }

  @action
  changeIncludeCompanyLogo(includeCompanyLogo: boolean) {
    this.includeCompanyLogo = includeCompanyLogo;
  }

  @dropTask
  *saveCustomMessage(): Generator<Promise<unknown>, unknown, MutationResponse> {
    this.saveMessageSuccess = false;
    this.saveMessageLoading = true;

    const result = yield this.account.updateCurrentUser({
      ...this.args.currentUser,
      preferences: {
        ...this.args.currentUser.preferences,
        datasetReportMessage: this.customMessage
      }
    });

    this.saveMessageLoading = false;

    if (result.successful) {
      this.saveMessageSuccess = true;
    }

    return result;
  }
}
