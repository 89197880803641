// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {TaskGenerator, timeout} from 'ember-concurrency';
import config from 'airthings/config/environment';
import {perform} from 'ember-concurrency-ts';

// Types
import Recordings from 'airthings/services/airthings/recordings';
import {JobState} from 'airthings/graphql/queries/recordings-calibration-certificate-generation-job';
import {RecordingsDeviceStruct} from 'airthings/types/recordings';

interface Args {
  device: RecordingsDeviceStruct;
  onCancel: () => {};
}

interface CalibrationCertificate {
  id: string;
  pdfFileUrl?: string;
}

enum ComponentState {
  LOADING,
  ERRORED,
  COMPLETED
}

export default class DevicesCalibrationCertificateGenerationConfiguration extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  state: ComponentState = ComponentState.LOADING;

  @tracked
  calibrationCertificate: CalibrationCertificate;

  get isLoading() {
    return this.state === ComponentState.LOADING;
  }

  get isErrored() {
    return this.state === ComponentState.ERRORED;
  }

  get isCompleted() {
    return this.state === ComponentState.COMPLETED;
  }

  @dropTask
  *generateCalibrationCertificateTask(): TaskGenerator<CalibrationCertificate> {
    if (
      !this.args.device ||
      !this.args.device.calibrationCertificate ||
      !this.args.device.calibrationCertificate.uuid
    ) {
      throw new Error('Unable to generate calibration certificate');
    }

    const {successful, result} =
      yield this.recordings.generateCalibrationCertificate(
        this.args.device.calibrationCertificate.uuid
      );

    if (successful) {
      return yield perform(
        this.pollForCalibrationCertificateGenerationJobTask,
        result?.id
      );
    }

    throw new Error('Unable to generate calibration certificate');
  }

  @restartableTask
  *pollForCalibrationCertificateGenerationJobTask(
    jobId: string
  ): TaskGenerator<void> {
    while (true) {
      const {state, calibrationCertificate} =
        yield this.recordings.fetchCalibrationCertificateGenerationJob(jobId);

      if (state === JobState.COMPLETED) return calibrationCertificate;

      if (state === JobState.ERRORED) {
        throw new Error('Calibration certificate generation errored');
      }

      yield timeout(
        config.APP.calibrationCertificateGeneration.jobPollingDelay
      );
    }
  }

  @action
  async generateCalibrationCertificate() {
    this.state = ComponentState.LOADING;

    try {
      const calibrationCertificate = await perform(
        this.generateCalibrationCertificateTask
      );

      this.state = ComponentState.COMPLETED;
      this.calibrationCertificate = calibrationCertificate;
    } catch (error) {
      this.state = ComponentState.ERRORED;
    }
  }
}
