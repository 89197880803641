export default {
  "font-medium": "500",
  "font-demi-bold": "600",
  "font-bold": "700",
  "section-title": "_section-title_19msho",
  "section-subtitle": "_section-subtitle_19msho",
  "box": "_box_19msho",
  "container": "_container_19msho",
  "attached-information": "_attached-information_19msho",
  "report-link": "_report-link_19msho",
  "form": "_form_19msho",
  "form-row": "_form-row_19msho",
  "form-field": "_form-field_19msho",
  "form-row-actions": "_form-row-actions_19msho",
  "errors": "_errors_19msho",
  "actions": "_actions_19msho",
  "action": "_action_19msho",
  "save-message-component": "_save-message-component_19msho",
  "save-message-button": "_save-message-button_19msho",
  "is-active": "_is-active_19msho",
  "save-message-success": "_save-message-success_19msho"
};
