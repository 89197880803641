// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {DatasetInformation, DatasetType} from 'airthings/types/recordings';
import {FormSelectOption} from 'airthings/pods/components/form/select/component';
import {inject as service} from '@ember/service';
import IntlService from 'ember-intl/services/intl';

interface Args {
  formData: DatasetInformation;
  onChange: (updatedFormData: DatasetInformation) => void;
  errors?: object;
}

export default class DatasetInformationForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get datasetTypeOptions(): FormSelectOption[] {
    return [
      {
        label: this.intl.t('recordings.dataset-types.none'),
        value: DatasetType.NONE
      },
      {
        label: this.intl.t('recordings.dataset-types.crosscheck'),
        value: DatasetType.CROSSCHECK,
        disabled: true
      },
      {
        label: this.intl.t('recordings.dataset-types.duplicate'),
        value: DatasetType.DUPLICATE,
        disabled: true
      },
      {
        label: this.intl.t('recordings.dataset-types.follow-up'),
        value: DatasetType.FOLLOW_UP
      },
      {
        label: this.intl.t('recordings.dataset-types.initial'),
        value: DatasetType.INITIAL
      },
      {
        label: this.intl.t('recordings.dataset-types.post-mitigation'),
        value: DatasetType.POST_MITIGATION
      },
      {
        label: this.intl.t('recordings.dataset-types.real-estate-transaction'),
        value: DatasetType.REAL_ESTATE_TRANSACTION
      }
    ];
  }
  @action
  changeName(updateName: string) {
    this.args.onChange({
      ...this.args.formData,
      name: updateName
    });
  }

  @action
  changeComment(updateComment: string) {
    this.args.onChange({
      ...this.args.formData,
      comment: updateComment
    });
  }

  @action
  changeTemporaryConditions(updateTemporaryConditions: string) {
    this.args.onChange({
      ...this.args.formData,
      temporaryConditions: updateTemporaryConditions
    });
  }

  @action
  changeProtocolViolations(updateProtocolViolations: string) {
    this.args.onChange({
      ...this.args.formData,
      protocolViolations: updateProtocolViolations
    });
  }

  @action
  changeDatasetType(updateDatasetType: DatasetType) {
    this.args.onChange({
      ...this.args.formData,
      datasetType: updateDatasetType
    });
  }
}
