// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import * as Sentry from '@sentry/browser';
import {queryManager} from 'ember-apollo-client';
import Transition from '@ember/routing/-private/transition';

// Services
import Account from 'airthings/services/airthings/account';
import Analytics from 'airthings/services/analytics';
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import RouterService from '@ember/routing/router-service';
import StateManager from 'airthings/services/state-manager';
import Translations from 'airthings/services/translations';
import IntlService from 'ember-intl/services/intl';
import Apollo from 'airthings/services/apollo';

// Utils
import {bootIntercom, hideIntercom} from 'airthings/utils/intercom';

// Config
import config from 'airthings/config/environment';

export default class AppRoute extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('state-manager')
  stateManager: StateManager;

  @service('airthings/account')
  account: Account;

  @service('fastboot')
  fastboot: FastBoot;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @service('translations')
  translations: Translations;

  @queryManager
  queryManager: Apollo;

  queryParams = {
    help: {
      refreshModel: true
    }
  };

  constructor() {
    super(...arguments);

    const router = this.router;

    router.on('routeDidChange', () => {
      this.analytics.trackPageView(router.currentURL);
    });
  }

  // eslint-disable-next-line complexity
  async beforeModel(transition: Transition) {
    const completionStatus = await this.account.fetchAccountCompletionStatus();

    if (!this.account.isLoggedIn || !completionStatus) {
      transition.abort();

      return this.account.logout();
    }

    if (!completionStatus.hasProfile) {
      transition.abort();

      this.router.replaceWith('lobby.profile');

      return;
    }

    // If an admin user doesn’t have a company assigned, it means he
    // should get back to the admin section.
    if (completionStatus.isAdmin && !completionStatus.hasCompany) {
      transition.abort();

      this.router.replaceWith('admin');

      return;
    }

    if (!completionStatus.hasCompany) {
      transition.abort();

      this.router.replaceWith('lobby.company');

      return;
    }
  }

  async model() {
    const currentUser = await this.account.watchCurrentUser(this.queryManager);

    if (!currentUser) return;

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: currentUser.viewer.id,
        email: currentUser.viewer.profile.email
      });
    });

    this.stateManager.setCurrentUser(
      this.account.presentCurrentUser(currentUser.viewer)
    );

    // This needs to run client side for Intercom to load properly
    if (!this.fastboot.isFastBoot) {
      bootIntercom({
        appId: config.intercom.appId,
        webappVersion: config.APP.version,
        email: currentUser.viewer.profile.email,
        userHash: currentUser.viewer.intercomHashWeb
      });

      // Allow each route to display the Intercom Messenger launcher
      hideIntercom();
    }

    return {
      currentUser
    };
  }
}
