export interface ReportsTemplateStruct {
  id: string | null;
  name: string;
  description: string;
  isCompanyFavorite?: boolean;
  reportTemplatePublication: null | {
    id?: string | null;
  };
  language: ReportsLanguage;
}

export interface ReportsTemplate {
  id: string | null;
  name: string;
  description: string;
  isCompanyFavorite: boolean;
  reportTemplatePublication: ReportsTemplatePublication | null;
}

export interface ReportsTemplatePublication {
  insertedAt: string;
}

export type StructFactory = <StructType>(
  base?: RecursivePartial<StructType>
) => StructType;

type ValidationFunction = <StructType>(
  struct: StructType
) => undefined | object;

type SanitizationFunction = <StructType>(struct: StructType) => StructType;

export interface ReportsBlockDefinition {
  name: string;
  componentNamespace: string;
  key: string;
  graphqlTypeName: string;
  lightIconPath: string;
  darkIconPath: string;
  description?: string;
  helper?: string;
  configurationHelper?: string;
  needsLargeTemplateSettingsModal?: boolean;
  titlePreview?: (templateSettings: unknown) => string | undefined;
  templateSettingsStruct?: StructFactory;
  reportSettingsStruct?: StructFactory;
  templateSettingsValidate?: ValidationFunction;
  reportSettingsValidate?: ValidationFunction;
  templateSettingsSanitize?: SanitizationFunction;
}

export interface ReportsConfigurableBlockDefinition
  extends ReportsBlockDefinition {
  reportSettingsStruct: StructFactory;
  reportSettingsValidate: ValidationFunction;
}

export interface ReportsBlockGroupDefinition {
  key: string;
  name: string;
  blocks: ReportsBlockDefinition[];
}

export interface ReportsBlockService {
  definition: ReportsBlockDefinition;
}

export interface ReportsBlockStruct {
  id: string | null;
  key: string;
  templateSettingsStruct?: unknown;
}

export interface ReportsConfigurableBlock {
  id: string;
  key: string;
  templateSettingsStruct?: unknown;
  reportSettingsStruct: object | null;
}

export interface ReportsConfigurationState {
  id: string;
  configuredAt: string | null;
  recordingStartedAt: string | null;
  recordingEndedAt: string | null;
  dataset: {
    id: string;
    timeZone: string;
  } | null;
  averageRadon: {
    value: number;
    unit: string;
  } | null;
  unconfiguredReportBlocks: ReportsConfigurableBlock[];
}

export interface ReportsTemplateSettingsComponentArgs<SettingsStructType> {
  settingsStruct: SettingsStructType;
  dismissErrors: () => void;
  errors?: object;
  onChange: (settings: SettingsStructType) => void;
}

export enum ReportsLanguage {
  EN = 'EN',
  FR = 'FR',
  ES = 'ES',
  IT = 'IT',
  NO = 'NO',
  DE = 'DE',
  SV = 'SV',
  FI = 'FI',
  DA = 'DA'
}
