// @ts-nocheck

// Vendor
import Component from '@glimmer/component';

// Types
import {RecordingsDeviceStruct} from 'airthings/types/recordings';

interface Args {
  device: RecordingsDeviceStruct;
}

export default class CrosscheckBadge extends Component<Args> {
  get comparisonDue() {
    return this.args.device.datasetsUntilCrosscheck <= 0;
  }
}
