export default {
  "font-bold": "700",
  "container": "_container_1sodmr",
  "error": "_error_1sodmr",
  "badge": "_badge_1sodmr",
  "icon": "_icon_1sodmr",
  "message": "_message_1sodmr",
  "actions": "_actions_1sodmr",
  "action": "_action_1sodmr"
};
