// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Types
import Apollo from 'airthings/services/apollo';
import AdminAffiliateProgram from 'airthings/services/airthings/admin/affiliate-program';

export default class AdminAffiliateProgramAdsIndexRoute extends Route {
  @service('airthings/admin/affiliate-program')
  adminAffiliateProgram: AdminAffiliateProgram;

  @queryManager
  queryManager: Apollo;

  async model() {
    return this.adminAffiliateProgram.watchAds(this.queryManager);
  }
}
