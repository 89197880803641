// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Services
import AdminAccountCompany from 'airthings/services/airthings/admin/account/company';

// Types
import {AdminAccountPaginatedCompaniesResponse} from 'airthings/graphql/queries/admin-account-paginated-companies';

// Utils
import {AdminAccountListCompanyStruct} from 'airthings/types/admin/account';

interface Args {
  validDeviceCount: number;
  company: AdminAccountListCompanyStruct;
  searchCompanyResults: AdminAccountPaginatedCompaniesResponse['admin'];
  onNextStep: () => void;
  onCompanyChange: (company: AdminAccountListCompanyStruct) => void;
  onCompanySearch: (term: string) => void;
}

export default class DevicesMoveSelectCompany extends Component<Args> {
  @service('airthings/admin/account/company')
  adminCompany: AdminAccountCompany;

  get isContinueDisabled() {
    return !Boolean(this.args.company);
  }

  get companyLabel() {
    return `${this.args.company.name} (${this.args.company.email})`;
  }

  @action search(term: string) {
    this.args.onCompanySearch(term);
  }
}
