export default {
  "font-bold": "700",
  "user-actions": "_user-actions_u6oom4",
  "name-cell": "_name-cell_u6oom4",
  "user-name": "_user-name_u6oom4",
  "company-name": "_company-name_u6oom4",
  "deleted": "_deleted_u6oom4",
  "move-user": "_move-user_u6oom4",
  "move-user-name": "_move-user-name_u6oom4",
  "row": "_row_u6oom4",
  "assign-affiliate-program-coupon-codes-actions": "_assign-affiliate-program-coupon-codes-actions_u6oom4",
  "move-actions": "_move-actions_u6oom4",
  "assign-affiliate-program-coupon-codes-action": "_assign-affiliate-program-coupon-codes-action_u6oom4",
  "move-action": "_move-action_u6oom4",
  "table-row": "_table-row_u6oom4"
};
