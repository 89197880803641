// Vendor
import {Ability} from 'ember-can';

// Types
import {AccountUserStruct, UserRole} from 'airthings/types/account';

export default class DatasetAbility extends Ability {
  currentUser: AccountUserStruct;

  get canDelete() {
    return this.currentUser.role === UserRole.MANAGER;
  }

  get canList() {
    return true;
  }

  get canShow() {
    return true;
  }

  get canEdit() {
    return true;
  }

  get canGenerateCsv() {
    return true;
  }
}
