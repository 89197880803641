// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';

export default class PageLogin extends Component {
  @service('airthings/account')
  account: Account;

  @tracked
  shouldDisplaySignUpWarning = false;

  @action
  initiateLoginFlow() {
    this.account.initiateLoginFlow();
  }

  @action
  initiateSignUpFlow() {
    this.account.initiateSignUpFlow();
  }

  @action
  displaySignUpWarning() {
    this.shouldDisplaySignUpWarning = true;
  }

  @action
  closeSignUpWarning() {
    this.shouldDisplaySignUpWarning = false;
  }
}
