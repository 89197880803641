// Vendor
import {gql} from '@apollo/client/core';

export enum JobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED'
}

export interface LabImportPdfGenerationJobVariables {
  jobId: string;
}

export interface LabImportPdfGenerationJobResponse {
  labImportPdfGenerationJob: {
    id: string;
    state: JobState;
    pdfUrl: string;
  };
}

export default gql`
  query LabImportPdfGenerationJob($jobId: ID!) {
    labImportPdfGenerationJob(jobId: $jobId) {
      id
      state
      pdfUrl
    }
  }
`;
