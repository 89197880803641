// Vendor
import {gql} from '@apollo/client/core';

export enum JobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED'
}

export interface DatasetBatchCSVGenerationJobVariables {
  jobId: string;
}

export interface DatasetBatchCSVGenerationJobResponse {
  viewer: {
    id: string;

    company: {
      id: string;

      datasetBatchCSVGenerationJob: {
        id: string;
        state: JobState;
        csvUrl?: string;
      };
    };
  };
}

export default gql`
  query RecordingsDatasetBatchCSVGenerationJob($jobId: ID!) {
    viewer {
      id

      company {
        id

        datasetBatchCSVGenerationJob(jobId: $jobId) {
          id
          state
          csvUrl
        }
      }
    }
  }
`;
