// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import API from 'airthings/services/airthings/affiliate-program/api';

export default class AffiliateProgram extends Service {
  @service('airthings/affiliate-program/api')
  api: API;

  /**
   * @description Returns all the active affiliate program ads available.
   */
  async fetchAds() {
    return this.api.fetchAds();
  }

  /**
   * @description Returns all the active affiliate program ads available as <select> options.
   */
  async fetchAdOptions() {
    const ads = await this.api.fetchAds();

    return ads.map(({id, name}) => ({
      label: name,
      value: id
    }));
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/affiliate-program': AffiliateProgram;
  }
}
