// Vendor
import Service, {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import Apollo from 'airthings/services/apollo';
import AdminAffiliateProgramAd from 'airthings/services/airthings/admin/affiliate-program/ad';
import {AdminAffiliateProgramAdStruct} from 'airthings/types/admin/affiliate-program';
import {AdminAffiliateProgramAdsResponse} from 'airthings/graphql/queries/admin-affiliate-program-ads';
import {AdminAffiliateProgramEditAdByIdResponse} from 'airthings/graphql/queries/admin-affiliate-program-edit-ad-by-id';

export default class AdminAffiliateProgram extends Service {
  @service('airthings/admin/affiliate-program/ad')
  adminAd: AdminAffiliateProgramAd;

  @action
  presentAdminAffiliateProgramAds(
    admin: AdminAffiliateProgramAdsResponse['admin']
  ) {
    return this.adminAd.presentAffiliateProgramAds(admin);
  }

  @action
  presentAdminAffiliateProgramEditAdById(
    admin: AdminAffiliateProgramEditAdByIdResponse['admin']
  ) {
    return this.adminAd.presentAffiliateProgramEditAdById(admin);
  }

  /**
   * @description Returns a watchQuery containing active affiliate program ads
   */
  async watchAds(queryManager: Apollo) {
    return this.adminAd.watchAds(queryManager);
  }

  /**
   * @description Returns a watchQuery containing the ad being edited.
   */
  async watchEditAdById(id: string, queryManager: Apollo) {
    return this.adminAd.watchEditAdById(queryManager, id);
  }

  /**
   * @description Delete an ad.
   */
  async deleteAd(adId: string) {
    return this.adminAd.deleteAd(adId);
  }

  /**
   * @description Create a new ad.
   */
  async createAd(ad: AdminAffiliateProgramAdStruct) {
    return this.adminAd.createAd(ad);
  }

  /**
   * @description Update an ad.
   */
  async updateAd(ad: AdminAffiliateProgramAdStruct) {
    return this.adminAd.updateAd(ad);
  }

  /**
   * @description Validates an ad and returns a hash with errors if there’s any
   */
  validateAd(ad: AdminAffiliateProgramAdStruct) {
    return this.adminAd.validateAd(ad);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/affiliate-program': AdminAffiliateProgram;
  }
}
