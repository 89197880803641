// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';

// Types
import IntlService from 'ember-intl/services/intl';

type DotAlignment = 'left' | 'right';

interface Args {
  dotAlignment: DotAlignment;
  readAt: string | null;
}

export default class CommunicationBoardMessagesReadStatusBadge extends Component<Args> {
  @service('intl')
  intl: IntlService;

  dotAlignment: DotAlignment = this.args.dotAlignment || 'left';

  get statusLabel() {
    const suffix = this.args.readAt ? 'read' : 'unread';

    return this.intl.t(
      `communication-board.messages.latest.message.status.${suffix}`
    );
  }
}
