// Vendor
import Component from '@glimmer/component';
import {dropTask} from 'ember-concurrency-decorators';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import Form, {Validator} from 'airthings/services/form';
import {ModalSize} from 'airthings/types/modal';

interface Args {
  recipientEmails: string[];
  ccCurrentUser: boolean;
  customMessage: string;
  customMessageHasChanged: boolean;
  includeCompanyLogo: boolean;
  measurement: {
    address: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  reportLink: string;
  onChangeRecipientEmails: (recipientEmails: string[]) => void;
  onChangeCcCurrentUser: (ccCurrentUser: boolean) => void;
  onChangeCustomMessage: (customMessage: string) => void;
  onSaveCustomMessage: () => void;
  onChangeIncludeCompanyLogo: (includeCompanyLogo: boolean) => void;
  onCancel: () => void;
  onSendReportByEmail: () => void;
  onSetModalSize?: (size: ModalSize) => void;
}

export default class EmailDeliveryForm extends Component<Args> {
  @service('form')
  form: Form;

  @action
  validate() {
    const validators: Array<Validator<string, 'required'>> = [
      {
        field: 'emails',
        isValid: this.args.recipientEmails.length > 0,
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix:
        'reports.generation.confirmation.email-delivery.form'
    });
  }

  @action
  onInsert() {
    if (typeof this.args.onSetModalSize !== 'function') return;

    this.args.onSetModalSize(ModalSize.LARGE);
  }

  @action
  onDestroy() {
    if (typeof this.args.onSetModalSize !== 'function') return;

    this.args.onSetModalSize(ModalSize.MEDIUM);
  }

  @dropTask
  *sendReportByEmailTask() {
    yield this.args.onSendReportByEmail();
  }
}
