// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {timeout} from 'ember-concurrency';
import config from 'airthings/config/environment';
import {TaskGenerator} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

// Types
import Weather from 'airthings/services/airthings/weather';
import {JobState} from 'airthings/graphql/queries/reports-report-generation-job';
import {RecordingsListDatasetStruct} from 'airthings/types/recordings';

interface Args {
  dataset: RecordingsListDatasetStruct;
  onCancel: () => {};
}

interface Dataset {
  id: string;
  pdfUrl?: string;
}

enum ComponentState {
  LOADING,
  ERRORED,
  COMPLETED
}

export default class DatasetsWeatherReportGeneration extends Component<Args> {
  @service('airthings/weather')
  weather: Weather;

  @tracked
  state: ComponentState = ComponentState.LOADING;

  @tracked
  dataset: Dataset;

  get isLoading() {
    return this.state === ComponentState.LOADING;
  }

  get isErrored() {
    return this.state === ComponentState.ERRORED;
  }

  get isCompleted() {
    return this.state === ComponentState.COMPLETED;
  }

  @dropTask
  *generateReportTask(datasetId: string): TaskGenerator<Dataset> {
    const {successful, result} =
      yield this.weather.generateDatasetWeatherReport(datasetId);

    if (successful) {
      return yield perform(this.pollForReportGenerationJobTask, result?.id);
    }

    throw new Error('Unable to generate PDF');
  }

  @restartableTask
  *pollForReportGenerationJobTask(jobId: string): TaskGenerator<Dataset> {
    while (true) {
      const {state, dataset} =
        yield this.weather.fetchDatasetWeatherReportGenerationJob(jobId);

      if (state === JobState.COMPLETED) return dataset;

      if (state === JobState.ERRORED) {
        throw new Error('PDF generation errored');
      }

      yield timeout(config.APP.reportGeneration.jobPollingDelay);
    }
  }

  @action
  async generateReport() {
    this.state = ComponentState.LOADING;

    try {
      const dataset: Dataset = await perform(
        this.generateReportTask,
        this.args.dataset.id
      );

      this.state = ComponentState.COMPLETED;
      this.dataset = dataset;
    } catch (error) {
      this.state = ComponentState.ERRORED;
    }
  }
}
