// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {gt} from '@ember/object/computed';

// Constants
import {Keyboard} from 'airthings/constants/keyboard';

// Config
import config from 'airthings/config/environment';

// Types
import Account from 'airthings/services/airthings/account';
import Analytics from 'airthings/services/analytics';
import {AccountUserStruct} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
}

export default class SidebarMenu extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @gt('args.currentUser.companyExpiredCalibrationDevicesCount', 0)
  showDeviceBadge: boolean;

  @tracked
  isOpen = false;

  appVersion = config.APP.version;

  @action
  toggle() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) this.analytics.trackMainNavigationExpand();
  }

  @action
  close() {
    this.isOpen = false;
  }

  @action
  onKeydown(event: KeyboardEvent) {
    if (event.key !== Keyboard.ESCAPE) return;

    this.close();
  }

  @action
  handleLinkClick(section: string) {
    this.analytics.trackMainNavigation(section);
  }

  allowOutsideClick() {
    return true;
  }
}
