// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import Media from 'ember-responsive/services/media';

export default class AppReporTtemplatesController extends Controller {
  @service('media')
  media: Media;
}
