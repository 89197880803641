export default {
  "font-normal": "400",
  "font-bold": "700",
  "size-3": "8px",
  "size-5": "32px",
  "button": "_button_znm4p3",
  "is-small": "_is-small_znm4p3",
  "loader": "_loader_znm4p3 _animations-spin_7nu7fk",
  "is-gray": "_is-gray_znm4p3",
  "is-flat-gray": "_is-flat-gray_znm4p3",
  "is-yellow": "_is-yellow_znm4p3",
  "is-light-yellow": "_is-light-yellow_znm4p3",
  "is-light-gray": "_is-light-gray_znm4p3",
  "is-red": "_is-red_znm4p3",
  "is-white": "_is-white_znm4p3",
  "is-small-green": "_is-small-green_znm4p3",
  "is-small-red": "_is-small-red_znm4p3",
  "is-loading": "_is-loading_znm4p3",
  "label": "_label_znm4p3"
};
