// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {AdminRecordingsEditCalibrationCertificateByIdResponse} from 'airthings/graphql/queries/admin-recordings-edit-calibration-certificate-by-id';
import {AdminRecordingsPaginatedCalibrationCertificatesResponse} from 'airthings/graphql/queries/admin-recordings-paginated-calibration-certificates';
import {
  AdminRecordingsCalibrationCertificateStruct,
  AdminRecordingsListCalibrationCertificateStruct,
  CalibrationType
} from 'airthings/types/admin/recordings';
import API from 'airthings/services/airthings/admin/recordings/api';
import Apollo from 'airthings/services/apollo';
import Form, {Validator} from 'airthings/services/form';
import {ComparisonDevice} from 'airthings/types/admin/recordings';
import RecordingJob from 'airthings/services/airthings/admin/recordings/job';

export default class AdminRecordingsCalibrationCertificate extends Service {
  @service('airthings/admin/recordings/api')
  api: API;

  @service('airthings/admin/recordings/job')
  job: RecordingJob;

  @service('form')
  form: Form;

  async watchPaginatedCalibrationCertificates(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.api.watchPaginatedCalibrationCertificates(
      queryManager,
      page,
      searchTerm
    );
  }

  async watchEditCalibrationCertificateById(queryManager: Apollo, id: string) {
    return this.api.watchEditCalibrationCertificateById(queryManager, id);
  }

  async createCalibrationCertificate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    const mutationResponse = await this.api.createCalibrationCertificate(
      calibrationCertificate
    );

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'recordings.calibration-certificate'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async updateCalibrationCertificate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    const mutationResponse = await this.api.updateCalibrationCertificate(
      calibrationCertificate
    );

    if (!mutationResponse) return;

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'recordings.calibration-certificate'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async deleteCalibrationCertificate(calibrationCertificateId: string) {
    const mutationResponse = await this.api.deleteCalibrationCertificate(
      calibrationCertificateId
    );

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'recordings.calibration-certificate'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async toggleCalibrationCertificate(calibrationCertificateId: string) {
    const mutationResponse = await this.api.toggleCalibrationCertificate(
      calibrationCertificateId
    );

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'recordings.calibration-certificate'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  /**
   * @description Returns a Promise that resolves with a calibration certificate generation job
   */
  async fetchCalibrationCertificateGenerationJob(jobId: string) {
    return this.job.fetchCalibrationCertificateGenerationJob(jobId);
  }

  validateCreate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    let validators: Array<Validator<string, 'required' | 'format'>> = [
      {
        field: 'device-serial-number',
        isValid: Boolean(calibrationCertificate.deviceSerialNumber),
        code: 'required'
      },
      {
        field: 'device-serial-number',
        isValid: /^\d{10}$/.test(calibrationCertificate.deviceSerialNumber),
        code: 'format'
      },
      {
        field: 'calibration-type',
        isValid: Boolean(
          calibrationCertificate.calibrationMeta &&
            calibrationCertificate.calibrationMeta.calibrationType
        ),
        code: 'required'
      }
    ];

    if (calibrationCertificate.comparisonDevices) {
      calibrationCertificate.comparisonDevices.forEach(
        (comparisonDevice: ComparisonDevice, index: number) => {
          validators = validators.concat([
            {
              field: `comparison-devices.${index}.serial-number`,
              isValid: Boolean(comparisonDevice.serialNumber),
              code: 'required'
            },
            {
              field: `comparison-devices.${index}.calibration-date`,
              isValid:
                !Boolean(comparisonDevice.calibrationDate) ||
                /^\d{4}\-\d{2}\-\d{2}$/.test(comparisonDevice.calibrationDate),
              code: 'format'
            }
          ]);
        }
      );
    }

    return this.form.validate(validators, {
      translationKeyPrefix: 'recordings.calibration-certificate'
    });
  }

  validateUpdate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    let validators: Array<Validator<string, 'required' | 'format'>> = [];

    if (calibrationCertificate.comparisonDevices) {
      calibrationCertificate.comparisonDevices.forEach(
        (comparisonDevice: ComparisonDevice, index: number) => {
          validators = validators.concat([
            {
              field: `comparison-devices.${index}.serial-number`,
              isValid: Boolean(comparisonDevice.serialNumber),
              code: 'required'
            },
            {
              field: `comparison-devices.${index}.calibration-date`,
              isValid:
                !Boolean(comparisonDevice.calibrationDate) ||
                /^\d{4}\-\d{2}\-\d{2}$/.test(comparisonDevice.calibrationDate),
              code: 'format'
            }
          ]);
        }
      );
    }

    return this.form.validate(validators, {
      translationKeyPrefix: 'recordings.calibration-certificate'
    });
  }

  presentAccountPaginatedCalibrationCertificates(
    admin: AdminRecordingsPaginatedCalibrationCertificatesResponse['admin']
  ) {
    return {
      calibrationCertificates:
        admin.paginatedCalibrationCertificates.entries.map(
          (certificate: AdminRecordingsListCalibrationCertificateStruct) => ({
            ...certificate,
            deletable:
              !certificate.activatedAt ||
              certificate.calibrationMeta.calibrationType ===
                CalibrationType.LAB,
            downloadable:
              certificate.activatedAt &&
              certificate.calibrationMeta.calibrationType !==
                CalibrationType.OTHER
          })
        ),
      pagination: admin.paginatedCalibrationCertificates.metadata
    };
  }

  presentAccountEditCalibrationCertificateById(
    admin: AdminRecordingsEditCalibrationCertificateByIdResponse['admin']
  ) {
    return admin.calibrationCertificate;
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/recordings/calibration-certificate': AdminRecordingsCalibrationCertificate;
  }
}
