export default {
  "container": "_container_1duig1",
  "fading": "_fading_1duig1",
  "is-open": "_is-open_1duig1",
  "header": "_header_1duig1",
  "button": "_button_1duig1",
  "button-icon": "_button-icon_1duig1",
  "with-burger": "_with-burger_1duig1",
  "logo-wrapper": "_logo-wrapper_1duig1",
  "logo": "_logo_1duig1",
  "navigation": "_navigation_1duig1",
  "link": "_link_1duig1",
  "icon": "_icon_1duig1",
  "with-datasets": "_with-datasets_1duig1",
  "with-templates": "_with-templates_1duig1",
  "with-devices": "_with-devices_1duig1",
  "with-company": "_with-company_1duig1",
  "with-dataset-settings": "_with-dataset-settings_1duig1",
  "with-user-management": "_with-user-management_1duig1",
  "with-affiliate-program": "_with-affiliate-program_1duig1",
  "admin-banner-container": "_admin-banner-container_1duig1",
  "admin-banner": "_admin-banner_1duig1"
};
