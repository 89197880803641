export default {
  "font-medium": "500",
  "container": "_container_1kpqeq",
  "icon": "_icon_1kpqeq",
  "name": "_name_1kpqeq",
  "title": "_title_1kpqeq",
  "actions": "_actions_1kpqeq",
  "action": "_action_1kpqeq",
  "is-reorder": "_is-reorder_1kpqeq",
  "helper": "_helper_1kpqeq"
};
