// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import {ReportSettingsStruct} from 'airthings/services/airthings/reports/block-definitions/signature';
import IntlService from 'ember-intl/services/intl';
import {ChoiceButtonsOption} from 'airthings/pods/components/form/choice-buttons/component';

interface Args {
  settingsStruct: ReportSettingsStruct;
  errors?: object;
  onChange: (settings: ReportSettingsStruct) => void;
}

enum SignatureMode {
  Electronic = 'electronic',
  Manual = 'manual'
}

export default class ReportsBlocksSignatureReportSettings extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get currentSignatureMode(): SignatureMode {
    return this.args.settingsStruct.isManualSignature
      ? SignatureMode.Manual
      : SignatureMode.Electronic;
  }

  get signatureModeOptions(): ChoiceButtonsOption[] {
    return [
      {
        label: this.intl.t(
          'reports.block-definitions.signature.report-settings.is-manual-signature.electronic'
        ),
        value: SignatureMode.Electronic
      },
      {
        label: this.intl.t(
          'reports.block-definitions.signature.report-settings.is-manual-signature.manual'
        ),
        value: SignatureMode.Manual
      }
    ];
  }

  @action
  changeSignatureLocation(signatureLocation: string) {
    this.args.onChange({...this.args.settingsStruct, signatureLocation});
  }

  @action
  changeSignatureMode(signatureMode: SignatureMode) {
    this.args.onChange({
      ...this.args.settingsStruct,
      isManualSignature: signatureMode === SignatureMode.Manual
    });
  }
}
