export default {
  "font-bold": "700",
  "container": "_container_1oiedq",
  "success": "_success_1oiedq",
  "badge": "_badge_1oiedq",
  "icon": "_icon_1oiedq",
  "message": "_message_1oiedq",
  "actions": "_actions_1oiedq",
  "action": "_action_1oiedq"
};
