// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import AdminAccount from 'airthings/services/airthings/admin/account';
import {AdminAccountCurrentUserResponse} from 'airthings/graphql/queries/admin-account-current-user';
import Can from 'ember-can/services/can';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';

export default class AdminAffiliateProgramAdsRoute extends Route {
  @service('airthings/admin/account')
  adminAccount: AdminAccount;

  @service('can')
  can: Can;

  @service('router')
  router: RouterService;

  beforeModel(transition: Transition) {
    const model = this.modelFor('admin') as {
      currentUser: AdminAccountCurrentUserResponse;
    };

    const currentUser = this.adminAccount.presentCurrentUser(model.currentUser);

    if (this.can.cannot('manage adminAffiliateProgram', null, {currentUser})) {
      transition.abort();

      this.router.replaceWith('admin');
    }
  }
}
