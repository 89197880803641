// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import Analytics from 'airthings/services/analytics';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import {AccountInvitationStruct} from 'airthings/types/account';

interface RouteParams {
  id: string;
}

export default class InvitationsRoute extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  constructor() {
    super(...arguments);

    const router = this.router;

    router.on('routeDidChange', () => {
      this.analytics.trackPageView(router.currentURL);
    });
  }

  async model(params: RouteParams, transition: Transition) {
    const invitation = await this.account.fetchInvitationByUUID(params.id);

    if (!invitation) {
      this.analytics.trackAccessInvitationUnknown();

      transition.abort();

      this.router.replaceWith('login', {
        queryParams: {error: 'invitation-unknown'}
      });

      return;
    }

    if (invitation.acceptedAt) {
      this.analytics.trackAccessInvitationAlreadyAccepted(invitation);

      transition.abort();

      this.router.replaceWith('login', {
        queryParams: {error: 'invitation-already-accepted'}
      });

      return;
    }

    if (this.invitationIsExpired(invitation)) {
      this.analytics.trackAccessInvitationExpired(invitation);

      transition.abort();

      this.router.replaceWith('login', {
        queryParams: {error: 'invitation-expired'}
      });

      return;
    }
  }

  redirect() {
    this.account.redirectToSignUpFlow();
  }

  private invitationIsExpired(invitation: AccountInvitationStruct) {
    if (!invitation.expiredAt) return false;

    const expirationTimestamp = Date.parse(invitation.expiredAt);

    return Date.now() > expirationTimestamp;
  }
}
