// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import AdminCommunicationBoard from 'airthings/services/airthings/admin/communication-board';

export default class AdminMessagesEditController extends Controller {
  @service('airthings/admin/communication-board')
  adminCommunicationBoard: AdminCommunicationBoard;
}
