// Vendor
import Service from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';

// Types
import {AccountUserStruct} from 'airthings/types/account';
import {AdminAccountUserStruct} from 'airthings/types/admin/account';
import Analytics from 'airthings/services/analytics';

export default class StateManager extends Service {
  @service('analytics')
  analytics: Analytics;

  @tracked
  currentUser: AccountUserStruct | AdminAccountUserStruct | null = null;

  @tracked
  userSidebarIsOpened: boolean = false;

  @tracked
  mobileMenuIsOpened: boolean = false;

  /**
   * @description Set current user
   */
  setCurrentUser(user: AccountUserStruct | AdminAccountUserStruct | null) {
    this.currentUser = user;
  }

  /**
   * @description Open the mobile menu
   */
  openMobileMenu() {
    this.analytics.trackMobileMenuExpand();

    this.mobileMenuIsOpened = true;
  }

  /**
   * @description Open the mobile menu
   */
  closeMobileMenu() {
    this.analytics.trackMobileMenuCollapse();

    this.mobileMenuIsOpened = false;
  }

  /**
   * @description Toggle the mobile menu
   */
  toggleMobileMenu() {
    if (this.mobileMenuIsOpened) return this.closeMobileMenu();

    this.openMobileMenu();
  }

  /**
   * @description Open the user side-panel
   */
  openUserSidebar() {
    this.analytics.trackUserSidebarExpand();

    this.userSidebarIsOpened = true;
  }

  /**
   * @description Close the user side-panel
   */
  closeUserSidebar() {
    this.analytics.trackUserSidebarCollapse();

    this.userSidebarIsOpened = false;
  }
}

declare module '@ember/service' {
  interface Registry {
    'state-manager': StateManager;
  }
}
