// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import FastBoot from 'ember-cli-fastboot/services/fastboot';

// Services
import Analytics from 'airthings/services/analytics';
import IntlService from 'ember-intl/services/intl';
import Reports from 'airthings/services/airthings/reports';
import RouterService from '@ember/routing/router-service';
import Translations from 'airthings/services/translations';

// Types
import {Report} from 'airthings/graphql/queries/report-by-uuid';

// Utils
import reportsLanguageToLocale from 'airthings/utils/locales';

// Config
import config from 'airthings/config/environment';

interface RouteParams {
  uuid: string;
}

export default class ReportRoute extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @service('airthings/reports')
  reports: Reports;

  @service('fastboot')
  fastboot: FastBoot;

  @service('translations')
  translations: Translations;

  constructor() {
    super(...arguments);

    const router = this.router;

    router.on('routeDidChange', () => {
      this.analytics.trackPageView(router.currentURL);
    });
  }

  async model(params: RouteParams) {
    // NOTE: We had problems with large datasets loaded in memory with FastBoot so we
    //       delegate it to the browser.
    if (this.fastboot.isFastBoot) return;

    return this.reports.fetchReportByUUID(params.uuid);
  }

  async afterModel(model: Report) {
    if (!model) return;

    const locale = reportsLanguageToLocale(model.language);

    this.intl.setLocale([locale, config.APP.reportLocaleFallback]);

    if (config.intl.ASYNC_TRANSLATIONS) {
      await this.translations.loadForLocale(locale);
    }
  }
}
