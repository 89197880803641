// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {TemplateSettingsStruct} from 'airthings/services/airthings/reports/block-definitions/report-summary';
import {ReportsTemplateSettingsComponentArgs} from 'airthings/types/reports';

export default class ReportsBlocksReportSummaryTemplateSettings extends Component<
  ReportsTemplateSettingsComponentArgs<TemplateSettingsStruct>
> {
  @action
  changeTitle(title: string) {
    this.args.onChange({...this.args.settingsStruct, title});
  }
}
