export default {
  "font-bold": "700",
  "description": "_description_hrg44s",
  "section": "_section_hrg44s",
  "label": "_label_hrg44s",
  "fields": "_fields_hrg44s",
  "isn-logo": "_isn-logo_hrg44s",
  "connection-button": "_connection-button_hrg44s",
  "connection-errors": "_connection-errors_hrg44s",
  "connection-success": "_connection-success_hrg44s"
};
