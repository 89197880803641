export default {
  "font-bold": "700",
  "errors": "_errors_ms9rlx",
  "settings-wrapper": "_settings-wrapper_ms9rlx",
  "actions": "_actions_ms9rlx",
  "action": "_action_ms9rlx",
  "progress": "_progress_ms9rlx",
  "report-summary": "_report-summary_ms9rlx",
  "is-mobile": "_is-mobile_ms9rlx"
};
