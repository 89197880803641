// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';
import Form, {Validator} from 'airthings/services/form';
import API from 'airthings/services/airthings/admin/affiliate-program/api';
import {AdminAffiliateProgramAdStruct} from 'airthings/types/admin/affiliate-program';
import {AdminAffiliateProgramAdsResponse} from 'airthings/graphql/queries/admin-affiliate-program-ads';
import {AdminAffiliateProgramEditAdByIdResponse} from 'airthings/graphql/queries/admin-affiliate-program-edit-ad-by-id';

export default class AdminAffiliateProgramAd extends Service {
  @service('airthings/admin/affiliate-program/api')
  api: API;

  @service('form')
  form: Form;

  async watchAds(queryManager: Apollo) {
    return this.api.watchAds(queryManager);
  }

  async watchEditAdById(queryManager: Apollo, id: string) {
    return this.api.watchEditAdById(queryManager, id);
  }

  async deleteAd(affiliateProgramAdId: string) {
    return this.api.deleteAd(affiliateProgramAdId);
  }

  async createAd(ad: AdminAffiliateProgramAdStruct) {
    return this.api.createAd(ad);
  }

  async updateAd(ad: AdminAffiliateProgramAdStruct) {
    return this.api.updateAd(ad);
  }

  presentAffiliateProgramAds(admin: AdminAffiliateProgramAdsResponse['admin']) {
    return admin.affiliateProgramAds;
  }

  presentAffiliateProgramEditAdById(
    admin: AdminAffiliateProgramEditAdByIdResponse['admin']
  ) {
    return admin.affiliateProgramAd;
  }

  validateAd(ad: AdminAffiliateProgramAdStruct) {
    const validators: Array<Validator<string, 'required'>> = [
      {
        field: 'name',
        isValid: Boolean(ad.name),
        code: 'required'
      },

      {
        field: 'image-url',
        isValid: Boolean(ad.imageUrl),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'admin.affiliate-program.ad-form'
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/affiliate-program/ad': AdminAffiliateProgramAd;
  }
}
