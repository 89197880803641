// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import fade from 'ember-animated/transitions/fade';
import {restartableTask} from 'ember-concurrency-decorators';
import {timeout} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

const FADE_TRANSITION_DURATION = 300;

interface Args {
  blockGroup: any;
  onSelect: (blockDefinition: any) => void;
}

export default class ReportsBlockPickerGroup extends Component<Args> {
  fadeTransition = fade;
  fadeTransitionDuration = FADE_TRANSITION_DURATION;

  @tracked
  isExpanded = false;

  @tracked
  isExpanding = false;

  @restartableTask
  *toggleListTask() {
    this.isExpanded = !this.isExpanded;

    this.isExpanding = true;

    yield timeout(this.fadeTransitionDuration);

    this.isExpanding = false;
  }

  @action
  toggleList() {
    perform(this.toggleListTask);
  }
}
