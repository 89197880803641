// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Types
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import AdminCommunicationBoard from 'airthings/services/airthings/admin/communication-board';
import Apollo from 'airthings/services/apollo';
import AppDatasetsEditController from 'airthings/pods/app/datasets/edit/controller';
import {AdminCommunicationBoardEditMessageByIdResponse} from 'airthings/graphql/queries/admin-communication-board-edit-message-by-id';

interface RouteParams {
  id: string;
}

interface RouteModel {
  message: AdminCommunicationBoardEditMessageByIdResponse | null;
}

export default class AppDatasetsEditRoute extends Route {
  controller: AppDatasetsEditController;

  @service('airthings/admin/communication-board')
  adminCommunicationBoard: AdminCommunicationBoard;

  @service('router')
  router: RouterService;

  @queryManager
  queryManager: Apollo;

  async model(params: RouteParams): Promise<RouteModel> {
    const message = await this.adminCommunicationBoard.watchEditMessageById(
      params.id,
      this.queryManager
    );

    return {
      message
    };
  }

  afterModel(model: RouteModel, transition: Transition) {
    if (model.message) return;

    transition.abort();
    this.router.replaceWith('admin.messages');
  }
}
