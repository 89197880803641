// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {dasherize} from '@ember/string';

// Types
import {
  RecordingsSampleUnit,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';

// Services
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';

interface Args {
  reportData: {
    title: string;
    recordingStartedAt: string;
    recordingEndedAt: string;
    durationInSeconds: number;
    datasetTimeZone: string;
    datasetType: string;
    averageRadon: {
      value: number;
      uncertainty: number;
      unit: RecordingsSampleUnit;
    };
    buildingMetadata: {
      roomType: string;
      floor: string;
    };
    measurementMetadata: {
      address: string;
      city: string;
      postalCode: string;
      state: string;
      country: string;
    };
    samplesStartedAt: string;
    samplesEndedAt: string;
  };
}

export default class RecordingsReportSummary extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @service('intl')
  intl: IntlService;

  sampleUnitTypes = RecordingsSampleUnitType;

  get radonUnitLabel() {
    return this.recordings.sampleUnitLabel(
      this.args.reportData.averageRadon.unit
    );
  }

  get addressLineTwo() {
    const {postalCode, city, state, country} =
      this.args.reportData.measurementMetadata;

    const address = [city, state, postalCode, country]
      .filter((token) => token)
      .join(', ');

    return address || 'N/A';
  }

  get datasetTypeText() {
    const key = dasherize(this.args.reportData.datasetType.toLowerCase());

    return this.intl.t(`recordings.dataset-types.${key}`);
  }
}
