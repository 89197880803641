// Types
import {RecordingsUnitSystem} from 'airthings/types/recordings';

export enum PreferredLanguage {
  EN = 'EN',
  FR = 'FR'
}

export enum UserRole {
  MANAGER = 'MANAGER',
  EMPLOYEE = 'EMPLOYEE'
}

export enum OnboardingStepSlug {
  WELCOME = 'WELCOME',
  AFFILIATE_PROGRAM = 'AFFILIATE_PROGRAM',
  DEVICE_MANAGEMENT_ONBOARDING = 'DEVICE_MANAGEMENT_ONBOARDING',
  DEVICES_MAP_ONBOARDING = 'DEVICE_MANAGEMENT_ONBOARDING',
  DATASET_MANAGEMENT_ONBOARDING = 'DATASET_MANAGEMENT_ONBOARDING',
  DATASET_DETAILS_ONBOARDING = 'DATASET_DETAILS_ONBOARDING',
  REPORT_TEMPLATE_MANAGEMENT_ONBOARDING = 'REPORT_TEMPLATE_MANAGEMENT_ONBOARDING',
  REPORT_TEMPLATE_CONFIGURATION_ONBOARDING = 'REPORT_TEMPLATE_CONFIGURATION_ONBOARDING',
  USER_MANAGEMENT_ONBOARDING = 'USER_MANAGEMENT_ONBOARDING',
  COMPANY_EDITION_ONBOARDING = 'COMPANY_EDITION_ONBOARDING',
  COMPANY_SETTINGS_ONBOARDING = 'COMPANY_SETTINGS_ONBOARDING',
  COMMUNICATION_BOARD_ONBOARDING = 'COMMUNICATION_BOARD_ONBOARDING',
  PROFILE_EDITION_ONBOARDING = 'PROFILE_EDITION_ONBOARDING'
}

export enum OnboardingSectionSlug {
  DATASET = 'DATASET',
  DATASETS = 'DATASETS',
  DEVICES = 'DEVICES',
  DEVICES_MAP = 'DEVICES_MAP',
  REPORT_TEMPLATES = 'REPORT_TEMPLATES',
  REPORT_TEMPLATE_CONFIGURATION = 'REPORT_TEMPLATE_CONFIGURATION',
  USERS = 'USERS',
  COMPANY_EDITION = 'COMPANY_EDITION',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  COMMUNICATION_BOARD = 'COMMUNICATION_BOARD',
  PROFILE_EDITION = 'PROFILE_EDITION',
  THIRD_PARTY_SETTINGS = 'THIRD_PARTY_SETTINGS'
}

export enum PremiumFeatureSlug {
  DEVICES_MAP = 'DEVICES_MAP'
}

export interface AccountUserInformation {
  name: string;
  email: string;
  phoneNumber: string;
}

export interface AccountUserPreferences {
  unitSystem: RecordingsUnitSystem;
  language: PreferredLanguage;
  datasetReportMessage: string;
}

export interface AccountUserAffiliateProgramCodes {
  __typename?: string;
  pro: string;
  report: string;
}

export interface AccountUserAffiliateProgram {
  isSubscribed: boolean;
  subscribedToAffiliateProgramAt: string | null;
  codes: AccountUserAffiliateProgramCodes | null;
}

export interface AccountOnboardingStep {
  id: string;
  slug: OnboardingStepSlug;
  sectionSlug: OnboardingSectionSlug;
  isReminder: boolean;
}

export interface AccountPremiumFeatureNotice {
  id: string;
  slug: PremiumFeatureSlug;
  isReminder: boolean;
}

export interface AccountUserStruct {
  id: string | null;
  role: UserRole;
  isAdmin: boolean;
  unreadMessagesCount: number;
  hasUnreadMessagesNotice: boolean;
  companyId: string | null;
  companyName: string | null;
  companyExpiredCalibrationDevicesCount: number | null;
  information: AccountUserInformation;
  preferences: AccountUserPreferences;
  hasActiveInvitations: boolean;
  affiliateProgram: AccountUserAffiliateProgram;
  onboardingSteps: AccountOnboardingStep[];
  premiumFeatureNotices: AccountPremiumFeatureNotice[];
  certificates?: Certificate[];
}

export interface AccountDetailedUserStruct {
  id: string | null;
  role: UserRole;
  isAdmin: boolean;
  unreadMessagesCount: number;
  hasUnreadMessagesNotice: boolean;
  companyId: string | null;
  companyName: string | null;
  companyExpiredCalibrationDevicesCount: number | null;
  information: AccountUserInformation;
  preferences: AccountUserPreferences;
  hasActiveInvitations: boolean;
  affiliateProgram: AccountUserAffiliateProgram;
  certificates: Certificate[];
  onboardingSteps: AccountOnboardingStep[];
  premiumFeatureNotices: AccountPremiumFeatureNotice[];
}

export interface AccountCompanyDatasetSettings {
  defaultDurationInSeconds: number | null;
  defaultDelayInSeconds: number | null;
  defaultMeasurementType: string | null;
  roomTypeOptions: string[];
  defaultRoomType: string | null;
  floorOptions: string[];
  defaultFloor: string | null;
  buildingTypeOptions: string[];
  defaultBuildingType: string | null;
  foundationTypeOptions: string[];
  defaultFoundationType: string | null;
  ventilationOptions: string[];
  defaultVentilation: string | null;
  mitigationSystemOptions: string[];
  defaultMitigationSystem: string | null;
}

export interface AccountCompanyStruct {
  id: string | null;
  information: AccountCompanyInformation;
  logo: AccountCompanyLogo;
  certificates: Certificate[];
  datasetSettings: AccountCompanyDatasetSettings;
}

export interface AccountInvitationStruct {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  expiredAt: string | null;
  acceptedAt: string | null;
  company: AccountInvitationCompanyStruct;
}

export interface AccountInvitationCompanyStruct {
  id: string;
}

export interface AccountCompanyLogo {
  url: string;
}

export interface AccountCompanyInformation {
  id?: string;
  name: string;
  email: string;
  phoneNumber: string;
  address: Address;
}

export interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  formattedAddress: string;
}

export interface AccountCompletionStatus {
  hasProfile: boolean;
  hasCompany: boolean;
}

export interface Certificate {
  name: string;
  number: string;
  expirationDate: string;
}

export interface AffialiateProgramSubscriptionStruct {
  uniqueCouponPrefix: string;
}

export enum ThirdPartyType {
  ISN = 'ISN'
}

export interface ThirdPartySettings {
  thirdPartyType: ThirdPartyType;
  domain?: string;
  companyKey?: string;
  accessKey?: string;
  secretKey?: string;
}
