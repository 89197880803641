// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';
import {DataProxy} from '@apollo/client/core';
import {
  ReportsBlockStruct,
  ReportsTemplateStruct
} from 'airthings/types/reports';
import GraphQL from 'airthings/services/airthings/graphql';

// GraphQL
import reportCompanyTemplates, {
  ReportCompanyTemplatesResponse
} from 'airthings/graphql/queries/report-company-templates';
import reportCompanyPublishedTemplateNames from 'airthings/graphql/queries/report-company-published-template-names';
import reportAvailableTemplateBlocks, {
  AvailableReportTemplateBlocksResponse
} from 'airthings/graphql/queries/report-available-template-blocks';
import reportCompanyTemplateBlocks, {
  ReportCompanyTemplateBlocksVariables
} from 'airthings/graphql/queries/report-company-template-blocks';
import reportCreateTemplateMutation, {
  ReportCreateTemplateVariables,
  ReportCreateTemplateResponse
} from 'airthings/graphql/mutations/report-create-template';
import reportUpdateTemplateMutation, {
  ReportUpdateTemplateVariables,
  ReportUpdateTemplateResponse
} from 'airthings/graphql/mutations/report-update-template';
import reportDuplicateTemplateMutation, {
  ReportDuplicateTemplateResponse,
  ReportDuplicateTemplateVariables
} from 'airthings/graphql/mutations/report-duplicate-template';
import reportDeleteTemplateMutation, {
  ReportDeleteTemplateVariables,
  ReportDeleteTemplateResponse
} from 'airthings/graphql/mutations/report-delete-template';
import reportCreateTemplateBlockMutation, {
  ReportCreateTemplateBlockVariables,
  ReportCreateTemplateBlockResponse
} from 'airthings/graphql/mutations/report-create-template-block';
import reportUpdateTemplateBlockMutation, {
  ReportUpdateTemplateBlockVariables,
  ReportUpdateTemplateBlockResponse
} from 'airthings/graphql/mutations/report-update-template-block';
import reportDeleteTemplateBlockMutation, {
  ReportDeleteTemplateBlockVariables,
  ReportDeleteTemplateBlockResponse
} from 'airthings/graphql/mutations/report-delete-template-block';
import reportReorderTemplateBlocksMutation, {
  ReportReorderTemplateBlocksVariables,
  ReportReorderTemplateBlocksResponse
} from 'airthings/graphql/mutations/report-reorder-template-blocks';
import reportPublishTemplateMutation, {
  ReportPublishTemplateVariables,
  ReportPublishTemplateResponse
} from 'airthings/graphql/mutations/report-publish-template';
import reportCreateReport, {
  ReportCreateReportVariables,
  ReportCreateReportResponse
} from 'airthings/graphql/mutations/report-create-report';
import reportByUUID, {
  ReportByUUIDResponse
} from 'airthings/graphql/queries/report-by-uuid';
import mockedReport, {
  MockedReportResponse
} from 'airthings/graphql/queries/mocked-report';
import reportConfigureReportBlock, {
  ReportConfigureReportBlockVariables,
  ReportConfigureReportBlockResponse
} from 'airthings/graphql/mutations/report-configure-report-block';
import reportCompanyDatasetByExternalId, {
  ReportCompanyDatasetByExternalIdVariables,
  ReportCompanyDatasetByExternalIdResponse
} from 'airthings/graphql/queries/report-company-dataset-by-external-id';
import reportGenerateReport, {
  GenerateReportVariables,
  GenerateReportResponse
} from 'airthings/graphql/mutations/reports-generate-report';
import reportGenerationJob, {
  ReportGenerationJobVariables,
  ReportGenerationJobResponse
} from 'airthings/graphql/queries/reports-report-generation-job';
import reportSendReportByEmail, {
  SendReportByEmailVariables,
  SendReportByEmailResponse
} from 'airthings/graphql/mutations/reports-send-report-by-email';

export default class API extends Service {
  @service('apollo')
  apollo: Apollo;

  @service('airthings/graphql')
  graphQL: GraphQL;

  async watchCompanyReportTemplates(queryManager: Apollo) {
    return queryManager.watchQuery({query: reportCompanyTemplates});
  }

  async fetchAvailableReportTemplateBlocks() {
    const response: AvailableReportTemplateBlocksResponse =
      await this.apollo.query({query: reportAvailableTemplateBlocks});

    return response.viewer.company.availableReportTemplateBlocks;
  }

  async createReportTemplate(template: ReportsTemplateStruct) {
    type ReturnType = ReportCreateTemplateResponse['createReportTemplate'];

    const variables: ReportCreateTemplateVariables = {
      name: template.name,
      description: template.description,
      language: template.language
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportCreateTemplateResponse = await this.apollo.mutate({
        mutation: reportCreateTemplateMutation,
        variables,

        update(store: DataProxy, {data: {createReportTemplate}}: any) {
          if (!createReportTemplate.successful) return;

          const data = store.readQuery({
            query: reportCompanyTemplates
          }) as ReportCompanyTemplatesResponse;

          const newData = {
            ...data,
            viewer: {
              ...data.viewer,
              company: {
                ...data.viewer.company,
                reportTemplates: [
                  createReportTemplate.result,
                  ...data.viewer.company.reportTemplates
                ]
              }
            }
          };

          store.writeQuery({query: reportCompanyTemplates, data: newData});
        }
      });

      return response.createReportTemplate;
    });
  }

  async updateReportTemplate(template: ReportsTemplateStruct) {
    if (!template.id) return;

    type ReturnType = ReportUpdateTemplateResponse['updateReportTemplate'];

    const variables: ReportUpdateTemplateVariables = {
      name: template.name,
      description: template.description,
      language: template.language,
      reportTemplateId: template.id
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportUpdateTemplateResponse = await this.apollo.mutate({
        mutation: reportUpdateTemplateMutation,
        variables
      });

      return response.updateReportTemplate;
    });
  }

  async duplicateReportTemplate(template: ReportsTemplateStruct) {
    if (!template.id) return;

    type ReturnType =
      ReportDuplicateTemplateResponse['duplicateReportTemplate'];

    const variables: ReportDuplicateTemplateVariables = {
      name: template.name,
      description: template.description,
      language: template.language,
      reportTemplateId: template.id
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportDuplicateTemplateResponse =
        await this.apollo.mutate({
          mutation: reportDuplicateTemplateMutation,
          variables,

          update(store: DataProxy, {data: {duplicateReportTemplate}}: any) {
            if (!duplicateReportTemplate.successful) return;

            const data = store.readQuery({
              query: reportCompanyTemplates
            }) as ReportCompanyTemplatesResponse;

            const newData = {
              ...data,
              viewer: {
                ...data.viewer,
                company: {
                  ...data.viewer.company,
                  reportTemplates: [
                    duplicateReportTemplate.result,
                    ...data.viewer.company.reportTemplates
                  ]
                }
              }
            };

            store.writeQuery({query: reportCompanyTemplates, data: newData});
          }
        });

      return response.duplicateReportTemplate;
    });
  }

  async deleteReportTemplate(reportTemplateId: string) {
    type ReturnType = ReportDeleteTemplateResponse['deleteReportTemplate'];

    const variables: ReportDeleteTemplateVariables = {
      reportTemplateId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportDeleteTemplateResponse = await this.apollo.mutate({
        mutation: reportDeleteTemplateMutation,
        variables,

        update(store: DataProxy, {data: {deleteReportTemplate}}: any) {
          if (!deleteReportTemplate.successful) return;

          let data;

          try {
            data = store.readQuery({
              query: reportCompanyTemplates
            }) as ReportCompanyTemplatesResponse;

            if (!data.viewer.company.reportTemplates) return;
          } catch (error) {
            // The cached query failed somehow
            return;
          }

          const newData = {
            ...data,
            viewer: {
              ...data.viewer,
              company: {
                ...data.viewer.company,
                reportTemplates: data.viewer.company.reportTemplates.filter(
                  (reportTemplate: ReportsTemplateStruct) => {
                    return reportTemplate.id !== reportTemplateId;
                  }
                )
              }
            }
          };

          store.writeQuery({
            query: reportCompanyTemplates,
            data: newData
          });
        }
      });

      return response.deleteReportTemplate;
    });
  }

  async watchCompanyTemplateBlocks(queryManager: Apollo, templateId: string) {
    const variables: ReportCompanyTemplateBlocksVariables = {templateId};

    return queryManager.watchQuery({
      query: reportCompanyTemplateBlocks,
      variables
    });
  }

  async createReportTemplateBlock(
    block: ReportsBlockStruct,
    templateId: string
  ) {
    type ReturnType =
      ReportCreateTemplateBlockResponse['createReportTemplateBlock'];

    const variables: ReportCreateTemplateBlockVariables = {
      reportTemplateId: templateId,
      reportTemplateBlock: {
        blockType: this.formatBlockType(block.key),
        settings: JSON.stringify(block.templateSettingsStruct || {})
      }
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportCreateTemplateBlockResponse =
        await this.apollo.mutate({
          mutation: reportCreateTemplateBlockMutation,
          variables
        });

      return {
        ...response.createReportTemplateBlock,
        messages: response.createReportTemplateBlock.messages.map(
          ({field, code}) => {
            return {
              code,
              field: this.formatBlockSettingsField(field, 'template')
            };
          }
        )
      };
    });
  }

  async updateReportTemplateBlock(block: ReportsBlockStruct) {
    type ReturnType =
      ReportUpdateTemplateBlockResponse['updateReportTemplateBlock'];

    const variables: ReportUpdateTemplateBlockVariables = {
      reportTemplateBlockId: block.id || '',
      settings: JSON.stringify(block.templateSettingsStruct || {})
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportUpdateTemplateBlockResponse =
        await this.apollo.mutate({
          mutation: reportUpdateTemplateBlockMutation,
          variables
        });

      return {
        ...response.updateReportTemplateBlock,
        messages: response.updateReportTemplateBlock.messages.map(
          ({field, code}) => {
            return {
              code,
              field: this.formatBlockSettingsField(field, 'template')
            };
          }
        )
      };
    });
  }

  async deleteReportTemplateBlock(blockId: string) {
    type ReturnType =
      ReportDeleteTemplateBlockResponse['deleteReportTemplateBlock'];

    const variables: ReportDeleteTemplateBlockVariables = {
      reportTemplateBlockId: blockId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportDeleteTemplateBlockResponse =
        await this.apollo.mutate({
          mutation: reportDeleteTemplateBlockMutation,
          variables
        });

      return response.deleteReportTemplateBlock;
    });
  }

  async reorderReportTemplateBlocks(
    reorderedBlocks: ReportsBlockStruct[],
    templateId: string
  ) {
    type ReturnType =
      ReportReorderTemplateBlocksResponse['reorderReportTemplateBlocks'];

    const variables: ReportReorderTemplateBlocksVariables = {
      reportTemplateId: templateId,
      reportTemplateBlockRanks: reorderedBlocks.map(
        ({id}: ReportsBlockStruct, index: number) => ({
          rank: index,
          reportTemplateBlockId: id || ''
        })
      )
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportReorderTemplateBlocksResponse =
        await this.apollo.mutate({
          mutation: reportReorderTemplateBlocksMutation,
          variables
        });

      return response.reorderReportTemplateBlocks;
    });
  }

  async publishReportTemplate(templateId: string) {
    type ReturnType = ReportPublishTemplateResponse['publishReportTemplate'];

    const variables: ReportPublishTemplateVariables = {
      reportTemplateId: templateId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportPublishTemplateResponse = await this.apollo.mutate({
        mutation: reportPublishTemplateMutation,
        variables
      });

      return response.publishReportTemplate;
    });
  }

  async createReport(
    datasetId: string,
    duplicateDatasetId: string | null,
    reportTemplatePublicationId: string,
    affiliateProgramAdId: string | null,
    truncatedSecondsFromStart: number,
    truncatedSecondsFromEnd: number,
    testStartedAt: Date,
    testEndedAt: Date
  ) {
    type ReturnType = ReportCreateReportResponse['createReport'];

    const variables: ReportCreateReportVariables = {
      datasetId,
      duplicateDatasetId,
      reportTemplatePublicationId,
      affiliateProgramAdId,
      truncatedSecondsFromStart,
      truncatedSecondsFromEnd,
      testStartedAt,
      testEndedAt
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportCreateReportResponse = await this.apollo.mutate({
        mutation: reportCreateReport,
        variables
      });

      const sortedBlocks = response.createReport.successful
        ? response.createReport.result.unconfiguredReportBlocks
            .sort((blockA, blockB) => blockA.rank - blockB.rank)
            .map((block) => ({
              ...block,
              key: this.parseBlockType(block.blockType),
              templateSettingsStruct: block.templateSettings,
              reportSettingsStruct: null
            }))
        : [];

      return {
        ...response.createReport,
        result: {
          ...response.createReport.result,
          unconfiguredReportBlocks: sortedBlocks
        }
      };
    });
  }

  async configureReportBlock(reportBlockId: string, reportSettings: object) {
    type ReturnType =
      ReportConfigureReportBlockResponse['configureReportBlock'];

    const variables: ReportConfigureReportBlockVariables = {
      reportBlockId,
      reportSettings: JSON.stringify(reportSettings)
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ReportConfigureReportBlockResponse =
        await this.apollo.mutate({
          mutation: reportConfigureReportBlock,
          variables
        });

      return {
        ...response.configureReportBlock,
        messages: response.configureReportBlock.messages.map(
          ({field, code}) => {
            return {
              code,
              field: this.formatBlockSettingsField(field, 'report')
            };
          }
        )
      };
    });
  }

  async fetchCompanyPublishedTemplateNames() {
    try {
      return await this.apollo.query({
        query: reportCompanyPublishedTemplateNames,
        fetchPolicy: 'no-cache'
      });
    } catch (error) {
      return null;
    }
  }

  parseBlockType(blockType: string) {
    return blockType.replace(/_/g, '-').toLowerCase();
  }

  async fetchReportByUUID(reportUUID: string) {
    const variables = {uuid: reportUUID};

    const response: ReportByUUIDResponse = await this.apollo.query({
      query: reportByUUID,
      variables
    });

    return response.reportByUuid;
  }

  async fetchMockedReport(reportTemplateId: string) {
    const variables = {reportTemplateId};

    const response: MockedReportResponse = await this.apollo.query({
      query: mockedReport,
      variables
    });

    return response.mockedReport;
  }

  async generateReport(reportId: string) {
    type ReturnType = GenerateReportResponse['generateReport'];

    const variables: GenerateReportVariables = {reportId};

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: GenerateReportResponse = await this.apollo.mutate({
        mutation: reportGenerateReport,
        variables
      });

      return response.generateReport;
    });
  }

  async fetchReportGenerationJob(jobId: string) {
    const variables: ReportGenerationJobVariables = {jobId};

    const response: ReportGenerationJobResponse = await this.apollo.query({
      query: reportGenerationJob,
      variables,
      fetchPolicy: 'no-cache'
    });

    return response.viewer.company.reportGenerationJob;
  }

  async fetchDatasetByExternalId(datasetExternalId: string) {
    const variables: ReportCompanyDatasetByExternalIdVariables = {
      externalId: datasetExternalId
    };

    const response: ReportCompanyDatasetByExternalIdResponse =
      await this.apollo.query({
        query: reportCompanyDatasetByExternalId,
        variables
      });

    return response.viewer?.company.datasetByExternalId;
  }

  async sendReportByEmail(
    reportId: string,
    recipientEmails: string[],
    ccCurrentUser: boolean,
    customMessage: string,
    includeCompanyLogo: boolean
  ) {
    type ReturnType = SendReportByEmailResponse['sendReportByEmail'];

    const variables: SendReportByEmailVariables = {
      reportId,
      recipientEmails,
      ccCurrentUser,
      customMessage,
      includeCompanyLogo
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: SendReportByEmailResponse = await this.apollo.mutate({
        mutation: reportSendReportByEmail,
        variables
      });

      return response.sendReportByEmail;
    });
  }

  private formatBlockType(blockKey: string) {
    return blockKey.replace(/-/g, '_').toUpperCase();
  }

  private formatBlockSettingsField(field: string, type: 'template' | 'report') {
    const [, blockKey, ...settingsField] = field.split('.');

    return [blockKey, `${type}-settings`, ...settingsField].join('.');
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/reports/api': API;
  }
}
