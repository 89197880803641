// Vendor
import Helper from '@ember/component/helper';
import {inject as service} from '@ember/service';

// Services
import IntlService from 'ember-intl/services/intl';

// Types
import {ReportsLanguage} from 'airthings/types/reports';

export default class LanguageName extends Helper {
  @service('intl')
  intl: IntlService;

  compute([language]: [ReportsLanguage]): string {
    return this.intl.t(`general.languages.${language}`);
  }
}
