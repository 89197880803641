// Vendor
import Component from '@glimmer/component';

export interface ChoiceButtonsOption {
  label: string;
  value: string;
}

interface Args {
  name: string;
  options: ChoiceButtonsOption[];
  value: string;
  onChange: (changedValue: string) => void;
}

export default class FormChoiceButtons extends Component<Args> {}
