// @ts-nocheck

// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import {
  RecordingsSampleStruct,
  RecordingsSampleUnitType,
  RecordingsSampleUnit
} from 'airthings/types/recordings';
import {Chart} from 'highcharts';
import Recordings from 'airthings/services/airthings/recordings';
import IntlService from 'ember-intl/services/intl';

// Constants
const TITLE_MAPPING = {
  RADON_SHORT_TERM_AVERAGE: 'recordings.chart.title.radon-short-term-average',
  TEMPERATURE: 'recordings.chart.title.temperature',
  PRESSURE: 'recordings.chart.title.pressure',
  HUMIDITY: 'recordings.chart.title.humidity'
};

interface Args {
  height: number;
  samples: RecordingsSampleStruct[];
  sampleType: RecordingsSampleUnitType;
  sampleUnit: RecordingsSampleUnit;
  xAxisMin: number;
  onChartInitialized?: (chart: Chart) => void;
}

export default class RecordingsChart extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  get sampleTypeIcon() {
    return this.args.sampleType.toLowerCase().replace(/_/g, '-');
  }

  get title() {
    return this.intl.t(TITLE_MAPPING[this.args.sampleType]);
  }

  get unitLabel() {
    return this.recordings.sampleUnitLabel(this.args.sampleUnit);
  }

  get valueDecimals() {
    return this.recordings.decimalCountForSampleUnitType(this.args.sampleType);
  }

  get data() {
    return this.args.samples.map((sample: RecordingsSampleStruct) => {
      return this.recordings.sampleToChartPoint(sample, this.args.sampleType);
    });
  }

  @action
  valueFormatter(value: number) {
    return this.recordings.formatSampleUnit(value, this.args.sampleType);
  }
}
