// Vendor
import {gql} from '@apollo/client/core';

export enum JobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED'
}

export interface ImportJobResult {
  inserted: number;
  errors: string[];
}

export interface CalibrationCertificateImportJobVariables {
  jobId: string;
}

export interface CalibrationCertificateImportJobResponse {
  calibrationCertificateImportJob: {
    id: string;
    state: JobState;
    importResult: ImportJobResult;
  };
}

export default gql`
  query CalibrationCertificateImportJob($jobId: ID!) {
    calibrationCertificateImportJob(jobId: $jobId) {
      id
      state
      importResult {
        inserted
        errors
      }
    }
  }
`;
