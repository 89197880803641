export default {
  "font-bold": "700",
  "block": "_block_4l7hob _light-box-wrapper_1ghgfm",
  "content": "_content_4l7hob _light-box-content_1ghgfm",
  "cell": "_cell_4l7hob _cell_1ghgfm",
  "key": "_key_4l7hob _key_1ghgfm",
  "value": "_value_4l7hob _value_1ghgfm",
  "header": "_header_4l7hob _light-box-header_1ghgfm",
  "icon": "_icon_4l7hob _header-icon_1ghgfm",
  "title": "_title_4l7hob _light-box-header-title_1ghgfm"
};
