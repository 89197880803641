export default {
  "font-bold": "700",
  "container": "_container_elz3xl",
  "success": "_success_elz3xl",
  "badge": "_badge_elz3xl",
  "icon": "_icon_elz3xl",
  "message": "_message_elz3xl",
  "actions": "_actions_elz3xl",
  "action": "_action_elz3xl",
  "download-button": "_download-button_elz3xl"
};
