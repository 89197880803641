export default {
  "font-bold": "700",
  "container": "_container_1lhigb",
  "error": "_error_1lhigb",
  "badge": "_badge_1lhigb",
  "icon": "_icon_1lhigb",
  "message": "_message_1lhigb",
  "actions": "_actions_1lhigb",
  "action": "_action_1lhigb"
};
