// Vendor
import Component from '@glimmer/component';

interface Args {
  route: string;
  label: string;
  theme: 'small-green' | 'gray' | 'light-gray';
  query?: object | null;
}

export default class Button extends Component<Args> {
  get isSmall() {
    return this.args.theme.startsWith('small-');
  }

  get query() {
    if (!this.args.query) return {};
    return this.args.query;
  }
}
