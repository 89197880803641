// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Utils
import parsePageParam from 'airthings/utils/parse-page-param';
import {hideIntercom, showIntercom} from 'airthings/utils/intercom';

// Types
import Can from 'ember-can/services/can';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Account from 'airthings/services/airthings/account';
import Recordings from 'airthings/services/airthings/recordings';
import Apollo from 'airthings/services/apollo';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

interface RouteParams {
  page?: string;
  q?: string;
}

export default class AppDatasetsIndexRoute extends Route {
  @service('airthings/account')
  account: Account;

  @service('can')
  can: Can;

  @service('router')
  router: RouterService;

  @service('airthings/recordings')
  recordings: Recordings;

  @queryManager
  queryManager: Apollo;

  queryParams = {
    page: {
      refreshModel: true
    },
    q: {
      refreshModel: true
    }
  };

  beforeModel(transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    if (this.can.cannot('list datasets', null, {currentUser})) {
      transition.abort();

      this.router.replaceWith('app');
    }

    showIntercom();
  }

  async model(params: RouteParams) {
    const currentUser = await this.account.watchCurrentUser(this.queryManager);

    const datasets = await this.recordings.watchCompanyPaginatedDataset(
      this.queryManager,
      parsePageParam(params),
      params.q
    );

    return {
      datasets,
      currentUser,
      searchTerm: params.q
    };
  }

  deactivate(): void {
    hideIntercom();
  }
}
