export default {
  "font-bold": "700",
  "block": "_block_1jxk0l _light-box-wrapper_vbfmil",
  "content": "_content_1jxk0l _light-box-content_vbfmil",
  "header": "_header_1jxk0l _light-box-header_vbfmil",
  "with-block-definition": "_with-block-definition_1jxk0l",
  "title": "_title_1jxk0l _light-box-header-title_vbfmil",
  "icon": "_icon_1jxk0l _header-icon_vbfmil",
  "two-column-table": "_two-column-table_1jxk0l _two-column-table_vbfmil",
  "certification-content": "_certification-content_1jxk0l _certification-content_vbfmil",
  "certification-header": "_certification-header_1jxk0l",
  "certification-icon": "_certification-icon_1jxk0l",
  "certification-item": "_certification-item_1jxk0l _certification-item_vbfmil",
  "certification-column": "_certification-column_1jxk0l _certification-column_vbfmil",
  "certification-item-label": "_certification-item-label_1jxk0l _certification-item-label_vbfmil",
  "certification-item-value": "_certification-item-value_1jxk0l _certification-item-value_vbfmil"
};
