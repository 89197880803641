// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import API from 'airthings/services/airthings/account/api';
import Form, {Validator} from 'airthings/services/form';
import {AffialiateProgramSubscriptionStruct} from 'airthings/types/account';

export default class AffiliateProgram extends Service {
  @service('airthings/account/api')
  api: API;

  @service('form')
  form: Form;

  subscriptionStruct(
    base?: RecursivePartial<AffialiateProgramSubscriptionStruct>
  ): AffialiateProgramSubscriptionStruct {
    return {
      uniqueCouponPrefix: '',
      ...base
    };
  }

  validateSubscription(
    affialiateProgramSubscription: AffialiateProgramSubscriptionStruct
  ) {
    const validators: Array<Validator<string, 'required'>> = [
      {
        field: 'uniqueCouponPrefix',
        isValid: Boolean(affialiateProgramSubscription.uniqueCouponPrefix),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix: 'app-affiliate-program.form'
    });
  }

  async subscribe(
    affiliateProgramSubscription: AffialiateProgramSubscriptionStruct
  ) {
    const mutationResponse = await this.api.subscribeToAffiliateProgram(
      affiliateProgramSubscription
    );

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'app-affiliate-program.form'
    });

    return {
      ...mutationResponse,
      messages
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/account/affiliate-program': AffiliateProgram;
  }
}
