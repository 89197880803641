// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Services
import Account from 'airthings/services/airthings/account';

// Types
import {AccountUserStruct, PremiumFeatureSlug} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
  slug: PremiumFeatureSlug;
}

export default class AccountPremiumFeatureNotice extends Component<Args> {
  @service('airthings/account')
  account: Account;

  get shouldDisplayNotice() {
    if (!this.args.currentUser.premiumFeatureNotices) return false;

    return this.args.currentUser.premiumFeatureNotices.some((notice) => {
      return notice.slug === this.args.slug;
    });
  }

  @dropTask
  *dismissPremiumFeatureNoticeTask(): TaskGenerator<void> {
    const slugToDismiss = this.args.slug;

    const result = yield this.account.dismissPremiumFeatureSlug(slugToDismiss);

    return result;
  }
}
