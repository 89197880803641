export default {
  "font-bold": "700",
  "block": "_block_12cfq9 _light-box-wrapper_vbfmil",
  "content": "_content_12cfq9 _light-box-content_vbfmil",
  "header": "_header_12cfq9 _light-box-header_vbfmil",
  "with-block-definition": "_with-block-definition_12cfq9",
  "icon": "_icon_12cfq9 _header-icon_vbfmil",
  "title": "_title_12cfq9 _light-box-header-title_vbfmil",
  "two-column-table": "_two-column-table_12cfq9 _two-column-table_vbfmil"
};
