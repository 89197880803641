// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import {OnboardingSectionSlug} from 'airthings/types/account';

export default class AppSettingsDatasetSettingsController extends Controller {
  @service('airthings/account')
  account: Account;

  onboardingSectionSlugs = OnboardingSectionSlug;
}
