// Vendor
import Component from '@glimmer/component';
import EmberComponent from '@ember/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';

// Types
import {
  ReportsBlockStruct,
  ReportsBlockDefinition
} from 'airthings/types/reports';
import Reports from 'airthings/services/airthings/reports';

interface Args {
  block: ReportsBlockStruct;
  dragHandleComponent: EmberComponent;
  onEdit: (block: ReportsBlockStruct) => void;
  onDelete: (block: ReportsBlockStruct) => void;
}

export default class ReportsConfiguredBlockListItem extends Component<Args> {
  @service('airthings/reports')
  reports: Reports;

  blockDefinition: ReportsBlockDefinition;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.blockDefinition = this.reports.getTemplateBlockDefinition(
      this.args.block.key
    );
  }

  get titlePreview() {
    if (!this.blockDefinition.titlePreview) return;

    return this.blockDefinition.titlePreview(
      this.args.block.templateSettingsStruct
    );
  }

  @action
  handleEdit() {
    this.args.onEdit(this.args.block);
  }

  @action
  handleDelete() {
    this.args.onDelete(this.args.block);
  }
}
