export default {
  "page-wrapper": "_page-wrapper_1jn8wt",
  "page": "_page_1jn8wt",
  "block": "_block_1jn8wt _light-box-wrapper_vbfmil",
  "content": "_content_1jn8wt _light-box-content_vbfmil",
  "header": "_header_1jn8wt _light-box-header_vbfmil",
  "two-column-table": "_two-column-table_1jn8wt _two-column-table_vbfmil",
  "charts-block": "_charts-block_1jn8wt",
  "charts": "_charts_1jn8wt"
};
