// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';
import GraphQL from 'airthings/services/airthings/graphql';
import {UserRole} from 'airthings/types/account';

// GraphQL
import adminAccountPaginatedCompanies, {
  AdminAccountPaginatedCompaniesResponse,
  AdminAccountPaginatedCompaniesVariables
} from 'airthings/graphql/queries/admin-account-paginated-companies';
import adminAccountPaginatedDevices, {
  AdminAccountPaginatedDevicesVariables
} from 'airthings/graphql/queries/admin-account-paginated-devices';
import adminAccountPaginatedUsers, {
  AdminAccountPaginatedUsersVariables
} from 'airthings/graphql/queries/admin-account-paginated-users';
import adminAccountAddAdminUserToCompanyMutation, {
  AdminAccountAddAdminUserToCompanyVariables,
  AdminAccountAddAdminUserToCompanyResponse
} from 'airthings/graphql/mutations/admin-account-add-admin-user-to-company';
import adminAccountRemoveAdminUserFromCompanyMutation, {
  AdminAccountRemoveAdminUserFromCompanyVariables,
  AdminAccountRemoveAdminUserFromCompanyResponse
} from 'airthings/graphql/mutations/admin-account-remove-admin-user-from-company';
import adminAccountMoveUserToCompanyMutation, {
  AdminAccountMoveUserToCompanyVariables,
  AdminAccountMoveUserToCompanyResponse
} from 'airthings/graphql/mutations/admin-account-move-user-to-company';
import adminAccountSubscribeUserToAffiliateProgramManuallyMutation, {
  AdminAccountSubscribeUserToAffiliateProgramManuallyVariables,
  AdminAccountSubscribeUserToAffiliateProgramManuallyResponse
} from 'airthings/graphql/mutations/admin-account-affiliate-program-user-subscription-manually';
import adminAccountDeleteCompanyMutation, {
  AdminAccountDeleteCompanyVariables,
  AdminAccountDeleteCompanyResponse
} from 'airthings/graphql/mutations/admin-account-delete-company';

import adminAccountCurrentUser, {
  AdminAccountCurrentUserResponse
} from 'airthings/graphql/queries/admin-account-current-user';

// Constants
const COMPANIES_PER_PAGE = 20;
const DEVICES_PER_PAGE = 20;
const USERS_PER_PAGE = 20;

export default class API extends Service {
  @service('airthings/graphql')
  graphQL: GraphQL;

  @service('apollo')
  apollo: Apollo;

  async watchPaginatedCompanies(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    const variables: AdminAccountPaginatedCompaniesVariables = {
      searchTerm,
      pagination: {
        page: page || 1,
        pageSize: COMPANIES_PER_PAGE
      }
    };

    return queryManager.watchQuery({
      query: adminAccountPaginatedCompanies,
      variables
    });
  }

  async watchPaginatedUsers(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    const variables: AdminAccountPaginatedUsersVariables = {
      searchTerm,
      pagination: {
        page: page || 1,
        pageSize: USERS_PER_PAGE
      }
    };

    return queryManager.watchQuery({
      query: adminAccountPaginatedUsers,
      variables
    });
  }

  async watchPaginatedDevices(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    const variables: AdminAccountPaginatedDevicesVariables = {
      searchTerm,
      pagination: {
        page: page || 1,
        pageSize: DEVICES_PER_PAGE
      }
    };

    return queryManager.watchQuery({
      query: adminAccountPaginatedDevices,
      variables
    });
  }

  async watchCurrentUser(
    queryManager: Apollo
  ): Promise<AdminAccountCurrentUserResponse> {
    return queryManager.watchQuery({query: adminAccountCurrentUser});
  }

  async logAs(companyId: string, role: UserRole) {
    type ReturnType =
      AdminAccountAddAdminUserToCompanyResponse['addAdminUserToCompany'];

    const variables: AdminAccountAddAdminUserToCompanyVariables = {
      companyId,
      role
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminAccountAddAdminUserToCompanyResponse =
        await this.apollo.mutate({
          mutation: adminAccountAddAdminUserToCompanyMutation,
          variables
        });

      return response.addAdminUserToCompany;
    });
  }

  async fetchCompanies(searchTerm?: string) {
    const variables: AdminAccountPaginatedCompaniesVariables = {
      searchTerm,
      pagination: {
        page: 1,
        pageSize: COMPANIES_PER_PAGE
      }
    };

    const response: AdminAccountPaginatedCompaniesResponse =
      await this.apollo.query({
        query: adminAccountPaginatedCompanies,
        variables
      });

    return response;
  }

  async logoutFrom(companyId: string) {
    type ReturnType =
      AdminAccountRemoveAdminUserFromCompanyResponse['removeAdminUserFromCompany'];

    const variables: AdminAccountRemoveAdminUserFromCompanyVariables = {
      companyId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminAccountRemoveAdminUserFromCompanyResponse =
        await this.apollo.mutate({
          mutation: adminAccountRemoveAdminUserFromCompanyMutation,
          variables
        });

      return response.removeAdminUserFromCompany;
    });
  }

  async moveUser(userId: string, companyId: string, role: UserRole) {
    type ReturnType =
      AdminAccountMoveUserToCompanyResponse['moveUserToCompany'];

    const variables: AdminAccountMoveUserToCompanyVariables = {
      userId,
      companyId,
      role
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminAccountMoveUserToCompanyResponse =
        await this.apollo.mutate({
          mutation: adminAccountMoveUserToCompanyMutation,
          variables
        });

      return response.moveUserToCompany;
    });
  }

  async assignAffiliateProgramCouponCodes(
    userId: string,
    proCouponCode: string,
    reportCouponCode: string
  ) {
    type ReturnType =
      AdminAccountSubscribeUserToAffiliateProgramManuallyResponse['subscribeUserToAffiliateProgramManually'];

    const variables: AdminAccountSubscribeUserToAffiliateProgramManuallyVariables =
      {
        userId,
        proCouponCode,
        reportCouponCode
      };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminAccountSubscribeUserToAffiliateProgramManuallyResponse =
        await this.apollo.mutate({
          mutation: adminAccountSubscribeUserToAffiliateProgramManuallyMutation,
          variables
        });

      return response.subscribeUserToAffiliateProgramManually;
    });
  }

  async deleteCompany(companyId: string) {
    type ReturnType = AdminAccountDeleteCompanyResponse['deleteCompany'];

    const variables: AdminAccountDeleteCompanyVariables = {
      companyId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminAccountDeleteCompanyResponse =
        await this.apollo.mutate({
          mutation: adminAccountDeleteCompanyMutation,
          variables
        });

      return response.deleteCompany;
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/account/api': API;
  }
}
