// Vendor
import Service from '@ember/service';
import {inject as service} from '@ember/service';
import {dasherize} from '@ember/string';

// Types
import IntlService from 'ember-intl/services/intl';
import {
  MutationResponse,
  MutationResponseError
} from 'airthings/services/airthings/graphql';

export interface Validator<FieldType, CodeType> {
  field: FieldType;
  isValid: boolean;
  code: CodeType;
}

export interface ValidationErrors {
  [field: string]: string | null;
}

interface Params {
  translationKeyPrefix: string;
}

export default class Form extends Service {
  @service('intl')
  intl: IntlService;

  validate(
    validators: Array<Validator<string, string>>,
    params: Params
  ): ValidationErrors | undefined {
    const erroredValidators = validators.filter(({isValid}) => !isValid);

    if (!erroredValidators.length) return;

    return erroredValidators.reduce(
      (errors: ValidationErrors, validator: Validator<string, string>) => {
        const errorMessage = this.translateError(
          params.translationKeyPrefix,
          dasherize(validator.field),
          validator.code
        );

        if (errorMessage) errors[validator.field] = errorMessage;

        return errors;
      },
      {}
    );
  }

  validateMutationResponse(
    response: MutationResponse,
    params: Params
  ): ValidationErrors | undefined {
    if (response.successful || response.messages.length === 0) return;

    return response.messages.reduce(
      (errors: ValidationErrors, error: MutationResponseError) => {
        const field = error.field ? dasherize(error.field) : 'generic';

        const errorMessage = this.translateError(
          params.translationKeyPrefix,
          field,
          error.code
        );

        if (errorMessage) errors[field] = errorMessage;

        return errors;
      },
      {}
    );
  }

  private translateError(
    translationKeyPrefix: string,
    field: string,
    code: string
  ): string | null {
    const sanitizedField = field.replace(/\.\d+\./g, '.');

    const translationKey = [
      translationKeyPrefix,
      sanitizedField,
      'errors',
      code
    ].join('.');

    if (!this.intl.exists(translationKey, this.intl.primaryLocale)) return null;

    return this.intl.t(translationKey);
  }
}

declare module '@ember/service' {
  interface Registry {
    form: Form;
  }
}
