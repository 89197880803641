// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {bool} from '@ember/object/computed';

export interface FormSelectOption {
  value: string | null;
  label: string;
  disabled?: boolean;
}

interface Args {
  label: string;
  disabled?: boolean;
  error?: string;
  onChange: (changedValue: string) => void;
  didInsert?: (inputElement: HTMLInputElement) => void;
  onKeydown?: (event: KeyboardEvent) => void;
  willDestroy?: () => void;
  required?: boolean;
  value: string;
  inline?: boolean;
  nullOptionLabel?: string;
  options?: Array<FormSelectOption | string>;
}

export default class FormSelect extends Component<Args> {
  @bool('args.error')
  isErrored: boolean;

  get options() {
    if (!this.args.options) return [];

    return this.args.options.map((option) => {
      if (typeof option !== 'string') return option;
      return {value: option, label: option};
    });
  }

  get hasValue() {
    return (
      this.args.value || (this.args.nullOptionLabel && this.args.value === null)
    );
  }

  get disabled() {
    if (typeof this.args.onChange !== 'function') return true;

    return this.args.disabled;
  }

  get inline() {
    return this.args.inline || false;
  }

  @action
  handleInput(event: {target: {value: any}}) {
    if (typeof this.args.onChange !== 'function') return;

    const selectedValue = event.target.value;
    this.args.onChange(selectedValue === '' ? null : selectedValue);
  }

  @action
  handleDidInsert(inputElement: HTMLInputElement): void {
    if (typeof this.args.didInsert !== 'function') return;

    this.args.didInsert(inputElement);
  }

  @action
  handleWillDestroy(): void {
    if (typeof this.args.willDestroy !== 'function') return;

    this.args.willDestroy();
  }
}
