// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Config
import config from 'airthings/config/environment';

// Types
import {AccountOnboardingStep} from 'airthings/types/account';

interface Args {
  onDismiss: (reminderDelayInDays?: number) => void;
  onboardingStep: AccountOnboardingStep;
}

export default class AccountOnboardingModalStepsWelcome extends Component<Args> {
  trainingUrl = config.APP.trainingUrl;

  @action
  handleDismiss() {
    this.args.onDismiss();
  }
}
