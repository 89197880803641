// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import {AccountCompanyStruct} from 'airthings/types/account';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

export default class AppCompanyEditRoute extends Route {
  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  model() {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    const company = this.modelFor('app.company');

    return {
      company,
      currentUser
    };
  }

  afterModel(model: AccountCompanyStruct | null, transition: Transition) {
    if (model) return;

    transition.abort();
    this.router.replaceWith('app');
  }
}
