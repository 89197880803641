// Vendor
import Component from '@glimmer/component';

interface Args {
  searchTerm?: string;
  searchPlaceholder?: string;
  onSearch: () => void;
  onClearSearch: () => void;
}

export default class SearchBar extends Component<Args> {}
