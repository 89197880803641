export default {
  "font-bold": "700",
  "block": "_block_7afs2 _white-box_vbfmil",
  "title": "_title_7afs2 _white-box-title_vbfmil",
  "headline": "_headline_7afs2",
  "box": "_box_7afs2",
  "box-line": "_box-line_7afs2",
  "box-line-signature": "_box-line-signature_7afs2",
  "box-details": "_box-details_7afs2"
};
