// Vendor
import Component from '@glimmer/component';
import {dasherize} from '@ember/string';
import {inject as service} from '@ember/service';

// Types
import Reports from 'airthings/services/airthings/reports';

interface Args {
  block: {
    id: string;
    blockType: string;
    reportData: any;
  };
}

export default class ReportsBlock extends Component<Args> {
  @service('airthings/reports')
  reports: Reports;

  get blockDefinition() {
    return this.reports.getTemplateBlockDefinition(
      dasherize(this.args.block.blockType)
    );
  }

  get blockComponent() {
    return `reports/blocks/${dasherize(this.args.block.blockType)}/output`;
  }
}
