// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import API from 'airthings/services/airthings/admin/account/api';
import Apollo from 'airthings/services/apollo';
import {UserRole} from 'airthings/types/account';
import {AdminAccountUserStruct} from 'airthings/types/admin/account';
import {AdminAccountCurrentUserResponse} from 'airthings/graphql/queries/admin-account-current-user';

export default class AdminAccountUser extends Service {
  @service('airthings/admin/account/api')
  api: API;

  // eslint-disable-next-line complexity
  struct(
    base?: RecursivePartial<AdminAccountUserStruct>
  ): AdminAccountUserStruct {
    return {
      id: base?.id || null,
      companyId: base?.companyId || null,
      unreadMessagesCount: 0,
      hasUnreadMessagesNotice: false,
      information: {
        name: '',
        ...base?.information
      },
      accessRights: {
        logAs: base?.accessRights?.logAs || false,
        messages: base?.accessRights?.messages || false,
        affiliateProgram: base?.accessRights?.affiliateProgram || false,
        calibrationCertificates:
          base?.accessRights?.calibrationCertificates || false
      }
    };
  }

  async logAs(companyId: string, role: UserRole) {
    return this.api.logAs(companyId, role);
  }

  async logoutFrom(companyId: string) {
    return this.api.logoutFrom(companyId);
  }

  async moveUser(userId: string, companyId: string, role: UserRole) {
    return this.api.moveUser(userId, companyId, role);
  }

  async assignAffiliateProgramCouponCodes(
    userId: string,
    proCouponCode: string,
    reportCouponCode: string
  ) {
    return this.api.assignAffiliateProgramCouponCodes(
      userId,
      proCouponCode,
      reportCouponCode
    );
  }

  async watchCurrentUser(queryManager: Apollo) {
    return this.api.watchCurrentUser(queryManager);
  }

  presentCurrentUser(
    response: AdminAccountCurrentUserResponse
  ): AdminAccountUserStruct {
    return this.struct({
      id: response.viewer.id,
      companyId: response.viewer.company?.id,
      information: {
        name: response.viewer.profile.name
      },
      accessRights: response.admin.accessRights
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/account/user': AdminAccountUser;
  }
}
