// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Services
import Recordings from 'airthings/services/airthings/recordings';

interface RouteParams {
  uuid: string;
}

export default class DeviceCrosschecksRoute extends Route {
  @service('airthings/recordings')
  recordings: Recordings;

  async model(params: RouteParams) {
    return this.recordings.fetchDeviceCrosschecks(params.uuid);
  }
}
