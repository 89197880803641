export default {
  "font-bold": "700",
  "link": "_link_lr7lw0",
  "errors": "_errors_lr7lw0",
  "table-row": "_table-row_lr7lw0",
  "is-favorite": "_is-favorite_lr7lw0",
  "favorite-badge": "_favorite-badge_lr7lw0",
  "template-favorite": "_template-favorite_lr7lw0",
  "delete-confirmation": "_delete-confirmation_lr7lw0",
  "delete-explanation": "_delete-explanation_lr7lw0",
  "delete-report-template": "_delete-report-template_lr7lw0",
  "delete-actions": "_delete-actions_lr7lw0",
  "delete-action": "_delete-action_lr7lw0",
  "edit-actions": "_edit-actions_lr7lw0",
  "edit-action": "_edit-action_lr7lw0",
  "create-actions": "_create-actions_lr7lw0",
  "create-action": "_create-action_lr7lw0"
};
