// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import AdminAffiliateProgram from 'airthings/services/airthings/admin/affiliate-program';
import {AdminAffiliateProgramAdStruct} from 'airthings/types/admin/affiliate-program';
import {AdminAffiliateProgramCreateAdResponse} from 'airthings/graphql/mutations/admin-affiliate-program-create-ad';

export default class PageAdminAffiliateProgramAdsNew extends Component {
  @service('airthings/admin/affiliate-program')
  adminAffiliateProgram: AdminAffiliateProgram;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @tracked
  ad: AdminAffiliateProgramAdStruct = {
    id: null,
    name: '',
    imageUrl: ''
  };

  @dropTask
  *adCreateTask(): TaskGenerator<void> {
    return yield this.adminAffiliateProgram.createAd(this.ad);
  }

  @action
  handleChange(updatedAd: AdminAffiliateProgramAdStruct) {
    this.ad = updatedAd;
  }

  @action
  validate(): object | undefined {
    return this.adminAffiliateProgram.validateAd(this.ad);
  }

  @action
  handleSuccess(
    result: AdminAffiliateProgramCreateAdResponse['createAffiliateProgramAd']
  ) {
    this.flashMessages.success(
      this.intl.t('admin-affiliate-program-ads-new.form.success')
    );

    this.router.transitionTo(
      'admin.affiliate-program-ads.edit',
      result.result.id
    );
  }
}
