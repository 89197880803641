// Vendor
import Component from '@glimmer/component';
import {dropTask} from 'ember-concurrency-decorators';
import {inject as service} from '@ember/service';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {AccountUserStruct} from 'airthings/types/account';
import AdminAccount from 'airthings/services/airthings/admin/account';

interface Args {
  currentUser: AccountUserStruct;
}

export default class AdminTopBanner extends Component<Args> {
  @service('airthings/admin/account')
  adminAccount: AdminAccount;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @dropTask
  *logoutTask(): TaskGenerator<void> {
    if (!this.args.currentUser.companyId) return;

    const {successful} = yield this.adminAccount.logoutFrom(
      this.args.currentUser.companyId
    );

    if (successful) {
      window.location.reload();
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }
}
