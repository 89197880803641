// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';
import GraphQL from 'airthings/services/airthings/graphql';
import {AdminAffiliateProgramAdStruct} from 'airthings/types/admin/affiliate-program';

// GraphQL
import adminAffiliateProgramAds from 'airthings/graphql/queries/admin-affiliate-program-ads';
import adminAffiliateProgramEditAdById, {
  AdminAffiliateProgramEditAdByIdVariables
} from 'airthings/graphql/queries/admin-affiliate-program-edit-ad-by-id';
import adminAffiliateProgramDeleteAdMutation, {
  AdminAffiliateProgramDeleteAdResponse,
  AdminAffiliateProgramDeleteAdVariables
} from 'airthings/graphql/mutations/admin-affiliate-program-delete-ad';
import adminAffiliateProgramCreateAdMutation, {
  AdminAffiliateProgramCreateAdResponse,
  AdminAffiliateProgramCreateAdVariables
} from 'airthings/graphql/mutations/admin-affiliate-program-create-ad';
import adminAffiliateProgramUpdateAdMutation, {
  AdminAffiliateProgramUpdateAdResponse,
  AdminAffiliateProgramUpdateAdVariables
} from 'airthings/graphql/mutations/admin-affiliate-program-update-ad';

export default class API extends Service {
  @service('airthings/graphql')
  graphQL: GraphQL;

  @service('apollo')
  apollo: Apollo;

  async watchAds(queryManager: Apollo) {
    return queryManager.watchQuery({
      query: adminAffiliateProgramAds
    });
  }

  async watchEditAdById(queryManager: Apollo, affiliateProgramAdId: string) {
    const variables: AdminAffiliateProgramEditAdByIdVariables = {
      affiliateProgramAdId
    };

    return queryManager.watchQuery({
      query: adminAffiliateProgramEditAdById,
      variables,
      fetchPolicy: 'no-cache'
    });
  }

  async deleteAd(affiliateProgramAdId: string) {
    type ReturnType =
      AdminAffiliateProgramDeleteAdResponse['deleteAffiliateProgramAd'];

    const variables: AdminAffiliateProgramDeleteAdVariables = {
      affiliateProgramAdId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminAffiliateProgramDeleteAdResponse =
        await this.apollo.mutate({
          mutation: adminAffiliateProgramDeleteAdMutation,
          variables,
          refetchQueries: ['AdminAffiliateProgramAds']
        });

      return response.deleteAffiliateProgramAd;
    });
  }

  async createAd(ad: AdminAffiliateProgramAdStruct) {
    type ReturnType =
      AdminAffiliateProgramCreateAdResponse['createAffiliateProgramAd'];

    const variables: AdminAffiliateProgramCreateAdVariables = {
      name: ad.name,
      imageUrl: ad.imageUrl
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminAffiliateProgramCreateAdResponse =
        await this.apollo.mutate({
          mutation: adminAffiliateProgramCreateAdMutation,
          variables,
          refetchQueries: ['AdminAffiliateProgramAds']
        });

      return response.createAffiliateProgramAd;
    });
  }

  async updateAd(ad: AdminAffiliateProgramAdStruct) {
    if (!ad.id) return;

    type ReturnType =
      AdminAffiliateProgramUpdateAdResponse['updateAffiliateProgramAd'];

    const variables: AdminAffiliateProgramUpdateAdVariables = {
      affiliateProgramAdId: ad.id,
      name: ad.name,
      imageUrl: ad.imageUrl
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminAffiliateProgramUpdateAdResponse =
        await this.apollo.mutate({
          mutation: adminAffiliateProgramUpdateAdMutation,
          variables
        });

      return response.updateAffiliateProgramAd;
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/affiliate-program/api': API;
  }
}
