// Vendor
import {Ability} from 'ember-can';

// Types
import {AccountUserStruct, UserRole} from 'airthings/types/account';
import {ReportsTemplateStruct} from 'airthings/types/reports';

export default class TemplateAbility extends Ability {
  currentUser: AccountUserStruct;
  reportTemplate: ReportsTemplateStruct;

  get canList() {
    return true;
  }

  get canCreate() {
    return true;
  }

  get canEdit() {
    return true;
  }

  get canConfigure() {
    return true;
  }

  get canDuplicate() {
    return true;
  }

  get canDelete() {
    return true;
  }

  get canPublish() {
    return true;
  }

  get canFavoriteForCompany() {
    return (
      !this.reportTemplate.isCompanyFavorite &&
      this.currentUser.role === UserRole.MANAGER &&
      this.reportTemplate.reportTemplatePublication
    );
  }
}
