// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action, computed} from '@ember/object';
import {alias, bool} from '@ember/object/computed';
import {tracked} from '@glimmer/tracking';
import moment from 'moment';

// Services
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';
import Weather from 'airthings/services/airthings/weather';

// Types
import {
  RecordingsDetailedDatasetStruct,
  RecordingsSamplesMetadata,
  RecordingsSampleStruct,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';
import {
  WeatherSampleStruct,
  WeatherSampleUnitType,
  WeatherSuccessMetadataStruct,
  WeatherWindPoint
} from 'airthings/types/weather';

interface Args {
  dataset: RecordingsDetailedDatasetStruct;
}

const DAILY_INTERVAL_THRESHOLD_IN_SECONDS = 2592000; // 30 days
const TIMESTAMP_FORMAT = 'YYYY-MM-DD, h:mm a';

export default class WeatherReport extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @service('airthings/weather')
  weather: Weather;

  @tracked
  showWeatherReportModal: Boolean = false;

  @bool('args.dataset.weatherData.metadata.error')
  hasWeatherError: boolean;

  @alias('args.dataset.weatherData.entries')
  weatherSamples: WeatherSampleStruct[];

  @alias('args.dataset.weatherData.metadata')
  weatherMetadata: WeatherSuccessMetadataStruct;

  @computed('weatherMetadata.location', function () {
    const {name, region, country} = this.weatherMetadata.location;

    return [name, region, country].filter((token) => token).join(', ');
  })
  location: string;

  @computed('weatherSamples[]', function () {
    return this.weatherSamples.map(
      (weatherSample: WeatherSampleStruct) => new Date(weatherSample.timestamp)
    );
  })
  dates: Date[];

  @computed('dates[]', function () {
    const date = Math.min.apply(null, this.dates);

    return moment(date).format(TIMESTAMP_FORMAT);
  })
  periodFrom: Date;

  @computed('dates[]', function () {
    const date = Math.max.apply(null, this.dates);

    return moment(date).format(TIMESTAMP_FORMAT);
  })
  periodTo: Date;

  @computed('periodFrom', 'periodTo', function () {
    return this.intl.t('weather.information.period', {
      from: this.periodFrom,
      to: this.periodTo
    });
  })
  period: string;

  // Makes sure the radon samples
  // - have the same count as weather samples
  // - are hourly or daily, base on dataset duration
  @computed(
    'args.dataset.hourlySamples.entries[]',
    'weatherSamples[]',
    function () {
      const weatherLength = this.weatherSamples.length;
      const paddingValues = [];

      const entries =
        this.args.dataset.durationInSeconds <
        DAILY_INTERVAL_THRESHOLD_IN_SECONDS
          ? this.args.dataset.hourlySamples.entries
          : this.recordings.dailyRadonAverage(
              this.args.dataset.hourlySamples.entries
            );

      for (let index = 0; index < weatherLength - entries.length; index++) {
        paddingValues.push({
          recordedAt: this.periodFrom,
          radonShortTermAverage: null
        });
      }

      return [...paddingValues, ...entries];
    }
  )
  radonSamples: RecordingsSampleStruct[];

  @alias('args.dataset.hourlySamples.metadata')
  radonMetadata: RecordingsSamplesMetadata;

  radonSampleType = RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE;
  get radonSampleUnit() {
    return this.radonMetadata.radonUnit;
  }

  temperatureSampleType = WeatherSampleUnitType.TEMPERATURE;
  get temperatureSampleUnit() {
    return this.weatherMetadata.temperatureUnit;
  }

  pressureSampleType = WeatherSampleUnitType.PRESSURE;
  get pressureSampleUnit() {
    return this.weatherMetadata.pressureUnit;
  }

  windSpeedSampleType = WeatherSampleUnitType.WIND_SPEED;
  get windSpeedSampleUnit() {
    return this.weatherMetadata.windSpeedUnit;
  }
  get windSpeedSampleUnitLabel() {
    return this.weather.sampleUnitLabel(this.windSpeedSampleUnit);
  }

  precipitationsSampleType = WeatherSampleUnitType.PRECIPITATIONS;
  get precipitationsSampleUnit() {
    return this.weatherMetadata.precipitationsUnit;
  }

  @action
  windSpeedPointFormatter(point: WeatherWindPoint) {
    return `${point.y} ${this.windSpeedSampleUnitLabel} ${point.windDirection}`;
  }
}
