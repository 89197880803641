export default {
  "font-bold": "700",
  "container": "_container_a2xued",
  "error": "_error_a2xued",
  "badge": "_badge_a2xued",
  "icon": "_icon_a2xued",
  "message": "_message_a2xued",
  "actions": "_actions_a2xued",
  "action": "_action_a2xued"
};
