// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {dasherize} from '@ember/string';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Services
import Account from 'airthings/services/airthings/account';
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import RouterService from '@ember/routing/router-service';
import StateManager from 'airthings/services/state-manager';

// Types
import {
  AccountUserStruct,
  OnboardingSectionSlug
} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
  sectionSlug: OnboardingSectionSlug;
  isHelp: boolean;
}

export default class AccountOnboardingModal extends Component<Args> {
  @service('airthings/account')
  account: Account;

  @service('fastboot')
  fastboot: FastBoot;

  @service('router')
  router: RouterService;

  @service('state-manager')
  stateManager: StateManager;

  get onboardingSteps() {
    if (!this.args.currentUser.onboardingSteps) return [];

    return this.args.currentUser.onboardingSteps.filter((step) => {
      return (
        step.sectionSlug === null || step.sectionSlug === this.args.sectionSlug
      );
    });
  }

  get isOnboarding() {
    return this.onboardingStepsCount > 0;
  }

  get onboardingStepsCount() {
    return this.onboardingSteps.length;
  }

  get currentOnboardingStep() {
    return this.onboardingSteps[0];
  }

  get currentOnboardingModalStepComponent() {
    if (!this.currentOnboardingStep) return;

    const componentName = dasherize(
      this.currentOnboardingStep.slug.toLowerCase()
    );

    return `account/onboarding-modal/steps/${componentName}`;
  }

  @action
  openUserSidebar() {
    this.stateManager.openUserSidebar();
  }

  @dropTask
  *dismissOnboardingStepTask(
    reminderDelayInDays?: number
  ): TaskGenerator<void> {
    const slugToDismiss = this.currentOnboardingStep.slug;

    const result = yield this.account.dismissOnboardingStepSlug(
      slugToDismiss,
      reminderDelayInDays || null
    );

    if (this.currentOnboardingStep) {
      // @ts-ignore
      this.router.replaceWith({
        queryParams: {help: this.currentOnboardingStep.id}
      });
    }

    return result;
  }
}
