export default {
  "row": "_row_1krz15",
  "column": "_column_1krz15",
  "solo-column": "_solo-column_1krz15",
  "wrapper": "_wrapper_1krz15",
  "input": "_input_1krz15",
  "edit-button": "_edit-button_1krz15",
  "is-editing": "_is-editing_1krz15",
  "helper": "_helper_1krz15"
};
