// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

// Types
import {RecordingsSampleUnitType} from 'airthings/types/recordings';
import Recordings from 'airthings/services/airthings/recordings';

interface Args {
  value: number;
  sampleUnitType: RecordingsSampleUnitType;
}

export default class Measurement extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  get formattedValue() {
    return this.recordings.formatSampleUnit(
      this.args.value,
      this.args.sampleUnitType
    );
  }
}
