export default {
  "font-medium": "500",
  "font-demi-bold": "600",
  "font-bold": "700",
  "block": "_block_7krgix _gray-box_vbfmil",
  "header": "_header_7krgix _dark-box-header_vbfmil",
  "title": "_title_7krgix _dark-box-header-title_vbfmil",
  "row": "_row_7krgix",
  "column": "_column_7krgix",
  "label-column": "_label-column_7krgix",
  "value": "_value_7krgix",
  "label": "_label_7krgix",
  "row-label": "_row-label_7krgix _pill-label_vbfmil",
  "radon-level-label": "_radon-level-label_7krgix",
  "address-label": "_address-label_7krgix",
  "duration-label": "_duration-label_7krgix",
  "other-info-label": "_other-info-label_7krgix"
};
