// Vendor
import Component from '@glimmer/component';

// Types
import {
  RecordingsDatasetCustomerMetadata,
  RecordingsDatasetOwnerMetadata
} from 'airthings/types/recordings';

interface Args {
  customerMetadata: RecordingsDatasetCustomerMetadata;
  ownerMetadata: RecordingsDatasetOwnerMetadata;
}

export default class RecordingsCustomerAndPropertyOwner extends Component<Args> {
  get shouldDisplayBlock() {
    return (
      this.shouldDisplayCustomerInformation ||
      this.shouldDisplayOwnerInformation
    );
  }

  get shouldDisplayCustomerInformation() {
    return this.hasInformation(this.args.customerMetadata);
  }

  get shouldDisplayOwnerInformation() {
    return this.hasInformation(this.args.ownerMetadata);
  }

  private hasInformation(metadata: object) {
    return (
      metadata &&
      Object.entries(metadata).some(([key, value]) => {
        if (key === '__typename') return false;
        return !!value;
      })
    );
  }

  get customerFormattedAddress() {
    return this.formatAddress(this.args.customerMetadata);
  }

  get ownerFormattedAddress() {
    return this.formatAddress(this.args.ownerMetadata);
  }

  private formatAddress(
    metadata: RecordingsDatasetCustomerMetadata | RecordingsDatasetOwnerMetadata
  ) {
    return [
      metadata.address,
      metadata.city,
      [metadata.state, metadata.postalCode].filter((value) => value).join(' '),
      metadata.country
    ]
      .filter((value) => value)
      .join(', ');
  }
}
