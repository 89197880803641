export default {
  "font-bold": "700",
  "font-medium": "500",
  "note": "_note_18pt67",
  "columns": "_columns_18pt67",
  "column": "_column_18pt67",
  "cell": "_cell_18pt67",
  "small": "_small_18pt67",
  "value": "_value_18pt67",
  "timestamp-column": "_timestamp-column_18pt67",
  "with-value": "_with-value_18pt67",
  "row-number": "_row-number_18pt67",
  "is-empty": "_is-empty_18pt67",
  "column-header": "_column-header_18pt67",
  "is-datetime": "_is-datetime_18pt67",
  "with-unit": "_with-unit_18pt67"
};
