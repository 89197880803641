export default {
  "font-bold": "700",
  "container": "_container_1txy37",
  "logo": "_logo_1txy37",
  "title": "_title_1txy37",
  "body": "_body_1txy37",
  "actions": "_actions_1txy37",
  "action": "_action_1txy37",
  "affiliate-link": "_affiliate-link_1txy37 _button_znm4p3 _is-gray_znm4p3"
};
