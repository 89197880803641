export default {
  "font-bold": "700",
  "container": "_container_1mx3cg",
  "error": "_error_1mx3cg",
  "badge": "_badge_1mx3cg",
  "icon": "_icon_1mx3cg",
  "message": "_message_1mx3cg",
  "actions": "_actions_1mx3cg",
  "action": "_action_1mx3cg"
};
