// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {RecordingsDeviceStruct} from 'airthings/types/recordings';

interface Args {
  formData: RecordingsDeviceStruct;
  onChange: (updatedFormData: RecordingsDeviceStruct) => void;
  errors?: object;
}

export default class EditForm extends Component<Args> {
  @action
  changeName(updateName: string) {
    this.args.onChange({
      ...this.args.formData,
      name: updateName
    });
  }
}
