// Vendor
import Service, {inject as service} from '@ember/service';

// Services
import API from 'airthings/services/airthings/recordings/api';
import RecordingJob from 'airthings/services/airthings/recordings/job';

// Types
import {
  WeatherChartPoint,
  WeatherSampleStruct,
  WeatherSampleUnit,
  WeatherSampleUnitType
} from 'airthings/types/weather';
import IntlService from 'ember-intl/services/intl';

// Constants
const UNIT_LABELS_MAPPING = {
  CELSIUS: 'weather.unit-labels.celsius',
  FAHRENHEIT: 'weather.unit-labels.fahrenheit',
  KILOPASCAL: 'weather.unit-labels.kilopascal',
  INCHES_OF_MERCURY: 'weather.unit-labels.inches-of-mercury',
  KILOMETERS_PER_HOUR: 'weather.unit-labels.kilometers-per-hour',
  MILES_PER_HOUR: 'weather.unit-labels.miles-per-hour',
  MILLIMETERS: 'weather.unit-labels.millimeters',
  INCHES: 'weather.unit-labels.inches'
};

const DECIMALS_COUNT_BY_SAMPLE_UNIT_TYPE = {
  [WeatherSampleUnitType.TEMPERATURE]: 0,
  [WeatherSampleUnitType.PRESSURE]: 1,
  [WeatherSampleUnitType.WIND_SPEED]: 0,
  [WeatherSampleUnitType.PRECIPITATIONS]: 2
};

export default class WeatherSample extends Service {
  @service('airthings/recordings/api')
  api: API;

  @service('intl')
  intl: IntlService;

  @service('airthings/recordings/job')
  job: RecordingJob;

  sampleToChartPoint(
    sample: WeatherSampleStruct,
    unitType: WeatherSampleUnitType
  ): WeatherChartPoint {
    const timeValue = new Date(sample.timestamp).getTime();

    switch (unitType) {
      case WeatherSampleUnitType.TEMPERATURE:
        return [timeValue, sample.temperature];
      case WeatherSampleUnitType.PRECIPITATIONS:
        return [timeValue, sample.precipitations];
      case WeatherSampleUnitType.PRESSURE:
        return [timeValue, sample.pressure];
      case WeatherSampleUnitType.WIND_SPEED:
        return {
          x: timeValue,
          y: sample.windSpeed,
          windDirection: sample.windDirection
        };
    }
  }

  sampleUnitLabel(unit: WeatherSampleUnit) {
    const translationKey = UNIT_LABELS_MAPPING[unit];
    if (!translationKey) return null;

    return this.intl.t(translationKey);
  }

  decimalCountForSampleUnitType(unitType: WeatherSampleUnitType) {
    return DECIMALS_COUNT_BY_SAMPLE_UNIT_TYPE[unitType];
  }

  formatSampleUnit(value: number, unitType: WeatherSampleUnitType) {
    return Intl.NumberFormat('en-us', {
      minimumFractionDigits: DECIMALS_COUNT_BY_SAMPLE_UNIT_TYPE[unitType],
      maximumFractionDigits: DECIMALS_COUNT_BY_SAMPLE_UNIT_TYPE[unitType]
    }).format(value);
  }

  /**
   * @description Starts the dataset weahter report generation on the API
   */
  async fetchDatasetWeatherReport(uuid: string, unitSystem: string) {
    return this.api.fetchDatasetWeatherReport(uuid, unitSystem);
  }

  /**
   * @description Starts the dataset weahter report generation on the API
   */
  async generateDatasetWeatherReport(datasetId: string) {
    return this.api.generateWeatherReport(datasetId);
  }

  /**
   * @description Returns a Promise that resolves with a dataset CSV generation job
   */
  async fetchDatasetWeatherReportGenerationJob(jobId: string) {
    return this.job.fetchDatasetWeatherReportGenerationJob(jobId);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/weather': WeatherSampleStruct;
  }
}
