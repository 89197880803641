export interface GraphqlPagination {
  previousPage: number | null;
  nextPage: number | null;
  currentPage: number;
  totalPages: number;
  totalEntries: number;
}

export interface GraphqlPaginationInput {
  page: number;
  pageSize: number;
}

export enum GraphqlSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
