// Vendor
import Modifier from 'ember-modifier';
import {next} from '@ember/runloop';

type EventWithPath = Event & {path: Node[]};

type Callback = (event: EventWithPath) => void;

export default class ClickOutsideModifier extends Modifier {
  isActive = true;
  callback: Callback;

  didReceiveArguments() {
    if (typeof this.args.positional[0] === 'function') {
      this.callback = this.args.positional[0];
    }

    if (typeof this.args.named.isActive === 'boolean') {
      this.isActive = this.args.named.isActive;
    }

    if (this.isActive) {
      this.setup();
    } else {
      this.teardown();
    }
  }

  willRemove() {
    this.teardown();
  }

  private setup() {
    this.addCursorOnIOS();

    // Make sure the binding is done in the next run-loop so the
    // callback is not fired within the same event that enables it
    next(this, () => {
      document.addEventListener('click', this.clickHandler);
    });
  }

  private teardown() {
    this.removeCursorOnIOS();
    document.removeEventListener('click', this.clickHandler);
  }

  // eslint-disable-next-line complexity
  private clickHandler = (event: EventWithPath) => {
    const path = event.path || event.composedPath?.();

    if (path && !path.includes(this.element as Node)) {
      this.callback(event);

      return;
    }

    // Check if the click target still is in the DOM.
    // If not, there is no way to know if it was inside the element or not.
    const isRemoved =
      !event.target || !this.documentOrBodyContains(event.target);

    // Check the element is found as a parent of the click target.
    const isInside =
      this.element &&
      (this.element === event.target ||
        this.element.contains(event.target as Node));

    if (!isRemoved && !isInside) {
      this.callback(event);
    }
  };

  private get isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  private documentOrBodyContains(element: EventTarget) {
    if (typeof document.contains === 'function') {
      return document.contains(element as Node);
    }

    return document.body.contains(element as Node);
  }

  private addCursorOnIOS() {
    if (!this.isIOS) return;

    document.body.style.cursor = 'pointer';
  }

  private removeCursorOnIOS() {
    if (!this.isIOS) return;

    document.body.style.cursor = '';
  }
}
