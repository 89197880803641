// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {AdminCommunicationBoardMessageStruct} from 'airthings/types/admin/communication-board';
import AdminCommunicationBoard from 'airthings/services/airthings/admin/communication-board';
import {dropTask} from 'ember-concurrency-decorators';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';

interface Args {
  message: AdminCommunicationBoardMessageStruct;
}

export default class PageAdminMessagesEdit extends Component<Args> {
  @service('airthings/admin/communication-board')
  adminCommunicationBoard: AdminCommunicationBoard;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @tracked
  message: AdminCommunicationBoardMessageStruct = this.args.message;

  @dropTask
  *messageUpdateTask(): TaskGenerator<void> {
    return yield this.adminCommunicationBoard.updateMessage(this.message);
  }

  @dropTask
  *publishMessageTask(message: AdminCommunicationBoardMessageStruct) {
    if (!message.id) return;

    // eslint-disable-next-line no-alert
    if (!window.confirm(this.intl.t('admin-messages-index.publish.confirm')))
      return;

    const {successful} = yield this.adminCommunicationBoard.publishMessage(
      message.id
    );

    if (successful) {
      this.flashMessages.success(
        this.intl.t('admin-messages-index.publish.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  handleChange(updatedMessage: AdminCommunicationBoardMessageStruct) {
    this.message = updatedMessage;
  }

  @action
  validate(): object | undefined {
    return this.adminCommunicationBoard.validateUpdateMessage(this.message);
  }

  @action
  handleSuccess() {
    this.flashMessages.success(this.intl.t('admin-messages-edit.form.success'));
  }
}
