// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import RouterService from '@ember/routing/router-service';

export default class LobbyIndex extends Route {
  @service('router')
  router: RouterService;

  redirect() {
    return this.router.replaceWith('lobby.profile');
  }
}
