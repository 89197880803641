// Vendor
import {helper} from '@ember/component/helper';

export const incrementIndex = function ([index, increment]: [
  number,
  number
]): number {
  return index + increment;
};

export default helper(incrementIndex);
