export default {
  "font-bold": "700",
  "block": "_block_1g75mr _light-box-wrapper_vbfmil",
  "weather-block": "_weather-block_1g75mr _light-box-wrapper_vbfmil",
  "weather-report-buttons-block": "_weather-report-buttons-block_1g75mr",
  "content": "_content_1g75mr _light-box-content_vbfmil",
  "header": "_header_1g75mr _light-box-header_vbfmil",
  "icon": "_icon_1g75mr _header-icon_vbfmil",
  "title": "_title_1g75mr _light-box-header-title_vbfmil",
  "two-column-table": "_two-column-table_1g75mr _two-column-table_vbfmil",
  "charts": "_charts_1g75mr",
  "error": "_error_1g75mr"
};
