// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Can from 'ember-can/services/can';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Account from 'airthings/services/airthings/account';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

interface RouteParams {
  userId: string;
}

export default class AppUserManagementEditRoute extends Route {
  @service('can')
  can: Can;

  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  beforeModel(transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    if (
      this.can.cannot('edit user', transition.to.params.userId, {currentUser})
    ) {
      transition.abort();

      this.router.replaceWith('app');
    }

    hideIntercom();
  }

  async model(params: RouteParams, transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    const user = await this.account.fetchUserById(params.userId);

    if (user === null) {
      transition.abort();

      this.router.replaceWith('app');
    }

    return {
      currentUser,
      userId: params.userId,
      user
    };
  }
}
