/* global google */

// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {bool} from '@ember/object/computed';
import {task} from 'ember-concurrency-decorators';
import {timeout} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

// Types
import {Address} from 'airthings/types/account';

// Config
import config from 'airthings/config/environment';

// Constants
import {Keyboard} from 'airthings/constants/keyboard';

interface Args {
  label: string;
  required?: boolean;
  value: Address;
  searchedValue: string;
  onChange: (newAddress: Address) => void;
  errors?: object;
}

export default class FormAddressInput extends Component<Args> {
  autocomplete: google.maps.places.Autocomplete;
  inputElement: HTMLInputElement;
  internalValue: Address | null = null;

  @tracked
  isEditing = this.args.searchedValue === '' || false;

  @tracked
  searchedValue = this.args.searchedValue || '';

  @tracked
  hasInternalError: boolean = false;

  @tracked
  addressValue: string = '';

  @tracked
  cityValue: string = '';

  @tracked
  countryValue: string = '';

  @tracked
  zipCodeValue: string = '';

  @tracked
  stateValue: string = '';

  @bool('searchedValue')
  hasValue: boolean;

  @task
  *focusInputTask() {
    yield timeout(0);

    this.inputElement.focus();
  }

  @action
  setupAutoComplete(inputElement: HTMLInputElement): void {
    this.inputElement = inputElement;

    if (config.environment === 'test') {
      return this.setupTestAutoComplete(inputElement);
    }

    this.autocomplete = new google.maps.places.Autocomplete(inputElement, {});

    this.autocomplete.addListener('place_changed', () => {
      const formAddress = this.parsePlace(this.autocomplete.getPlace());
      if (!formAddress) return;

      this.searchedValue = formAddress.formattedAddress;
      this.internalValue = formAddress;
      this.isEditing = false;
      this.hasInternalError = false;
      this.broadcastAddressToFields(formAddress);

      this.args.onChange(formAddress);
    });
  }

  @action
  toggleIsEditing() {
    if (!this.isEditing) {
      this.reset();

      perform(this.focusInputTask);
    }

    if (this.isEditing && !this.internalValue) {
      this.hasInternalError = true;

      return;
    }

    this.isEditing = !this.isEditing;
  }

  @action
  teardownAutoComplete() {
    if (!this.autocomplete) return;

    this.autocomplete.unbindAll();
  }

  @action
  handleSearchAddressInputChange(newSearchedValue: string) {
    this.searchedValue = newSearchedValue;
  }

  @action
  handleAddressInputChange(newAddressValue: string) {
    this.addressValue = newAddressValue;
    this.onAddressFieldsChange();
  }

  @action
  handleCityInputChange(newCityValue: string) {
    this.cityValue = newCityValue;
    this.onAddressFieldsChange();
  }

  @action
  handleCountryInputChange(newCountryValue: string) {
    this.countryValue = newCountryValue;
    this.onAddressFieldsChange();
  }

  @action
  handleZipCodeInputChange(newZipCodeValue: string) {
    this.zipCodeValue = newZipCodeValue;
    this.onAddressFieldsChange();
  }

  @action
  handleStateInputChange(newStateValue: string) {
    this.stateValue = newStateValue;
    this.onAddressFieldsChange();
  }

  @action
  preventFormSubmission(event: KeyboardEvent) {
    if (event.key !== Keyboard.ENTER) return;

    event.preventDefault();
    return false;
  }

  parsePlace(place: google.maps.places.PlaceResult): Address | null {
    if (!place.address_components || !place.formatted_address) return null;

    const streetNumber = this.findAddressComponent(
      place.address_components,
      'street_number'
    );

    const streetName = this.findAddressComponent(
      place.address_components,
      'route'
    );

    return {
      address: [streetNumber, streetName].join(' '),
      city: this.findAddressComponent(
        place.address_components,
        'locality',
        'postal_town'
      ),
      state: this.findAddressComponent(
        place.address_components,
        'administrative_area_level_1'
      ),
      country: this.findAddressComponent(place.address_components, 'country'),
      zipCode: this.findAddressComponent(
        place.address_components,
        'postal_code'
      ),
      formattedAddress: place.formatted_address
    };
  }

  buildFormattedAddress(): string {
    return [
      this.addressValue,
      this.cityValue,
      [this.stateValue, this.zipCodeValue].join(' '),
      this.countryValue
    ].join(', ');
  }

  private broadcastAddressToFields(address: Address) {
    this.addressValue = address.address;
    this.cityValue = address.city;
    this.countryValue = address.country;
    this.zipCodeValue = address.zipCode;
    this.stateValue = address.state;
  }

  private onAddressFieldsChange() {
    const newAddress = {
      address: this.addressValue,
      city: this.cityValue,
      country: this.countryValue,
      zipCode: this.zipCodeValue,
      state: this.stateValue,
      formattedAddress: this.buildFormattedAddress()
    };

    this.args.onChange(newAddress);
  }

  private findAddressComponent(
    addressComponents: google.maps.GeocoderAddressComponent[],
    ...searchedTypes: string[]
  ): string {
    const addressComponentValue = searchedTypes
      .map((type) => {
        return addressComponents.find(({types}) => {
          return types.includes(type);
        });
      })
      .find(Boolean);

    return addressComponentValue ? addressComponentValue.long_name : '';
  }

  private setupTestAutoComplete(inputElement: HTMLInputElement) {
    inputElement.addEventListener(
      'manual_place_changed',
      (event: CustomEvent<Address>) => {
        this.args.onChange(event.detail);
      }
    );
  }

  private reset() {
    const resetedAddress = {
      address: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      formattedAddress: ''
    };

    this.broadcastAddressToFields(resetedAddress);

    this.searchedValue = '';
    this.internalValue = null;

    this.args.onChange(resetedAddress);
  }
}
