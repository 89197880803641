// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {AdminAffiliateProgramAdStruct} from 'airthings/types/admin/affiliate-program';

interface Args {
  formData: AdminAffiliateProgramAdStruct;
  onChange: (updatedFormData: AdminAffiliateProgramAdStruct) => void;
  errors?: object;
}

export default class AdForm extends Component<Args> {
  @action
  changeName(name: string) {
    this.args.onChange({
      ...this.args.formData,
      name
    });
  }

  @action
  changeImageUrl(imageUrl: string) {
    this.args.onChange({
      ...this.args.formData,
      imageUrl
    });
  }
}
