// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import Account from 'airthings/services/airthings/account';
import {
  AccountUserStruct,
  AccountUserInformation,
  AccountUserPreferences
} from 'airthings/types/account';
import Analytics from 'airthings/services/analytics';
import RouterService from '@ember/routing/router-service';
import {AccountUpdateCurrentUserResponse} from 'airthings/graphql/mutations/account-update-current-user';
import ValidationErrors from 'airthings/services/form';

interface Args {
  user: AccountUserStruct;
}

export default class PageLobbyProfile extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  @tracked
  currentUser: AccountUserStruct = this.args.user;

  @dropTask
  *accountPersistTask(): TaskGenerator<void> {
    return yield this.account.updateCurrentUser(this.currentUser);
  }

  @action
  changeInformation(updatedInformation: AccountUserInformation) {
    this.currentUser = {
      ...this.currentUser,
      information: updatedInformation
    };
  }

  @action
  changePreferences(updatedPreferences: AccountUserPreferences) {
    this.currentUser = {
      ...this.currentUser,
      preferences: updatedPreferences
    };
  }

  @action
  validate() {
    return this.account.validateUser(this.currentUser);
  }

  @action
  handleSuccess(
    response: AccountUpdateCurrentUserResponse['updateCurrentUserProfile']
  ) {
    this.analytics.trackProfileUpdate(response.result);

    this.navigateToCompany();
  }

  @action
  handleFailure() {
    this.analytics.trackProfileUpdateFailure(this.currentUser);
  }

  @action
  handleValidationFailure(errors: ValidationErrors) {
    this.analytics.trackProfileUpdateValidationFailure(
      this.currentUser,
      errors
    );
  }

  @action
  navigateToCompany() {
    this.router.transitionTo('lobby.company');
  }
}
