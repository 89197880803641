// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import Analytics from 'airthings/services/analytics';
import Reports from 'airthings/services/airthings/reports';
import {
  ReportsConfigurableBlock,
  ReportsConfigurableBlockDefinition
} from 'airthings/types/reports';
import {RecordingsListDatasetStruct} from 'airthings/types/recordings';

interface Args {
  dataset: RecordingsListDatasetStruct;
  configurableBlock: ReportsConfigurableBlock;
  isFirstStep: boolean;
  onCancel: () => void;
  onSuccess: (configuredBlock: ReportsConfigurableBlock) => void;
  onPrevious: () => void;
}

interface BlockTemplateSettings {
  title?: string;
}

export default class ReportsGenerationBlockConfiguration extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/reports')
  reports: Reports;

  @tracked
  settingsStructState: object | null;

  get settingsStruct(): object {
    if (this.settingsStructState) return this.settingsStructState;

    return this.blockDefinition.reportSettingsStruct(
      this.args.configurableBlock.reportSettingsStruct || {}
    );
  }

  get blockDefinition() {
    return this.reports.getTemplateBlockDefinition(
      this.args.configurableBlock.key
    ) as ReportsConfigurableBlockDefinition;
  }

  get reportSettingsComponent() {
    return `reports/blocks/${this.blockDefinition.componentNamespace}/report-settings`;
  }

  get blockTitle(): string {
    const blockName = this.blockDefinition.name;

    if (!this.args.configurableBlock.templateSettingsStruct) return blockName;

    const templateSettings = this.args.configurableBlock
      .templateSettingsStruct as BlockTemplateSettings;

    if (!templateSettings.title) return blockName;
    return templateSettings.title;
  }

  get configurationHelper(): string | undefined {
    return this.blockDefinition.configurationHelper;
  }

  @dropTask
  *configureBlockTask(): TaskGenerator<void> {
    return yield this.reports.configureReportBlock(
      this.args.configurableBlock.id,
      this.settingsStruct
    );
  }

  @action
  changeSettingsStruct(settingsStruct: object) {
    this.settingsStructState = settingsStruct;
  }

  @action
  handleBlockConfigureSuccess() {
    this.args.onSuccess({
      ...this.args.configurableBlock,
      reportSettingsStruct: this.settingsStruct
    });

    this.analytics.trackReportConfiguration(this.args.dataset, {
      reportConfigurationAction: 'continue'
    });

    this.teardownBlockState();
  }

  @action
  handlePrevious() {
    this.teardownBlockState();
    this.args.onPrevious();
  }

  @action
  validate() {
    return this.blockDefinition.reportSettingsValidate(this.settingsStruct);
  }

  private teardownBlockState() {
    this.settingsStructState = null;
  }
}
