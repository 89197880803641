export default {
  "toolbar": "_toolbar_1lbftd",
  "toolbar-button": "_toolbar-button_1lbftd",
  "is-align": "_is-align_1lbftd",
  "is-bold": "_is-bold_1lbftd",
  "is-italic": "_is-italic_1lbftd",
  "is-underline": "_is-underline_1lbftd",
  "is-strike": "_is-strike_1lbftd",
  "is-subscript": "_is-subscript_1lbftd",
  "is-superscript": "_is-superscript_1lbftd",
  "is-align-left": "_is-align-left_1lbftd",
  "is-align-center": "_is-align-center_1lbftd",
  "is-align-right": "_is-align-right_1lbftd",
  "is-bullet-list": "_is-bullet-list_1lbftd",
  "is-ordered-list": "_is-ordered-list_1lbftd",
  "is-undo": "_is-undo_1lbftd",
  "is-redo": "_is-redo_1lbftd",
  "is-clean": "_is-clean_1lbftd",
  "editor-wrapper": "_editor-wrapper_1lbftd",
  "has-error": "_has-error_1lbftd",
  "editor": "_editor_1lbftd"
};
