// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {alias} from '@ember/object/computed';
import {dasherize} from '@ember/string';

// Types
import {
  AdminRecordingsCalibrationCertificateStruct,
  CalibrationType,
  FactoryCalibration,
  LabCalibration,
  OtherCalibration
} from 'airthings/types/admin/recordings';
import {ComparisonDevice} from 'airthings/types/admin/recordings';

// Services
import IntlService from 'ember-intl/services/intl';

interface Args {
  formData: AdminRecordingsCalibrationCertificateStruct;
  onChange: (
    updatedFormData: AdminRecordingsCalibrationCertificateStruct
  ) => void;
  errors?: object;
}

export default class RecordingsCalibrationCertificateForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @alias('args.formData.calibrationMeta.calibrationType')
  calibrationType: CalibrationType;

  get showComparisonDevicesForm() {
    return (
      this.calibrationType === CalibrationType.FACTORY ||
      this.calibrationType === CalibrationType.LAB
    );
  }

  get calibrationTypeOptions() {
    return [
      {
        value: CalibrationType.FACTORY,
        label: this.intl.t(
          'recordings.calibration-certificate.calibration-type.options.factory'
        )
      },
      {
        value: CalibrationType.LAB,
        label: this.intl.t(
          'recordings.calibration-certificate.calibration-type.options.lab'
        )
      },
      {
        value: CalibrationType.OTHER,
        label: this.intl.t(
          'recordings.calibration-certificate.calibration-type.options.other'
        )
      }
    ];
  }

  get calibrationComponent() {
    if (!this.calibrationType) return;

    return `recordings/calibration-certificates/${dasherize(
      this.calibrationType
    )}-calibration-form`;
  }

  @action
  changeCalibrationType(calibrationType: CalibrationType) {
    switch (calibrationType) {
      case CalibrationType.FACTORY:
        this.onCalibrationMetaChange({
          calibrationType,
          calibrationFactorMultiplier: '',
          testDuration: '',
          averageBarometricPressure: '',
          averageRadonConcentration: '',
          averageRelativeHumidity: '',
          averageTemperature: ''
        });
        break;

      case CalibrationType.LAB:
        this.onCalibrationMetaChange({
          calibrationType,
          calibrationFactorMultiplier: '',
          testDuration: '',
          averageRadonConcentration: '',
          averageRelativeHumidity: '',
          averageTemperature: '',
          initialRelativeError: '',
          averageReading: '',
          adjustedRelativeError: '',
          calibratorTitle: '',
          calibratorCertificationNumber: '',
          backgroundExposure: ''
        });
        break;

      case CalibrationType.OTHER:
        this.onCalibrationMetaChange({
          calibrationType
        });
        break;

      default:
        this.onCalibrationMetaChange(null);
        break;
    }
  }

  @action
  onCalibrationMetaChange(
    calibrationMeta:
      | FactoryCalibration
      | LabCalibration
      | OtherCalibration
      | null
  ) {
    this.args.onChange({
      ...this.args.formData,
      calibrationMeta
    });
  }

  @action
  changeComparisonDevices(updatedComparisonDevices: ComparisonDevice[]) {
    this.args.onChange({
      ...this.args.formData,
      comparisonDevices: updatedComparisonDevices
    });
  }
}
