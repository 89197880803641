export default {
  "font-medium": "500",
  "font-demi-bold": "600",
  "size-3": "8px",
  "size-4": "16px",
  "size-5": "32px",
  "tab-badge": "_tab-badge_1elxga",
  "columns": "_columns_1elxga",
  "list-column": "_list-column_1elxga",
  "info": "_info_1elxga",
  "title": "_title_1elxga",
  "map-column": "_map-column_1elxga",
  "card": "_card_1elxga",
  "is-active": "_is-active_1elxga",
  "actions": "_actions_1elxga",
  "button": "_button_1elxga",
  "icon": "_icon_1elxga",
  "map-container": "_map-container_1elxga",
  "ember-google-map": "_ember-google-map_1elxga",
  "google-map-button": "_google-map-button_1elxga",
  "loader": "_loader_1elxga _animations-spin_7nu7fk",
  "description": "_description_1elxga",
  "devices-list": "_devices-list_1elxga"
};
