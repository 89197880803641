// Vendor
import {Ability} from 'ember-can';

// Types
import {AdminAccountUserStruct} from 'airthings/types/admin/account';

export default class AdminCompanyAbility extends Ability {
  currentUser: AdminAccountUserStruct;

  get canLogAs() {
    return this.currentUser.accessRights.logAs;
  }
}
