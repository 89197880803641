// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

interface Args {
  label: string;
  onClick?: () => {};
  onClose: () => {};
  isDangerous?: boolean;
  route?: string;
  model?: object;
}

export default class ActionSheetItem extends Component<Args> {
  @action
  handleClick() {
    if (typeof this.args.onClose === 'function') this.args.onClose();
    if (typeof this.args.onClick === 'function') this.args.onClick();
  }
}
