// Vendor
import Component from '@glimmer/component';

// Types
import {AdminAccountListCompanyStruct} from 'airthings/types/admin/account';
import {AdminRecordingsDeviceStruct} from 'airthings/types/admin/recordings';

interface Args {
  devices: Array<RecursivePartial<AdminRecordingsDeviceStruct>>;
  company: AdminAccountListCompanyStruct;
  onMoveDevices: () => void;
  movingDevices: boolean;
}

export default class DevicesMoveReview extends Component<Args> {
  get companyLabel() {
    return `${this.args.company.name} (${this.args.company.email})`;
  }

  get devices() {
    return this.args.devices
      .filter(({id}) => Boolean(id))
      .map((device, index) => ({
        ...device,
        index: index + 1
      }));
  }
}
