// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {AccountUserStruct, ThirdPartySettings} from 'airthings/types/account';
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import {OnboardingSectionSlug} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
  thirdPartySettings: ThirdPartySettings[];
  onboardingSectionSlug: OnboardingSectionSlug;
  setSettingsDirty: () => void;
  clearSettingsDirty: () => void;
}

export default class PageAppSettingsThirdPartySettings extends Component<Args> {
  @service('airthings/account')
  account: Account;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @tracked
  thirdPartySettings: ThirdPartySettings[] = this.args.thirdPartySettings;

  @dropTask
  *thirdPartySettingsPersistTask(): TaskGenerator<void> {
    return yield this.account.updateThirdPartySettings(this.thirdPartySettings);
  }

  @action
  changeThirdPartySettings(newSettings: ThirdPartySettings[]) {
    this.args.setSettingsDirty();

    this.thirdPartySettings = newSettings;
  }

  @action
  handleSuccess() {
    this.args.clearSettingsDirty();
    this.flashMessages.success(
      this.intl.t('app-settings-third-party-settings.form.success')
    );
  }
}
