export default {
  "font-bold": "700",
  "container": "_container_kbj2cm",
  "error": "_error_kbj2cm",
  "badge": "_badge_kbj2cm",
  "icon": "_icon_kbj2cm",
  "message": "_message_kbj2cm",
  "actions": "_actions_kbj2cm",
  "action": "_action_kbj2cm"
};
