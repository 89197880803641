// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import CommunicationBoard from 'airthings/services/airthings/communication-board';
import Account from 'airthings/services/airthings/account';
import {OnboardingSectionSlug} from 'airthings/types/account';

export default class AppMessagesIndexController extends Controller {
  @service('airthings/communication-board')
  communicationBoard: CommunicationBoard;

  @service('airthings/account')
  account: Account;

  onboardingSectionSlugs = OnboardingSectionSlug;
}
