// Vendor
import {Ability} from 'ember-can';

// Types
import {AccountUserStruct} from 'airthings/types/account';

export default class ReportAbility extends Ability {
  currentUser: AccountUserStruct;

  get canGenerate() {
    return true;
  }
}
