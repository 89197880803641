// Vendor
import Component from '@glimmer/component';

interface Args {
  title: string;
  hideName: boolean;
  hideEmail: boolean;
  hidePhoneNumber: boolean;
  userProfile: {
    name: string;
    phoneNumber: string;
    email: string;
    certificates: Array<{
      name: string;
      number: string;
      expirationDate: string;
    }>;
  } | null;
}

export default class RecordingsUserInformation extends Component<Args> {
  get hasCertificate() {
    return (
      this.args.userProfile && this.args.userProfile.certificates.length > 0
    );
  }

  get hasInformation() {
    return (
      !this.args.hideName || !this.args.hideEmail || !this.args.hidePhoneNumber
    );
  }

  get shouldDisplayBlock() {
    return this.hasInformation || this.hasCertificate;
  }
}
