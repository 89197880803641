export default {
  "font-bold": "700",
  "block": "_block_1bu0nj _light-box-wrapper_1ghgfm",
  "content": "_content_1bu0nj _light-box-content_1ghgfm",
  "cell": "_cell_1bu0nj _cell_1ghgfm",
  "key": "_key_1bu0nj _key_1ghgfm",
  "value": "_value_1bu0nj _value_1ghgfm",
  "header": "_header_1bu0nj _light-box-header_1ghgfm",
  "icon": "_icon_1bu0nj _header-icon_1ghgfm",
  "title": "_title_1bu0nj _light-box-header-title_1ghgfm"
};
