// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {AccountUserStruct} from 'airthings/types/account';
import {OnboardingSectionSlug} from 'airthings/types/account';
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';

interface Args {
  currentUser: AccountUserStruct;
  title: string;
  subTitle?: string;
  backButtonRoute?: string;
  displayUserSidebarButton?: boolean;
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageHeader extends Component<Args> {
  @service('airthings/account')
  account: Account;

  @service('router')
  router: RouterService;

  get displayUserSidebarButton() {
    if (!this.args.currentUser) return false;
    if (this.args.displayUserSidebarButton === undefined) return true;

    return this.args.displayUserSidebarButton;
  }

  get displayOnboardingButton() {
    return Boolean(this.args.onboardingSectionSlug);
  }

  @task
  *resetOnboardingSectionTask(): TaskGenerator<void> {
    const result = yield this.account.resetOnboardingSectionSlug(
      this.args.onboardingSectionSlug
    );

    // @ts-ignore
    this.router.replaceWith({queryParams: {help: true}});

    return result;
  }
}
