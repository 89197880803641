// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {AccountCompanyLogo} from 'airthings/types/account';

interface Args {
  formData: AccountCompanyLogo;
  onChange: (updatedFormData: AccountCompanyLogo) => void;
  errors?: object;
}

export default class AccountCompanyInformationForm extends Component<Args> {
  @action
  changeLogoUrl(updatedLogoUrl: string): void {
    this.args.onChange({
      ...this.args.formData,
      url: updatedLogoUrl
    });
  }
}
