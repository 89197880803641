export default {
  "device-list": "_device-list_z86jbx",
  "row": "_row_z86jbx",
  "index": "_index_z86jbx",
  "email": "_email_z86jbx",
  "company": "_company_z86jbx",
  "cell": "_cell_z86jbx",
  "cell-name": "_cell-name_z86jbx",
  "cell-email": "_cell-email_z86jbx",
  "actions": "_actions_z86jbx"
};
