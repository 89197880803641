// Vendor
import Service from '@ember/service';

export interface MutationResponseError {
  code: string;
  field: string;
}

export interface MutationResponse<T = unknown> {
  successful: boolean;
  messages: MutationResponseError[];
  result?: T;
}

interface MutationErrorResponse {
  successful: false;
  messages: [];
  result: null;
}

export default class GraphQL extends Service {
  async runMutation<ReturnType>(callback: () => Promise<ReturnType>) {
    try {
      return await callback();
    } catch (error) {
      const response: MutationErrorResponse = {
        successful: false,
        result: null,
        messages: []
      };

      return response;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/graphql': GraphQL;
  }
}
