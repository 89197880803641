export default {
  "font-medium": "500",
  "font-bold": "700",
  "size-2": "4px",
  "delete-confirmation": "_delete-confirmation_1c8z2b",
  "delete-explanation": "_delete-explanation_1c8z2b",
  "delete-device": "_delete-device_1c8z2b",
  "delete-device-name": "_delete-device-name_1c8z2b",
  "delete-device-number": "_delete-device-number_1c8z2b",
  "delete-actions": "_delete-actions_1c8z2b",
  "delete-action": "_delete-action_1c8z2b",
  "errors": "_errors_1c8z2b",
  "creation-confirmation-title": "_creation-confirmation-title_1c8z2b",
  "creation-confirmation-body": "_creation-confirmation-body_1c8z2b",
  "calibration-expiration": "_calibration-expiration_1c8z2b",
  "calibration-due-modal": "_calibration-due-modal_1c8z2b",
  "calibration-expired-modal": "_calibration-expired-modal_1c8z2b",
  "icon": "_icon_1c8z2b",
  "crosscheck-modal": "_crosscheck-modal_1c8z2b",
  "crosscheck-due": "_crosscheck-due_1c8z2b",
  "modal-actions": "_modal-actions_1c8z2b",
  "modal-action": "_modal-action_1c8z2b",
  "table-row": "_table-row_1c8z2b",
  "row-cell": "_row-cell_1c8z2b",
  "empty": "_empty_1c8z2b",
  "badge": "_badge_1c8z2b",
  "badge-expired": "_badge-expired_1c8z2b",
  "calibration-expired-icon": "_calibration-expired-icon_1c8z2b",
  "tab-badge": "_tab-badge_1c8z2b",
  "button": "_button_1c8z2b",
  "search-bar-button": "_search-bar-button_1c8z2b",
  "mobile-actions": "_mobile-actions_1c8z2b",
  "view-details-button": "_view-details-button_1c8z2b",
  "row-cell-wrap": "_row-cell-wrap_1c8z2b",
  "table-row-header": "_table-row-header_1c8z2b",
  "table-row-cell": "_table-row-cell_1c8z2b",
  "desktop-actions": "_desktop-actions_1c8z2b"
};
