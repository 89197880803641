export default {
  "animations-fade-slide-in": "_animations-fade-slide-in_7nu7fk",
  "fade-slide-in": "_fade-slide-in_7nu7fk",
  "animations-fade-slide-in-from-right": "_animations-fade-slide-in-from-right_7nu7fk",
  "fade-slide-in-from-right": "_fade-slide-in-from-right_7nu7fk",
  "animations-spin": "_animations-spin_7nu7fk",
  "spin": "_spin_7nu7fk",
  "animations-fade-in": "_animations-fade-in_7nu7fk",
  "fade-in": "_fade-in_7nu7fk"
};
