// @ts-nocheck

// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import Moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import Highcharts from 'highcharts';

// Types
import IntlService from 'ember-intl/services/intl';
import {AxisLabelsFormatterContextObject} from 'highcharts';
import {
  RecordingsSamplesMetadata,
  RecordingsSampleStruct,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';
import Recordings from 'airthings/services/airthings/recordings';
import {GraphTemplateSettingsActionLevelStruct} from 'airthings/services/airthings/reports/block-definitions/graph';

// Constants
const NAME_MAPPING = {
  RADON_SHORT_TERM_AVERAGE:
    'reports.block-definitions.graph.output.radon-short-term-average',
  TEMPERATURE: 'reports.block-definitions.graph.output.temperature',
  PRESSURE: 'reports.block-definitions.graph.output.pressure',
  HUMIDITY: 'reports.block-definitions.graph.output.humidity'
};

interface Args {
  reportData: {
    title: string;
    datasetTimeZone: string;
    samples: {
      metadata: RecordingsSamplesMetadata;
      entries: RecordingsSampleStruct[];
    };
    selectedUnitTypes: RecordingsSampleUnitType[];
    actionLevels: GraphTemplateSettingsActionLevelStruct[];
  };
}

// NOTE: Highcharts require moment.js to be available in
//       window.moment to display time series in a specific
//       timezone.
declare global {
  interface Window {
    moment: any;
  }
}

window.moment = Moment;
MomentTimeZone();

export default class ReportBlockGraph extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @action
  initializeChart(chartElement: HTMLElement) {
    Highcharts.chart(chartElement, {
      chart: {
        animation: false,
        type: 'line',
        height: 400,
        marginTop: 100,
        // eslint-disable-next-line no-magic-numbers
        spacing: [25, 10, 15, 10],
        style: {
          fontFamily: 'AvenirNext'
        }
      },
      title: {
        text: this.args.reportData.title,
        y: 5,
        style: {
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontSize: '14px'
        }
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: this.args.reportData.selectedUnitTypes.map(
        (unitType: RecordingsSampleUnitType, index: number) => ({
          labels: {
            formatter: (context: AxisLabelsFormatterContextObject) => {
              return `${this.recordings.formatSampleUnit(
                context.value,
                unitType
              )} ${this.unitLabelForUnitType(unitType)}`;
            }
          },
          title: {
            text: this.nameForUnitType(unitType)
          },
          opposite: index === 1,
          plotLines: this.getActionLevelsForUnitType(unitType).map(
            (actionLevel) => ({
              value: actionLevel.threshold,
              dashStyle: this.dashStyleForSeriesIndex(index),
              color: this.colorForSeriesIndex(index),
              width: 2,
              label: {
                text: actionLevel.label,
                align: index === 0 ? 'left' : 'right'
              }
            })
          ),
          max: this.getMaximumForUnitType(unitType)
        })
      ),
      series: this.args.reportData.selectedUnitTypes.map(
        (unitType: RecordingsSampleUnitType, index: number) => ({
          animation: false,
          name: this.nameForUnitType(unitType),
          type: 'line',
          color: this.colorForSeriesIndex(index),
          data: this.args.reportData.samples.entries.map(
            (sample: RecordingsSampleStruct) => {
              return this.recordings.sampleToChartPoint(sample, unitType);
            }
          ),
          dashStyle: this.dashStyleForSeriesIndex(index),
          marker: {
            enabled: false
          },
          yAxis: index
        })
      ),
      tooltip: {
        enabled: false
      },
      legend: {
        align: 'right',
        backgroundColor: '#f3f2f0',
        borderRadius: 6,
        floating: true,
        layout: 'vertical',
        itemMarginBottom: 5,
        itemMarginTop: 5,
        verticalAlign: 'top',
        x: 11,
        y: -25,
        width: 175
      },
      credits: {
        enabled: false
      },
      time: {
        timezone: this.args.reportData.datasetTimeZone
      }
    });
  }

  private getActionLevelsForUnitType(unitType: RecordingsSampleUnitType) {
    return this.args.reportData.actionLevels.filter((actionLevel) => {
      return unitType === actionLevel.unitType;
    });
  }

  private getMaximumForUnitType(unitType: RecordingsSampleUnitType) {
    const sampleValues = this.args.reportData.samples.entries.map((sample) => {
      switch (unitType) {
        case RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE:
          return sample.radonShortTermAverage;
        case RecordingsSampleUnitType.PRESSURE:
          return sample.pressure;
        case RecordingsSampleUnitType.TEMPERATURE:
          return sample.temperature;
        case RecordingsSampleUnitType.HUMIDITY:
          return sample.humidity;
      }
    });

    const maxSampleValue = Math.max(...sampleValues);

    const actionLevelThresholds = this.getActionLevelsForUnitType(unitType).map(
      (actionLevel) => actionLevel.threshold
    );

    const maxActionLevelThreshold = Math.max(...actionLevelThresholds);

    return Math.max(maxSampleValue, maxActionLevelThreshold);
  }

  private nameForUnitType(unitType: RecordingsSampleUnitType) {
    return this.intl.t(NAME_MAPPING[unitType]);
  }

  private unitLabelForUnitType(unitType: RecordingsSampleUnitType) {
    switch (unitType) {
      case RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE:
        return this.recordings.sampleUnitLabel(
          this.args.reportData.samples.metadata.radonUnit
        );
      case RecordingsSampleUnitType.PRESSURE:
        return this.recordings.sampleUnitLabel(
          this.args.reportData.samples.metadata.pressureUnit
        );
      case RecordingsSampleUnitType.TEMPERATURE:
        return this.recordings.sampleUnitLabel(
          this.args.reportData.samples.metadata.temperatureUnit
        );
      case RecordingsSampleUnitType.HUMIDITY:
        return this.recordings.sampleUnitLabel(
          this.args.reportData.samples.metadata.humidityUnit
        );
    }
  }

  private dashStyleForSeriesIndex(index: number) {
    return index === 0 ? 'Solid' : 'ShortDot';
  }

  private colorForSeriesIndex(index: number) {
    return index === 0 ? '#323e46' : '#5e6e77';
  }
}
