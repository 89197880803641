// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Services
import Account from 'airthings/services/airthings/account';
import Recordings from 'airthings/services/airthings/recordings';

// Types
import {OnboardingSectionSlug} from 'airthings/types/account';

export default class AppDatasetsIndexController extends Controller {
  @service('airthings/account')
  account: Account;

  @service('airthings/recordings')
  recordings: Recordings;

  onboardingSectionSlugs = OnboardingSectionSlug;
}
