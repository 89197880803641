export default {
  "font-bold": "700",
  "header": "_header_pwouto",
  "header-menu": "_header-menu_pwouto",
  "header-menu-actions": "_header-menu-actions_pwouto",
  "header-menu-item": "_header-menu-item_pwouto",
  "last-modified": "_last-modified_pwouto",
  "sections-wrapper": "_sections-wrapper_pwouto",
  "charts": "_charts_pwouto",
  "with-hidden-header": "_with-hidden-header_pwouto",
  "first-block": "_first-block_pwouto"
};
