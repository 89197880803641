export default {
  "font-bold": "700",
  "container": "_container_13lvva",
  "title": "_title_13lvva",
  "attachments": "_attachments_13lvva",
  "attachments-wrapper": "_attachments-wrapper_13lvva",
  "attachment-title": "_attachment-title_13lvva",
  "attachment-cell": "_attachment-cell_13lvva",
  "is-date": "_is-date_13lvva",
  "attachment-name": "_attachment-name_13lvva",
  "attachment-delete": "_attachment-delete_13lvva",
  "new-attachments-wrapper": "_new-attachments-wrapper_13lvva",
  "new-attachments": "_new-attachments_13lvva",
  "attachment-add": "_attachment-add_13lvva",
  "delete-confirmation": "_delete-confirmation_13lvva",
  "delete-actions": "_delete-actions_13lvva",
  "delete-action": "_delete-action_13lvva",
  "creator-name-title": "_creator-name-title_13lvva",
  "creator-name": "_creator-name_13lvva",
  "date-title": "_date-title_13lvva",
  "date": "_date_13lvva"
};
