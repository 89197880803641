// Vendor
import Service, {inject as service} from '@ember/service';
import {DataProxy} from '@apollo/client/core';

// Types
import {
  AccountCompanyStruct,
  AccountUserStruct,
  AccountInvitationStruct,
  PreferredLanguage,
  AccountDetailedUserStruct,
  AffialiateProgramSubscriptionStruct,
  ThirdPartySettings,
  PremiumFeatureSlug
} from 'airthings/types/account';
import Apollo from 'airthings/services/apollo';
import GraphQL from 'airthings/services/airthings/graphql';

// GraphQL
import accountCurrentUserProfileQuery, {
  AccountCurrentUserProfileResponse
} from 'airthings/graphql/queries/account-current-user-profile';
import accountCurrentUserCompanyQuery, {
  AccountCurrentUserCompanyResponse
} from 'airthings/graphql/queries/account-current-user-company';
import accountUserProfileQuery, {
  AccountUserProfileResponse
} from 'airthings/graphql/queries/account-user-profile';
import accountCompanyUsers, {
  AccountCompanyUser,
  AccountCompanyUsersResponse
} from 'airthings/graphql/queries/account-company-users';
import accountInvitationByUUIDQuery, {
  AccountInvitationByUUID
} from 'airthings/graphql/queries/account-invitation-by-uuid';
import accountIsnAccountQuery, {
  AccountIsnAccountResponse
} from 'airthings/graphql/queries/account-isn-account';
import AccountUpdateCurrentUserMutation, {
  AccountUpdateCurrentUserVariables,
  AccountUpdateCurrentUserResponse
} from 'airthings/graphql/mutations/account-update-current-user';
import AccountUpdateUserMutation, {
  AccountUpdateUserVariables,
  AccountUpdateUserResponse
} from 'airthings/graphql/mutations/account-update-user';
import accountCreateCompanyMutation, {
  AccountCreateCompanyVariables,
  AccountCreateCompanyResponse
} from 'airthings/graphql/mutations/account-create-company';
import accountUpdateCompanyMutation, {
  AccountUpdateCompanyVariables,
  AccountUpdateCompanyResponse
} from 'airthings/graphql/mutations/account-update-company';
import accountCreateInvitationMutation, {
  AccountCreateInvitationVariables,
  AccountCreateInvitationResponse
} from 'airthings/graphql/mutations/account-create-invitation';
import accountResendInvitationMutation, {
  AccountResendInvitationVariables,
  AccountResendInvitationResponse
} from 'airthings/graphql/mutations/account-resend-invitation';
import accountDeleteInvitationMutation, {
  AccountDeleteInvitationVariables,
  AccountDeleteInvitationResponse
} from 'airthings/graphql/mutations/account-delete-invitation';
import accountDeleteUserMutation, {
  AccountDeleteUserVariables,
  AccountDeleteUserResponse
} from 'airthings/graphql/mutations/account-delete-user';
import accountDismissOnboardingStep, {
  AccountDismissOnboardingStepResponse,
  AccountDismissOnboardingStepVariables
} from 'airthings/graphql/mutations/account-dismiss-onboarding-step';
import accountResetOnboardingSection, {
  AccountResetOnboardingSectionResponse,
  AccountResetOnboardingSectionVariables
} from 'airthings/graphql/mutations/account-reset-onboarding-section';
import accountAffiliateProgramSubscription, {
  AccountAffiliateProgramSubscriptionVariables,
  AccountAffiliateProgramSubscriptionResponse
} from 'airthings/graphql/mutations/account-affiliate-program-subscription';
import accountUpdateCompanyDatasetSettingsMutation, {
  AccountUpdateCompanyDatasetSettingsResponse,
  AccountUpdateCompanyDatasetSettingsVariables
} from 'airthings/graphql/mutations/account-update-company-dataset-settings';
import accountUpdateThirdPartySettingsMutation, {
  AccountUpdateThirdPartySettingsResponse,
  AccountUpdateThirdPartySettingsVariables
} from 'airthings/graphql/mutations/account-update-third-party-settings';
import accountAssignFavoriteReportTemplateToCompany, {
  AccountAssignFavoriteReportTemplateToCompanyResponse,
  AccountAssignFavoriteReportTemplateToCompanyVariables
} from 'airthings/graphql/mutations/account-assign-favorite-report-template-to-company';
import accountCurrentUserThirdPartySettings, {
  AccountCurrentUserThirdPartySettingsResponse
} from 'airthings/graphql/queries/account-current-user-third-party-settings';
import accountDismissUnreadMessagesNotice, {
  AccountDismissUnreadMessagesNoticeResponse
} from 'airthings/graphql/mutations/account-dismiss-unread-messages-notice';
import accountDismissPremiumFeatureNotice, {
  AccountDismissPremiumFeatureNoticeResponse,
  AccountDismissPremiumFeatureNoticeVariables
} from 'airthings/graphql/mutations/account-dismiss-premium-feature';

export default class API extends Service {
  @service('airthings/graphql')
  graphQL: GraphQL;

  @service('apollo')
  apollo: Apollo;

  async fetchCurrentUser() {
    try {
      const response: AccountCurrentUserProfileResponse =
        await this.apollo.query({
          query: accountCurrentUserProfileQuery
        });

      return response.viewer;
    } catch (error) {
      return null;
    }
  }

  async fetchUserById(userId: string) {
    try {
      const response: AccountUserProfileResponse = await this.apollo.query({
        query: accountUserProfileQuery,
        variables: {userId}
      });

      return response.viewer.company.user;
    } catch (error) {
      return null;
    }
  }

  async fetchInvitationByUUID(uuid: string) {
    try {
      const response: AccountInvitationByUUID = await this.apollo.query({
        query: accountInvitationByUUIDQuery,
        variables: {uuid}
      });

      return response.invitationByUUID;
    } catch (error) {
      return null;
    }
  }

  async assignFavoriteReportTemplateToCompany(reportTemplateId: string) {
    type ReturnType =
      AccountAssignFavoriteReportTemplateToCompanyResponse['assignFavoriteReportTemplateToCompany'];

    const variables: AccountAssignFavoriteReportTemplateToCompanyVariables = {
      reportTemplateId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountAssignFavoriteReportTemplateToCompanyResponse =
        await this.apollo.mutate({
          mutation: accountAssignFavoriteReportTemplateToCompany,
          variables
        });

      return response.assignFavoriteReportTemplateToCompany;
    });
  }

  async updateCurrentUser(user: AccountUserStruct) {
    type ReturnType =
      AccountUpdateCurrentUserResponse['updateCurrentUserProfile'];

    const variables: AccountUpdateCurrentUserVariables = {
      name: user.information.name,
      phoneNumber: user.information.phoneNumber,
      preferredLanguage: PreferredLanguage.EN,
      preferredUnitSystem: user.preferences.unitSystem,
      datasetReportMessage: user.preferences.datasetReportMessage
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountUpdateCurrentUserResponse =
        await this.apollo.mutate({
          mutation: AccountUpdateCurrentUserMutation,
          variables
        });

      return response.updateCurrentUserProfile;
    });
  }

  async updateUser(userId: string, user: AccountDetailedUserStruct) {
    type ReturnType = AccountUpdateUserResponse['updateUserProfile'];

    const variables: AccountUpdateUserVariables = {
      userId,
      name: user.information.name,
      phoneNumber: user.information.phoneNumber,
      preferredLanguage: PreferredLanguage.EN,
      preferredUnitSystem: user.preferences.unitSystem,
      role: user.role,
      certificates: user.certificates
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountUpdateUserResponse = await this.apollo.mutate({
        mutation: AccountUpdateUserMutation,
        variables
      });

      return response.updateUserProfile;
    });
  }

  async createCompany(company: AccountCompanyStruct) {
    type ReturnType = AccountCreateCompanyResponse['createCompany'];

    const variables: AccountCreateCompanyVariables = {
      address: company.information.address,
      certificates: company.certificates,
      email: company.information.email,
      logoUrl: company.logo.url,
      name: company.information.name,
      phoneNumber: company.information.phoneNumber
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountCreateCompanyResponse = await this.apollo.mutate({
        mutation: accountCreateCompanyMutation,
        variables,

        // Since the mutation doesn’t return a clear link from the company to
        // the user, we create it so that Apollo knows that the current user now
        // has a company
        update(store: DataProxy, {data: {createCompany}}: any) {
          const data = store.readQuery({
            query: accountCurrentUserProfileQuery
          }) as AccountCurrentUserProfileResponse;

          const newData = {
            ...data,
            viewer: {
              ...data.viewer,
              company: {
                ...createCompany.result,
                expiredCalibrationDevicesCount: 0
              },
              profile: {
                ...data.viewer.profile,
                role: createCompany.result.owner.profile.role
              }
            }
          };

          store.writeQuery({
            query: accountCurrentUserProfileQuery,
            data: newData
          });
        }
      });

      // Since the address subfields are hidden, we have to expose a global
      // error that will be displayed for the address input.
      const messages = [...response.createCompany.messages];

      const addressHasErrors = messages.some(({field}) => {
        return /^address\./.test(field);
      });

      if (addressHasErrors) messages.push({field: 'address', code: 'required'});

      return {
        ...response.createCompany,
        messages
      };
    });
  }

  async updateCompany(company: AccountCompanyStruct) {
    type ReturnType = AccountUpdateCompanyResponse['updateCompany'];

    const variables: AccountUpdateCompanyVariables = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      companyId: company.information.id!,
      address: company.information.address,
      certificates: company.certificates,
      email: company.information.email,
      logoUrl: company.logo.url,
      name: company.information.name,
      phoneNumber: company.information.phoneNumber
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountUpdateCompanyResponse = await this.apollo.mutate({
        mutation: accountUpdateCompanyMutation,
        variables
      });

      // Since the address subfields are hidden, we have to expose a global
      // error that will be displayed for the address input.
      const messages = [...response.updateCompany.messages];

      const addressHasErrors = messages.some(({field}) => {
        return /^address\./.test(field);
      });

      if (addressHasErrors) messages.push({field: 'address', code: 'required'});

      return {
        ...response.updateCompany,
        messages
      };
    });
  }

  async updateCompanyDatasetSettings(company: AccountCompanyStruct) {
    type ReturnType =
      AccountUpdateCompanyDatasetSettingsResponse['updateCompanyDatasetSettings'];

    const variables: AccountUpdateCompanyDatasetSettingsVariables = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      companyId: company.information.id!,
      datasetSettings: company.datasetSettings
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountUpdateCompanyDatasetSettingsResponse =
        await this.apollo.mutate({
          mutation: accountUpdateCompanyDatasetSettingsMutation,
          variables
        });

      return response.updateCompanyDatasetSettings;
    });
  }

  async updateThirdPartySettings(thirdPartySettings: ThirdPartySettings[]) {
    type ReturnType =
      AccountUpdateThirdPartySettingsResponse['updateThirdPartySettings'];

    const variables: AccountUpdateThirdPartySettingsVariables = {
      thirdPartySettings
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountUpdateThirdPartySettingsResponse =
        await this.apollo.mutate({
          mutation: accountUpdateThirdPartySettingsMutation,
          variables
        });

      return response.updateThirdPartySettings;
    });
  }

  async createInvitation(invitation: AccountInvitationStruct) {
    type ReturnType = AccountCreateInvitationResponse['createInvitation'];

    const variables: AccountCreateInvitationVariables = {
      name: invitation.name,
      email: invitation.email,
      role: invitation.role
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountCreateInvitationResponse =
        await this.apollo.mutate({
          mutation: accountCreateInvitationMutation,
          variables,

          update(store: DataProxy, {data: {createInvitation}}: any) {
            if (!createInvitation.successful) return;

            const data = store.readQuery({
              query: accountCompanyUsers
            }) as AccountCompanyUsersResponse;

            const newData = {
              ...data,
              viewer: {
                ...data.viewer,
                company: {
                  ...data.viewer.company,
                  pendingInvitations: [
                    ...data.viewer.company.pendingInvitations,
                    createInvitation.result
                  ]
                }
              }
            };

            store.writeQuery({query: accountCompanyUsers, data: newData});
          }
        });

      return response.createInvitation;
    });
  }

  async resendInvitation(invitationId: string) {
    type ReturnType = AccountResendInvitationResponse['resendInvitation'];

    const variables: AccountResendInvitationVariables = {
      invitationId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountResendInvitationResponse =
        await this.apollo.mutate({
          mutation: accountResendInvitationMutation,
          variables
        });

      return response.resendInvitation;
    });
  }

  async deleteInvitation(invitationId: string) {
    type ReturnType = AccountDeleteInvitationResponse['deleteInvitation'];

    const variables: AccountDeleteInvitationVariables = {
      invitationId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountDeleteInvitationResponse =
        await this.apollo.mutate({
          mutation: accountDeleteInvitationMutation,
          variables,

          update(store: DataProxy, {data: {deleteInvitation}}: any) {
            if (!deleteInvitation.successful) return;

            const data = store.readQuery({
              query: accountCompanyUsers
            }) as AccountCompanyUsersResponse;

            const newData = {
              ...data,
              viewer: {
                ...data.viewer,
                company: {
                  ...data.viewer.company,
                  pendingInvitations:
                    data.viewer.company.pendingInvitations.filter(
                      (pendingInvitation: AccountInvitationStruct) => {
                        return invitationId !== pendingInvitation.id;
                      }
                    )
                }
              }
            };

            store.writeQuery({
              query: accountCompanyUsers,
              data: newData
            });
          }
        });

      return response.deleteInvitation;
    });
  }

  async deleteUser(userId: string) {
    type ReturnType = AccountDeleteUserResponse['deleteUser'];

    const variables: AccountDeleteUserVariables = {
      userId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountDeleteUserResponse = await this.apollo.mutate({
        mutation: accountDeleteUserMutation,
        variables,

        update(store: DataProxy, {data: {deleteUser}}: any) {
          if (!deleteUser.successful) return;

          const data = store.readQuery({
            query: accountCompanyUsers
          }) as AccountCompanyUsersResponse;

          const newData = {
            ...data,
            viewer: {
              ...data.viewer,
              company: {
                ...data.viewer.company,
                users: data.viewer.company.users.filter(
                  (user: AccountCompanyUser) => {
                    return userId !== user.id;
                  }
                )
              }
            }
          };

          store.writeQuery({
            query: accountCompanyUsers,
            data: newData
          });
        }
      });

      return response.deleteUser;
    });
  }

  async dismissOnboardingStepSlug(
    stepSlug: string,
    reminderDelayInDays: number | null
  ) {
    type ReturnType =
      AccountDismissOnboardingStepResponse['dismissOnboardingStep'];

    const variables: AccountDismissOnboardingStepVariables = {
      stepSlug,
      reminderDelayInDays
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountDismissOnboardingStepResponse =
        await this.apollo.mutate({
          mutation: accountDismissOnboardingStep,
          variables
        });

      return response.dismissOnboardingStep;
    });
  }

  async resetOnboardingSectionSlug(onboardingSectionSlug: string) {
    type ReturnType =
      AccountResetOnboardingSectionResponse['resetOnboardingSection'];

    const variables: AccountResetOnboardingSectionVariables = {
      onboardingSectionSlug
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountResetOnboardingSectionResponse =
        await this.apollo.mutate({
          mutation: accountResetOnboardingSection,
          variables
        });

      return response.resetOnboardingSection;
    });
  }

  async dismissPremiumFeatureSlug(featureSlug: PremiumFeatureSlug) {
    type ReturnType =
      AccountDismissPremiumFeatureNoticeResponse['dismissPremiumFeatureNotice'];

    const variables: AccountDismissPremiumFeatureNoticeVariables = {
      featureSlug
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountDismissPremiumFeatureNoticeResponse =
        await this.apollo.mutate({
          mutation: accountDismissPremiumFeatureNotice,
          variables
        });

      return response.dismissPremiumFeatureNotice;
    });
  }

  async subscribeToAffiliateProgram(
    affiliateProgramSubscription: AffialiateProgramSubscriptionStruct
  ) {
    type ReturnType =
      AccountAffiliateProgramSubscriptionResponse['subscribeToAffiliateProgram'];

    const variables: AccountAffiliateProgramSubscriptionVariables = {
      uniqueCouponPrefix: affiliateProgramSubscription.uniqueCouponPrefix
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountAffiliateProgramSubscriptionResponse =
        await this.apollo.mutate({
          mutation: accountAffiliateProgramSubscription,
          variables
        });

      return response.subscribeToAffiliateProgram;
    });
  }

  async fetchThirdPartySettings() {
    try {
      const response: AccountCurrentUserThirdPartySettingsResponse =
        await this.apollo.query({
          query: accountCurrentUserThirdPartySettings,
          fetchPolicy: 'no-cache'
        });

      return response.viewer.thirdPartySettings;
    } catch (error) {
      return null;
    }
  }

  async fetchIsnAccount(
    domain: string,
    companyKey: string,
    accessKey: string,
    secretKey: string
  ) {
    const variables = {
      domain,
      companyKey,
      accessKey,
      secretKey
    };

    try {
      const response: AccountIsnAccountResponse = await this.apollo.query({
        query: accountIsnAccountQuery,
        variables,
        fetchPolicy: 'no-cache'
      });

      return response.viewer;
    } catch (error) {
      return null;
    }
  }

  async dismissUnreadMessagesNotice() {
    type ReturnType =
      AccountDismissUnreadMessagesNoticeResponse['dismissUnreadMessagesNotice'];

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AccountDismissUnreadMessagesNoticeResponse =
        await this.apollo.mutate({
          mutation: accountDismissUnreadMessagesNotice
        });

      return response.dismissUnreadMessagesNotice;
    });
  }

  async watchCompanyUsers(
    queryManager: Apollo
  ): Promise<AccountCompanyUsersResponse> {
    return queryManager.watchQuery({query: accountCompanyUsers});
  }

  async watchCurrentUser(
    queryManager: Apollo
  ): Promise<AccountCurrentUserProfileResponse> {
    return queryManager.watchQuery({query: accountCurrentUserProfileQuery});
  }

  async watchCurrentUserCompany(
    queryManager: Apollo
  ): Promise<AccountCurrentUserCompanyResponse> {
    return queryManager.watchQuery({query: accountCurrentUserCompanyQuery});
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/account/api': API;
  }
}
