// Vendor
import {helper} from '@ember/component/helper';

export const isFirstOfArray = function ([array, item]: [
  any[],
  unknown
]): boolean {
  return array[0] === item;
};

export default helper(isFirstOfArray);
