// Vendor
import Component from '@glimmer/component';
import {action, computed} from '@ember/object';
import {bool} from '@ember/object/computed';
import {inject as service} from '@ember/service';
import humanizeDuration from 'humanize-duration';

// Types
import {
  AdminRecordingsCalibrationCertificateStruct,
  LabCalibration
} from 'airthings/types/admin/recordings';

// Services
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';

interface Args {
  formData: AdminRecordingsCalibrationCertificateStruct;
  metaFormData: LabCalibration;
  onChange: (
    updatedFormData: AdminRecordingsCalibrationCertificateStruct
  ) => void;
  onMetaChange: (updatedMetaFormData: LabCalibration) => void;
}

const parseDate = (date: Date | string | null) => {
  if (!date) return null;

  if (typeof date === 'string') {
    return new Date(date);
  }

  return date;
};

export default class LabCalibrationForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @bool('args.formData.id')
  persisted: boolean;

  @computed('args.formData.activatedAt', function () {
    return this.recordings.computeExpirationDate(
      this.args.formData.activatedAt
    );
  })
  expiredAt: Date | null;

  @computed('args.formData.activatedAt', function () {
    return this.recordings.isCalibrationDue(this.expiredAt);
  })
  calibrationDue: boolean;

  @computed('args.formData.activatedAt', function () {
    return this.recordings.isCalibrationExpired(this.expiredAt);
  })
  calibrationExpired: boolean;

  changeWithDuration(
    formData: AdminRecordingsCalibrationCertificateStruct,
    metaFormData: LabCalibration
  ) {
    const startedAt = parseDate(formData.startedAt);
    const endedAt = parseDate(formData.endedAt);
    let testDuration = null;

    if (startedAt && endedAt && startedAt < endedAt) {
      testDuration = humanizeDuration(endedAt.getTime() - startedAt.getTime(), {
        units: ['h'],
        round: true
      });
    }

    this.args.onChange(formData);

    this.args.onMetaChange({
      ...metaFormData,
      testDuration
    });
  }

  @action
  changeDeviceSerialNumber(deviceSerialNumber: string) {
    this.args.onChange({
      ...this.args.formData,
      deviceSerialNumber
    });
  }

  @action
  changeActivatedAt(activatedAt: Date) {
    this.args.onChange({
      ...this.args.formData,
      activatedAt
    });
  }

  @action
  changeCalibrationFactorMultiplier(calibrationFactorMultiplier: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      calibrationFactorMultiplier
    });
  }

  @action
  changeStartedAt(startedAt: Date) {
    this.changeWithDuration(
      {
        ...this.args.formData,
        startedAt
      },
      this.args.metaFormData
    );
  }

  @action
  changeEndedAt(endedAt: Date) {
    this.changeWithDuration(
      {
        ...this.args.formData,
        endedAt
      },
      this.args.metaFormData
    );
  }

  @action
  changeAverageRadonConcentration(averageRadonConcentration: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      averageRadonConcentration
    });
  }

  @action
  changeAverageTemperature(averageTemperature: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      averageTemperature
    });
  }

  @action
  changeAverageRelativeHumidity(averageRelativeHumidity: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      averageRelativeHumidity
    });
  }

  @action
  changeAverageReading(averageReading: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      averageReading
    });
  }

  @action
  changeInitialRelativeError(initialRelativeError: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      initialRelativeError
    });
  }

  @action
  changeAdjustedRelativeError(adjustedRelativeError: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      adjustedRelativeError
    });
  }

  @action
  changeCalibratorTitle(calibratorTitle: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      calibratorTitle
    });
  }

  @action
  changeCalibratorCertificationNumber(calibratorCertificationNumber: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      calibratorCertificationNumber
    });
  }

  @action
  changeBackgroundExposure(backgroundExposure: string) {
    this.args.onMetaChange({
      ...this.args.metaFormData,
      backgroundExposure
    });
  }
}
