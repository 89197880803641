// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {AccountInvitationStruct, UserRole} from 'airthings/types/account';
import API from 'airthings/services/airthings/account/api';
import Form, {Validator} from 'airthings/services/form';

export default class AccountInvitation extends Service {
  @service('airthings/account/api')
  api: API;

  @service('form')
  form: Form;

  // eslint-disable-next-line complexity
  struct(
    base?: RecursivePartial<AccountInvitationStruct>
  ): AccountInvitationStruct {
    return {
      id: '',
      name: '',
      email: '',
      role: UserRole.EMPLOYEE,
      expiredAt: null,
      acceptedAt: null,
      ...base,
      company: {
        id: '',
        ...base?.company
      }
    };
  }

  async createInvitation(invitation: AccountInvitationStruct) {
    const mutationResponse = await this.api.createInvitation(invitation);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'account.invitation-form'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async resendInvitation(invitationId: string) {
    return this.api.resendInvitation(invitationId);
  }

  async deleteInvitation(invitationId: string) {
    return this.api.deleteInvitation(invitationId);
  }

  validate(invitation: AccountInvitationStruct) {
    let validators: Array<Validator<string, 'required' | 'format'>> = [];

    validators = validators.concat([
      {
        field: 'name',
        isValid: Boolean(invitation.name),
        code: 'required'
      },
      {
        field: 'email',
        isValid: Boolean(invitation.email),
        code: 'required'
      },
      {
        field: 'role',
        isValid: Boolean(invitation.role),
        code: 'required'
      }
    ]);

    return this.form.validate(validators, {
      translationKeyPrefix: 'account.invitation-form'
    });
  }

  async fetchInvitationByUUID(uuid: string) {
    const invitation = await this.api.fetchInvitationByUUID(uuid);

    return invitation ? this.struct(invitation) : null;
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/account/invitation': AccountInvitation;
  }
}
