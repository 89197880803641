// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';
import Form, {Validator} from 'airthings/services/form';

export interface DynamicParagraphTemplateSettingsOptionStruct {
  id?: string;
  title: string;
  body: string;
}

export interface TemplateSettingsStruct {
  options: DynamicParagraphTemplateSettingsOptionStruct[];
}

export interface ReportSettingsStruct {
  optionId?: string;
}

export interface ReportsDynamicParagraphBlockDefinition
  extends ReportsBlockDefinition {
  templateSettingsOptionStruct: (
    base: Partial<DynamicParagraphTemplateSettingsOptionStruct>
  ) => DynamicParagraphTemplateSettingsOptionStruct;
}

// Constants
const TEMPORARY_ID_PREFIX = 'tmp-';

export default class DynamicParagraphBlock
  extends Service
  implements ReportsBlockService
{
  @service('form')
  form: Form;

  @service('intl')
  intl: IntlService;

  private temporaryId = 0;

  get definition(): Readonly<ReportsDynamicParagraphBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'dynamic-paragraph',
      get name() {
        return intl.t('reports.block-definitions.dynamic-paragraph.name');
      },
      get description() {
        return intl.t(
          'reports.block-definitions.dynamic-paragraph.description'
        );
      },
      get helper() {
        return intl.t('reports.block-definitions.dynamic-paragraph.helper');
      },
      get configurationHelper() {
        return intl.t(
          'reports.block-definitions.dynamic-paragraph.configuration-helper'
        );
      },
      needsLargeTemplateSettingsModal: true,
      componentNamespace: 'dynamic-paragraph',
      lightIconPath:
        '/assets/svgs/report-block-icons/dynamic-paragraph-light.svg',
      darkIconPath:
        '/assets/svgs/report-block-icons/dynamic-paragraph-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockDynamicParagraph',
      templateSettingsStruct: this.templateSettingsStruct.bind(this),
      templateSettingsValidate: this.templateSettingsValidate.bind(this),
      templateSettingsOptionStruct:
        this.templateSettingsOptionStruct.bind(this),
      templateSettingsSanitize: this.templateSettingsSanitize.bind(this),
      reportSettingsStruct: this.reportSettingsStruct.bind(this),
      reportSettingsValidate: this.reportSettingsValidate.bind(this),
      titlePreview: this.titlePreview.bind(this)
    });
  }

  private titlePreview(templateSettings: TemplateSettingsStruct) {
    return templateSettings.options.map((option) => option.title).join(', ');
  }

  private templateSettingsOptionStruct(
    base: Partial<DynamicParagraphTemplateSettingsOptionStruct>
  ): DynamicParagraphTemplateSettingsOptionStruct {
    return {
      id: `${TEMPORARY_ID_PREFIX}${this.temporaryId++}`,
      title: '',
      body: '',
      ...base
    };
  }

  private templateSettingsStruct(
    base: Partial<TemplateSettingsStruct>
  ): TemplateSettingsStruct {
    if (!base.options) {
      return {options: [this.templateSettingsOptionStruct({})]};
    }

    return {
      options: base.options.map(this.templateSettingsOptionStruct.bind(this))
    };
  }

  private templateSettingsSanitize(
    templateSettings: TemplateSettingsStruct
  ): TemplateSettingsStruct {
    return {
      ...templateSettings,
      options: templateSettings.options.map((option) => ({
        ...option,
        id:
          !option.id || option.id.startsWith(TEMPORARY_ID_PREFIX)
            ? undefined
            : option.id
      }))
    };
  }

  private templateSettingsValidate(
    templateSettings: TemplateSettingsStruct
  ): object | undefined {
    let validators: Array<Validator<string, 'required'>> = [
      {
        field: 'options',
        isValid:
          Boolean(templateSettings.options) &&
          templateSettings.options.length > 0,
        code: 'required'
      }
    ];

    if (templateSettings.options) {
      templateSettings.options.forEach((option, index) => {
        validators = validators.concat([
          {
            field: `options.${index}.title`,
            isValid: Boolean(option.title),
            code: 'required'
          },
          {
            field: `options.${index}.body`,
            isValid: Boolean(option.body),
            code: 'required'
          }
        ]);
      });
    }

    return this.form.validate(validators, {
      translationKeyPrefix:
        'reports.block-definitions.dynamic-paragraph.template-settings'
    });
  }

  private reportSettingsStruct(
    base: RecursivePartial<ReportSettingsStruct>
  ): ReportSettingsStruct {
    return base;
  }

  private reportSettingsValidate(
    reportSettings: ReportSettingsStruct
  ): object | undefined {
    const validators: Array<Validator<string, 'required'>> = [
      {
        field: 'option-id',
        isValid: Boolean(reportSettings.optionId),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix:
        'reports.block-definitions.dynamic-paragraph.report-settings'
    });
  }
}
