// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';
import Form, {Validator} from 'airthings/services/form';

export interface TemplateSettingsStruct {
  title: string;
}

export interface ReportSettingsStruct {
  signatureLocation?: string;
  isManualSignature: boolean;
}

export default class SignatureBlock
  extends Service
  implements ReportsBlockService
{
  @service('form')
  form: Form;

  @service('intl')
  intl: IntlService;

  get definition(): Readonly<ReportsBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'signature',
      get name() {
        return intl.t('reports.block-definitions.signature.name');
      },
      get description() {
        return intl.t('reports.block-definitions.signature.description');
      },
      get configurationHelper() {
        return intl.t(
          'reports.block-definitions.signature.configuration-helper'
        );
      },
      get helper() {
        return intl.t('reports.block-definitions.signature.helper');
      },
      lightIconPath: '/assets/svgs/report-block-icons/signature-light.svg',
      darkIconPath: '/assets/svgs/report-block-icons/signature-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockSignature',
      componentNamespace: 'signature',
      templateSettingsStruct: this.templateSettingsStruct.bind(this),
      templateSettingsValidate: this.templateSettingsValidate.bind(this),
      reportSettingsStruct: this.reportSettingsStruct.bind(this),
      reportSettingsValidate: this.reportSettingsValidate.bind(this),
      titlePreview: this.titlePreview.bind(this)
    });
  }

  private titlePreview(templateSettings: TemplateSettingsStruct) {
    return templateSettings.title;
  }

  private templateSettingsStruct(
    base: RecursivePartial<TemplateSettingsStruct>
  ): TemplateSettingsStruct {
    return {
      title:
        base.title ||
        this.intl.t(
          'reports.block-definitions.signature.template-settings.title.default-value'
        )
    };
  }

  private templateSettingsValidate(
    templateSettings: TemplateSettingsStruct
  ): object | undefined {
    const validators: Array<Validator<string, 'required'>> = [
      {
        field: 'title',
        isValid: Boolean(templateSettings.title),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix:
        'reports.block-definitions.signature.template-settings'
    });
  }

  private reportSettingsStruct(
    base: RecursivePartial<ReportSettingsStruct>
  ): ReportSettingsStruct {
    return {
      signatureLocation: base.signatureLocation || '',
      isManualSignature: Boolean(base.isManualSignature)
    };
  }

  private reportSettingsValidate(
    _reportSettings: ReportSettingsStruct
  ): object | undefined {
    const validators: Array<Validator<string, 'required'>> = [];

    return this.form.validate(validators, {
      translationKeyPrefix:
        'reports.block-definitions.signature.report-settings'
    });
  }
}
