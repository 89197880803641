// Vendor
import {inject as service} from '@ember/service';
import {HttpLink} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {InMemoryCache, NormalizedCacheObject} from '@apollo/client/cache';
import ApolloService from 'ember-apollo-client/services/apollo';
import fetch, {Response} from 'fetch';
import * as Sentry from '@sentry/browser';

// Types
import ShoeBox from 'airthings/services/shoebox';
import Account from 'airthings/services/airthings/account';
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import Reports from 'airthings/services/airthings/reports';

// Config
import config from 'airthings/config/environment';

// Constants
const TYPE_EQUIVALENCES: {[key: string]: string} = {
  PaginatedDataset: 'Dataset',
  PaginatedUserMessage: 'Message'
};

const EMPTY_RESPONSE = '{"data": {}}';

const dataIdFromObject = (result: any): string | false => {
  if (result.id && result.__typename) {
    const typename = TYPE_EQUIVALENCES[result.__typename] || result.__typename;
    return `${typename}${result.id}`;
  }

  return false;
};

export default class Apollo extends ApolloService {
  @service('shoebox')
  shoebox: ShoeBox;

  @service('fastboot')
  fastboot: FastBoot;

  @service('airthings/account')
  account: Account;

  @service('airthings/reports')
  reports: Reports;

  clientOptions() {
    return {
      assumeImmutableResults: true,
      cache: this.cache(),
      link: this.link(),
      ssrMode: this.fastboot.isFastBoot
    };
  }

  link() {
    const httpLink = new HttpLink({
      uri: config.apollo.apiURL,
      fetch: this.buildFetchWithRefreshTokenAndRetry()
    });

    const authenticationLink = this.createAuthenticationLink();
    const errorLink = this.createErrorLink();

    return errorLink.concat(authenticationLink.concat(httpLink));
  }

  cache() {
    const cache = new InMemoryCache({
      dataIdFromObject,
      possibleTypes: this.getPossibleTypes()
    });

    const cachedContent = this.shoebox.read(
      config.apollo.SSR_CACHE_KEY
    ) as NormalizedCacheObject;

    if (!cachedContent) return cache;

    return cache.restore(cachedContent);
  }

  extractCache() {
    return this.client.cache.extract();
  }

  async clearCache() {
    await this.client.resetStore();
  }

  private createAuthenticationLink() {
    return setContext(() => {
      if (!this.account.isLoggedIn) return;

      const token = this.account.retrieveAccessToken();

      return {
        headers: {
          'x-authentication-token': token
        }
      };
    });
  }

  private createErrorLink() {
    return onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({message, locations, path}) => {
          Sentry.captureMessage(`${message}\n${locations}\n${path}`);
        });
      }

      if (networkError) {
        Sentry.captureException(networkError);
      }
    });
  }

  /* eslint-disable */
  private buildFetchWithRefreshTokenAndRetry() {
    return async (uri: string, options: RequestInit) => {
      const response = await fetch(uri, options);
      const result = await response.json();

      const isMutation =
        options.body &&
        JSON.parse(options.body.toString()).query.startsWith('mutation');

      const tokenIsExpired = this.inferTokenExpirationFromResult(
        result,
        isMutation
      );

      if (!tokenIsExpired) {
        // The viewer is not null, but we already used the body of the response,
        // send a new response to avoid errors when reading the body again
        return new Response(JSON.stringify(result));
      }

      try {
        await this.account.refreshToken();
      } catch (error) {
        // We couldn’t refresh the token somehow, log out the user
        this.account.logout();
        return new Response(EMPTY_RESPONSE);
      }

      // The refresh worked, we have a new token, retry the last call with it
      const newOptions = {
        ...options,
        headers: {
          ...options.headers,
          'x-authentication-token': this.account.retrieveAccessToken()!
        }
      };

      const retryResponse = await fetch(uri, newOptions);
      const retryResult = await retryResponse.json();

      const tokenIsStillExpired = this.inferTokenExpirationFromResult(
        retryResult,
        isMutation
      );

      if (tokenIsStillExpired) {
        // Somehow the call stills fails, log out the user
        this.account.logout();
        return new Response(EMPTY_RESPONSE);
      }

      return new Response(JSON.stringify(retryResult));
    };
  }

  private inferTokenExpirationFromResult(
    result: any,
    isMutation: boolean
  ): boolean {
    return (
      this.account.isLoggedIn &&
      (isMutation
        ? this.isUnauthorizedMutation(result)
        : this.isUnauthorizedQuery(result))
    );
  }

  private getPossibleTypes() {
    const templateBlockPossibleTypes = this.reports
      .getTemplateBlockDefinitions()
      .map(({graphqlTypeName}) => graphqlTypeName);

    return {
      ReportTemplateBlock: templateBlockPossibleTypes
    };
  }

  private isUnauthorizedMutation(result: any) {
    if (result.errors) return false;
    if (!result.data) return false;

    return Object.values(result.data).some((value: any) => {
      return (
        value?.messages?.[0]?.field === 'base' &&
        value?.messages?.[0]?.code === 'unauthorized'
      );
    });
  }

  private isUnauthorizedQuery(result: any) {
    return result.data?.viewer === null;
  }
}

declare module '@ember/service' {
  interface Registry {
    apollo: Apollo;
  }
}
