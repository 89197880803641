// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {ChoiceButtonsOption} from 'airthings/pods/components/form/choice-buttons/component';

interface Args {
  name: string;
  option: ChoiceButtonsOption;
  value: string;
  onSelect: (changedValue: string) => void;
}

export default class FormChoiceButtonsOption extends Component<Args> {
  get isChecked() {
    return this.args.option.value === this.args.value;
  }

  @action
  onKeydown(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.args.onSelect(this.args.option.value);
    }
  }
}
