export enum WeatherUnitSystem {
  UNITED_STATES = 'UNITED_STATES',
  METRIC = 'METRIC'
}

export enum WeatherSampleUnitType {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  WIND_SPEED = 'WIND_SPEED',
  PRECIPITATIONS = 'PRECIPITATIONS'
}

export type WeatherSampleUnit =
  | WeatherTemperatureUnit
  | WeatherWindSpeedUnit
  | WeatherPrecipitationsUnit
  | WeatherPressureUnit;

export enum WeatherWindSpeedUnit {
  KILOMETERS_PER_HOUR = 'KILOMETERS_PER_HOUR',
  MILES_PER_HOUR = 'MILES_PER_HOUR'
}

export enum WeatherTemperatureUnit {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum WeatherPressureUnit {
  KILOPASCAL = 'KILOPASCAL',
  INCHES_OF_MERCURY = 'INCHES_OF_MERCURY'
}

export enum WeatherPrecipitationsUnit {
  MILLIMETERS = 'MILLIMETERS',
  INCHES = 'INCHES'
}

export type WeatherChartPoint = [number, number] | WeatherWindPoint;

export interface WeatherWindPoint {
  x: number;
  y: number;
  windDirection: string;
}

export interface WeatherStruct {
  metadata: WeatherSuccessMetadataStruct | WeatherFailureMetadataStruct;
  entries: WeatherSampleStruct[];
}

export interface WeatherSuccessMetadataStruct {
  temperatureUnit: WeatherTemperatureUnit;
  pressureUnit: WeatherPressureUnit;
  windSpeedUnit: WeatherSampleUnit;
  precipitationsUnit: WeatherWindSpeedUnit;
}

export interface WeatherFailureMetadataStruct {
  error: string;
}

export interface WeatherSampleStruct {
  precipitations: number;
  pressure: number;
  temperature: number;
  timestamp: Date;
  windDirection: string;
  windSpeed: number;
}
