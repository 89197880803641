// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action, computed} from '@ember/object';
import {bool} from '@ember/object/computed';
import {tracked} from '@glimmer/tracking';
import moment from 'moment';

// Services
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';
import Weather from 'airthings/services/airthings/weather';

// Types
import {
  RecordingsDetailedDatasetStruct,
  RecordingsSampleStruct,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';
import {
  WeatherSampleStruct,
  WeatherSampleUnitType,
  WeatherSuccessMetadataStruct,
  WeatherWindPoint
} from 'airthings/types/weather';

interface Args {
  title: string;
  dataset: RecordingsDetailedDatasetStruct;
}

const DAILY_INTERVAL_THRESHOLD_IN_SECONDS = 2592000; // 30 days

export default class RecordingsWeatherInformation extends Component<Args> {
  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @service('airthings/weather')
  weather: Weather;

  @tracked
  showWeatherReportModal: Boolean = false;

  @bool('args.dataset.weatherData.metadata.error')
  hasWeatherError: boolean;

  @computed('args.dataset.weatherData.metadata.location', function () {
    const {name, region, country} =
      this.args.dataset.weatherData.metadata.location;

    return [name, region, country].filter((token) => token).join(', ');
  })
  location: string;

  @computed('args.dataset.weatherData.entries[]', function () {
    return this.args.dataset.weatherData.entries.map(
      (weatherSample: WeatherSampleStruct) => new Date(weatherSample.timestamp)
    );
  })
  dates: Date[];

  @computed('args.dataset.weatherData.entries[]', function () {
    const entries = this.args.dataset.weatherData.entries.map(
      (weatherSample: WeatherSampleStruct) =>
        new Date(weatherSample.timestamp).getTime()
    );

    return Math.min.apply(null, entries);
  })
  minWeatherTimestamp: Date;

  @computed('dates[]', function () {
    const date = Math.min.apply(null, this.dates);

    return moment(date).format('YYYY-MM-DD, h:mm a');
  })
  periodFrom: string;

  @computed('dates[]', function () {
    const date = Math.max.apply(null, this.dates);

    return moment(date).format('YYYY-MM-DD, h:mm a');
  })
  periodTo: string;

  @computed('periodFrom', 'periodTo', function () {
    return this.intl.t('weather.information.period', {
      from: this.periodFrom,
      to: this.periodTo
    });
  })
  period: string;

  // Makes sure the samples are hourly or daily, base on dataset duration
  @computed('args.dataset.hourlySamples.entries[]', function () {
    const entries =
      this.args.dataset.durationInSeconds < DAILY_INTERVAL_THRESHOLD_IN_SECONDS
        ? this.args.dataset.hourlySamples.entries
        : this.recordings.dailyRadonAverage(
            this.args.dataset.hourlySamples.entries
          );

    return entries;
  })
  radonSamples: RecordingsSampleStruct[];

  get successMetadata() {
    return this.args.dataset.weatherData
      .metadata as WeatherSuccessMetadataStruct;
  }

  radonSampleType = RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE;
  get radonSampleUnit() {
    return this.args.dataset.hourlySamples.metadata.radonUnit;
  }

  temperatureSampleType = WeatherSampleUnitType.TEMPERATURE;
  get temperatureSampleUnit() {
    return this.successMetadata.temperatureUnit;
  }

  pressureSampleType = WeatherSampleUnitType.PRESSURE;
  get pressureSampleUnit() {
    return this.successMetadata.pressureUnit;
  }

  windSpeedSampleType = WeatherSampleUnitType.WIND_SPEED;
  get windSpeedSampleUnit() {
    return this.successMetadata.windSpeedUnit;
  }
  get windSpeedSampleUnitLabel() {
    return this.weather.sampleUnitLabel(this.windSpeedSampleUnit);
  }

  precipitationsSampleType = WeatherSampleUnitType.PRECIPITATIONS;
  get precipitationsSampleUnit() {
    return this.successMetadata.precipitationsUnit;
  }

  @action
  windSpeedPointFormatter(point: WeatherWindPoint) {
    return `${point.y} ${this.windSpeedSampleUnitLabel} ${point.windDirection}`;
  }

  @action
  handleWeatherReport() {
    this.showWeatherReportModal = true;
  }

  @action
  cancelWeatherReport() {
    this.showWeatherReportModal = false;
  }

  @action
  showWeatherReport() {
    window.open(this.args.dataset.weatherReportUrl, '_blank', 'noopener');
  }
}
