// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {AccountCompanyStruct, Certificate} from 'airthings/types/account';
import API from 'airthings/services/airthings/account/api';
import Form, {Validator} from 'airthings/services/form';
import AccountUser from 'airthings/services/airthings/account/user';
import Apollo from 'airthings/services/apollo';
import AccountInvitation from 'airthings/services/airthings/account/invitation';
import {AccountCompanyUsersResponse} from 'airthings/graphql/queries/account-company-users';
import {AccountCurrentUserCompanyResponse} from 'airthings/graphql/queries/account-current-user-company';

export default class AccountCompany extends Service {
  @service('airthings/account/api')
  api: API;

  @service('form')
  form: Form;

  @service('airthings/account/invitation')
  invitation: AccountInvitation;

  @service('airthings/account/user')
  user: AccountUser;

  // eslint-disable-next-line complexity
  struct(base?: RecursivePartial<AccountCompanyStruct>): AccountCompanyStruct {
    return {
      id: '',
      information: {
        name: '',
        email: '',
        phoneNumber: '',
        ...base?.information,
        address: {
          address: '',
          city: '',
          state: '',
          country: '',
          zipCode: '',
          formattedAddress: '',
          ...base?.information?.address
        }
      },
      logo: {
        url: '',
        ...base?.logo
      },
      certificates: (base?.certificates as Certificate[]) ?? [],
      datasetSettings: {
        defaultDurationInSeconds:
          base?.datasetSettings?.defaultDurationInSeconds ?? null,
        defaultDelayInSeconds:
          base?.datasetSettings?.defaultDelayInSeconds ?? null,
        defaultMeasurementType:
          base?.datasetSettings?.defaultMeasurementType ?? null,
        roomTypeOptions:
          (base?.datasetSettings?.roomTypeOptions as string[]) ?? [],
        defaultRoomType: base?.datasetSettings?.defaultRoomType ?? null,
        floorOptions: (base?.datasetSettings?.floorOptions as string[]) ?? [],
        defaultFloor: base?.datasetSettings?.defaultFloor ?? null,
        buildingTypeOptions:
          (base?.datasetSettings?.buildingTypeOptions as string[]) ?? [],
        defaultBuildingType: base?.datasetSettings?.defaultBuildingType ?? null,
        foundationTypeOptions:
          (base?.datasetSettings?.foundationTypeOptions as string[]) ?? [],
        defaultFoundationType:
          base?.datasetSettings?.defaultFoundationType ?? null,
        ventilationOptions:
          (base?.datasetSettings?.ventilationOptions as string[]) ?? [],
        defaultVentilation: base?.datasetSettings?.defaultVentilation ?? null,
        mitigationSystemOptions:
          (base?.datasetSettings?.mitigationSystemOptions as string[]) ?? [],
        defaultMitigationSystem:
          base?.datasetSettings?.defaultMitigationSystem ?? null
      }
    };
  }

  async createCompany(company: AccountCompanyStruct) {
    const mutationResponse = await this.api.createCompany(company);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'account.company'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async assignFavoriteReportTemplate(reportTemplateId: string) {
    const mutationResponse =
      await this.api.assignFavoriteReportTemplateToCompany(reportTemplateId);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'account.company'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async updateCompany(company: AccountCompanyStruct) {
    const mutationResponse = await this.api.updateCompany(company);

    const messages = this.form.validateMutationResponse(mutationResponse, {
      translationKeyPrefix: 'account.company'
    });

    return {
      ...mutationResponse,
      messages
    };
  }

  async updateCompanyDatasetSettings(company: AccountCompanyStruct) {
    return this.api.updateCompanyDatasetSettings(company);
  }

  async watchCurrentUserCompany(queryManager: Apollo) {
    return this.api.watchCurrentUserCompany(queryManager);
  }

  async watchUsers(queryManager: Apollo) {
    return this.api.watchCompanyUsers(queryManager);
  }

  validate(company: AccountCompanyStruct) {
    let validators: Array<Validator<string, 'required' | 'format'>> = [];

    if (company.information) {
      validators = validators.concat([
        {
          field: 'name',
          isValid: Boolean(company.information.name),
          code: 'required'
        },
        {
          field: 'email',
          isValid: Boolean(company.information.email),
          code: 'required'
        },
        {
          field: 'phone-number',
          isValid: Boolean(company.information.phoneNumber),
          code: 'required'
        }
      ]);
    }

    if (company.certificates) {
      company.certificates.forEach(
        (certificate: Certificate, index: number) => {
          validators = validators.concat([
            {
              field: `certificates.${index}.name`,
              isValid: Boolean(certificate.name),
              code: 'required'
            },
            {
              field: `certificates.${index}.number`,
              isValid: Boolean(certificate.number),
              code: 'required'
            },
            {
              field: `certificates.${index}.expiration-date`,
              isValid:
                !Boolean(certificate.expirationDate) ||
                /^\d{2}\/\d{2}\/\d{4}$/.test(certificate.expirationDate),
              code: 'format'
            }
          ]);
        }
      );
    }

    return this.form.validate(validators, {
      translationKeyPrefix: 'account.company'
    });
  }

  presentAccountCompanyUsers(viewer: AccountCompanyUsersResponse['viewer']) {
    return {
      users: viewer.company.users.map((user) => {
        return this.user.struct({
          id: user.id,
          role: user.profile.role,
          companyId: viewer.company.id,
          information: {
            name: user.profile.name,
            email: user.profile.email
          }
        });
      }),
      pendingInvitations: viewer.company.pendingInvitations.map(
        (pendingInvitation) => {
          return this.invitation.struct({
            id: pendingInvitation.id,
            name: pendingInvitation.name,
            email: pendingInvitation.email,
            role: pendingInvitation.role,
            expiredAt: pendingInvitation.expiredAt,
            acceptedAt: pendingInvitation.acceptedAt
          });
        }
      )
    };
  }

  presentAccountUserCompany(
    viewer: AccountCurrentUserCompanyResponse['viewer']
  ) {
    const company = viewer.company;

    return this.struct({
      id: company.id,
      information: {
        id: company.id,
        name: company.name,
        email: company.email,
        phoneNumber: company.phoneNumber,
        address: {
          address: company.address.address,
          city: company.address.city,
          country: company.address.country,
          formattedAddress: company.address.formattedAddress,
          state: company.address.state,
          zipCode: company.address.zipCode
        }
      },
      logo: {
        url: company.logoUrl
      },
      certificates: company.certificates.map((certificate) => {
        return {
          name: certificate.name,
          number: certificate.number,
          expirationDate: certificate.expirationDate
        };
      }),
      datasetSettings: company.datasetSettings
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/account/company': AccountCompany;
  }
}
