// Vendor
import Service, {inject as service} from '@ember/service';
import API from 'airthings/services/airthings/reports/api';

export default class Report extends Service {
  @service('airthings/reports/api')
  api: API;

  async fetchReportByUUID(reportUUID: string) {
    return this.api.fetchReportByUUID(reportUUID);
  }

  async fetchMockedReport(reportTemplateId: string) {
    return this.api.fetchMockedReport(reportTemplateId);
  }

  async fetchDatasetByExternalId(datasetExternalId: string) {
    return this.api.fetchDatasetByExternalId(datasetExternalId);
  }

  async generateReport(reportId: string) {
    return this.api.generateReport(reportId);
  }

  async sendReportByEmail(
    reportId: string,
    recipientEmails: string[],
    ccCurrentUser: boolean,
    customMessage: string,
    includeCompanyLogo: boolean
  ) {
    return this.api.sendReportByEmail(
      reportId,
      recipientEmails,
      ccCurrentUser,
      customMessage,
      includeCompanyLogo
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/reports/report': Report;
  }
}
