// Vendor
import Service, {inject as service} from '@ember/service';

// Config
import Metrics from 'ember-metrics/services/metrics';
import config from 'airthings/config/environment';

// Types
import {UserRole} from 'airthings/types/account';
import StateManager from 'airthings/services/state-manager';
import ValidationErrors from 'airthings/services/form';
import {Certificate} from 'airthings/types/account';
import WebviewBridge from 'airthings/services/webview-bridge';

enum AnalyticEvents {
  ACCESS_INVITATION_ALREADY_ACCEPTED = 'accessUserInviteAlreadyAccepted',
  ACCESS_INVITATION_EXPIRED = 'accessUserInviteExpired',
  ACCESS_INVITATION_UNKNOWN = 'accessUserInviteUnknown',
  AFFILIATE_PROGRAM_SUBSCRIPTION_SUCCEEDED = 'joinAffiliate',
  AFFILIATE_PROGRAM_SUBSCRIPTION_FAILED = 'joinAffiliateError',
  COMPANY_CREATED = 'companyCreated',
  COMPANY_CREATION_FAILED = 'companyCreationError',
  DEVICE_ADDED = 'deviceAdded',
  DEVICE_CREATION_FAILED = 'deviceAddedError',
  DEVICE_DELETED = 'deviceDeleted',
  DATASET_DELETED = 'datasetDeleted',
  INVITATION_DELETED = 'userInviteDeleted',
  INVITATION_RESENT = 'userInviteResent',
  INVITATION_SENT = 'userInviteSent',
  MAIN_NAVIGATION_CLICK = 'mainNavClick',
  MAIN_NAVIGATION_EXPAND = 'mainNavExpand',
  MOBILE_MENU_COLLAPSE = 'mobileMenuCollapse',
  MOBILE_MENU_EXPAND = 'mobileMenuExpand',
  PAGE_VIEW = 'virtualPageView',
  REPORT_CONFIGURATION = 'reportConfiguration',
  REPORT_GENERATION_FAILURE = 'errorReportGeneration',
  REPORT_GENERATION_SUCCESS = 'reportGenerated',
  REPORT_TEMPLATE_BLOCK_CONFIGURED = 'reportTemplateBlockConfiguration',
  REPORT_TEMPLATE_BLOCKS_REORDERED = 'reportTemplateBlocksReorder',
  REPORT_TEMPLATE_CREATED = 'reportTemplateCreated',
  REPORT_TEMPLATE_CREATION_STARTED = 'createReportTemplateClick',
  REPORT_TEMPLATE_CREATION_ABORTED = 'reportTemplateCreationCancelled',
  REPORT_TEMPLATE_DELETED = 'reportTemplateDeleted',
  REPORT_TEMPLATE_EDITION_STARTED = 'reportTemplateEditionStart',
  REPORT_TEMPLATE_CONFIGURATION_STARTED = 'reportTemplateConfigurationStart',
  REPORT_TEMPLATE_PUBLISHED = 'reportTemplatePublication',
  USER_PROFILE_UPDATED = 'userProfileUpdated',
  USER_PROFILE_UPDATE_FAILED = 'userProfileUpdateError',
  USER_DELETED = 'userDeleted',
  USER_SIDEBAR_EXPAND = 'userSidebarExpand',
  USER_SIDEBAR_COLLAPSE = 'userSidebarCollapse',
  USER_SIDEBAR_NAVIGATION_CLICK = 'userSidebarNavClick'
}
const GENERIC_ERROR_VALUE = 'generic error';
const MAIN_NAVIGATION_SECTION_LABELS = {
  'datasets': 'leftPanelDatasets',
  'dataset-settings': 'leftPanelDatasetSettingss',
  'report-templates': 'leftPanelReportTemplates',
  'devices': 'leftPanelMonitors',
  'users': 'leftPanelUsers',
  'company': 'leftPanelCompany',
  'affiliate-program': 'leftPanelAffiliateProgram'
};
const USER_SIDEBAR_NAVIGATION_SECTION_LABELS = {
  training: 'leftPanelTraining',
  help: 'leftPanelHelp',
  logout: 'leftPanelLogout'
};

export default class Analytics extends Service {
  @service('metrics')
  metrics: Metrics;

  @service('state-manager')
  stateManager: StateManager;

  @service('webview-bridge')
  webviewBridge: WebviewBridge;

  analytics: Analytics;
  trackPageView(url: string) {
    this.trackEvent(AnalyticEvents.PAGE_VIEW, {url});
  }

  trackCompanyCreation(company: {
    id: string;
    logoUrl: string | null;
    certificates: object[];
  }) {
    const logoAdded = company.logoUrl && company.logoUrl !== '';
    const companyCertificationAdded = company.certificates.length > 0;

    this.trackEvent(AnalyticEvents.COMPANY_CREATED, {
      companyId: company.id,
      logoAdded,
      companyCertificationAdded
    });
  }

  trackCompanyCreationValidationFailure(errors: ValidationErrors) {
    const validationError = this.formatValidationError(errors);

    this.trackEvent(AnalyticEvents.COMPANY_CREATION_FAILED, {
      companyCreationErrorType: validationError
    });
  }

  trackCompanyCreationFailure() {
    this.trackEvent(AnalyticEvents.COMPANY_CREATION_FAILED, {
      companyCreationErrorType: GENERIC_ERROR_VALUE
    });
  }

  trackDeviceCreation(device: {serialNumber: string}) {
    this.trackEvent(AnalyticEvents.DEVICE_ADDED, {
      deviceSerialNumber: device.serialNumber
    });
  }

  trackDeviceCreationValidationFailure(errors: ValidationErrors) {
    const validationError = this.formatValidationError(errors);

    this.trackEvent(AnalyticEvents.DEVICE_CREATION_FAILED, {
      deviceCreationErrorType: validationError
    });
  }

  trackDeviceCreationFailure() {
    this.trackEvent(AnalyticEvents.DEVICE_CREATION_FAILED, {
      deviceCreationErrorType: GENERIC_ERROR_VALUE
    });
  }

  trackDeviceDeletion(device: {serialNumber: string}) {
    this.trackEvent(AnalyticEvents.DEVICE_DELETED, {
      deviceSerialNumber: device.serialNumber
    });
  }

  trackDatasetDeletion(dataset: {id: string}) {
    this.trackEvent(AnalyticEvents.DATASET_DELETED, {
      datasetId: dataset.id
    });
  }

  trackReportGenerationSuccess(report: {dataset: {id: string}}) {
    this.trackEvent(AnalyticEvents.REPORT_GENERATION_SUCCESS, {
      datasetId: report.dataset.id
    });
  }

  trackReportGenerationFailure(report: {dataset: {id: string}}) {
    this.trackEvent(AnalyticEvents.REPORT_GENERATION_FAILURE, {
      datasetId: report.dataset.id
    });
  }

  trackReportConfiguration(dataset: {id: string}, options = {}) {
    this.trackEvent(AnalyticEvents.REPORT_CONFIGURATION, {
      ...options,
      datasetId: dataset.id
    });
  }

  trackInvitationSent(invitation: {role: UserRole}) {
    this.trackEvent(AnalyticEvents.INVITATION_SENT, {role: invitation.role});
  }

  trackInvitationResent(invitation: {role: UserRole}) {
    this.trackEvent(AnalyticEvents.INVITATION_RESENT, {role: invitation.role});
  }

  trackInvitationDeletion(invitation: {role: UserRole}) {
    this.trackEvent(AnalyticEvents.INVITATION_DELETED, {role: invitation.role});
  }

  trackAccessInvitationUnknown() {
    this.trackEvent(AnalyticEvents.ACCESS_INVITATION_UNKNOWN);
  }

  trackAccessInvitationAlreadyAccepted(invitation: {company: {id: string}}) {
    this.trackEvent(AnalyticEvents.ACCESS_INVITATION_ALREADY_ACCEPTED, {
      companyId: invitation.company.id
    });
  }

  trackAccessInvitationExpired(invitation: {company: {id: string}}) {
    this.trackEvent(AnalyticEvents.ACCESS_INVITATION_EXPIRED, {
      companyId: invitation.company.id
    });
  }

  trackUserDeletion(user: {profile: {role: UserRole}}) {
    this.trackEvent(AnalyticEvents.USER_DELETED, {
      role: user.profile.role
    });
  }

  trackMainNavigation(section: string) {
    this.trackEvent(AnalyticEvents.MAIN_NAVIGATION_CLICK, {
      section:
        MAIN_NAVIGATION_SECTION_LABELS[
          section as keyof typeof MAIN_NAVIGATION_SECTION_LABELS
        ]
    });
  }

  trackMainNavigationExpand() {
    this.trackEvent(AnalyticEvents.MAIN_NAVIGATION_EXPAND);
  }

  trackUserSidebarNavigation(section: string) {
    this.trackEvent(AnalyticEvents.MAIN_NAVIGATION_CLICK, {
      section:
        USER_SIDEBAR_NAVIGATION_SECTION_LABELS[
          section as keyof typeof USER_SIDEBAR_NAVIGATION_SECTION_LABELS
        ]
    });
  }

  trackUserSidebarExpand() {
    this.trackEvent(AnalyticEvents.USER_SIDEBAR_EXPAND);
  }

  trackUserSidebarCollapse() {
    this.trackEvent(AnalyticEvents.USER_SIDEBAR_COLLAPSE);
  }

  trackMobileMenuExpand() {
    this.trackEvent(AnalyticEvents.MOBILE_MENU_EXPAND);
  }

  trackMobileMenuCollapse() {
    this.trackEvent(AnalyticEvents.MOBILE_MENU_COLLAPSE);
  }

  trackAffiliateProgramSubscriptionSuccess() {
    this.trackEvent(AnalyticEvents.AFFILIATE_PROGRAM_SUBSCRIPTION_SUCCEEDED);
  }

  trackAffiliateProgramSubscriptionFailure() {
    this.trackEvent(AnalyticEvents.AFFILIATE_PROGRAM_SUBSCRIPTION_FAILED, {
      affiliateProgramSubscriptionErrorType: GENERIC_ERROR_VALUE
    });
  }

  trackAffiliateProgramSubscriptionValidationFailure(errors: ValidationErrors) {
    const validationError = this.formatValidationError(errors);

    this.trackEvent(AnalyticEvents.AFFILIATE_PROGRAM_SUBSCRIPTION_FAILED, {
      affiliateProgramSubscriptionErrorType: validationError
    });
  }

  trackReportTemplateCreationStart() {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_CREATION_STARTED);
  }

  trackReportTemplateCreation(template: {id: string | null}) {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_CREATED, {
      templateId: template.id
    });
  }

  trackReportTemplateCreationAbort() {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_CREATION_ABORTED);
  }

  trackReportTemplateEditionStart(template: {id: string | null}) {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_EDITION_STARTED, {
      templateId: template.id
    });
  }

  trackReportTemplateConfigurationStart(template: {id: string | null}) {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_CONFIGURATION_STARTED, {
      templateId: template.id
    });
  }

  trackProfileUpdate(user: {
    id: string | null;
    certificates?: Certificate[];
    preferredUnitSystem: string;
  }) {
    const profileCertificationAdded = user.certificates
      ? user.certificates.length > 0
      : false;

    this.trackEvent(AnalyticEvents.USER_PROFILE_UPDATED, {
      updatedUserId: user.id,
      preferredUnitSystem: user.preferredUnitSystem,
      profileCertificationAdded
    });
  }

  trackProfileUpdateValidationFailure(
    user: {id: string | null},
    errors: ValidationErrors
  ) {
    const validationError = this.formatValidationError(errors);

    this.trackEvent(AnalyticEvents.USER_PROFILE_UPDATE_FAILED, {
      updatedUserId: user.id,
      userProfileUpdateErrorType: validationError
    });
  }

  trackProfileUpdateFailure(user: {id: string | null}) {
    this.trackEvent(AnalyticEvents.USER_PROFILE_UPDATE_FAILED, {
      updatedUserId: user.id,
      userProfileUpdateErrorType: GENERIC_ERROR_VALUE
    });
  }

  trackReportTemplateBlockConfigure(
    template: {id: string},
    options: {
      templateEditionAction: string;
      block: {key: string};
    }
  ) {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_BLOCK_CONFIGURED, {
      elementName: options.block.key,
      templateEditionAction: options.templateEditionAction,
      templateId: template.id
    });
  }

  trackReportTemplatePublication(template: {id: string}) {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_PUBLISHED, {
      templateId: template.id
    });
  }

  trackReportTemplateDeletion(template: {id: string}) {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_DELETED, {
      templateId: template.id
    });
  }

  trackReportTemplateBlocksReorder(template: {id: string}) {
    this.trackEvent(AnalyticEvents.REPORT_TEMPLATE_BLOCKS_REORDERED, {
      templateId: template.id
    });
  }

  private currentUserInfo() {
    return {
      userId: this.stateManager.currentUser?.id,
      companyId: this.stateManager.currentUser?.companyId
    };
  }

  private trackEvent(event: AnalyticEvents, options = {}) {
    const eventProperties = {
      event,
      timestamp: Date.now(),
      ...this.currentUserInfo(),
      ...options
    };

    if (config.analytics.debugTools) {
      // eslint-disable-next-line no-console
      console.log('[Analytics]', eventProperties);
    }

    if (this.webviewBridge.isEmbedded) {
      this.webviewBridge.triggerAnalyticsEvent(eventProperties);
    } else {
      this.metrics.trackEvent(eventProperties);
    }
  }

  private formatValidationError(errors: ValidationErrors) {
    if (!errors) return GENERIC_ERROR_VALUE;

    const fields = Object.keys(errors);

    return errors[fields[0] as keyof typeof errors];
  }
}

declare module '@ember/service' {
  interface Registry {
    analytics: Analytics;
  }
}
