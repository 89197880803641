// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';

// Constants
import {Keyboard} from 'airthings/constants/keyboard';

// Types
import {AdminRecordingsDeviceStruct} from 'airthings/types/admin/recordings';

interface Args {
  devices: Array<
    RecursivePartial<AdminRecordingsDeviceStruct> & {loading: boolean}
  >;
  validDeviceCount: number;
  invalidDeviceCount: number;
  onAddDevice: (serialNumber: string) => void;
}

export default class DevicesMoveSelectDevices extends Component<Args> {
  @tracked
  input: string = '';

  get isContinueDisabled() {
    return this.args.validDeviceCount === 0;
  }

  get devices() {
    return this.args.devices.map((device, index) => ({
      ...device,
      index: index + 1
    }));
  }

  get hasInvalidDevices() {
    return this.args.invalidDeviceCount > 0;
  }

  @action
  handleKeydown(event: KeyboardEvent) {
    if (event.key !== Keyboard.ENTER) return;

    this.input = (event.target as HTMLInputElement).value;
    this.addDevice();
  }

  @action
  changeInput(value: string) {
    this.input = value;
  }

  @action
  addDevice() {
    this.args.onAddDevice(this.input);
    this.input = '';
  }
}
