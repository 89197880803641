// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import * as Sentry from '@sentry/browser';

// Types
import Account from 'airthings/services/airthings/account';
import Analytics from 'airthings/services/analytics';
import RouterService from '@ember/routing/router-service';
import StateManager from 'airthings/services/state-manager';
import Transition from '@ember/routing/-private/transition';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';

export default class LoginRoute extends Route {
  @service('analytics')
  analytics: Analytics;

  @service('state-manager')
  stateManager: StateManager;

  @service('airthings/account')
  account: Account;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  constructor() {
    super(...arguments);

    const router = this.router;

    router.on('routeDidChange', () => {
      this.analytics.trackPageView(router.currentURL);
    });
  }

  beforeModel(transition: Transition) {
    if (this.account.isLoggedIn) {
      transition.abort();

      this.router.replaceWith('app');

      return;
    }

    if (transition.to.queryParams.error) {
      this.flashMessages.danger(
        this.intl.t(`login.errors.${transition.to.queryParams.error}`),
        {sticky: true}
      );
    }

    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });

    this.stateManager.setCurrentUser(null);
  }
}
