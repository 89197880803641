export default {
  "font-bold": "700",
  "container": "_container_e3ztt",
  "error": "_error_e3ztt",
  "badge": "_badge_e3ztt",
  "icon": "_icon_e3ztt",
  "message": "_message_e3ztt",
  "actions": "_actions_e3ztt",
  "action": "_action_e3ztt"
};
