// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

interface Args {
  label: string;
  confirmLabel: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default class ConfirmAction extends Component<Args> {
  @tracked
  isConfirming: boolean;

  @action
  askConfirmation() {
    this.isConfirming = true;
  }

  @action
  cancelAction() {
    this.isConfirming = false;
    if (typeof this.args.onCancel === 'function') this.args.onCancel();
  }

  @action
  confirmAction() {
    this.isConfirming = false;
    if (typeof this.args.onConfirm === 'function') this.args.onConfirm();
  }
}
