export default {
  "font-medium": "500",
  "font-demi-bold": "600",
  "font-bold": "700",
  "block": "_block_w9j3be _gray-box_vbfmil",
  "header": "_header_w9j3be _dark-box-header_vbfmil",
  "title": "_title_w9j3be _dark-box-header-title_vbfmil",
  "row": "_row_w9j3be",
  "tampering-row": "_tampering-row_w9j3be",
  "value": "_value_w9j3be",
  "uncertainty-row": "_uncertainty-row_w9j3be",
  "column": "_column_w9j3be",
  "label-column": "_label-column_w9j3be",
  "label": "_label_w9j3be",
  "tampering-description": "_tampering-description_w9j3be",
  "tampering-events-list": "_tampering-events-list_w9j3be",
  "tampering-events-list-item": "_tampering-events-list-item_w9j3be",
  "tampering-event-date": "_tampering-event-date_w9j3be",
  "tampering-events-sublist": "_tampering-events-sublist_w9j3be",
  "tampering-events-sublist-item": "_tampering-events-sublist-item_w9j3be",
  "row-label": "_row-label_w9j3be _pill-label_vbfmil",
  "radon-level-label": "_radon-level-label_w9j3be _pill-label_vbfmil",
  "pressure-label": "_pressure-label_w9j3be _pill-label_vbfmil",
  "temperature-label": "_temperature-label_w9j3be _pill-label_vbfmil",
  "tampering-label": "_tampering-label_w9j3be _pill-label_vbfmil",
  "humidity-label": "_humidity-label_w9j3be _pill-label_vbfmil"
};
