// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Utils
import parsePageParam from 'airthings/utils/parse-page-param';

// Types
import AdminAccount from 'airthings/services/airthings/admin/account';
import Apollo from 'airthings/services/apollo';

interface RouteParams {
  page?: string;
  q?: string;
}

export default class AdminCompaniesIndexRoute extends Route {
  @service('airthings/admin/account')
  adminAccount: AdminAccount;

  @queryManager
  queryManager: Apollo;

  queryParams = {
    page: {
      refreshModel: true
    },
    q: {
      refreshModel: true
    }
  };

  async model(params: RouteParams) {
    const companies = await this.adminAccount.watchPaginatedCompanies(
      this.queryManager,
      parsePageParam(params),
      params.q
    );

    return {
      companies,
      searchTerm: params.q
    };
  }
}
