export default {
  "font-bold": "700",
  "container": "_container_d0dv1g",
  "success": "_success_d0dv1g",
  "badge": "_badge_d0dv1g",
  "icon": "_icon_d0dv1g",
  "message": "_message_d0dv1g",
  "actions": "_actions_d0dv1g",
  "action": "_action_d0dv1g",
  "download-button": "_download-button_d0dv1g"
};
