// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import WebviewBridge from 'airthings/services/webview-bridge';
import {AccountUserStruct} from 'airthings/types/account';
import {RecordingsListDatasetStruct} from 'airthings/types/recordings';

interface Args {
  dataset?: RecordingsListDatasetStruct;
  currentUser: AccountUserStruct;
}

export default class PageMobileReportConfiguration extends Component<Args> {
  @service('webview-bridge')
  webviewBridge: WebviewBridge;

  @action
  cancelReportGeneration() {
    this.webviewBridge.triggerReportConfigurationCancel();
  }

  @action
  scrollTop() {
    document.body.scrollTop = 0;
  }
}
