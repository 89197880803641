// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {bool} from '@ember/object/computed';
import {tracked} from '@glimmer/tracking';

interface Args {
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  onChange: (changedValues: string[]) => void;
  required?: boolean;
  values: string[];
}

export default class FormTagsInput extends Component<Args> {
  @bool('args.error')
  isErrored: boolean;

  @tracked
  isFocused: boolean = false;

  get hasValues() {
    return this.args.values.length > 0;
  }

  get disabled() {
    if (typeof this.args.onChange !== 'function') return true;

    return this.args.disabled;
  }

  @action
  addValue(value: string) {
    this.args.onChange([...this.args.values, value]);
  }

  @action
  removeValueAtIndex(removingIndex: number) {
    const filteredValues = this.args.values.filter(
      (_tag, index) => index !== removingIndex
    );

    this.args.onChange([...filteredValues]);
  }

  @action
  handleDidInsert(container: HTMLElement) {
    const input = container.querySelector('input');
    if (!input) return;

    input.addEventListener('focus', this.handleTagInputFocus.bind(this));
    input.addEventListener('blur', this.handleTagInputBlur.bind(this));
  }

  @action
  handleWillDestroy(container: HTMLElement) {
    const input = container.querySelector('input');
    if (!input) return;

    input.removeEventListener('focus', this.handleTagInputFocus.bind(this));
    input.removeEventListener('blur', this.handleTagInputBlur.bind(this));
  }

  private handleTagInputFocus() {
    this.isFocused = true;
  }

  private handleTagInputBlur() {
    this.isFocused = false;
  }
}
