// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {
  MarkerClusterer,
  SuperClusterAlgorithm
} from '@googlemaps/markerclusterer';

// Services
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import Geolocator from 'airthings/services/geolocator';

// Types
import {AccountUserStruct, PremiumFeatureSlug} from 'airthings/types/account';
import {DeviceWithLocationStruct} from 'airthings/types/recordings';

// Utils
import {destinationUrl} from 'airthings/utils/google-maps';

type MapDevice = DeviceWithLocationStruct & {marker: google.maps.Marker};

interface Args {
  currentUser: AccountUserStruct;
  devices: MapDevice[];
}

// Defaults to USA geographic center
const DEFAULT_ZOOM = 5;
const DEFAULT_LAT = 39.8283459;
const DEFAULT_LNG = -98.5816684;

// Zoom in when geolocated
const GEOLOCATED_ZOOM = 12;

const SVG_SIZE = 48;
const SVG_SIZE_ACTIVE = 54;
const SVG = window.btoa(
  '<svg viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg" style="filter: drop-shadow(0 3px 3px rgb(0, 0, 0, 0.2));"><g><rect x="1" width="32" height="32" rx="16" fill="#43525B"/><rect x="2.5" y="1.5" width="29" height="29" rx="14.5" stroke="#fff" stroke-width="3"/></g><g><path d="m17 37 6-8H11l6 8Z" fill="#fff"/></g></svg>'
);
const SVG_ACTIVE = window.btoa(
  '<svg viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg" style="filter: drop-shadow(0 3px 3px rgb(0, 0, 0, 0.2));"><g><rect x="1" width="32" height="32" rx="16" fill="#FEBF00"/><rect x="2.5" y="1.5" width="29" height="29" rx="14.5" stroke="#fff" stroke-width="3"/></g><g><path d="m17 37 6-8H11l6 8Z" fill="#fff"/></g></svg>'
);
const DEVICE_MAP_ICON = {
  url: `data:image/svg+xml;base64,${SVG}`,
  scaledSize: new google.maps.Size(SVG_SIZE, SVG_SIZE)
};
const DEVICE_MAP_ICON_ACTIVE = {
  url: `data:image/svg+xml;base64,${SVG_ACTIVE}`,
  scaledSize: new google.maps.Size(SVG_SIZE_ACTIVE, SVG_SIZE_ACTIVE)
};
const HIGHLIGHT_ZINDEX = 10;
const HIGHLIGHT_ZOOM = 15;

const renderer = {
  render: ({
    count,
    position
  }: {
    count: number;
    position?: google.maps.MarkerOptions['position'];
  }) => {
    const svg = window.btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" style="filter: drop-shadow(0 3px 3px rgb(0, 0, 0, 0.2));">
        <circle cx="120" cy="120" r="110" fill="#ABB2B7" />
        <circle cx="120" cy="120" r="90" fill="#7E888E" />
      </svg>`);

    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(SVG_SIZE, SVG_SIZE)
      },
      label: {
        text: String(count),
        color: 'rgba(255,255,255,0.9)',
        fontSize: '12px'
      },
      title: `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
    });
  }
};

const algorithm = new SuperClusterAlgorithm({maxZoom: HIGHLIGHT_ZOOM - 1});

export default class PageAppDevicesMap extends Component<Args> {
  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('geolocator')
  geolocator: Geolocator;

  readonly defaultZoom: number = DEFAULT_ZOOM;
  readonly mapPremiumFeatureSlug: string = PremiumFeatureSlug.DEVICES_MAP;

  get highlightedDeviceId() {
    return this.highlightedDevice?.id;
  }

  get mapStyles() {
    return [
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [{visibility: 'off'}]
      },
      {
        featureType: 'poi',
        stylers: [{visibility: 'off'}]
      },
      {
        featureType: 'transit',
        stylers: [{visibility: 'off'}]
      }
    ];
  }

  deviceNodes: {[key: string]: HTMLElement} = {};
  map: google.maps.Map;
  mapCenterPosition: google.maps.LatLng;

  @tracked
  mapDevices: MapDevice[] = [];

  @tracked
  highlightedDevice: MapDevice;

  @tracked
  searchTerm: string = '';

  @tracked
  geolocationDisabled: boolean = true;

  @tracked
  markerCluster: MarkerClusterer;

  @tracked
  geolocating: boolean = false;

  get devices() {
    return this.mapDevices
      .filter((device: MapDevice) => {
        return this.matchesSearchTerm(device);
      })
      .sort((a: MapDevice, b: MapDevice) => {
        const sortA = a.name.toLocaleLowerCase();
        const sortB = b.name.toLocaleLowerCase();

        if (sortA < sortB) return -1;
        if (sortA > sortB) return 1;
        return 0;
      });
  }

  @action
  addDeviceNode(device: MapDevice, node: HTMLElement) {
    this.deviceNodes[device.id] = node;
  }

  @action
  highlightDevice(device: MapDevice) {
    if (this.highlightedDevice) {
      this.highlightedDevice.marker.setZIndex(0);
      this.highlightedDevice.marker.setIcon(DEVICE_MAP_ICON);
    }

    this.highlightedDevice = device;
    this.deviceNodes[device.id].scrollIntoView({behavior: 'smooth'});
    this.map.setZoom(HIGHLIGHT_ZOOM);
    this.map.panTo({lat: device.latitude, lng: device.longitude});
    device.marker.setIcon(DEVICE_MAP_ICON_ACTIVE);
    device.marker.setZIndex(HIGHLIGHT_ZINDEX);
  }

  @action
  onMapInitialized({map}: {map: google.maps.Map}) {
    this.map = map;
    this.generateMapDevices();
    this.geoLocate();
  }

  @action
  recenterMap(map: google.maps.Map) {
    if (!this.mapCenterPosition) return this.geoLocate();

    map.setCenter(this.mapCenterPosition);
  }

  @action
  zoomIn(map: google.maps.Map) {
    const zoom = map.getZoom() || DEFAULT_ZOOM;

    map.setZoom(zoom + 1);
  }

  @action
  zoomOut(map: google.maps.Map) {
    const zoom = map.getZoom() || DEFAULT_ZOOM;

    map.setZoom(zoom - 1);
  }

  @action
  search(term: string) {
    this.searchTerm = term;
    this.refreshMarkers();
  }

  @action
  clearSearch() {
    this.searchTerm = '';
    this.refreshMarkers();
  }

  refreshMarkers() {
    const markers = this.mapDevices
      .filter(this.matchesSearchTerm.bind(this))
      .map((device: MapDevice) => device.marker);

    this.markerCluster.clearMarkers();
    this.markerCluster.addMarkers(markers);
  }

  generateMapDevices() {
    const markers: google.maps.Marker[] = [];
    const map = this.map;

    this.mapDevices = this.args.devices.map((device) => {
      const marker = new google.maps.Marker({
        icon: DEVICE_MAP_ICON,
        position: {
          lat: device.latitude,
          lng: device.longitude
        },
        map: this.map
      });

      markers.push(marker);

      const deviceWithMarker = {
        ...device,
        marker,
        destinationUrl: destinationUrl(device.address)
      };

      marker.addListener('click', () => {
        this.highlightDevice(deviceWithMarker);
      });

      return deviceWithMarker;
    });

    this.markerCluster = new MarkerClusterer({
      map,
      markers,
      renderer,
      algorithm
    });
  }

  geoLocate() {
    this.mapCenterPosition = new google.maps.LatLng(DEFAULT_LAT, DEFAULT_LNG);
    this.map.setCenter(this.mapCenterPosition);
    this.geolocating = true;

    this.geolocator.geolocate(
      (position: google.maps.LatLng) => {
        this.mapCenterPosition = position;
        this.geolocationDisabled = false;
        this.geolocating = false;
        this.map.setCenter(position);
        this.map.setZoom(GEOLOCATED_ZOOM);

        new google.maps.Marker({
          position,
          map: this.map,
          icon: '/assets/images/current-position.png'
        });
      },
      (error: string) => {
        this.flashMessages.danger(error);
        this.geolocationDisabled = true;
        this.geolocating = false;
      }
    );
  }

  matchesSearchTerm(device: MapDevice) {
    const term = this.searchTerm.trim().toLocaleLowerCase();

    return (
      term.length === 0 ||
      device.name.toLowerCase().includes(term) ||
      device.serialNumber.toLowerCase().includes(term)
    );
  }
}
