export default {
  "font-bold": "700",
  "block": "_block_92oslz _light-box-wrapper_vbfmil",
  "content": "_content_92oslz _light-box-content_vbfmil",
  "header": "_header_92oslz _light-box-header_vbfmil",
  "with-block-definition": "_with-block-definition_92oslz",
  "icon": "_icon_92oslz _header-icon_vbfmil",
  "title": "_title_92oslz _light-box-header-title_vbfmil",
  "two-column-table": "_two-column-table_92oslz _two-column-table_vbfmil"
};
