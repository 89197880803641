// Vendor
import Component from '@glimmer/component';
import move from 'ember-animated/motions/move';
import {fadeOut, fadeIn} from 'ember-animated/motions/opacity';

// Types
import {ReportsBlockStruct} from 'airthings/types/reports';
import {Sprite} from 'ember-animated';

interface Args {
  blocks: ReportsBlockStruct[];
  isDeletingBlock: boolean;
  onEdit: (block: ReportsBlockStruct) => void;
  onDelete: (block: ReportsBlockStruct) => void;
  onReorder: (reorderedBlocks: ReportsBlockStruct[]) => void;
}

interface TransitionParams {
  keptSprites: Sprite[];
  insertedSprites: Sprite[];
  removedSprites: Sprite[];
}

export default class ReportsConfiguredBlockList extends Component<Args> {
  *transition({
    keptSprites,
    insertedSprites,
    removedSprites
  }: TransitionParams) {
    for (const sprite of keptSprites) {
      move(sprite);
    }

    for (const sprite of insertedSprites) {
      fadeIn(sprite);
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite);
    }
  }
}
