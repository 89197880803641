// Vendor
import Controller from '@ember/controller';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import Recordings from 'airthings/services/airthings/recordings';

export default class AppDatasetsEditController extends Controller {
  @tracked
  uploadingAttachmentsCount: number = 0;

  @service('airthings/recordings')
  recordings: Recordings;

  get hasUploadingAttachments() {
    return this.uploadingAttachmentsCount > 0;
  }

  @action
  incrementUploadingAttachmentsCount() {
    this.uploadingAttachmentsCount++;
  }

  @action
  decrementUploadingAttachmentsCount() {
    this.uploadingAttachmentsCount--;
  }
}
