// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {dasherize} from '@ember/string';

// Services
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';

// Types
import {
  RecordingsAggregatedSamples,
  RecordingsDatasetBuildingMetadata,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';

interface Args {
  title: string;
  name: string;
  datasetType: string;
  timeZone: string;
  recordingStartedAt: string;
  recordingEndedAt: string;
  datasetDelayInSeconds: number;
  durationInSeconds: number;
  comment: string;
  aggregatedSamples: RecordingsAggregatedSamples;
  aggregatedSamplesMainDataset?: RecordingsAggregatedSamples;
  aggregatedSamplesDuplicateDataset?: RecordingsAggregatedSamples;
  buildingMetadata: RecordingsDatasetBuildingMetadata;
  duplicateDatasetName?: string;
}

export default class RecordingsTestInformation extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @service('intl')
  intl: IntlService;

  sampleUnitTypes = RecordingsSampleUnitType;

  get hasDuplicateDataset() {
    return (
      this.args.aggregatedSamplesMainDataset &&
      this.args.aggregatedSamplesDuplicateDataset
    );
  }

  get radonUnitLabel() {
    return this.recordings.sampleUnitLabel(
      this.args.aggregatedSamples.metadata.radonUnit
    );
  }

  get datasetTypeText() {
    const key = dasherize(this.args.datasetType.toLowerCase());

    return this.intl.t(`recordings.dataset-types.${key}`);
  }
}
