export default {
  "font-bold": "700",
  "page-wrapper": "_page-wrapper_1k1b7b",
  "page": "_page_1k1b7b",
  "header": "_header_1k1b7b",
  "header-logo": "_header-logo_1k1b7b",
  "header-title": "_header-title_1k1b7b",
  "header-subtitle": "_header-subtitle_1k1b7b",
  "licenses": "_licenses_1k1b7b",
  "nsrb": "_nsrb_1k1b7b",
  "nrpp": "_nrpp_1k1b7b",
  "disclaimer": "_disclaimer_1k1b7b",
  "authority": "_authority_1k1b7b",
  "signature": "_signature_1k1b7b",
  "signature-title": "_signature-title_1k1b7b",
  "signature-line": "_signature-line_1k1b7b",
  "signature-line-signature": "_signature-line-signature_1k1b7b",
  "date": "_date_1k1b7b",
  "date-title": "_date-title_1k1b7b",
  "date-line": "_date-line_1k1b7b",
  "date-line-date": "_date-line-date_1k1b7b",
  "footer": "_footer_1k1b7b",
  "brand": "_brand_1k1b7b",
  "brand-name": "_brand-name_1k1b7b",
  "brand-address": "_brand-address_1k1b7b"
};
