export interface AdminRecordingsCalibrationCertificateStruct {
  id: string | null;
  deviceSerialNumber: string;
  activatedAt: Date | null;
  startedAt: Date | null;
  endedAt: Date | null;
  calibrationMeta:
    | FactoryCalibration
    | LabCalibration
    | OtherCalibration
    | null;
  comparisonDevices: ComparisonDevice[];
}

export interface AdminRecordingsListCalibrationCertificateStruct {
  id: string;
  uuid: string;
  deviceSerialNumber: string;
  insertedAt: string;
  activatedAt: string | null;
  expiredAt: string | null;
  calibrationMeta: FactoryCalibration | LabCalibration | OtherCalibration;
}

export interface FactoryCalibration {
  __typename?: string;
  calibrationType: CalibrationType.FACTORY;
  calibrationFactorMultiplier: string | null;
  testDuration: string | null;
  averageRadonConcentration: string | null;
  averageTemperature: string | null;
  averageRelativeHumidity: string | null;
  averageBarometricPressure: string | null;
}

export interface LabCalibration {
  __typename?: string;
  calibrationType: CalibrationType.LAB;
  calibrationFactorMultiplier: string | null;
  testDuration: string | null;
  averageRadonConcentration: string | null;
  averageTemperature: string | null;
  averageRelativeHumidity: string | null;
  initialRelativeError: string | null;
  averageReading: string | null;
  adjustedRelativeError: string | null;
  calibratorTitle: string | null;
  calibratorCertificationNumber: string | null;
  backgroundExposure: string | null;
}

export interface OtherCalibration {
  __typename?: string;
  calibrationType: CalibrationType.OTHER;
}

export interface ComparisonDevice {
  serialNumber: string;
  calibrationDate: string;
}

export enum CalibrationType {
  FACTORY = 'FACTORY',
  LAB = 'LAB',
  OTHER = 'OTHER'
}

export interface AdminRecordingsDeviceStruct {
  id: string;
  serialNumber: string;
  deletedAt: Date | null;
  company: {
    email: string;
  };
}

export interface AdminRecordingsDevicesMoveResultStruct {
  devices: AdminRecordingsDeviceStruct[];
  errors: Array<{
    deviceId: string;
    error: string;
  }>;
}

export enum AdminRecordingsDeviceMoveStep {
  SELECT_DEVICES = 'SELECT_DEVICES',
  SELECT_COMPANY = 'SELECT_COMPANY',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED'
}
