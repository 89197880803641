// Vendor
import Component from '@glimmer/component';
import {computed} from '@ember/object';
import {bool} from '@ember/object/computed';

// Types
import {ImportJobResult} from 'airthings/graphql/queries/recordings-calibration-certificates-import-job';

interface Args {
  importResult: ImportJobResult;
}

export default class FactoryImportSuccess extends Component<Args> {
  @bool('args.importResult.inserted')
  hasInsertedRows: boolean;

  @bool('args.importResult.errors.length')
  hasErrors: boolean;

  @computed('args.importResult.errors[]', function () {
    return this.args.importResult.errors.join('\n');
  })
  errors: string;
}
