export default {
  "font-demi-bold": "600",
  "block": "_block_12pf02 _light-box-wrapper_vbfmil",
  "content": "_content_12pf02 _light-box-content_vbfmil",
  "header": "_header_12pf02 _light-box-header_vbfmil",
  "print-block": "_print-block_12pf02",
  "title": "_title_12pf02 _light-box-header-title_vbfmil",
  "with-block-definition": "_with-block-definition_12pf02",
  "icon": "_icon_12pf02 _header-icon_vbfmil",
  "two-column-table": "_two-column-table_12pf02 _two-column-table_vbfmil",
  "property-header": "_property-header_12pf02 _dark-box-header_vbfmil",
  "property-header-title": "_property-header-title_12pf02 _dark-box-header-title_vbfmil",
  "same-as-customer-label": "_same-as-customer-label_12pf02"
};
