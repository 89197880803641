// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {inject as service} from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FlashMessages from 'ember-cli-flash/services/flash-messages';

// Types
import {UserRole} from 'airthings/types/account';
import {AdminAccountListDeviceStruct} from 'airthings/types/admin/account';
import {GraphqlPagination} from 'airthings/types/graphql';
import AdminAccount from 'airthings/services/airthings/admin/account';
import RouterService from '@ember/routing/router-service';

interface Args {
  devices: AdminAccountListDeviceStruct[];
  pagination: GraphqlPagination;
  searchTerm?: string;
}

export default class PageAdminDevicesIndex extends Component<Args> {
  @service('airthings/admin/account')
  adminAccount: AdminAccount;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @dropTask
  *logAsTask(device: AdminAccountListDeviceStruct, role: UserRole) {
    const {successful} = yield this.adminAccount.logAs(device.company.id, role);

    if (successful) {
      window.location.reload();
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  search(term: string) {
    this.router.replaceWith('admin.devices', {queryParams: {q: term}});
  }

  @action
  clearSearch() {
    this.router.replaceWith('admin.devices', {
      queryParams: {q: null}
    });
  }
}
