// Vendor
import Service, {inject as service} from '@ember/service';

// Config
import config from 'airthings/config/environment';

// Types
import Cookies from 'ember-cookies/services/cookies';
import Location from 'airthings/services/location';
import Apollo from 'airthings/services/apollo';
import FastBoot from 'ember-cli-fastboot/services/fastboot';

// GraphQL
import refreshTokenMutation from 'airthings/graphql/mutations/refresh-token';
import RouterService from '@ember/routing/router-service';

interface RefreshTokenMutationResult {
  refreshAccessToken: {
    result: {
      accessToken: string;
      refreshToken: string;
    };

    successful: boolean;
  };
}

export default class OAuth extends Service {
  @service('apollo')
  apollo: Apollo;

  @service('cookies')
  cookies: Cookies;

  @service('fastboot')
  fastboot: FastBoot;

  @service('location')
  location: Location;

  @service('router')
  router: RouterService;

  initiateLoginFlow() {
    const loginUrl = `${
      config.airthingsAuth.loginUrl
    }?${this.authQueryParams()}`;

    this.location.assign(loginUrl);
  }

  initiateSignUpFlow() {
    const signUpUrl = `${
      config.airthingsAuth.signUpUrl
    }?${this.authQueryParams()}`;

    this.location.assign(signUpUrl);
  }

  redirectToSignUpFlow() {
    if (!this.fastboot.isFastBoot) return;

    const signUpUrl = `${
      config.airthingsAuth.signUpUrl
    }?${this.authQueryParams()}`;

    this.fastboot.response.statusCode = 307;
    this.fastboot.response.headers.append('Location', signUpUrl);
  }

  hasToken() {
    return Boolean(this.cookies.read(config.airthingsAuth.tokenStorageKey));
  }

  retrieveRawToken() {
    const tokens = this.cookies.read(config.airthingsAuth.tokenStorageKey);
    if (!tokens) return;

    return tokens;
  }

  persistRawToken(rawToken: string) {
    this.cookies.write(config.airthingsAuth.tokenStorageKey, rawToken, {
      raw: true,
      path: '/'
    });
  }

  retrieveAccessToken() {
    const tokens = this.retrieveTokens() as {
      accessToken: string;
      refreshToken: string;
    };

    return tokens.accessToken;
  }

  persistTokens(accessToken: string, refreshToken: string) {
    const tokens = `accessToken:${accessToken},refreshToken:${refreshToken}`;

    this.persistRawToken(tokens);
  }

  clearTokens() {
    this.cookies.clear(config.airthingsAuth.tokenStorageKey, {
      path: '/'
    });
  }

  async refreshToken() {
    const refreshToken = this.retrieveRefreshToken();

    if (!refreshToken) {
      throw new Error('Unable to refresh token');
    }

    // Since our token isn’t good anymore, we clear it so that the
    // `this.account.refreshToken()` call below doesn’t use it.
    this.persistTokens('', refreshToken);

    const mutation = refreshTokenMutation;
    const variables = {refreshToken};

    let response;

    try {
      response = (await this.apollo.mutate({
        mutation,
        variables
      })) as RefreshTokenMutationResult;
    } catch (error) {
      throw new Error('Unable to refresh token');
    }

    if (
      !response.refreshAccessToken ||
      response.refreshAccessToken.successful === false
    ) {
      throw new Error('Unable to refresh token');
    }

    return {
      accessToken: response.refreshAccessToken.result.accessToken,
      refreshToken: response.refreshAccessToken.result.refreshToken
    };
  }

  retrieveRefreshToken() {
    const tokens = this.retrieveTokens() as {
      accessToken: string;
      refreshToken: string;
    };

    return tokens.refreshToken;
  }

  private retrieveTokens() {
    const tokens = this.retrieveRawToken();
    if (!tokens) return;

    return tokens.split(',').reduce((memo, keyValue) => {
      const [key, value] = keyValue.split(':');

      return {...memo, [key]: value};
    }, {});
  }

  private authQueryParams() {
    return [
      `redirect_uri=${encodeURIComponent(config.airthingsAuth.redirectUri)}`,
      `client_id=${encodeURIComponent(config.airthingsAuth.clientId)}`,
      `scope=${encodeURIComponent(config.airthingsAuth.scope)}`
    ].join('&');
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/account/oauth': OAuth;
  }
}
