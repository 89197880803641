export default {
  "sidebar": "_sidebar_e76lgu",
  "logo-wrapper": "_logo-wrapper_e76lgu",
  "logo": "_logo_e76lgu",
  "logo-link": "_logo-link_e76lgu",
  "navigation": "_navigation_e76lgu",
  "actions": "_actions_e76lgu",
  "action-logout": "_action-logout_e76lgu",
  "section-title": "_section-title_e76lgu",
  "section": "_section_e76lgu",
  "link": "_link_e76lgu"
};
