// Vendor
import Route from '@ember/routing/route';
import Can from 'ember-can/services/can';
import {inject as service} from '@ember/service';

// Services
import RouterService from '@ember/routing/router-service';

// Types
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';
import Account from 'airthings/services/airthings/account';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

export default class AppCompanySettingsIndexRoute extends Route {
  @service('airthings/account')
  account: Account;

  @service('can')
  can: Can;

  @service('router')
  router: RouterService;

  beforeModel() {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    hideIntercom();

    if (this.can.can('edit company', null, {currentUser})) {
      this.replaceWith('app.settings.dataset-settings');
    } else {
      this.replaceWith('app.settings.third-party-settings');
    }
  }
}
