// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {TaskGenerator, timeout} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

// Types
import {
  ReportsBlockStruct,
  ReportsBlockDefinition,
  ReportsTemplateStruct,
  ReportsLanguage
} from 'airthings/types/reports';
import Analytics from 'airthings/services/analytics';
import Reports from 'airthings/services/airthings/reports';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import {AccountUserStruct} from 'airthings/types/account';
import RouterService from '@ember/routing/router-service';
import {OnboardingSectionSlug} from 'airthings/types/account';

// Config
import config from 'airthings/config/environment';

// Constants
const DELETE_ANIMATION_DELAY = 500;

interface Args {
  currentUser: AccountUserStruct;
  templateId: string;
  templateName: string;
  templateDescription: string;
  templateLanguage: ReportsLanguage;
  blocks: Array<{
    id: string;
    blockDefinition: ReportsBlockDefinition;
    templateSettingsStruct: unknown;
  }>;
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageAppReportTemplatesConfigure extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/reports')
  reports: Reports;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @tracked
  stagedBlock: ReportsBlockStruct | null = null;

  @tracked
  stageAction: 'add' | 'edit' | 'delete' | null;

  @tracked
  reorderingBlocks: ReportsBlockStruct[] | null = null;

  @tracked
  deletingReportTemplate: ReportsTemplateStruct | null = null;

  get isStagedForDeletion() {
    return this.stageAction === 'delete';
  }

  get isNotStagedForDeletion() {
    return !this.isStagedForDeletion;
  }

  get stagedBlockInlineSvgPath() {
    if (!this.stagedBlock || !this.stagedBlock.key) return;

    return `report-block-icons/${this.stagedBlock.key}`;
  }

  get stagedBlockDefinition() {
    if (!this.stagedBlock || !this.stagedBlock.key) return;

    return this.reports.getTemplateBlockDefinition(this.stagedBlock.key);
  }

  get configuredBlocks(): ReportsBlockStruct[] {
    if (this.reorderingBlocks) return this.reorderingBlocks;

    return this.args.blocks.map(
      ({id, blockDefinition, templateSettingsStruct}) => {
        return {
          id,
          key: blockDefinition.key,
          templateSettingsStruct
        };
      }
    );
  }

  get hasNoConfiguredBlocks() {
    return this.configuredBlocks.length === 0;
  }

  @dropTask
  *deleteReportTemplateTask(): TaskGenerator<void> {
    if (!this.deletingReportTemplate?.id) return;

    const result = yield this.reports.deleteTemplate(
      this.deletingReportTemplate.id
    );

    this.deletingReportTemplate = null;

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('app-report-templates-configure.delete-modal.success')
      );

      this.analytics.trackReportTemplateDeletion({id: this.args.templateId});

      this.router.replaceWith('app.report-templates');
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  stageBlockForAddition(blockDefinition: ReportsBlockDefinition) {
    const block = {id: null, key: blockDefinition.key};

    this.stageAction = 'add';

    this.trackEvent('click', block);

    this.stagedBlock = {
      ...block,
      templateSettingsStruct: blockDefinition.templateSettingsStruct
        ? blockDefinition.templateSettingsStruct({})
        : null
    };
  }

  @action
  stageBlockForEdition(block: ReportsBlockStruct) {
    this.stageAction = 'edit';

    this.trackEvent('edit', block);

    this.stagedBlock = block;
  }

  @action
  stageBlockForDeletion(block: ReportsBlockStruct) {
    this.stageAction = 'delete';

    this.stagedBlock = block;
  }

  @action
  unstageBlock() {
    this.stageAction = null;
    this.stagedBlock = null;
  }

  @action
  persistSuccess() {
    this.flashMessages.success(
      this.intl.t('app-report-templates-configure.save-success')
    );

    if (this.stagedBlock) this.trackEvent('save', this.stagedBlock);

    this.unstageBlock();
  }

  @action
  reorderBlocks(reorderedBlocks: ReportsBlockStruct[]) {
    this.reorderingBlocks = reorderedBlocks;
    perform(this.persistBlocksReorderTask);
  }

  @action
  publishTemplate() {
    if (this.hasNoConfiguredBlocks) return;

    perform(this.publishTemplateTask);
  }

  @action
  initiateDeleteReportTemplate() {
    this.deletingReportTemplate = {
      id: this.args.templateId,
      name: this.args.templateName,
      description: this.args.templateDescription,
      language: this.args.templateLanguage,
      reportTemplatePublication: null
    };
  }

  @action
  cancelDeleteReportTemplate() {
    this.deletingReportTemplate = null;
  }

  @action
  cancelReportBlockPersist() {
    if (this.stagedBlock) this.trackEvent('cancel', this.stagedBlock);

    this.unstageBlock();
  }

  @dropTask
  *publishTemplateTask(): TaskGenerator<void> {
    const result = yield this.reports.publishTemplate(this.args.templateId);

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('app-report-templates-configure.publish-success')
      );

      this.analytics.trackReportTemplatePublication({id: this.args.templateId});
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *deleteBlockTask(): TaskGenerator<void> {
    if (!this.stagedBlock || !this.stagedBlock.id) return;

    const result = yield this.reports.deleteTemplateBlock(this.stagedBlock.id);

    this.trackEvent('delete', this.stagedBlock);

    this.unstageBlock();

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('app-report-templates-configure.delete-block-modal.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }

    yield timeout(DELETE_ANIMATION_DELAY);
  }

  @restartableTask
  *persistBlocksReorderTask() {
    yield timeout(config.APP.reportTemplateBlocks.reorderTimeout);

    yield this.reports.reorderTemplateBlocks(
      this.configuredBlocks,
      this.args.templateId
    );

    this.analytics.trackReportTemplateBlocksReorder({id: this.args.templateId});

    this.reorderingBlocks = null;
  }

  private trackEvent(action: string, block: {key: string}) {
    this.analytics.trackReportTemplateBlockConfigure(
      {id: this.args.templateId},
      {
        templateEditionAction: action,
        block
      }
    );
  }
}
