// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';
import move from 'ember-animated/motions/move';
import {fadeIn, fadeOut} from 'ember-animated/motions/opacity';

// Types
import {RecordingsDatasetAttachmentStruct} from 'airthings/types/recordings';
import Recordings from 'airthings/services/airthings/recordings';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import {Sprite} from 'ember-animated';
import {TaskGenerator} from 'ember-concurrency';
import {DataProxy} from '@apollo/client/core';

interface Args {
  title: string;
  datasetId: string;
  readOnly: boolean;
  attachments: RecordingsDatasetAttachmentStruct[];
  onAttachmentDelete: (store: DataProxy, attachmentId: string) => void;
  onIncrementUploadingAttachmentsCountChange: () => void;
  onDecrementUploadingAttachmentsCountChange: () => void;
}

interface TransitionParams {
  keptSprites: Sprite[];
  insertedSprites: Sprite[];
  removedSprites: Sprite[];
}

export default class RecordingsAttachments extends Component<Args> {
  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  stagedAttachmentForDeletion: RecordingsDatasetAttachmentStruct | null = null;

  @tracked
  currentTemporaryAttachmentId: number = 0;

  @tracked
  temporaryAttachmentIds: number[] = [];

  get readOnly() {
    return this.args.readOnly ?? false;
  }

  *transition({
    keptSprites,
    insertedSprites,
    removedSprites
  }: TransitionParams) {
    for (const sprite of keptSprites) {
      move(sprite);
    }

    for (const sprite of insertedSprites) {
      fadeIn(sprite);
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite);
    }
  }

  @dropTask
  *deleteAttachmentTask(): TaskGenerator<void> {
    if (!this.stagedAttachmentForDeletion) return;

    const result = yield this.recordings.deleteDatasetAttachment(
      this.stagedAttachmentForDeletion,
      this.args.onAttachmentDelete
    );

    this.stagedAttachmentForDeletion = null;

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('recordings.attachments.delete-modal.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  stageAttachmentForDeletion(attachment: RecordingsDatasetAttachmentStruct) {
    this.stagedAttachmentForDeletion = attachment;
  }

  @action
  cancelDeletion() {
    this.stagedAttachmentForDeletion = null;
  }

  @action
  addTemporaryAttachmentId() {
    this.temporaryAttachmentIds = [
      ...this.temporaryAttachmentIds,
      this.currentTemporaryAttachmentId++
    ];
  }

  @action
  handleTemporaryAttachmentUploadStart(_temporaryAttachmentId: number) {
    this.args.onIncrementUploadingAttachmentsCountChange();
  }

  @action
  handleTemporaryAttachmentUploadComplete(temporaryAttachmentId: number) {
    this.temporaryAttachmentIds = this.temporaryAttachmentIds.filter(
      (id) => id !== temporaryAttachmentId
    );

    this.args.onDecrementUploadingAttachmentsCountChange();
  }
}
