export default {
  "font-normal": "400",
  "font-medium": "500",
  "font-bold": "700",
  "link": "_link_n00bgx",
  "search-bar-button": "_search-bar-button_n00bgx",
  "name-cell": "_name-cell_n00bgx",
  "device-cell": "_device-cell_n00bgx",
  "device-name": "_device-name_n00bgx",
  "date-label": "_date-label_n00bgx",
  "dataset-name": "_dataset-name_n00bgx",
  "delete-dataset": "_delete-dataset_n00bgx",
  "delete-dataset-name": "_delete-dataset-name_n00bgx",
  "delete-dataset-device": "_delete-dataset-device_n00bgx",
  "delete-actions": "_delete-actions_n00bgx",
  "delete-action": "_delete-action_n00bgx",
  "mobile-actions": "_mobile-actions_n00bgx",
  "view-details-button": "_view-details-button_n00bgx",
  "table-row": "_table-row_n00bgx",
  "desktop-actions": "_desktop-actions_n00bgx"
};
