export default {
  "font-bold": "700",
  "container": "_container_c7rblq",
  "form-container": "_form-container_c7rblq",
  "form-block": "_form-block_c7rblq",
  "form-field": "_form-field_c7rblq",
  "errors": "_errors_c7rblq",
  "template-select-loader": "_template-select-loader_c7rblq _animations-spin_7nu7fk",
  "field-row": "_field-row_c7rblq",
  "field-label": "_field-label_c7rblq",
  "field-value": "_field-value_c7rblq",
  "time-details-title": "_time-details-title_c7rblq",
  "time-details": "_time-details_c7rblq",
  "truncate-date-fields": "_truncate-date-fields_c7rblq",
  "actions": "_actions_c7rblq",
  "action": "_action_c7rblq",
  "time-truncates": "_time-truncates_c7rblq"
};
