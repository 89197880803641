// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import IntlService from 'ember-intl/services/intl';

// Types
import {ComparisonDevice} from 'airthings/types/admin/recordings';

interface Errors {
  [field: string]: string;
}

interface Args {
  comparisonDevice: ComparisonDevice;
  onChange: (updatedComparisonDevice: ComparisonDevice, index: number) => void;
  onRemove: (index: number) => void;
  index: number;
  errors?: Errors;
}

export default class RecordingsComparisonDevicesFormItem extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get serialNumberError(): string | undefined {
    if (!this.args.errors) return;
    return this.args.errors[
      `comparison-devices.${this.args.index}.serial-number`
    ];
  }

  get calibrationDateError(): string | undefined {
    if (!this.args.errors) return;
    return this.args.errors[
      `comparison-devices.${this.args.index}.calibration-date`
    ];
  }

  get calibrationDateInputMask() {
    return {
      regex: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
      placeholder: this.intl.t(
        'recordings.calibration-certificate.comparison-devices.calibration-date.placeholder'
      )
    };
  }

  @action
  changeSerialNumber(updatedSerialNumber: string) {
    this.args.onChange(
      {
        ...this.args.comparisonDevice,
        serialNumber: updatedSerialNumber
      },
      this.args.index
    );
  }

  @action
  changeCalibrationDate(updatedCalibrationDate: string) {
    this.args.onChange(
      {
        ...this.args.comparisonDevice,
        calibrationDate: updatedCalibrationDate
      },
      this.args.index
    );
  }

  @action
  removeComparisonDevice() {
    this.args.onRemove(this.args.index);
  }
}
