// Vendor
import {helper} from '@ember/component/helper';
import stripHtml from 'string-strip-html';

export const truncateHMTL = function ([content, length]: [
  string,
  number
]): string {
  const textContent = stripHtml(content);
  const truncatedContent = textContent.substring(0, length);

  if (content.length > length) return `${truncatedContent} […]`;

  return truncatedContent;
};

export default helper(truncateHMTL);
