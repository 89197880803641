// Vendor
import Component from '@glimmer/component';

interface Args {
  reports: Array<{
    id: string;
    configuredAt: string;
    pdfFileUrl: string;
    user: {
      id: string;
      profile: {
        id: string;
        name: string;
      };
    };
  }>;
}

export default class ReportDatasetReports extends Component<Args> {}
