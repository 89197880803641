// Vendor
import Service from '@ember/service';
import {inject as service} from '@ember/service';
import config from 'airthings/config/environment';
import Cookies from 'ember-cookies/services/cookies';
import moment from 'moment';

const COOKIE_EXPIRES_HOURS = 12;

export default class Geolocator extends Service {
  @service('cookies')
  cookies: Cookies;

  /**
   * @description If field and directions are passed, store settings in cookie. Returns values from cookies.
   */
  geolocate(
    onSuccess: (latlng: google.maps.LatLng) => void,
    onError: (error: string) => void
  ) {
    const position = JSON.parse(
      this.cookies.read(config.APP.geolocationKey) || '{}'
    );

    if (position.lat && position.lng) {
      const latlng = new google.maps.LatLng(position.lat, position.lng);

      return onSuccess(latlng);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const latlng = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );

          this.cookies.write(
            config.APP.geolocationKey,
            JSON.stringify(latlng),
            {
              raw: true,
              path: '/',
              expires: moment().add(COOKIE_EXPIRES_HOURS, 'h').toDate()
            }
          );

          onSuccess(latlng);
        },
        () => {
          onError('Could not geolocate your position');
        }
      );
    } else {
      onError('Geolocation not supported');
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    geolocator: Geolocator;
  }
}
