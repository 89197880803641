export default {
  "company-list": "_company-list_1o6w67",
  "empty-search": "_empty-search_1o6w67",
  "icon": "_icon_1o6w67",
  "row": "_row_1o6w67",
  "is-active": "_is-active_1o6w67",
  "cell": "_cell_1o6w67",
  "cell-name": "_cell-name_1o6w67",
  "cell-email": "_cell-email_1o6w67",
  "input-wrapper": "_input-wrapper_1o6w67",
  "actions": "_actions_1o6w67"
};
