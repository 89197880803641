// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {restartableTask} from 'ember-concurrency-decorators';
import fetch from 'fetch';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {perform} from 'ember-concurrency-ts';
import {TaskGenerator} from 'ember-concurrency';

// Config
import config from 'airthings/config/environment';

// Types
import Account from 'airthings/services/airthings/account';

interface Args {
  onChange: (changedUrl: string, fileType: string) => void;
  onUpload?: () => void;
  helper?: string;
  isLoading?: boolean;
  accept?: string;
}

interface SignatureResponse {
  uploadSignedUrl: string;
  fileUrl: string;
}

export default class FileInput extends Component<Args> {
  @service('airthings/account')
  account: Account;

  inputElement?: HTMLInputElement;
  abortController: AbortController | null;

  @tracked
  currentFilename: string = '';

  @tracked
  hasError: boolean = false;

  @restartableTask
  *fileUploadTask(file: File): TaskGenerator<string | null> {
    this.abortCurrentTransaction();

    const signatureResponse = yield this.fetchSignedUploadURL(file);
    if (!signatureResponse) {
      this.hasError = true;
      return null;
    }

    const uploadSucceeded = yield this.uploadFile(
      file,
      signatureResponse.uploadSignedUrl
    );
    this.hasError = !uploadSucceeded;
    if (!uploadSucceeded) return null;

    return signatureResponse.fileUrl;
  }

  @action
  fileInputInsert(inputElement: HTMLInputElement) {
    this.inputElement = inputElement;
  }

  @action
  async handleFileChange(event: {target: {files: File[]}}) {
    if (typeof this.args.onUpload === 'function') this.args.onUpload();

    const file = event.target.files[0];
    this.resetInput();

    this.currentFilename = file.name;
    const fileUrl = await perform(this.fileUploadTask, file);
    if (!fileUrl) return;

    this.args.onChange(fileUrl, file.type);
  }

  private async fetchSignedUploadURL(
    file: File
  ): Promise<SignatureResponse | null> {
    const userToken = this.account.retrieveAccessToken();
    if (!userToken) return null;

    try {
      this.abortController = new AbortController();

      const response: Response = await fetch(
        config.directUpload.urlGenerationUrl,
        {
          method: 'POST',
          body: JSON.stringify({
            name: file.name
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-Authentication-Token': userToken
          },
          signal: this.abortController.signal
        }
      );

      const payload = await response.json();

      return {
        uploadSignedUrl: payload.signed_url,
        fileUrl: payload.url
      };
    } catch (error) {
      return null;
    } finally {
      this.abortController = null;
    }
  }

  private async uploadFile(
    file: File,
    uploadSignedUrl: string
  ): Promise<boolean> {
    try {
      this.abortController = new AbortController();

      const response = await fetch(uploadSignedUrl, {
        method: 'PUT',
        body: file,
        signal: this.abortController.signal
      });

      return response.ok;
    } catch (error) {
      return false;
    } finally {
      this.abortController = null;
    }
  }

  private abortCurrentTransaction() {
    if (!this.abortController) return;

    this.abortController.abort();
  }

  private resetInput() {
    if (!this.inputElement) return;

    this.inputElement.value = '';
  }
}
