// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';
import GraphQL from 'airthings/services/airthings/graphql';
import {AdminRecordingsCalibrationCertificateStruct} from 'airthings/types/admin/recordings';

// GraphQL
import adminRecordingsPaginatedCalibrationCertificates, {
  AdminRecordingsPaginatedCalibrationCertificatesVariables
} from 'airthings/graphql/queries/admin-recordings-paginated-calibration-certificates';
import adminRecordingsCreateCalibrationCertificate, {
  AdminRecordingsCreateCalibrationCertificateResponse,
  AdminRecordingsCreateCalibrationCertificateVariables
} from 'airthings/graphql/mutations/admin-recordings-create-calibration-certificate';
import adminRecordingsUpdateCalibrationCertificate, {
  AdminRecordingsUpdateCalibrationCertificateResponse,
  AdminRecordingsUpdateCalibrationCertificateVariables
} from 'airthings/graphql/mutations/admin-recordings-update-calibration-certificate';
import adminRecordingsDeleteCalibrationCertificate, {
  AdminRecordingsDeleteCalibrationCertificateResponse,
  AdminRecordingsDeleteCalibrationCertificateVariables
} from 'airthings/graphql/mutations/admin-recordings-delete-calibration-certificate';
import adminRecordingsToggleCalibrationCertificate, {
  AdminRecordingsToggleCalibrationCertificateResponse,
  AdminRecordingsToggleCalibrationCertificateVariables
} from 'airthings/graphql/mutations/admin-recordings-toggle-calibration-certificate';
import adminRecordingsEditCalibrationCertificateById, {
  AdminRecordingsEditCalibrationCertificateByIdVariables
} from 'airthings/graphql/queries/admin-recordings-edit-calibration-certificate-by-id';
import adminRecordingsCalibrationCertificateGenerationJob, {
  AdminRecordingsCalibrationCertificateGenerationJobResponse,
  AdminRecordingsCalibrationCertificateGenerationJobVariables
} from 'airthings/graphql/queries/admin-recordings-calibration-certificate-generation-job';
import adminRecordingsDeviceBySerialNumber, {
  AdminRecordingsDeviceBySerialNumberResponse,
  AdminRecordingsDeviceBySerialNumberVariables
} from 'airthings/graphql/queries/admin-recordings-device-by-serial-number';
import adminRecordingsMoveDevices, {
  AdminRecordingsMoveDevicesResponse,
  AdminRecordingsMoveDevicesVariables
} from 'airthings/graphql/mutations/admin-recordings-move-devices';

// Constants
const CALIBRATION_CERTIFICATES_PER_PAGE = 20;

export default class API extends Service {
  @service('airthings/graphql')
  graphQL: GraphQL;

  @service('apollo')
  apollo: Apollo;

  async watchPaginatedCalibrationCertificates(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    const variables: AdminRecordingsPaginatedCalibrationCertificatesVariables =
      {
        searchTerm,
        pagination: {
          page: page || 1,
          pageSize: CALIBRATION_CERTIFICATES_PER_PAGE
        }
      };

    return queryManager.watchQuery({
      query: adminRecordingsPaginatedCalibrationCertificates,
      fetchPolicy: 'no-cache',
      variables
    });
  }

  async watchEditCalibrationCertificateById(queryManager: Apollo, id: string) {
    const variables: AdminRecordingsEditCalibrationCertificateByIdVariables = {
      calibrationCertificateId: id
    };

    return queryManager.watchQuery({
      query: adminRecordingsEditCalibrationCertificateById,
      variables,
      fetchPolicy: 'no-cache'
    });
  }

  async createCalibrationCertificate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    type ReturnType =
      AdminRecordingsCreateCalibrationCertificateResponse['createCalibrationCertificate'];

    const variables: AdminRecordingsCreateCalibrationCertificateVariables = {
      calibrationCertificate: {
        deviceSerialNumber: calibrationCertificate.deviceSerialNumber,
        startedAt: calibrationCertificate.startedAt,
        endedAt: calibrationCertificate.endedAt,
        calibrationMeta: calibrationCertificate.calibrationMeta,
        comparisonDevices: calibrationCertificate.comparisonDevices
      }
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminRecordingsCreateCalibrationCertificateResponse =
        await this.apollo.mutate({
          mutation: adminRecordingsCreateCalibrationCertificate,
          variables
        });

      return response.createCalibrationCertificate;
    });
  }

  async updateCalibrationCertificate(
    calibrationCertificate: AdminRecordingsCalibrationCertificateStruct
  ) {
    if (!calibrationCertificate.id || !calibrationCertificate.calibrationMeta)
      return;

    type ReturnType =
      AdminRecordingsUpdateCalibrationCertificateResponse['updateCalibrationCertificate'];

    const {__typename, ...calibrationMeta} =
      calibrationCertificate.calibrationMeta;
    const comparisonDevices = calibrationCertificate.comparisonDevices.map(
      (comparisonDevice) => ({
        serialNumber: comparisonDevice.serialNumber,
        calibrationDate: comparisonDevice.calibrationDate
      })
    );

    const variables: AdminRecordingsUpdateCalibrationCertificateVariables = {
      calibrationCertificateId: calibrationCertificate.id,
      calibrationCertificate: {
        activatedAt: calibrationCertificate.activatedAt,
        startedAt: calibrationCertificate.startedAt,
        endedAt: calibrationCertificate.endedAt,
        calibrationMeta,
        comparisonDevices
      }
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminRecordingsUpdateCalibrationCertificateResponse =
        await this.apollo.mutate({
          mutation: adminRecordingsUpdateCalibrationCertificate,
          variables,
          refetchQueries: ['AdminRecordingsPaginatedCalibrationCertificates']
        });

      return response.updateCalibrationCertificate;
    });
  }

  async deleteCalibrationCertificate(calibrationCertificateId: string) {
    type ReturnType =
      AdminRecordingsDeleteCalibrationCertificateResponse['deleteCalibrationCertificate'];

    const variables: AdminRecordingsDeleteCalibrationCertificateVariables = {
      calibrationCertificateId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminRecordingsDeleteCalibrationCertificateResponse =
        await this.apollo.mutate({
          mutation: adminRecordingsDeleteCalibrationCertificate,
          variables,
          refetchQueries: ['AdminRecordingsPaginatedCalibrationCertificates']
        });

      return response.deleteCalibrationCertificate;
    });
  }

  async toggleCalibrationCertificate(calibrationCertificateId: string) {
    type ReturnType =
      AdminRecordingsToggleCalibrationCertificateResponse['toggleCalibrationCertificate'];

    const variables: AdminRecordingsToggleCalibrationCertificateVariables = {
      calibrationCertificateId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminRecordingsToggleCalibrationCertificateResponse =
        await this.apollo.mutate({
          mutation: adminRecordingsToggleCalibrationCertificate,
          variables,
          refetchQueries: ['AdminRecordingsPaginatedCalibrationCertificates']
        });

      return response.toggleCalibrationCertificate;
    });
  }

  async fetchCalibrationCertificateGenerationJob(jobId: string) {
    const variables: AdminRecordingsCalibrationCertificateGenerationJobVariables =
      {
        jobId
      };

    const response: AdminRecordingsCalibrationCertificateGenerationJobResponse =
      await this.apollo.query({
        query: adminRecordingsCalibrationCertificateGenerationJob,
        variables,
        fetchPolicy: 'no-cache'
      });

    return response.calibrationCertificateGenerationJob;
  }

  async fetchDeviceBySerialNumber(serialNumber: string) {
    const variables: AdminRecordingsDeviceBySerialNumberVariables = {
      serialNumber
    };

    const response: AdminRecordingsDeviceBySerialNumberResponse =
      await this.apollo.query({
        query: adminRecordingsDeviceBySerialNumber,
        variables,
        fetchPolicy: 'no-cache'
      });

    return response.admin.deviceBySerialNumber;
  }

  async moveDevices(deviceIds: string[], companyId: string) {
    type ReturnType = AdminRecordingsMoveDevicesResponse['moveDevices'];

    const variables: AdminRecordingsMoveDevicesVariables = {
      deviceIds,
      companyId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: AdminRecordingsMoveDevicesResponse =
        await this.apollo.mutate({
          mutation: adminRecordingsMoveDevices,
          variables,
          refetchQueries: ['AdminAccountPaginatedDevices']
        });

      return response.moveDevices;
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/recordings/api': API;
  }
}
