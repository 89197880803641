// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import Apollo from 'airthings/services/apollo';
import {
  DevicesSortField,
  RecordingsAttachmentType,
  RecordingsDatasetAttachmentStruct,
  RecordingsDeviceStruct,
  RecordingsEditDatasetStruct,
  RecordingsListDatasetStruct,
  RecordingsUnitSystem
} from 'airthings/types/recordings';
import {DataProxy} from '@apollo/client/core';
import GraphQL from 'airthings/services/airthings/graphql';

// GraphQL
import recordingsCompanyDevices, {
  RecordingsCompanyDevicesVariables
} from 'airthings/graphql/queries/recordings-company-devices';
import recordingsCompanyPaginatedDatasets, {
  RecordingsCompanyPaginatedDatasetsVariables
} from 'airthings/graphql/queries/recordings-company-paginated-datasets';
import recordingsDetailedDatasetByExternalId, {
  RecordingsDetailedDatasetByExternalIdVariables
} from 'airthings/graphql/queries/recordings-detailed-dataset-by-external-id';
import recordingsDeleteDeviceMutation, {
  RecordingsDeleteDeviceResponse,
  RecordingsDeleteDeviceVariables
} from 'airthings/graphql/mutations/recordings-delete-device';
import recordingsUpdateDeviceMutation, {
  RecordingsUpdateDeviceResponse,
  RecordingsUpdateDeviceVariables
} from 'airthings/graphql/mutations/recordings-update-device';
import recordingsCreateDeviceMutation, {
  RecordingsCreateDeviceResponse,
  RecordingsCreateDeviceVariables
} from 'airthings/graphql/mutations/recordings-create-device';
import recordingsDeleteDatasetMutation, {
  RecordingsDeleteDatasetResponse,
  RecordingsDeleteDatasetVariables
} from 'airthings/graphql/mutations/recordings-delete-dataset';
import recordingsDeleteAttachmentMutation, {
  RecordingsDeleteAttachmentResponse,
  RecordingsDeleteAttachmentVariables
} from 'airthings/graphql/mutations/recordings-delete-attachment';
import recordingsCreateDatasetAttachment, {
  RecordingsCreateDatasetAttachmentResponse,
  RecordingsCreateDatasetAttachmentVariables
} from 'airthings/graphql/mutations/recordings-create-dataset-attachment';
import recordingsEditDatasetByExternalId, {
  RecordingsEditDatasetByExternalIdResponse,
  RecordingsEditDatasetByExternalIdVariables
} from 'airthings/graphql/queries/recordings-edit-dataset-by-external-id';
import recordingsUpdateDatasetMutation, {
  RecordingsUpdateDatasetResponse,
  RecordingsUpdateDatasetVariables
} from 'airthings/graphql/mutations/recordings-update-dataset';
import recordingsGenerateDatasetCSV, {
  GenerateDatasetCSVVariables,
  GenerateDatasetCSVResponse
} from 'airthings/graphql/mutations/recordings-generate-dataset-csv';
import recordingsGenerateBatchCSV, {
  GenerateBatchCSVVariables,
  GenerateBatchCSVResponse
} from 'airthings/graphql/mutations/recordings-generate-batch-csv';
import recordingsGenerateDatasetWeatherReport, {
  GenerateDatasetWeatherReportVariables,
  GenerateDatasetWeatherReportResponse
} from 'airthings/graphql/mutations/recordings-generate-dataset-weather-report';
import recordingsGenerateCalibrationCertificate, {
  GenerateCalibrationCertificateVariables,
  GenerateCalibrationCertificateResponse
} from 'airthings/graphql/mutations/recordings-generate-calibration-certificate';
import recordingsImportFactoryCalibrationCertificates, {
  ImportFactoryCalibrationCertificatesVariables,
  ImportFactoryCalibrationCertificatesResponse
} from 'airthings/graphql/mutations/recordings-import-factory-calibration-certificates';
import recordingsImportLabCalibrationCertificates, {
  ImportLabCalibrationCertificatesVariables,
  ImportLabCalibrationCertificatesResponse
} from 'airthings/graphql/mutations/recordings-import-lab-calibration-certificates';
import recordingsGenerateLabImportPdf, {
  GenerateLabImportPdfVariables,
  GenerateLabImportPdfResponse
} from 'airthings/graphql/mutations/recordings-generate-lab-import-pdf';
import datasetCSVGenerationJob, {
  DatasetCSVGenerationJobVariables,
  DatasetCSVGenerationJobResponse
} from 'airthings/graphql/queries/recordings-dataset-csv-generation-job';
import datasetBatchCSVGenerationJob, {
  DatasetBatchCSVGenerationJobVariables,
  DatasetBatchCSVGenerationJobResponse
} from 'airthings/graphql/queries/recordings-dataset-batch-csv-generation-job';
import datasetWeatherReportGenerationJob, {
  DatasetWeatherReportGenerationJobVariables,
  DatasetWeatherReportGenerationJobResponse
} from 'airthings/graphql/queries/recordings-dataset-weather-report-generation-job';
import calibrationCertificateGenerationJob, {
  CalibrationCertificateGenerationJobVariables,
  CalibrationCertificateGenerationJobResponse
} from 'airthings/graphql/queries/recordings-calibration-certificate-generation-job';
import calibrationCertificateByUUID, {
  CalibrationCertificateByUUIDResponse
} from 'airthings/graphql/queries/recordings-calibration-certificate-by-uuid';
import calibrationCertificatesByImportJob, {
  CalibrationCertificatesByImportJobResponse
} from 'airthings/graphql/queries/recordings-calibration-certificates-by-import-job';
import calibrationCertificateImportJob, {
  CalibrationCertificateImportJobResponse,
  CalibrationCertificateImportJobVariables
} from 'airthings/graphql/queries/recordings-calibration-certificates-import-job';
import labImportPdfGenerationJob, {
  LabImportPdfGenerationJobResponse,
  LabImportPdfGenerationJobVariables
} from 'airthings/graphql/queries/recordings-lab-import-pdf-generation-job';
import recordingsDatasetWeatherReport, {
  RecordingsDatasetWeatherReportResponse
} from 'airthings/graphql/queries/recordings-dataset-weather-report';
import {GraphqlSortDirection} from 'airthings/types/graphql';
import recordingsCompanyDevicesWithLocation from 'airthings/graphql/queries/recordings-company-devices-with-location';
import duplicatedCompanyDatasets, {
  DuplicatedCompanyDatasetResponse,
  DuplicatedCompanyDatasetVariables
} from 'airthings/graphql/queries/duplicated-company-datasets';
import recordingsCompanyDeviceCrosschecks, {
  RecordingsCompanyDeviceCrosschecksVariables
} from 'airthings/graphql/queries/recordings-company-device-crosschecks';
import recordingsDeviceCrosschecks, {
  RecordingsDeviceCrosschecksResponse,
  RecordingsDeviceCrosschecksVariables
} from 'airthings/graphql/queries/recordings-device-crosschecks';
import recordingsGenerateCrosschecksReport, {
  GenerateCrosschecksReportResponse,
  GenerateCrosschecksReportVariables
} from 'airthings/graphql/mutations/recordings-generate-crosschecks-report';
import crosschecksReportGenerationJob, {
  CrosschecksReportGenerationJobResponse,
  CrosschecksReportGenerationJobVariables
} from 'airthings/graphql/queries/recordings-crosschecks-report-generation-job';

// Constants
const DATASETS_PER_PAGE = 10;

export default class API extends Service {
  @service('apollo')
  apollo: Apollo;

  @service('airthings/graphql')
  graphQL: GraphQL;

  async watchCompanyDevices(
    queryManager: Apollo,
    searchTerm?: string,
    sortField?: DevicesSortField,
    sortDirection?: GraphqlSortDirection
  ) {
    const variables: RecordingsCompanyDevicesVariables = {
      searchTerm
    };

    if (sortField && sortDirection) {
      variables.sort = [
        {
          field: sortField,
          direction: sortDirection
        }
      ];
    }

    return queryManager.watchQuery({
      query: recordingsCompanyDevices,
      variables
    });
  }

  async watchCompanyDevicesWithLocation(queryManager: Apollo) {
    return queryManager.watchQuery({
      query: recordingsCompanyDevicesWithLocation
    });
  }

  async watchCompanyDeviceCrosschecks(
    queryManager: Apollo,
    serialNumber: string
  ) {
    const variables: RecordingsCompanyDeviceCrosschecksVariables = {
      serialNumber,
      unitSystem: RecordingsUnitSystem.UNITED_STATES
    };

    return queryManager.watchQuery({
      query: recordingsCompanyDeviceCrosschecks,
      variables
    });
  }

  async fetchDeviceCrosschecks(uuid: string) {
    const variables: RecordingsDeviceCrosschecksVariables = {
      uuid,
      unitSystem: RecordingsUnitSystem.UNITED_STATES
    };

    const response: RecordingsDeviceCrosschecksResponse =
      await this.apollo.query({
        query: recordingsDeviceCrosschecks,
        variables
      });

    return response;
  }

  async watchCompanyPaginatedDatasets(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    const variables: RecordingsCompanyPaginatedDatasetsVariables = {
      searchTerm,
      pagination: {
        page: page || 1,
        pageSize: DATASETS_PER_PAGE
      }
    };

    return queryManager.watchQuery({
      query: recordingsCompanyPaginatedDatasets,
      variables
    });
  }

  async fetchDuplicateDatesets(datasetId: string) {
    const variables: DuplicatedCompanyDatasetVariables = {
      datasetId
    };

    const response: DuplicatedCompanyDatasetResponse = await this.apollo.query({
      query: duplicatedCompanyDatasets,
      variables
    });

    return response;
  }

  async deleteDevice(device: RecordingsDeviceStruct) {
    type ReturnType = RecordingsDeleteDeviceResponse['deleteDevice'];

    const variables: RecordingsDeleteDeviceVariables = {
      deviceId: device.id || ''
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: RecordingsDeleteDeviceResponse = await this.apollo.mutate(
        {
          mutation: recordingsDeleteDeviceMutation,
          variables,
          refetchQueries: ['RecordingsCompanyDevices']
        }
      );

      return response.deleteDevice;
    });
  }

  async updateDevice(device: RecordingsDeviceStruct) {
    type ReturnType = RecordingsUpdateDeviceResponse['updateDevice'];

    const variables: RecordingsUpdateDeviceVariables = {
      name: device.name || '',
      deviceId: device.id || ''
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: RecordingsUpdateDeviceResponse = await this.apollo.mutate(
        {
          mutation: recordingsUpdateDeviceMutation,
          variables
        }
      );

      return response.updateDevice;
    });
  }

  async createDevice(device: RecordingsDeviceStruct) {
    type ReturnType = RecordingsCreateDeviceResponse['createDevice'];

    const variables: RecordingsCreateDeviceVariables = {
      name: device.name,
      serialNumber: device.serialNumber
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: RecordingsCreateDeviceResponse = await this.apollo.mutate(
        {
          mutation: recordingsCreateDeviceMutation,
          variables,
          refetchQueries: ['RecordingsCompanyDevices']
        }
      );

      return response.createDevice;
    });
  }

  async watchDetailedDatasetByExternalId(
    externalId: string,
    unitSystem: RecordingsUnitSystem,
    queryManager: Apollo
  ) {
    const variables: RecordingsDetailedDatasetByExternalIdVariables = {
      externalId,
      unitSystem
    };

    return queryManager.watchQuery({
      query: recordingsDetailedDatasetByExternalId,
      variables
    });
  }

  async watchEditDatasetByExternalId(externalId: string, queryManager: Apollo) {
    const variables: RecordingsEditDatasetByExternalIdVariables = {
      externalId
    };

    return queryManager.watchQuery({
      query: recordingsEditDatasetByExternalId,
      variables
    });
  }

  removeDeletedAttachmentFromEditDatasetByExternalId(
    store: DataProxy,
    attachmentId: string,
    externalId: string
  ) {
    const data = store.readQuery({
      query: recordingsEditDatasetByExternalId,
      variables: {
        externalId
      }
    }) as RecordingsEditDatasetByExternalIdResponse;

    if (!data.viewer.company.datasetByExternalId) return;

    const newData = {
      ...data,
      viewer: {
        ...data.viewer,
        company: {
          ...data.viewer.company,
          datasetByExternalId: {
            ...data.viewer.company.datasetByExternalId,
            attachments:
              data.viewer.company.datasetByExternalId.attachments.filter(
                ({id}) => id !== attachmentId
              )
          }
        }
      }
    };

    store.writeQuery({
      query: recordingsEditDatasetByExternalId,
      data: newData
    });
  }

  async deleteDataset(dataset: RecordingsListDatasetStruct) {
    type ReturnType = RecordingsDeleteDatasetResponse['deleteDataset'];

    const variables: RecordingsDeleteDatasetVariables = {
      datasetId: dataset.id || ''
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: RecordingsDeleteDatasetResponse =
        await this.apollo.mutate({
          mutation: recordingsDeleteDatasetMutation,
          variables
        });

      return response.deleteDataset;
    });
  }

  async updateDataset(dataset: RecordingsEditDatasetStruct) {
    type ReturnType = RecordingsUpdateDatasetResponse['updateDataset'];

    const variables: RecordingsUpdateDatasetVariables = {
      datasetId: dataset.information.id,
      datasetType: dataset.information.datasetType,
      name: dataset.information.name,
      comment: dataset.information.comment,
      temporaryConditions: dataset.information.temporaryConditions,
      protocolViolations: dataset.information.protocolViolations,
      metadata: {
        building: dataset.buildingMetadata,
        owner: dataset.ownerMetadata,
        contact: dataset.contactMetadata,
        customer: dataset.customerMetadata,
        measurement: dataset.measurementMetadata,
        user: dataset.userMetadata
      }
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: RecordingsUpdateDatasetResponse =
        await this.apollo.mutate({
          mutation: recordingsUpdateDatasetMutation,
          variables
        });

      return response.updateDataset;
    });
  }

  async deleteDatasetAttachment(
    attachment: RecordingsDatasetAttachmentStruct,
    updateCallback?: (store: DataProxy, attachmentId: string) => void
  ) {
    type ReturnType = RecordingsDeleteAttachmentResponse['deleteAttachment'];

    const variables: RecordingsDeleteAttachmentVariables = {
      attachmentId: attachment.id
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: RecordingsDeleteAttachmentResponse =
        await this.apollo.mutate({
          mutation: recordingsDeleteAttachmentMutation,
          variables,

          update(store: DataProxy, {data: {deleteAttachment}}: any) {
            if (!deleteAttachment.successful || !updateCallback) return;

            updateCallback(store, attachment.id);
          }
        });

      return response.deleteAttachment;
    });
  }

  async createDatasetAttachment(
    datasetId: string,
    attachmentUrl: string,
    attachmentType: RecordingsAttachmentType
  ) {
    type ReturnType =
      RecordingsCreateDatasetAttachmentResponse['createDatasetAttachment'];

    const variables: RecordingsCreateDatasetAttachmentVariables = {
      datasetId,
      attachment: {
        url: attachmentUrl,
        type: attachmentType
      }
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: RecordingsCreateDatasetAttachmentResponse =
        await this.apollo.mutate({
          mutation: recordingsCreateDatasetAttachment,
          variables
        });

      return response.createDatasetAttachment;
    });
  }

  async generateDatasetCSV(datasetId: string) {
    type ReturnType = GenerateDatasetCSVResponse['generateDatasetCSV'];

    const variables: GenerateDatasetCSVVariables = {datasetId};

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: GenerateDatasetCSVResponse = await this.apollo.mutate({
        mutation: recordingsGenerateDatasetCSV,
        variables
      });

      return response.generateDatasetCSV;
    });
  }

  async generateDatasetBatchCSV(from: Date, to: Date, includeUnnamed: boolean) {
    type ReturnType = GenerateBatchCSVResponse['generateDatasetBatchCSV'];

    const variables: GenerateBatchCSVVariables = {
      from,
      to,
      includeUnnamed
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: GenerateBatchCSVResponse = await this.apollo.mutate({
        mutation: recordingsGenerateBatchCSV,
        variables
      });

      return response.generateDatasetBatchCSV;
    });
  }

  async generateWeatherReport(datasetId: string) {
    type ReturnType =
      GenerateDatasetWeatherReportResponse['generateDatasetWeatherReport'];

    const variables: GenerateDatasetWeatherReportVariables = {datasetId};

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: GenerateDatasetWeatherReportResponse =
        await this.apollo.mutate({
          mutation: recordingsGenerateDatasetWeatherReport,
          variables
        });

      return response.generateDatasetWeatherReport;
    });
  }

  async generateCalibrationCertificate(calibrationCertificateUUID: string) {
    type ReturnType =
      GenerateCalibrationCertificateResponse['generateCalibrationCertificate'];

    const variables: GenerateCalibrationCertificateVariables = {
      calibrationCertificateUUID
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: GenerateCalibrationCertificateResponse =
        await this.apollo.mutate({
          mutation: recordingsGenerateCalibrationCertificate,
          variables
        });

      return response.generateCalibrationCertificate;
    });
  }

  async importFactoryCalibrationCertificates(fileUrl: string) {
    type ReturnType =
      ImportFactoryCalibrationCertificatesResponse['importFactoryCalibrationCertificates'];

    const variables: ImportFactoryCalibrationCertificatesVariables = {
      fileUrl
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ImportFactoryCalibrationCertificatesResponse =
        await this.apollo.mutate({
          mutation: recordingsImportFactoryCalibrationCertificates,
          variables
        });

      return response.importFactoryCalibrationCertificates;
    });
  }

  async importLabCalibrationCertificates(fileUrl: string) {
    type ReturnType =
      ImportLabCalibrationCertificatesResponse['importLabCalibrationCertificates'];

    const variables: ImportLabCalibrationCertificatesVariables = {
      fileUrl
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: ImportLabCalibrationCertificatesResponse =
        await this.apollo.mutate({
          mutation: recordingsImportLabCalibrationCertificates,
          variables
        });

      return response.importLabCalibrationCertificates;
    });
  }

  async generateLabImportPdf(jobId: string) {
    type ReturnType = GenerateLabImportPdfResponse['generateLabImportPdf'];

    const variables: GenerateLabImportPdfVariables = {
      jobId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: GenerateLabImportPdfResponse = await this.apollo.mutate({
        mutation: recordingsGenerateLabImportPdf,
        variables
      });

      return response.generateLabImportPdf;
    });
  }

  async fetchDatasetCSVGenerationJob(jobId: string) {
    const variables: DatasetCSVGenerationJobVariables = {jobId};

    const response: DatasetCSVGenerationJobResponse = await this.apollo.query({
      query: datasetCSVGenerationJob,
      variables,
      fetchPolicy: 'no-cache'
    });

    return response.viewer.company.datasetCSVGenerationJob;
  }

  async fetchDatasetBatchCSVGenerationJob(jobId: string) {
    const variables: DatasetBatchCSVGenerationJobVariables = {jobId};

    const response: DatasetBatchCSVGenerationJobResponse =
      await this.apollo.query({
        query: datasetBatchCSVGenerationJob,
        variables,
        fetchPolicy: 'no-cache'
      });

    return response.viewer.company.datasetBatchCSVGenerationJob;
  }

  async fetchDatasetWeatherReportGenerationJob(jobId: string) {
    const variables: DatasetWeatherReportGenerationJobVariables = {jobId};

    const response: DatasetWeatherReportGenerationJobResponse =
      await this.apollo.query({
        query: datasetWeatherReportGenerationJob,
        variables,
        fetchPolicy: 'no-cache'
      });

    return response.viewer.company.datasetWeatherReportGenerationJob;
  }

  async fetchCalibrationCertificateGenerationJob(jobId: string) {
    const variables: CalibrationCertificateGenerationJobVariables = {jobId};

    const response: CalibrationCertificateGenerationJobResponse =
      await this.apollo.query({
        query: calibrationCertificateGenerationJob,
        variables,
        fetchPolicy: 'no-cache'
      });

    return response.viewer.company.calibrationCertificateGenerationJob;
  }

  async fetchCalibrationCertificateImportJob(jobId: string) {
    const variables: CalibrationCertificateImportJobVariables = {jobId};

    const response: CalibrationCertificateImportJobResponse =
      await this.apollo.query({
        query: calibrationCertificateImportJob,
        variables,
        fetchPolicy: 'no-cache'
      });

    return response.calibrationCertificateImportJob;
  }

  async fetchLabImportPdfGenerationJob(jobId: string) {
    const variables: LabImportPdfGenerationJobVariables = {jobId};

    const response: LabImportPdfGenerationJobResponse = await this.apollo.query(
      {
        query: labImportPdfGenerationJob,
        variables,
        fetchPolicy: 'no-cache'
      }
    );

    return response.labImportPdfGenerationJob;
  }

  async fetchCalibrationCertificateByUUID(calibrationCertificateUUID: string) {
    const variables = {uuid: calibrationCertificateUUID};

    const response: CalibrationCertificateByUUIDResponse =
      await this.apollo.query({
        query: calibrationCertificateByUUID,
        variables
      });

    return response.calibrationCertificateByUUID;
  }

  async fetchCalibrationCertificatesByImportJob(jobId: string) {
    const variables = {jobId};

    const response: CalibrationCertificatesByImportJobResponse =
      await this.apollo.query({
        query: calibrationCertificatesByImportJob,
        variables
      });

    return response.calibrationCertificatesByImportJob;
  }

  async fetchDatasetWeatherReport(uuid: string, unitSystem: string) {
    const variables = {uuid, unitSystem};

    const response: RecordingsDatasetWeatherReportResponse =
      await this.apollo.query({
        query: recordingsDatasetWeatherReport,
        variables
      });

    return response.datasetWeatherByUUID;
  }

  async generateCrosscheckReport(deviceId: string) {
    type ReturnType =
      GenerateCrosschecksReportResponse['generateCrosschecksReport'];

    const variables: GenerateCrosschecksReportVariables = {
      deviceId
    };

    return this.graphQL.runMutation<ReturnType>(async () => {
      const response: GenerateCrosschecksReportResponse =
        await this.apollo.mutate({
          mutation: recordingsGenerateCrosschecksReport,
          variables
        });

      return response.generateCrosschecksReport;
    });
  }

  async fetchCrosschecksReportGenerationJob(jobId: string) {
    const variables: CrosschecksReportGenerationJobVariables = {jobId};

    const response: CrosschecksReportGenerationJobResponse =
      await this.apollo.query({
        query: crosschecksReportGenerationJob,
        variables,
        fetchPolicy: 'no-cache'
      });

    return response.viewer.company.crosschecksReportGenerationJob;
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/recordings/api': API;
  }
}
