// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';
import Form, {Validator} from 'airthings/services/form';

export interface TemplateSettingsStruct {
  title: string;
}

export interface ReportSettingsStruct {
  includeTampering: boolean;
}

export default class ReportInformationBlock
  extends Service
  implements ReportsBlockService
{
  @service('form')
  form: Form;

  @service('intl')
  intl: IntlService;

  get definition(): Readonly<ReportsBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'detailed-measurement-data',
      get name() {
        return intl.t(
          'reports.block-definitions.detailed-measurement-data.name'
        );
      },
      get description() {
        return intl.t(
          'reports.block-definitions.detailed-measurement-data.description'
        );
      },
      get helper() {
        return intl.t(
          'reports.block-definitions.detailed-measurement-data.helper'
        );
      },
      get configurationHelper() {
        return intl.t(
          'reports.block-definitions.detailed-measurement-data.configuration-helper'
        );
      },
      lightIconPath:
        '/assets/svgs/report-block-icons/detailed-measurement-data-light.svg',
      darkIconPath:
        '/assets/svgs/report-block-icons/detailed-measurement-data-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockDetailedMeasurementData',
      componentNamespace: 'detailed-measurement-data',
      templateSettingsStruct: this.templateSettingsStruct.bind(this),
      templateSettingsValidate: this.templateSettingsValidate.bind(this),
      reportSettingsStruct: this.reportSettingsStruct.bind(this),
      reportSettingsValidate: this.reportSettingsValidate.bind(this),
      titlePreview: this.titlePreview.bind(this)
    });
  }

  private titlePreview(templateSettings: TemplateSettingsStruct) {
    return templateSettings.title;
  }

  private templateSettingsStruct(
    base: RecursivePartial<TemplateSettingsStruct>
  ): TemplateSettingsStruct {
    return {
      title:
        base.title ||
        this.intl.t(
          'reports.block-definitions.detailed-measurement-data.template-settings.title.default-value'
        )
    };
  }

  private templateSettingsValidate(
    templateSettings: TemplateSettingsStruct
  ): object | undefined {
    const validators: Array<Validator<string, 'required'>> = [
      {
        field: 'title',
        isValid: Boolean(templateSettings.title),
        code: 'required'
      }
    ];

    return this.form.validate(validators, {
      translationKeyPrefix:
        'reports.block-definitions.detailed-measurement-data.template-settings'
    });
  }

  private reportSettingsStruct(
    base: RecursivePartial<ReportSettingsStruct>
  ): ReportSettingsStruct {
    return {
      includeTampering: base.includeTampering || false
    };
  }

  private reportSettingsValidate(
    _reportSettings: ReportSettingsStruct
  ): object | undefined {
    return;
  }
}
