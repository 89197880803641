/* global require */

import ApplicationInstance from '@ember/application/instance';
import Reports from 'airthings/services/airthings/reports';
import {ReportsBlockService} from 'airthings/types/reports';

export const initialize = (appInstance: ApplicationInstance): void => {
  const reportService = appInstance.lookup(
    'service:airthings/reports'
  ) as Reports;

  // TypeScript thinks that `require` is `NodeRequire`
  // We need to ignore it to access `entries` on it.
  // @ts-ignore
  Object.keys(require.entries)
    .filter((moduleName) =>
      moduleName.startsWith(
        'airthings/services/airthings/reports/block-definitions/'
      )
    )
    .map((moduleName) => moduleName.replace('airthings/services/', ''))
    .forEach((moduleName) => {
      const blockService = appInstance.lookup(
        `service:${moduleName}`
      ) as ReportsBlockService;
      const blockName = moduleName.replace(
        'airthings/reports/block-definitions/',
        ''
      );

      reportService.registerTemplateBlockService(blockName, blockService);
    });
};

export default {
  initialize
};
