// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

// Services
import RouterService from '@ember/routing/router-service';

// Types
import {GraphqlSortDirection} from 'airthings/types/graphql';

interface Args {
  field: string;
  sort: {
    field?: string;
    direction?: GraphqlSortDirection;
  };
}

export default class SortLink extends Component<Args> {
  @service('router')
  router: RouterService;

  isSorted(direction: GraphqlSortDirection) {
    return (
      this.args.sort.field === this.args.field &&
      this.args.sort.direction === direction
    );
  }

  get isSortedAsc(): boolean {
    return this.isSorted(GraphqlSortDirection.ASC);
  }

  get isSortedDesc(): boolean {
    return this.isSorted(GraphqlSortDirection.DESC);
  }

  get otherField(): string | null {
    return this.args.sort.direction !== GraphqlSortDirection.DESC
      ? this.args.field
      : null;
  }

  get nextSortParams(): {
    sortField: string | null;
    sortDirection: GraphqlSortDirection | null;
  } {
    if (this.args.sort.field !== this.args.field) {
      return {
        sortField: this.args.field,
        sortDirection: GraphqlSortDirection.ASC
      };
    }

    if (this.args.sort.direction === GraphqlSortDirection.ASC) {
      return {
        sortField: this.args.field,
        sortDirection: GraphqlSortDirection.DESC
      };
    }

    if (this.args.sort.direction === GraphqlSortDirection.DESC) {
      return {sortField: '', sortDirection: null};
    }

    return {
      sortField: this.args.field,
      sortDirection: GraphqlSortDirection.ASC
    };
  }
}
