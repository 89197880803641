// Vendor
import Component from '@glimmer/component';
import {dropTask} from 'ember-concurrency-decorators';
import {inject as service} from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {AdminCommunicationBoardListMessageStruct} from 'airthings/types/admin/communication-board';
import {GraphqlPagination} from 'airthings/types/graphql';
import AdminCommunicationBoard from 'airthings/services/airthings/admin/communication-board';

interface Args {
  messages: AdminCommunicationBoardListMessageStruct[];
  pagination: GraphqlPagination;
}

export default class PageAdminMessagesIndex extends Component<Args> {
  @service('airthings/admin/communication-board')
  adminCommunicationBoard: AdminCommunicationBoard;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  get availableMessages() {
    return this.args.messages.filter((message) => message.deletedAt === null);
  }

  @dropTask
  *publishMessage(message: AdminCommunicationBoardListMessageStruct) {
    // eslint-disable-next-line no-alert
    if (!window.confirm(this.intl.t('admin-messages-index.publish.confirm')))
      return;

    const {successful} = yield this.adminCommunicationBoard.publishMessage(
      message.id
    );

    if (successful) {
      this.flashMessages.success(
        this.intl.t('admin-messages-index.publish.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @dropTask
  *deleteMessage(
    message: AdminCommunicationBoardListMessageStruct
  ): TaskGenerator<void> {
    // eslint-disable-next-line no-alert
    if (!window.confirm(this.intl.t('admin-messages-index.delete.confirm')))
      return;

    const result = yield this.adminCommunicationBoard.deleteMessage(message.id);

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('admin-messages-index.delete.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }
}
