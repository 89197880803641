// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

// Types
import Recordings from 'airthings/services/airthings/recordings';
import {ReportsConfigurationState} from 'airthings/types/reports';
import {
  RecordingsSampleUnit,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';

interface Args {
  reportState: ReportsConfigurationState;
}

export default class ReportsGenerationSummary extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  sampleUnitTypes = RecordingsSampleUnitType;

  get radonUnitLabel() {
    if (!this.args.reportState || !this.args.reportState.averageRadon)
      return '';

    return this.recordings.sampleUnitLabel(
      this.args.reportState.averageRadon.unit as RecordingsSampleUnit
    );
  }
}
