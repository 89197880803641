export default {
  "font-bold": "700",
  "container": "_container_11z24g",
  "success": "_success_11z24g",
  "badge": "_badge_11z24g",
  "icon": "_icon_11z24g",
  "message": "_message_11z24g",
  "actions": "_actions_11z24g",
  "action": "_action_11z24g",
  "download-button": "_download-button_11z24g"
};
