// Vendor
import {helper} from '@ember/component/helper';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';

export const dateIsPassed = function ([dateString]: [string]): boolean {
  const date = parseISO(dateString);

  return isPast(date);
};

export default helper(dateIsPassed);
