// Vendor
import Component from '@glimmer/component';
// import {tracked} from '@glimmer/tracking';
import {readOnly} from '@ember/object/computed';
import {action} from '@ember/object';

// Types
import FlashMessage from 'ember-cli-flash/flash/object';

interface Args {
  flash: FlashMessage;
}

export default class Flash extends Component<Args> {
  @readOnly('args.flash.exiting')
  isExiting: boolean;

  @action
  exitMessage() {
    this.args.flash.exitMessage();
  }
}
