// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Types
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import CommunicationBoard from 'airthings/services/airthings/communication-board';
import Account from 'airthings/services/airthings/account';
import StateManager from 'airthings/services/state-manager';
import WebviewBridge from 'airthings/services/webview-bridge';
import Apollo from 'airthings/services/apollo';

interface RouteParams {
  messageId: string;
}

export default class MobileMessageRoute extends Route {
  @service('state-manager')
  stateManager: StateManager;

  @service('router')
  router: RouterService;

  @service('webview-bridge')
  webviewBridge: WebviewBridge;

  @service('airthings/communication-board')
  communicationBoard: CommunicationBoard;

  @service('airthings/account')
  account: Account;

  @queryManager
  queryManager: Apollo;

  beforeModel(transition: Transition) {
    const isEmbedded = this.webviewBridge.isEmbedded;
    const isLoggedIn = this.account.isLoggedIn;

    if (!isEmbedded || !isLoggedIn) {
      transition.abort();

      this.router.replaceWith('app');
    }
  }

  async model(params: RouteParams) {
    const message = await this.communicationBoard.watchMessage(
      this.queryManager,
      params.messageId
    );

    return {message};
  }
}
