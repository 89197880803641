// Vendor
import {gql} from '@apollo/client/core';

export enum JobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED'
}

export interface DatasetCSVGenerationJobVariables {
  jobId: string;
}

export interface DatasetCSVGenerationJobResponse {
  viewer: {
    id: string;

    company: {
      id: string;

      datasetCSVGenerationJob: {
        id: string;
        state: JobState;

        dataset: {
          id: string;
          csvUrl?: string;
        };
      };
    };
  };
}

export default gql`
  query RecordingsDatasetCSVGenerationJob($jobId: ID!) {
    viewer {
      id

      company {
        id

        datasetCSVGenerationJob(jobId: $jobId) {
          id
          state

          dataset {
            id
            csvUrl
          }
        }
      }
    }
  }
`;
