// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {AdminCommunicationBoardMessageStruct} from 'airthings/types/admin/communication-board';
import AdminCommunicationBoard from 'airthings/services/airthings/admin/communication-board';
import {dropTask} from 'ember-concurrency-decorators';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import {AdminCommunicationBoardCreateMessageResponse} from 'airthings/graphql/mutations/admin-communication-board-create-message';

export default class PageAdminMessagesNew extends Component {
  @service('airthings/admin/communication-board')
  adminCommunicationBoard: AdminCommunicationBoard;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @tracked
  message: AdminCommunicationBoardMessageStruct = {
    id: null,
    title: '',
    content: '',
    publishedAt: null
  };

  @dropTask
  *messageCreateTask(): TaskGenerator<void> {
    return yield this.adminCommunicationBoard.createMessage(this.message);
  }

  @action
  handleChange(updatedMessage: AdminCommunicationBoardMessageStruct) {
    this.message = updatedMessage;
  }

  @action
  validate(): object | undefined {
    return this.adminCommunicationBoard.validateCreateMessage(this.message);
  }

  @action
  handleSuccess(
    result: AdminCommunicationBoardCreateMessageResponse['createMessage']
  ) {
    this.flashMessages.success(this.intl.t('admin-messages-new.form.success'));

    this.navigateToAdminMessagesEdit(result.result.id);
  }

  @action
  navigateToAdminMessagesEdit(messageId: string) {
    this.router.transitionTo('admin.messages.edit', messageId);
  }
}
