export default {
  "font-bold": "700",
  "block": "_block_ey6gu6 _light-box-wrapper_vbfmil",
  "content": "_content_ey6gu6 _light-box-content_vbfmil",
  "header": "_header_ey6gu6 _light-box-header_vbfmil",
  "with-block-definition": "_with-block-definition_ey6gu6",
  "icon": "_icon_ey6gu6 _header-icon_vbfmil",
  "title": "_title_ey6gu6 _light-box-header-title_vbfmil",
  "two-column-table": "_two-column-table_ey6gu6 _two-column-table_vbfmil"
};
