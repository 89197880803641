// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {AdminCommunicationBoardMessageStruct} from 'airthings/types/admin/communication-board';

interface Args {
  formData: AdminCommunicationBoardMessageStruct;
  onChange: (updatedFormData: AdminCommunicationBoardMessageStruct) => void;
  errors?: object;
}

export default class CommunicationBoardMessagesForm extends Component<Args> {
  @action
  changeTitle(title: string) {
    this.args.onChange({
      ...this.args.formData,
      title
    });
  }

  @action
  changeContent(content: string) {
    this.args.onChange({
      ...this.args.formData,
      content
    });
  }
}
