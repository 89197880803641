// Vendor
import Component from '@glimmer/component';

// Types
import {AdminRecordingsCalibrationCertificateStruct} from 'airthings/types/admin/recordings';

interface Args {
  calibrationCertificate: AdminRecordingsCalibrationCertificateStruct;
  hideSticker?: boolean;
}

export default class FactoryCalibrationCertificate extends Component<Args> {}
