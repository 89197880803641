// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import move from 'ember-animated/motions/move';
import {fadeIn, fadeOut} from 'ember-animated/motions/opacity';

// Types
import {Certificate} from 'airthings/types/account';
import Account from 'airthings/services/airthings/account';
import {Sprite} from 'ember-animated';

interface TransitionParams {
  keptSprites: Sprite[];
  insertedSprites: Sprite[];
  removedSprites: Sprite[];
}

interface Args {
  title: string;
  formData: Certificate[];
  onChange: (updatedCertificates: Certificate[]) => void;
  errors?: object;
}

export default class AccountCertificatesForm extends Component<Args> {
  @service('airthings/account')
  account: Account;

  *transition({
    keptSprites,
    insertedSprites,
    removedSprites
  }: TransitionParams) {
    for (const sprite of keptSprites) {
      move(sprite);
    }

    for (const sprite of insertedSprites) {
      fadeIn(sprite);
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite);
    }
  }

  @action
  changeCertificate(updatedCertificate: Certificate, index: number) {
    const certificates = [...this.args.formData];
    certificates[index] = updatedCertificate;

    this.args.onChange(certificates);
  }

  @action
  addCertificate() {
    const certificates = [...this.args.formData];
    certificates.push(this.account.certificateStruct());

    this.args.onChange(certificates);
  }

  @action
  removeCertificate(index: number) {
    const certificates = [...this.args.formData];
    certificates.splice(index, 1);

    this.args.onChange(certificates);
  }
}
