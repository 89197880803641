export default {
  "device-list": "_device-list_1dk3fm",
  "row": "_row_1dk3fm",
  "index": "_index_1dk3fm",
  "email": "_email_1dk3fm",
  "error": "_error_1dk3fm",
  "icon": "_icon_1dk3fm",
  "row-actions": "_row-actions_1dk3fm",
  "loader": "_loader_1dk3fm _animations-spin_7nu7fk",
  "input-wrapper": "_input-wrapper_1dk3fm",
  "review-error": "_review-error_1dk3fm",
  "actions": "_actions_1dk3fm"
};
