// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {
  ReportsBlockDefinition,
  ReportsBlockService
} from 'airthings/types/reports';
import IntlService from 'ember-intl/services/intl';

export default class PageBreakBlock
  extends Service
  implements ReportsBlockService
{
  @service('intl')
  intl: IntlService;

  get definition(): Readonly<ReportsBlockDefinition> {
    const intl = this.intl;

    return Object.freeze({
      key: 'page-break',
      get name() {
        return intl.t('reports.block-definitions.page-break.name');
      },
      get helper() {
        return intl.t('reports.block-definitions.page-break.helper');
      },
      lightIconPath: '/assets/svgs/report-block-icons/page-break-light.svg',
      darkIconPath: '/assets/svgs/report-block-icons/page-break-dark.svg',
      graphqlTypeName: 'ReportTemplateBlockPageBreak',
      componentNamespace: 'page-break'
    });
  }
}
