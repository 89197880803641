// Vendor
import Component from '@glimmer/component';
import {dropTask} from 'ember-concurrency-decorators';
import {inject as service} from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import {TaskGenerator} from 'ember-concurrency';

// Types
import AdminAffiliateProgram from 'airthings/services/airthings/admin/affiliate-program';
import {AdminAffiliateProgramAdStruct} from 'airthings/types/admin/affiliate-program';

interface Args {
  ads: AdminAffiliateProgramAdStruct[];
}

export default class PageAdminAffiliateProgramAdsIndex extends Component<Args> {
  @service('airthings/admin/affiliate-program')
  adminAffiliateProgram: AdminAffiliateProgram;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @dropTask
  *deleteAd(ad: AdminAffiliateProgramAdStruct): TaskGenerator<void> {
    const confirmationMessage = this.intl.t(
      'admin-affiliate-program-ads-index.delete.confirm'
    );

    // eslint-disable-next-line no-alert
    if (!ad.id || !window.confirm(confirmationMessage)) return;

    const result = yield this.adminAffiliateProgram.deleteAd(ad.id);

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('admin-affiliate-program-ads-index.delete.success')
      );
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }
}
