// Vendor
import Component from '@glimmer/component';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

interface Args {
  calibrationCertificate: {
    expiredAt: string;
  };
}

export default class CalibrationCertificateSticker extends Component<Args> {
  get nextCalibrationDate() {
    const date = parseISO(this.args.calibrationCertificate.expiredAt);

    return format(date, 'yyyy-MMM-dd');
  }
}
