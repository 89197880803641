// Vendor
import {Ability} from 'ember-can';

// Types
import {AccountUserStruct, UserRole} from 'airthings/types/account';

export default class UserAbility extends Ability {
  currentUser: AccountUserStruct;
  user: AccountUserStruct;

  get canList() {
    return this.currentUser.role === UserRole.MANAGER;
  }

  get canEdit() {
    return (
      this.currentUser.role === UserRole.MANAGER ||
      this.currentUser.id === this.user.id
    );
  }

  get canDelete() {
    return (
      this.currentUser.role === UserRole.MANAGER &&
      this.currentUser.id !== this.user.id
    );
  }
}
