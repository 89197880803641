// Vendor
import Service, {inject as service} from '@ember/service';

// Types
import {AdminAccountPaginatedCompaniesResponse} from 'airthings/graphql/queries/admin-account-paginated-companies';
import {AdminAccountPaginatedDevicesResponse} from 'airthings/graphql/queries/admin-account-paginated-devices';
import {AdminAccountPaginatedUsersResponse} from 'airthings/graphql/queries/admin-account-paginated-users';
import {AdminAccountCompanyStruct} from 'airthings/types/admin/account';
import API from 'airthings/services/airthings/admin/account/api';
import Apollo from 'airthings/services/apollo';

export default class AdminAccountCompany extends Service {
  @service('airthings/admin/account/api')
  api: API;

  // eslint-disable-next-line complexity
  struct(): AdminAccountCompanyStruct {
    return {
      information: {
        name: '',
        deletedAt: ''
      }
    };
  }

  async watchPaginatedCompanies(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.api.watchPaginatedCompanies(queryManager, page, searchTerm);
  }

  async watchPaginatedUsers(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.api.watchPaginatedUsers(queryManager, page, searchTerm);
  }

  async watchPaginatedDevices(
    queryManager: Apollo,
    page?: number,
    searchTerm?: string
  ) {
    return this.api.watchPaginatedDevices(queryManager, page, searchTerm);
  }

  async fetchCompanies(searchTerm?: string) {
    return this.api.fetchCompanies(searchTerm);
  }

  presentAccountPaginatedCompanies(
    admin: AdminAccountPaginatedCompaniesResponse['admin']
  ) {
    return {
      companies: admin.paginatedCompanies.entries,
      pagination: admin.paginatedCompanies.metadata
    };
  }

  presentAccountPaginatedUsers(
    admin: AdminAccountPaginatedUsersResponse['admin']
  ) {
    return {
      users: admin.paginatedUsers.entries,
      companies: admin.companies,
      pagination: admin.paginatedUsers.metadata
    };
  }

  presentAccountPaginatedDevices(
    admin: AdminAccountPaginatedDevicesResponse['admin']
  ) {
    return {
      devices: admin.paginatedDevices.entries,
      pagination: admin.paginatedDevices.metadata
    };
  }

  async deleteCompany(companyId: string) {
    return this.api.deleteCompany(companyId);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/account/company': AdminAccountCompany;
  }
}
