// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

// Types
import Account from 'airthings/services/airthings/account';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

export default class AppUserManagementEditRoute extends Route {
  @service('airthings/account')
  account: Account;

  beforeModel() {
    hideIntercom();
  }

  async model() {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    let user = null;

    if (currentUser.id) {
      user = await this.account.fetchUserById(currentUser.id);
    }

    return {
      user,
      currentUser
    };
  }
}
