// Vendor
import Service, {inject as service} from '@ember/service';
import Apollo from 'airthings/services/apollo';

// Types
import API from 'airthings/services/airthings/communication-board/api';
import {GraphqlPaginationInput} from 'airthings/types/graphql';
import {CommunicationBoardPaginatedMessagesResponse} from 'airthings/graphql/queries/communication-board-paginated-messages';
import {CommunicationBoardMessageByIdResponse} from 'airthings/graphql/queries/communication-board-message-by-id';

export default class CommunicationBoardMessage extends Service {
  @service('airthings/communication-board/api')
  api: API;

  async fetchPaginatedMessages(pagination: GraphqlPaginationInput) {
    return await this.api.fetchPaginatedMessages(pagination);
  }

  async watchPaginatedMessages(queryManager: Apollo, page?: number) {
    return this.api.watchPaginatedMessages(queryManager, page);
  }

  async watchMessage(queryManager: Apollo, messageId: string) {
    return this.api.watchMessage(queryManager, messageId);
  }

  async markMessageAsRead(messageId: string) {
    return this.api.markMessageAsRead(messageId);
  }

  async markMessageAsUnread(messageId: string) {
    return this.api.markMessageAsUnread(messageId);
  }

  async markMessageAsDeleted(messageId: string) {
    return this.api.markMessageAsDeleted(messageId);
  }

  presentCommunicationBoardPaginatedMessages(
    viewer: CommunicationBoardPaginatedMessagesResponse['viewer']
  ) {
    return {
      messages: viewer.paginatedMessages.entries,
      pagination: viewer.paginatedMessages.metadata
    };
  }

  presentCommunicationBoardMessageById(
    viewer: CommunicationBoardMessageByIdResponse['viewer']
  ) {
    return viewer.messageById;
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/communication-board/message': CommunicationBoardMessage;
  }
}
