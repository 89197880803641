// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';

// Services
import Recordings from 'airthings/services/airthings/recordings';
import RouterService from '@ember/routing/router-service';
import WebviewBridge from 'airthings/services/webview-bridge';

// Types
import {
  RecordingsDetailedDatasetStruct,
  RecordingsSampleUnitType,
  RecordingsUnitSystem
} from 'airthings/types/recordings';
import {AccountUserStruct} from 'airthings/types/account';
import {OnboardingSectionSlug} from 'airthings/types/account';
import {ModalSize} from 'airthings/types/modal';

interface Args {
  dataset: RecordingsDetailedDatasetStruct;
  currentUser: AccountUserStruct;
  externalId: string;
  unitSystem: RecordingsUnitSystem;
  onIncrementUploadingAttachmentsCountChange: () => void;
  onDecrementUploadingAttachmentsCountChange: () => void;
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageAppDatasetsShow extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @service('router')
  router: RouterService;

  @service('webview-bridge')
  webviewBridge: WebviewBridge;

  @tracked
  stagedDatasetForReportGeneration: RecordingsDetailedDatasetStruct | null = null;

  @tracked
  isReportModalLarge: boolean = false;

  radonShortTimeAverageSampleType =
    RecordingsSampleUnitType.RADON_SHORT_TERM_AVERAGE;
  get radonSampleUnit() {
    return this.args.dataset.hourlySamples.metadata.radonUnit;
  }

  temperatureSampleType = RecordingsSampleUnitType.TEMPERATURE;
  get temperatureSampleUnit() {
    return this.args.dataset.hourlySamples.metadata.temperatureUnit;
  }

  pressureSampleType = RecordingsSampleUnitType.PRESSURE;
  get pressureSampleUnit() {
    return this.args.dataset.hourlySamples.metadata.pressureUnit;
  }

  humiditySampleType = RecordingsSampleUnitType.HUMIDITY;
  get humiditySampleUnit() {
    return this.args.dataset.hourlySamples.metadata.humidityUnit;
  }

  get shouldDisplayHeader() {
    return !this.webviewBridge.isEmbedded;
  }

  get shouldDisplayReports() {
    return (
      !this.webviewBridge.isEmbedded && this.args.dataset.reports.length > 0
    );
  }

  @action
  editReport(dataset: RecordingsDetailedDatasetStruct) {
    this.router.transitionTo('app.datasets.edit', dataset.externalId);
  }

  @action
  generateReport(dataset: RecordingsDetailedDatasetStruct) {
    this.stagedDatasetForReportGeneration = dataset;
  }

  @action
  cancelReportGeneration() {
    this.stagedDatasetForReportGeneration = null;
  }

  @action
  setReportModalSize(size: ModalSize) {
    this.isReportModalLarge = size === ModalSize.LARGE;
  }
}
