// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {
  RecordingsCrosscheckDataset,
  RecordingsCrosscheckPairedDataset
} from 'airthings/types/recordings';

interface Args {
  onThresholdRowClicked?: () => void;
  index: number;
  dataset: RecordingsCrosscheckDataset;
  pairedDataset: RecordingsCrosscheckPairedDataset;
  interactive: boolean;
}

const RADON_DECIMALS = 2;
const RPD_DECIMALS = 3;

const AVG_THRESHOLD_1 = 2;
const AVG_THRESHOLD_2 = 4;
const CLASS_A_WARNING_THRESHOLD = 1;
const CLASS_B_DANGER_THRESHOLD = 0.67;
const CLASS_B_WARNING_THRESHOLD = 0.5;
const CLASS_C_DANGER_THRESHOLD = 0.36;
const CLASS_C_WARNING_THRESHOLD = 0.28;

export default class PageAppDevicesCrosschecks extends Component<Args> {
  get number() {
    return this.args.index + 1;
  }

  get avgA() {
    return this.args.dataset.aggregatedSamples.averageRadon;
  }

  get avgB() {
    return this.args.pairedDataset.aggregatedSamples.averageRadon;
  }

  get averageRadon() {
    return (this.avgA + this.avgB) / 2;
  }

  get roundedAverageRadon() {
    return this.averageRadon.toFixed(RADON_DECIMALS);
  }

  get rpd() {
    if (this.averageRadon === 0) return 0;

    return Math.abs(this.avgA - this.avgB) / this.averageRadon;
  }

  get roundedRpd() {
    return this.rpd.toFixed(RPD_DECIMALS);
  }

  // eslint-disable-next-line complexity
  get rowThresholdClass() {
    if (this.averageRadon >= AVG_THRESHOLD_2) {
      if (this.rpd >= CLASS_C_DANGER_THRESHOLD) return 'row-danger';
      return this.rpd >= CLASS_C_WARNING_THRESHOLD ? 'row-warning' : '';
    }

    if (this.averageRadon >= AVG_THRESHOLD_1) {
      if (this.rpd >= CLASS_B_DANGER_THRESHOLD) return 'row-danger';
      return this.rpd >= CLASS_B_WARNING_THRESHOLD ? 'row-warning' : '';
    }

    return Math.abs(this.avgA - this.avgB) > CLASS_A_WARNING_THRESHOLD
      ? 'row-warning'
      : '';
  }

  get showThresholdIcon() {
    return this.rowThresholdClass !== '';
  }

  get reportClass() {
    return this.args.interactive ? '' : 'report';
  }

  @action
  onThresholdRowClicked() {
    if (Boolean(this.rowThresholdClass) && this.args.onThresholdRowClicked) {
      this.args.onThresholdRowClicked();
    }
  }
}
