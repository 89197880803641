// Vendor
import Component from '@glimmer/component';

interface Args {
  showOnlyFirst?: boolean;
  errors?: object;
}

export default class FormErrors extends Component<Args> {
  get showOnlyFirst() {
    return this.args.showOnlyFirst || false;
  }

  get uniqueErrorMessages(): string[] {
    if (!this.args.errors) return [];

    const errorMessages = Object.values(this.args.errors);

    const filteredMessages = errorMessages.filter(
      (errorMessage: string, index: number) => {
        return errorMessages.indexOf(errorMessage) === index;
      }
    );

    if (this.showOnlyFirst) {
      return filteredMessages.slice(0, 1);
    }

    return filteredMessages;
  }
}
