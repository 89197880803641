export default {
  "font-medium": "500",
  "font-demi-bold": "600",
  "font-bold": "700",
  "dark-box-header": "_dark-box-header_1ghgfm",
  "dark-box-header-title": "_dark-box-header-title_1ghgfm",
  "light-box-wrapper": "_light-box-wrapper_1ghgfm",
  "light-box-header": "_light-box-header_1ghgfm",
  "light-box-header-title": "_light-box-header-title_1ghgfm",
  "header-icon-wrapper": "_header-icon-wrapper_1ghgfm",
  "header-icon": "_header-icon_1ghgfm",
  "light-box-content": "_light-box-content_1ghgfm",
  "cell": "_cell_1ghgfm",
  "key": "_key_1ghgfm",
  "value": "_value_1ghgfm"
};
