// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {computed} from '@ember/object';
import {bool, readOnly} from '@ember/object/computed';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';

// Services
import AccountUser from 'airthings/services/airthings/account/user';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';

// Types
import {ThirdPartySettings, ThirdPartyType} from 'airthings/types/account';

interface Args {
  formData: ThirdPartySettings[];
  onChange: (updatedFormData: ThirdPartySettings[]) => void;
  errors?: object;
}

export default class AccountThirdPartySettingsForm extends Component<Args> {
  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('airthings/account/user')
  user: AccountUser;

  @tracked
  isnConnectionAttempted: boolean = false;

  @tracked
  isnConnectionErrors: string[] = [];

  @bool('isnConnectionErrors.length')
  hasIsnConnectionErrors: boolean;

  @computed('args.formData.[]', function () {
    return this.args.formData.find(
      (settingsEntry: ThirdPartySettings) =>
        settingsEntry.thirdPartyType === ThirdPartyType.ISN
    );
  })
  isnSettings: ThirdPartySettings;

  @readOnly('isnSettings.domain')
  isnDomain: string;

  @readOnly('isnSettings.companyKey')
  isnCompanyKey: string;

  @readOnly('isnSettings.accessKey')
  isnAccessKey: string;

  @readOnly('isnSettings.secretKey')
  isnSecretKey: string;

  @computed(
    'isnDomain',
    'isnCompanyKey',
    'isnAccessKey',
    'isnSecretKey',
    function () {
      return (
        Boolean(this.isnDomain) &&
        Boolean(this.isnCompanyKey) &&
        Boolean(this.isnAccessKey) &&
        Boolean(this.isnSecretKey)
      );
    }
  )
  isnConnectionReady: boolean;

  @computed('isnConnectionAttempted', 'hasIsnConnectionErrors', function () {
    return this.isnConnectionAttempted && !this.hasIsnConnectionErrors;
  })
  isnConnectionSuccess: boolean;

  @action
  changeIsnDomain(domain: string) {
    this.args.onChange(this.changeSettings(ThirdPartyType.ISN, {domain}));
  }

  @action
  changeIsnCompanyKey(companyKey: string) {
    this.args.onChange(this.changeSettings(ThirdPartyType.ISN, {companyKey}));
  }

  @action
  changeIsnAccessKey(accessKey: string) {
    this.args.onChange(this.changeSettings(ThirdPartyType.ISN, {accessKey}));
  }

  @action
  changeIsnSecretKey(secretKey: string) {
    this.args.onChange(this.changeSettings(ThirdPartyType.ISN, {secretKey}));
  }

  changeSettings(type: ThirdPartyType, changes: {}) {
    return this.args.formData.map((settings: ThirdPartySettings) => {
      return settings.thirdPartyType === type
        ? {...settings, ...changes}
        : settings;
    });
  }

  @dropTask
  *testIsnConnectionTask(): TaskGenerator<void> {
    this.isnConnectionAttempted = false;
    this.isnConnectionErrors = [];

    const result = yield this.user.fetchIsnAccount(
      this.isnDomain,
      this.isnCompanyKey,
      this.isnAccessKey,
      this.isnSecretKey
    );

    this.isnConnectionAttempted = true;

    if (result && result.isnAccount) {
      this.isnConnectionErrors = [];
    } else {
      this.isnConnectionErrors = [
        this.intl.t('account.third-party-settings.isn-connection-error')
      ];
    }
  }
}
