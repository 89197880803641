export default {
  "font-bold": "700",
  "flash": "_flash_18wfyn _animations-fade-slide-in-from-right_7nu7fk",
  "is-exiting": "_is-exiting_18wfyn",
  "badge": "_badge_18wfyn",
  "is-success": "_is-success_18wfyn",
  "icon": "_icon_18wfyn",
  "is-danger": "_is-danger_18wfyn",
  "content": "_content_18wfyn",
  "button": "_button_18wfyn"
};
