// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';
import Can from 'ember-can/services/can';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';

// Config
import config from 'airthings/config/environment';

// Services
import Recordings from 'airthings/services/airthings/recordings';
import Apollo from 'airthings/services/apollo';
import Account from 'airthings/services/airthings/account';
import SortManager from 'airthings/services/sort-manager';

// Types
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';
import {DevicesSortField} from 'airthings/types/recordings';
import {GraphqlSortDirection} from 'airthings/types/graphql';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

interface RouteParams {
  q?: string;
  sortField?: DevicesSortField;
  sortDirection?: GraphqlSortDirection;
}

export default class AppDevicesIndexRoute extends Route {
  @service('can')
  can: Can;

  @service('router')
  router: RouterService;

  @service('airthings/account')
  account: Account;

  @service('airthings/recordings')
  recordings: Recordings;

  @service('sort-manager')
  sortManager: SortManager;

  @queryManager
  queryManager: Apollo;

  queryParams = {
    q: {
      refreshModel: true
    },
    sortField: {
      refreshModel: true
    },
    sortDirection: {
      refreshModel: true
    }
  };

  beforeModel(transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    if (this.can.cannot('list devices', null, {currentUser})) {
      transition.abort();

      this.router.replaceWith('app');
    }

    hideIntercom();
  }

  async model(params: RouteParams) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    const sort = this.sortManager.loadAndPersistSorting(
      config.APP.devicesSortKey,
      params
    );

    const devices = await this.recordings.watchCompanyDevices(
      this.queryManager,
      params.q,
      sort.field,
      sort.direction
    );

    return {
      currentUser,
      devices,
      sort,
      searchTerm: params.q
    };
  }
}
