// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';

// Types
import {AccountUserInformation} from 'airthings/types/account';

interface Args {
  title: string;
  formData: AccountUserInformation;
  onChange: (updatedFormData: AccountUserInformation) => void;
  errors?: object;
}

export default class AccountUserInformationForm extends Component<Args> {
  @action
  changePhoneNumber(updatedPhoneNumber: string): void {
    this.args.onChange({
      ...this.args.formData,
      phoneNumber: updatedPhoneNumber
    });
  }

  @action
  changeName(updatedName: string): void {
    this.args.onChange({
      ...this.args.formData,
      name: updatedName
    });
  }
}
