// Vendor
import Service from '@ember/service';
import window from 'ember-window-mock';

// Types
import {
  TriggerReportConfigurationCompletedParams,
  WebviewBridgeInterface
} from 'airthings/services/webview-bridge';

type EventHandler = (params?: string) => void;

export default class WebviewBridgeAndroid
  extends Service
  implements WebviewBridgeInterface
{
  triggerAnalyticsEvent(params: object) {
    const eventHandler = this.getEventHandler('trackAnalyticsEvent');

    eventHandler(JSON.stringify(params));
  }

  triggerReportConfigurationCompleted(
    params: TriggerReportConfigurationCompletedParams
  ) {
    const eventHandler = this.getEventHandler('onReportConfigurationCompleted');

    eventHandler(JSON.stringify(params));
  }

  triggerReportConfigurationCancel() {
    const eventHandler = this.getEventHandler('onReportConfigurationCancel');

    eventHandler();
  }

  private getEventHandler(eventName: string): EventHandler {
    if (
      !window.AirthingsMobile ||
      typeof window.AirthingsMobile[eventName] !== 'function'
    ) {
      throw new Error(
        `WebviewBridge[Android] expected window.AirthingsMobile.${eventName} to be defined.`
      );
    }

    return window.AirthingsMobile[eventName].bind(window.AirthingsMobile);
  }
}

declare module '@ember/service' {
  interface Registry {
    'webview-bridge/android': WebviewBridgeAndroid;
  }
}
