// Types
import {AvailableLocale} from 'airthings/services/translations';
import {ReportsLanguage} from 'airthings/types/reports';

// eslint-disable-next-line complexity
export default function reportsLanguageToLocale(
  language: ReportsLanguage
): AvailableLocale {
  switch (language) {
    case ReportsLanguage.DA:
      return 'da';
    case ReportsLanguage.DE:
      return 'de';
    case ReportsLanguage.EN:
      return 'en-ca';
    case ReportsLanguage.ES:
      return 'es';
    case ReportsLanguage.FI:
      return 'fi';
    case ReportsLanguage.FR:
      return 'fr';
    case ReportsLanguage.IT:
      return 'it';
    case ReportsLanguage.NO:
      return 'no';
    case ReportsLanguage.SV:
      return 'sv';
    default:
      return 'en-ca';
  }
}
