// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Services
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';

interface Args {
  importCertificates: (file: string) => {};
  onCancel: () => {};
}

export default class FactoryImportForm extends Component<Args> {
  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  fileUrl: string;

  @action
  setFile(value: string) {
    this.fileUrl = value;
  }

  @action
  importCertificates() {
    if (!this.fileUrl) return;

    this.args.importCertificates(this.fileUrl);
  }
}
