// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';
import {action} from '@ember/object';
import {TaskGenerator} from 'ember-concurrency';

// Types
import Recordings from 'airthings/services/airthings/recordings';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';

interface Args {
  temporaryAttachmentId: number;
  datasetId: string;
  onSuccess: (temporaryAttachmentId: number) => void;
  onUpload: (temporaryAttachmentId: number) => void;
}

export default class RecordingsAttachmentsForm extends Component<Args> {
  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  @dropTask
  *createAttachmentTask(
    fileUrl: string,
    fileType: string
  ): TaskGenerator<void> {
    const result = yield this.recordings.createDatasetAttachment(
      this.args.datasetId,
      fileUrl,
      fileType
    );

    if (result.successful) {
      this.flashMessages.success(
        this.intl.t('recordings.attachments.add-success')
      );
      this.args.onSuccess(this.args.temporaryAttachmentId);
    } else {
      this.flashMessages.danger(this.intl.t('general.generic-error-message'));
    }
  }

  @action
  handleUpload() {
    this.args.onUpload(this.args.temporaryAttachmentId);
  }
}
