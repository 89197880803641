// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import Reports from 'airthings/services/airthings/reports';
import {OnboardingSectionSlug} from 'airthings/types/account';

export default class AppReportTemplatesConfigureController extends Controller {
  @service('airthings/reports')
  reports: Reports;

  onboardingSectionSlugs = OnboardingSectionSlug;
}
