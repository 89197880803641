// Vendor
import Service, {inject as service} from '@ember/service';
import API from 'airthings/services/airthings/admin/recordings/api';

export default class AdminRecordingJob extends Service {
  @service('airthings/admin/recordings/api')
  api: API;

  async fetchCalibrationCertificateGenerationJob(jobId: string) {
    return this.api.fetchCalibrationCertificateGenerationJob(jobId);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/admin/recordings/job': AdminRecordingJob;
  }
}
