// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import FastBoot from 'ember-cli-fastboot/services/fastboot';
import {action} from '@ember/object';
import window from 'ember-window-mock';
import Transition from '@ember/routing/-private/transition';

// Services
import IntlService from 'ember-intl/services/intl';
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';

// Types
import {AccountCompanyStruct} from 'airthings/types/account';
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

// Config
import config from 'airthings/config/environment';

// Controller
import AppSettingsThirdPartySettingsController from 'airthings/pods/app/settings/third-party-settings/controller';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

export default class AppSettingsThirdPartySettingsRoute extends Route {
  controller: AppSettingsThirdPartySettingsController;

  @service('airthings/account')
  account: Account;

  @service('fastboot')
  fastboot: FastBoot;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  get canLeaveRoute() {
    return !this.controller.hasDirtySettings;
  }

  beforeModel() {
    hideIntercom();
  }

  async model() {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser({
      ...model.currentUser.viewer
    });

    const thirdPartySettings = await this.account.fetchThirdPartySettings();

    return {
      currentUser,
      thirdPartySettings
    };
  }

  afterModel(model: AccountCompanyStruct | null, transition: Transition) {
    if (model) return;

    transition.abort();
    this.router.replaceWith('app');
  }

  @action
  didTransition(): void {
    if (this.fastboot.isFastBoot || !config.APP.handleBeforeUnloadEvents) {
      return;
    }

    window.addEventListener(
      'beforeunload',
      this.promptUnsavedAttachmentsConfirmation.bind(this)
    );
  }

  @action
  deactivate(): void {
    if (this.fastboot.isFastBoot || !config.APP.handleBeforeUnloadEvents) {
      return;
    }

    window.removeEventListener(
      'beforeunload',
      this.promptUnsavedAttachmentsConfirmation.bind(this)
    );
  }

  @action
  willTransition(transition: Transition): void {
    if (this.canLeaveRoute) return;

    const confirmationMessage = this.intl.t(
      'app-settings-third-party-settings.leave-confirmation'
    );

    if (window.confirm(confirmationMessage)) {
      this.controller.clearSettingsDirty();
      return;
    }

    transition.abort();
  }

  private promptUnsavedAttachmentsConfirmation(event: Event) {
    if (this.canLeaveRoute) return;

    event.preventDefault();
    event.returnValue = window.confirm(
      this.intl.t('app-settings-third-party-settings.leave-confirmation')
    );
  }
}
