// Vendor
import {helper} from '@ember/component/helper';

interface Errors {
  [field: string]: string;
}

export const blockSettingsErrors = function ([errors]: [Errors?]): Errors {
  if (!errors) return {};

  return Object.keys(errors).reduce((memo: Errors, field: string) => {
    memo[field.replace(/^.+-settings\./, '')] = errors[field];
    return memo;
  }, {});
};

export default helper(blockSettingsErrors);
