// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import AdminRecordings from 'airthings/services/airthings/admin/recordings';

export default class AdminCalibrationCertificatesEditController extends Controller {
  @service('airthings/admin/recordings')
  adminRecordings: AdminRecordings;
}
