// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {timeout} from 'ember-concurrency';
import config from 'airthings/config/environment';
import {TaskGenerator} from 'ember-concurrency';
import {perform} from 'ember-concurrency-ts';

// Types
import Recordings from 'airthings/services/airthings/recordings';
import {JobState} from 'airthings/graphql/queries/reports-report-generation-job';

interface Args {
  onCancel: () => {};
}

enum ComponentState {
  FORM,
  LOADING,
  ERRORED,
  COMPLETED
}

export default class DatasetsBatchCSVGeneration extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  state: ComponentState = ComponentState.FORM;

  @tracked
  csvUrl: string;

  get isLoading() {
    return this.state === ComponentState.LOADING;
  }

  get isErrored() {
    return this.state === ComponentState.ERRORED;
  }

  get isCompleted() {
    return this.state === ComponentState.COMPLETED;
  }

  @dropTask
  *generateCSVTask(
    from: Date,
    to: Date,
    includeUnnamed: boolean
  ): TaskGenerator<string> {
    const {successful, result} = yield this.recordings.generateDatasetBatchCSV(
      from,
      to,
      includeUnnamed
    );

    if (successful) {
      return yield perform(this.pollForCSVGenerationJobTask, result?.id);
    }

    throw new Error('Unable to generate CSV');
  }

  @restartableTask
  *pollForCSVGenerationJobTask(jobId: string): TaskGenerator<string> {
    while (true) {
      const {state, csvUrl} =
        yield this.recordings.fetchDatasetBatchCSVGenerationJob(jobId);

      if (state === JobState.COMPLETED) return csvUrl;

      if (state === JobState.ERRORED) {
        throw new Error('CSV generation errored');
      }

      yield timeout(config.APP.csvGeneration.jobPollingDelay);
    }
  }

  @action
  async generateCSV(from: Date, to: Date, includeUnnamed: boolean) {
    this.state = ComponentState.LOADING;

    try {
      this.csvUrl = await perform(
        this.generateCSVTask,
        from,
        to,
        includeUnnamed
      );

      this.state = ComponentState.COMPLETED;
    } catch (error) {
      this.state = ComponentState.ERRORED;
    }
  }
}
