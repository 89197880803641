// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import StateManager from 'airthings/services/state-manager';

interface Args {
  unreadMessagesCount: number;
}

export default class SidebarUserToggleButton extends Component<Args> {
  @service('state-manager')
  stateManager: StateManager;

  get shouldHideBadge() {
    return this.args.unreadMessagesCount === 0;
  }

  @action
  openUserSidePanel() {
    this.stateManager.openUserSidebar();
  }
}
