// Vendor
import {Ability} from 'ember-can';

// Types
import {AdminAccountUserStruct} from 'airthings/types/admin/account';

export default class AdminDeviceAbility extends Ability {
  currentUser: AdminAccountUserStruct;

  get canManage() {
    return true;
  }
}
