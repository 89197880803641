// Vendor
import Component from '@glimmer/component';
import {alias} from '@ember/object/computed';
import Recordings from 'airthings/services/airthings/recordings';
import {inject as service} from '@ember/service';
import {
  RecordingsSamplesMetadata,
  RecordingsSampleStruct,
  RecordingsSampleUnitType
} from 'airthings/types/recordings';
import {ReportsBlockDefinition} from 'airthings/types/reports';

interface Args {
  blockDefinition: ReportsBlockDefinition;
  reportData: {
    samples: {
      metadata: RecordingsSamplesMetadata;
      entries: RecordingsSampleStruct[];
    };
    durationInSeconds: number;
    datasetTimeZone: string;
  };
}

export default class ReportBlockTabulatedRadonValuesPerHour extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @alias('args.reportData.samples.entries')
  samples: RecordingsSampleStruct[];

  @alias('args.reportData.samples.metadata')
  metadata: RecordingsSamplesMetadata;

  sampleUnitTypes = RecordingsSampleUnitType;

  get temperatureValues() {
    return this.samples.map((sample) => sample.temperature);
  }

  get radonValues() {
    return this.samples.map((sample) => sample.radonShortTermAverage);
  }

  get humidityValues() {
    return this.samples.map((sample) => sample.humidity);
  }

  get pressureValues() {
    return this.samples.map((sample) => sample.pressure);
  }

  get timeValues() {
    return this.samples.map((sample) => sample.recordedAt);
  }

  get temperatureUnit() {
    return this.recordings.sampleUnitLabel(this.metadata.temperatureUnit);
  }

  get pressureUnit() {
    return this.recordings.sampleUnitLabel(this.metadata.pressureUnit);
  }

  get humidityUnit() {
    return this.recordings.sampleUnitLabel(this.metadata.humidityUnit);
  }

  get radonUnit() {
    return this.recordings.sampleUnitLabel(this.metadata.radonUnit);
  }
}
