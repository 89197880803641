// Vendor
import Component from '@glimmer/component';
import {htmlSafe} from '@ember/template';

interface Args {
  content: string;
}

export default class RichText extends Component<Args> {
  get safeContent() {
    return htmlSafe(this.args.content);
  }
}
