// Vendor
import Service, {inject as service} from '@ember/service';
import API from 'airthings/services/airthings/recordings/api';

export default class CalibrationCertificate extends Service {
  @service('airthings/recordings/api')
  api: API;

  async fetchCalibrationCertificateByUUID(calibrationCertificateUUID: string) {
    return this.api.fetchCalibrationCertificateByUUID(
      calibrationCertificateUUID
    );
  }

  async generatePDF(calibrationCertificateUUID: string) {
    return this.api.generateCalibrationCertificate(calibrationCertificateUUID);
  }

  async importFactory(fileUrl: string) {
    return this.api.importFactoryCalibrationCertificates(fileUrl);
  }

  async importLab(fileUrl: string) {
    return this.api.importLabCalibrationCertificates(fileUrl);
  }

  async generateLabImportPdf(jobId: string) {
    return this.api.generateLabImportPdf(jobId);
  }

  async fetchCalibrationCertificatesByImportJob(jobId: string) {
    return this.api.fetchCalibrationCertificatesByImportJob(jobId);
  }
}

declare module '@ember/service' {
  interface Registry {
    'airthings/recordings/calibration-certificate': CalibrationCertificate;
  }
}
