// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {
  AccountUserStruct,
  AccountCompanyDatasetSettings,
  AccountCompanyStruct
} from 'airthings/types/account';
import Account from 'airthings/services/airthings/account';
import RouterService from '@ember/routing/router-service';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import {OnboardingSectionSlug} from 'airthings/types/account';

interface Args {
  currentUser: AccountUserStruct;
  company: AccountCompanyStruct;
  onboardingSectionSlug: OnboardingSectionSlug;
}

export default class PageAppSettingsDatasetSettings extends Component<Args> {
  @service('airthings/account')
  account: Account;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('router')
  router: RouterService;

  @tracked
  companyStruct: AccountCompanyStruct = this.args.company;

  @dropTask
  *companyDatasetSettingsPersistTask(): TaskGenerator<void> {
    return yield this.account.updateCompanyDatasetSettings(this.companyStruct);
  }

  @action
  changeDatasetSettings(datasetSettings: AccountCompanyDatasetSettings) {
    this.companyStruct = {
      ...this.companyStruct,
      datasetSettings
    };
  }

  @action
  handleSuccess() {
    this.flashMessages.success(
      this.intl.t('app-settings-dataset-settings.form.success')
    );
  }
}
