// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';

interface Args {
  title: string;
  subTitle?: string;
  theme: 'no-header' | 'yellow' | 'red' | 'gray';
  disableFocusTrap?: boolean;
  iconPath?: string;
  loading?: boolean;
  isLarge?: boolean;
  headerCentered?: boolean;
  onClose?: () => void;
}

export default class Modal extends Component<Args> {
  @tracked
  bodyElement: HTMLElement | null = null;

  get focusTrapEnabled() {
    return this.args.disableFocusTrap ? false : true;
  }

  get shouldDisplayHeader() {
    return this.args.theme !== 'no-header';
  }

  get shouldDisplayIcon() {
    return this.args.iconPath;
  }

  @action
  handleClose() {
    if (this.args.loading || !this.args.onClose) return;

    return this.args.onClose();
  }

  @action
  initializeBody(target: HTMLElement) {
    this.bodyElement = target;
  }

  @action
  scrollTop() {
    if (!this.bodyElement) return;

    this.bodyElement.scrollTop = 0;
  }
}
