// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import AdminAffiliateProgram from 'airthings/services/airthings/admin/affiliate-program';

export default class AdminAffiliateProgramAdsEditController extends Controller {
  @service('airthings/admin/affiliate-program')
  adminAffiliateProgram: AdminAffiliateProgram;
}
