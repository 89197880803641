// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask, restartableTask} from 'ember-concurrency-decorators';
import {TaskGenerator, timeout} from 'ember-concurrency';
import config from 'airthings/config/environment';
import {perform} from 'ember-concurrency-ts';

// Types
import Recordings from 'airthings/services/airthings/recordings';
import {
  JobState,
  ImportJobResult
} from 'airthings/graphql/queries/recordings-calibration-certificates-import-job';

interface Args {
  onCancel: () => {};
  onClose: () => {};
}

enum ImportState {
  FORM,
  LOADING,
  ERRORED,
  COMPLETED
}

export default class LabImport extends Component<Args> {
  @service('airthings/recordings')
  recordings: Recordings;

  @tracked
  state: ImportState = ImportState.FORM;

  @tracked
  importResult: ImportJobResult;

  @tracked
  pdfUrl: string;

  get isLoading() {
    return this.state === ImportState.LOADING;
  }

  get isErrored() {
    return this.state === ImportState.ERRORED;
  }

  get isCompleted() {
    return this.state === ImportState.COMPLETED;
  }

  @dropTask
  *importCertificatesTask(
    fileUrl: string
  ): TaskGenerator<{importResult: ImportJobResult; id: string}> {
    const {successful, result} =
      yield this.recordings.importLabCalibrationCertificates(fileUrl);

    if (successful) {
      return yield perform(this.pollForImportJobTask, result?.id);
    }

    throw new Error('Unable to import CSV');
  }

  @restartableTask
  *pollForImportJobTask(jobId: string): TaskGenerator<void> {
    while (true) {
      const job = yield this.recordings.fetchCalibrationCertificateImportJob(
        jobId
      );

      if (job.state === JobState.COMPLETED) return job;

      if (job.state === JobState.ERRORED) {
        throw new Error('CSV import failed');
      }

      yield timeout(config.APP.calibrationCertificateImport.jobPollingDelay);
    }
  }

  @restartableTask
  *generatePdfTask(jobId: string): TaskGenerator<string> {
    const {successful, result} = yield this.recordings.generateLabImportPdf(
      jobId
    );

    if (successful) {
      return yield perform(this.pollForPdfJobTask, result?.id);
    }

    throw new Error('Unable to generate PDF');
  }

  @restartableTask
  *pollForPdfJobTask(jobId: string): TaskGenerator<string> {
    while (true) {
      const {state, pdfUrl} =
        yield this.recordings.fetchLabImportPdfGenerationJob(jobId);

      if (state === JobState.COMPLETED) return pdfUrl;

      if (state === JobState.ERRORED) {
        throw new Error('PDF generation failed');
      }

      yield timeout(config.APP.reportGeneration.jobPollingDelay);
    }
  }

  @action
  async importCertificates(fileUrl: string) {
    this.state = ImportState.LOADING;

    try {
      const importJob = await perform(this.importCertificatesTask, fileUrl);

      this.importResult = importJob.importResult;

      this.pdfUrl = await perform(this.generatePdfTask, importJob.id);

      this.state = ImportState.COMPLETED;
    } catch (error) {
      this.state = ImportState.ERRORED;
    }
  }
}
