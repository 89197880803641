// Vendor
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {dropTask} from 'ember-concurrency-decorators';
import {TaskGenerator} from 'ember-concurrency';

// Types
import {AccountUserStruct} from 'airthings/types/account';
import {CommunicationBoardMessageStruct} from 'airthings/types/communication-board';
import {GraphqlPagination} from 'airthings/types/graphql';
import CommunicationBoard from 'airthings/services/airthings/communication-board';
import RouterService from '@ember/routing/router-service';

interface Args {
  currentUser: AccountUserStruct;
  currentMessage: CommunicationBoardMessageStruct | null;
  messages: CommunicationBoardMessageStruct[];
  pagination: GraphqlPagination;
}

const MESSAGE_EXCERPT_LENGTH = 250;

export default class PageAppMessagesIndex extends Component<Args> {
  @service('airthings/communication-board')
  communicationBoard: CommunicationBoard;

  @service('router')
  router: RouterService;

  messageExcerptLength = MESSAGE_EXCERPT_LENGTH;

  get availableMessages() {
    return this.args.messages.filter((message) => message.deletedAt === null);
  }

  @dropTask
  *markMessageAsReadTask(
    message: CommunicationBoardMessageStruct
  ): TaskGenerator<void> {
    return yield this.communicationBoard.markMessageAsRead(message.id);
  }

  @dropTask
  *markMessageAsUnreadTask(
    message: CommunicationBoardMessageStruct
  ): TaskGenerator<void> {
    return yield this.communicationBoard.markMessageAsUnread(message.id);
  }

  @dropTask
  *markMessageAsDeletedTask(
    message: CommunicationBoardMessageStruct
  ): TaskGenerator<void> {
    return yield this.communicationBoard.markMessageAsDeleted(message.id);
  }
}
