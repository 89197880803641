// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import addYears from 'date-fns/addYears';

// Services
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import Recordings from 'airthings/services/airthings/recordings';

interface Args {
  generateCSV: (from: Date, to: Date, includeUnnamed: boolean) => {};
  onCancel: () => {};
}

export default class DatasetsBatchCSVGenerationForm extends Component<Args> {
  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @service('airthings/recordings')
  recordings: Recordings;

  get minFrom() {
    return this.to ? addYears(this.to, -1) : null;
  }

  get maxTo() {
    return this.from ? addYears(this.from, 1) : null;
  }

  @tracked
  from: Date | null = null;

  @tracked
  to: Date | null = null;

  @tracked
  includeUnnamed: boolean = false;

  @action
  setFrom(value: Date) {
    this.from = value;
  }

  @action
  setTo(value: Date) {
    this.to = value;
  }

  @action
  setIncludeUnnamed(value: boolean) {
    this.includeUnnamed = value;
  }

  @action
  generateCSV() {
    if (!this.from || !this.to) return;

    this.args.generateCSV(this.from, this.to, this.includeUnnamed);
  }
}
