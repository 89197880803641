// Vendor
import Service from '@ember/service';

// Types
import {
  TriggerReportConfigurationCompletedParams,
  WebviewBridgeInterface
} from 'airthings/services/webview-bridge';

export default class WebviewBridgeDefault
  extends Service
  implements WebviewBridgeInterface
{
  triggerAnalyticsEvent(_params: object) {
    return;
  }

  triggerReportConfigurationCompleted(
    _params: TriggerReportConfigurationCompletedParams
  ) {
    return;
  }

  triggerReportConfigurationCancel() {
    return;
  }
}

declare module '@ember/service' {
  interface Registry {
    'webview-bridge/default': WebviewBridgeDefault;
  }
}
