// Vendor
import {inject as service} from '@ember/service';
import Router from '@ember/routing/router-service';
import Route from '@ember/routing/route';

export default class IndexRoute extends Route {
  @service router: Router;

  beforeModel() {
    this.router.replaceWith('app.datasets');
  }
}
