// Vendor
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';

// Types
import IntlService from 'ember-intl/services/intl';
import {ChoiceButtonsOption} from 'airthings/pods/components/form/choice-buttons/component';
import {UserRole} from 'airthings/types/account';

interface Args {
  formData: UserRole;
  onChange: (updatedFormData: UserRole) => void;
  errors?: object;
}

// Constants
const ROLE_OPTIONS = [
  {
    labelKey: 'account.user-role-form.role.employee',
    value: 'EMPLOYEE'
  },
  {
    labelKey: 'account.user-role-form.role.manager',
    value: 'MANAGER'
  }
];

export default class AccountUserRoleForm extends Component<Args> {
  @service('intl')
  intl: IntlService;

  get roleOptions(): ChoiceButtonsOption[] {
    return ROLE_OPTIONS.map(({labelKey, value}) => ({
      value,
      label: this.intl.t(labelKey)
    }));
  }
}
