export default {
  "font-bold": "700",
  "block": "_block_dwjxk0 _light-box-wrapper_vbfmil",
  "content": "_content_dwjxk0 _light-box-content_vbfmil",
  "certification-content": "_certification-content_dwjxk0 _certification-content_vbfmil",
  "header": "_header_dwjxk0 _light-box-header_vbfmil",
  "with-block-definition": "_with-block-definition_dwjxk0",
  "icon": "_icon_dwjxk0 _header-icon_vbfmil",
  "title": "_title_dwjxk0 _light-box-header-title_vbfmil",
  "with-certification-icon": "_with-certification-icon_dwjxk0",
  "two-column-table": "_two-column-table_dwjxk0 _two-column-table_vbfmil",
  "certification-header": "_certification-header_dwjxk0",
  "certification-icon": "_certification-icon_dwjxk0",
  "certification-column": "_certification-column_dwjxk0 _certification-column_vbfmil",
  "certification-item": "_certification-item_dwjxk0 _certification-item_vbfmil",
  "certification-item-label": "_certification-item-label_dwjxk0 _certification-item-label_vbfmil",
  "certification-item-value": "_certification-item-value_dwjxk0 _certification-item-value_vbfmil"
};
