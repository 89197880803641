export default {
  "font-bold": "700",
  "container": "_container_fb9yrn",
  "success": "_success_fb9yrn",
  "badge": "_badge_fb9yrn",
  "icon": "_icon_fb9yrn",
  "message": "_message_fb9yrn",
  "actions": "_actions_fb9yrn",
  "action": "_action_fb9yrn",
  "download-button": "_download-button_fb9yrn"
};
