// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';

// Services
import Account from 'airthings/services/airthings/account';
import FastBoot from 'ember-cli-fastboot/services/fastboot';

// Types
import {OnboardingSectionSlug} from 'airthings/types/account';

export default class AppSettingsThirdPartySettingsController extends Controller {
  @service('airthings/account')
  account: Account;

  @service('fastboot')
  fastboot: FastBoot;

  onboardingSectionSlugs = OnboardingSectionSlug;

  @tracked
  hasDirtySettings: boolean = false;

  @action
  setSettingsDirty() {
    this.hasDirtySettings = true;
  }

  @action
  clearSettingsDirty() {
    this.hasDirtySettings = false;
  }
}
