// Vendor
import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

// Types
import Recordings from 'airthings/services/airthings/recordings';
import {OnboardingSectionSlug} from 'airthings/types/account';

export default class AppDevicesIndexController extends Controller {
  @service('airthings/recordings')
  recordings: Recordings;

  onboardingSectionSlugs = OnboardingSectionSlug;
}
