// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';
import Can from 'ember-can/services/can';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';

// Services
import Recordings from 'airthings/services/airthings/recordings';
import Apollo from 'airthings/services/apollo';
import Account from 'airthings/services/airthings/account';

// Types
import {AccountCurrentUserProfileResponse} from 'airthings/graphql/queries/account-current-user-profile';

// Utils
import {hideIntercom} from 'airthings/utils/intercom';

interface RouteParams {
  serialNumber: string;
}

export default class AppDevicesCrosschecksRoute extends Route {
  @service('can')
  can: Can;

  @service('router')
  router: RouterService;

  @service('airthings/account')
  account: Account;

  @service('airthings/recordings')
  recordings: Recordings;

  @queryManager
  queryManager: Apollo;

  beforeModel(transition: Transition) {
    const model = this.modelFor('app') as {
      currentUser: AccountCurrentUserProfileResponse;
    };

    const currentUser = this.account.presentCurrentUser(
      model.currentUser.viewer
    );

    if (this.can.cannot('list devices', null, {currentUser})) {
      transition.abort();

      this.router.replaceWith('app');
    }

    hideIntercom();
  }

  async model(params: RouteParams) {
    const currentUser = await this.account.watchCurrentUser(this.queryManager);

    const result = await this.recordings.watchCompanyDeviceCrosschecks(
      this.queryManager,
      params.serialNumber
    );

    return {
      currentUser,
      result
    };
  }
}
