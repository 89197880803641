// Vendor
import {helper} from '@ember/component/helper';

// Constants
const HOUR_IN_SECONDS = 3600;
const MINUTE_IN_SECONDS = 60;

export const formatSeconds = function ([seconds]: [number]): string {
  const hours = Math.floor(seconds / HOUR_IN_SECONDS);
  seconds -= hours * HOUR_IN_SECONDS;

  const minutes = Math.floor(seconds / MINUTE_IN_SECONDS);
  seconds -= minutes * MINUTE_IN_SECONDS;

  const parts = [];
  if (hours) parts.push(`${hours}h`);

  return parts.join(' ');
};

export default helper(formatSeconds);
