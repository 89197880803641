// Vendor
import Component from '@glimmer/component';
import {computed} from '@ember/object';
import {AdminRecordingsCalibrationCertificateStruct} from 'airthings/types/admin/recordings';

interface Args {
  calibrationCertificate: AdminRecordingsCalibrationCertificateStruct;
  hideSticker?: boolean;
}

export default class PageCalibrationCertificate extends Component<Args> {
  @computed(
    'args.calibrationCertificate.calibrationMeta.calibrationType',
    function () {
      return `calibration-certificate/${this.args.calibrationCertificate.calibrationMeta.calibrationType.toLowerCase()}-calibration-certificate`;
    }
  )
  certificateComponent: string;
}
