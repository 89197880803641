// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Types
import {AccountOnboardingStep} from 'airthings/types/account';
import RouterService from '@ember/routing/router-service';

// Constants
const REMINDER_DELAY_IN_DAYS = 30;

interface Args {
  onDismiss: (reminderDelayInDays?: number) => Promise<any>;
  onboardingStep: AccountOnboardingStep;
}

export default class AccountOnboardingModalStepsAffiliateProgram extends Component<Args> {
  @service('router')
  router: RouterService;

  get isReminder() {
    return this.args.onboardingStep.isReminder;
  }

  @action
  async handleDismiss() {
    if (this.isReminder) return this.args.onDismiss();

    await this.args.onDismiss(REMINDER_DELAY_IN_DAYS);
  }

  @action
  async handleAction() {
    await this.handleDismiss();

    this.router.transitionTo('app.affiliate-program');
  }
}
