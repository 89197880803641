// Vendor
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

// Config
import config from 'airthings/config/environment';

// Types
import Account from 'airthings/services/airthings/account';
import Analytics from 'airthings/services/analytics';
import {AccountUserStruct} from 'airthings/types/account';
import StateManager from 'airthings/services/state-manager';

interface Args {
  currentUser: AccountUserStruct;
  onToggle: () => void;
  isOpen: boolean;
}

export default class SidebarMenu extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @service('state-manager')
  stateManager: StateManager;

  appVersion = config.APP.version;

  get isOpened() {
    return this.stateManager.mobileMenuIsOpened;
  }

  get shouldDisplayLogAsNotice() {
    return (
      this.stateManager.mobileMenuIsOpened && this.args.currentUser.isAdmin
    );
  }

  @action
  toggle() {
    this.stateManager.toggleMobileMenu();
  }

  @action
  handleLinkClick(section: string) {
    this.analytics.trackMainNavigation(section);

    this.stateManager.closeMobileMenu();
  }
}
