// Vendor
import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import {queryManager} from 'ember-apollo-client';

// Utils
import parsePageParam from 'airthings/utils/parse-page-param';

// Types
import AdminRecordings from 'airthings/services/airthings/admin/recordings';
import Apollo from 'airthings/services/apollo';

interface RouteParams {
  page?: string;
  q?: string;
}

export default class AdminCalibrationCertificatesIndexRoute extends Route {
  @service('airthings/admin/recordings')
  adminRecordings: AdminRecordings;

  @queryManager
  queryManager: Apollo;

  queryParams = {
    page: {
      refreshModel: true
    },
    q: {
      refreshModel: true
    }
  };

  async model(params: RouteParams) {
    const calibrationCertificates =
      await this.adminRecordings.watchPaginatedCalibrationCertificates(
        this.queryManager,
        parsePageParam(params),
        params.q
      );

    return {
      calibrationCertificates,
      searchTerm: params.q
    };
  }
}
