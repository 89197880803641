interface Params {
  page?: string;
}

export default function parsePageParam(params: Params): number | undefined {
  if (!params.page) return;

  const page = parseInt(params.page, 10);
  if (!page) return;

  return page;
}
