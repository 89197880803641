// Vendor
import Component from '@glimmer/component';

// Types
import {RecordingsDatasetCustomerMetadata} from 'airthings/types/recordings';

interface Args {
  measurementMetadata: RecordingsDatasetCustomerMetadata;
}

export default class PropertyInformation extends Component<Args> {
  get formattedAddress() {
    return [
      this.args.measurementMetadata.address,
      this.args.measurementMetadata.city,
      [
        this.args.measurementMetadata.state,
        this.args.measurementMetadata.postalCode
      ]
        .filter((value) => value)
        .join(' '),
      this.args.measurementMetadata.country
    ]
      .filter((value) => value)
      .join(', ');
  }
}
