export default {
  "page": "_page_gpzlz1",
  "sticker": "_sticker_gpzlz1",
  "sticker-header": "_sticker-header_gpzlz1",
  "sticker-header-logo": "_sticker-header-logo_gpzlz1",
  "sticker-content": "_sticker-content_gpzlz1",
  "sticker-info": "_sticker-info_gpzlz1",
  "sticker-key": "_sticker-key_gpzlz1",
  "sticker-value": "_sticker-value_gpzlz1"
};
