// Vendor
import {Ability} from 'ember-can';

// Types
import {AdminAccountUserStruct} from 'airthings/types/admin/account';

export default class AdminCalibrationCertificateAbility extends Ability {
  currentUser: AdminAccountUserStruct;

  get canManage() {
    return this.currentUser.accessRights.calibrationCertificates;
  }
}
